<div mat-dialog-title>
    <div class="title-container">
        <div class="title-left">
            <button #closeBtn type="button" class="close-button" mat-icon-button aria-label="Close" mat-dialog-close>
                <mat-icon>close</mat-icon>
            </button>
            <h1>Filters</h1>
        </div>
        <button type="button" class="save-button" mat-icon-button aria-label="Close" (click)="onSave()">
            <mat-icon color="primary">check</mat-icon>
        </button>
    </div>
</div>
<div mat-dialog-content>
    <div class="content-container">
        <div class="filters-row">
            <div class="input-field border-light-bottom">
                <wtls-text-field placeholder="Name" [(value)]="filterData.name"></wtls-text-field>
            </div>
        </div>
        <div class="filters-row">
            <div class="input-field border-light-bottom">
                <wtls-text-field placeholder="Address" [(value)]="filterData.address"></wtls-text-field>
            </div>
        </div>
        <div class="filters-row">
            <div class="input-field border-light-bottom">
                <wtls-text-field placeholder="City" [(value)]="filterData.city"></wtls-text-field>
            </div>
        </div>
        <div class="filters-row">
            <div class="input-field">
                <wtls-dropdown placeholder="State" [options]="stateOptions" [(value)]="filterData.state"></wtls-dropdown>
            </div>
            <div class="input-field border-light-bottom">
                <wtls-text-field placeholder="Zip" [(value)]="filterData.zip"></wtls-text-field>
            </div>
        </div>
        <div class="filters-row">
            <div class="input-field">
                <wtls-dropdown placeholder="Clinic Location" [options]="clinicLocationOptions" [(value)]="filterData.clinicLocation"></wtls-dropdown>
            </div>
        </div>
    </div>
</div>