<div class="record-container border-light-bottom">
  <div class="record-column">
    <div class="record-preview-container">
      <div class="radio-button-column">
        <div class="radio-button-wrapper">
          <mat-radio-button
            [checked]="checked"
            [value]="patient"
            color="primary"
            (click)="changePatient($event)"
          ></mat-radio-button>
        </div>
      </div>
      <div class="arrow-icon-wrapper">
        <button
          type="button"
          mat-icon-button
          aria-label="Edit Filters"
          (click)="toggleExpanded()"
        >
          <mat-icon *ngIf="!(isExpanded | async)">chevron_right</mat-icon>
          <mat-icon *ngIf="isExpanded | async">expand_more</mat-icon>
        </button>
      </div>
      <div class="record-preview">
        <h4 *ngIf="!patient.name" class="record-name"><em>No Name</em></h4>
        <h4 *ngIf="patient.name" class="record-name">{{ patient.name }}</h4>
        <span *ngIf="!patient.address.street"
          ><em>No Address Available</em></span
        >
        <span
          *ngIf="patient.address.street && !(isExpanded | async)"
          class="record-address"
          >{{
            patient.address.street +
              ", " +
              patient.address.city +
              " " +
              patient.address.state +
              " " +
              patient.address.zip | truncateEllipsis: 24
          }}</span
        >
        <span
          *ngIf="patient.address.street && (isExpanded | async)"
          class="record-address"
          >{{
            patient.address.street +
              ", " +
              patient.address.city +
              " " +
              patient.address.state +
              " " +
              patient.address.zip
          }}</span
        >
      </div>
    </div>
    <div *ngIf="isExpanded | async" class="record-details">
      <div class="border-light-top"></div>
      <div class="record-details-row">
        <div class="record-details-column">
          <h5>Age:</h5>
          <span>{{ patient.age | number  }}</span>
        </div>
      </div>
      <div class="record-details-row">
        <div class="record-details-column">
          <h5>Clinic Location:</h5>
          <span>{{ patient.clinicLocation }}</span>
        </div>
      </div>
      <div class="record-details-row">
        <div class="record-details-column">
          <h5>Schedule:</h5>
          <div class="spinner-wrapper" *ngIf="scheduleLoading">
            <mat-progress-spinner
              color="primary"
              mode="indeterminate"
              diameter="30"
            >
            </mat-progress-spinner>
          </div>
          <span *ngIf="!scheduleLoading && !availability[0]"
            ><em>No Schedule</em></span
          >
         
          <span  *ngIf="!scheduleLoading && availability[0]" >
            <table>
              <tr *ngFor="let time of display_time; index as i">
                <span>{{days[i]}}</span>
                <span>{{time}}</span>&nbsp;
                <!-- <span class ="dot" [ngClass]="{
                  'dot-red' : availability_status[i] == 0,
                  'dot-green' : availability_status[i] == 2,
                  'dot-yellow' : availability_status[i] == 1}">
                </span> -->
              </tr>
            </table>
            <!-- <table>
            <span *ngFor="let time of display_time; index as i"> 
              <tr>
                {{ time }}
                {{ time.dayOfWeek }} {{time.startTime}} {{ time.endTime }}
              </tr>
              <tr class ="dot" [ngClass]="{
                'dot-red' : availability_status[i] == 0,
                'dot-green' : availability_status[i] == 2,
                'dot-yellow' : availability_status[i] == 1}">
              </tr>
            </span>
            </table> -->
          </span>

         
        </div>
     
      </div>
      <div class="record-details-row">
        <div class="record-details-column">
          <h5>Client Type:</h5>
          <span>{{ patient.clientType }}</span>
        </div>
      </div>
      <div class="record-details-row">
        <div class="record-details-column">
          <h5>Insurance Type:</h5>
          <span>{{ patient.insuranceType }}</span>
        </div>
      </div>
      <div class="record-details-row">
        <div class="record-details-column">
          <h5>Intake Stage:</h5>
          <span>{{ patient.intakeStage }}</span>
        </div>
      </div>
      <!---------code start-->
      <!-- <div class="record-details-row">
        <div class="record-details-column">
          <h5>onschedId:</h5>
       <span>{{ patient.onschedId }}</span>
        </div>
      </div> -->

      <!---- code------->
      <div class="record-details-row">
        <div class="record-details-column">
          <div class="button-group">
            <!-- button
              type="button"
              mat-stroked-button
              (click)="edit.emit(fullPatient)"
            >
              Edit222
            </button -->
            <!-- button
              type="button"
              mat-stroked-button
              (click)="delete.emit(patient)"
            >
              Delete
            </button -->

        </div>
      </div>
    </div>
  </div>
</div>
