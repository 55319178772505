import { Injectable } from '@angular/core';
import { catchError, mergeMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { ProvidersService } from 'src/app/services/providers/providers.service';
import { ProvidersActions } from '../actions';

@Injectable({
    providedIn: 'root'
})
export class ProvidersEffects {

    // loadProviders$ = createEffect(() => 
    //     this.actions$.pipe(
    //         ofType(ProvidersActions.loadProviders),
    //         mergeMap(action => this.providersService.getProviders(action.options).pipe(
    //             map(providers => ProvidersActions.loadProvidersSuccess({ providers })),
    //             catchError(error => {
    //                 console.log(error);
    //                 return of(ProvidersActions.loadProvidersError({ error }))
    //             })
    //         ))
    //     )
    // )

    constructor(
        private actions$: Actions,
        private providersService: ProvidersService
    ) { }
}