import { Component, OnInit, Input } from '@angular/core';
import { Match } from 'src/app/models';
import { MatchesFacade } from 'src/app/services/facades/matches.facade';
import { MapFacade } from 'src/app/services/facades/map.facade';
import { MatchFacade } from 'src/app/services/facades/match.facade';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';

interface Marker {
  label: google.maps.MarkerLabel,
  icon: google.maps.Icon,
  location: {
    lat: number,
    lng: number
  },
  selected: boolean
}

@Component({
  selector: 'wtls-assigned-google-map',
  templateUrl: './assigned-google-map.component.html',
  styleUrls: ['./assigned-google-map.component.scss']
})
export class AssignedGoogleMapComponent implements OnInit {
  @Input() matches: Match[];
  @Input() selected: Match[];

  markers: google.maps.Marker[];

  match: Match;

  mapFullScreen: boolean;

  showPanel: boolean;

  index: number;

  constructor(
    private dialog: MatDialog,
    private matchesFacade: MatchesFacade,
    private matchFacade: MatchFacade,
    private mapFacade: MapFacade
  ) { 
    matchFacade.getMatch().subscribe(
      match => {
        this.match = match;

        if (match.patient) {
          this.showPanel = true;
          this.index = this.matches.indexOf(match) + 1;
        } else {
          this.showPanel = false;
        }
      }
    )

    mapFacade.getMapFullScreen().subscribe(
      fullScreen => {
        this.mapFullScreen = fullScreen;
      }
    )
  }

  ngOnInit(): void {
  }

  selectMarker(): void {

  }

  closePanel(): void {
    this.showPanel = false
  }

  unassignMatch(match: Match): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: match
    });

    dialogRef.beforeClosed().subscribe(result => {
      if (result) {
        this.matchesFacade.unassignedMatch(match);
      }
    })
  }
}
