import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { MatchesState } from '../reducers';

export const selectMatches = (state: AppState) => state.matches;

export const selectMatchesList = createSelector(
  selectMatches,
  (state: MatchesState) => state.list
);

export const selectMatchesLoading = createSelector(
  selectMatches,
  (state: MatchesState) => state.loading
)

export const selectMatchesError = createSelector(
  selectMatches,
  (state: MatchesState) => state.error
);