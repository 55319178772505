<div class="d-flex justify-content-center align-items-center h-100">
  <div class="panel-container">
    <h1>Log In</h1>
    <form #loginForm="ngForm" (ngSubmit)="onSubmit()">
      <mat-form-field class="w-100">
        <mat-label>Email</mat-label>
        <input
          matInput
          type="email"
          name="email"
          #email="ngModel"
          [(ngModel)]="formData.email"
          required
        />
        <mat-error *ngIf="email.invalid">Email is required</mat-error>
      </mat-form-field>
      <mat-form-field class="w-100">
        <mat-label>Password</mat-label>
        <input
          matInput
          type="password"
          name="password"
          #password="ngModel"
          [(ngModel)]="formData.password"
          required
        />
        <mat-error *ngIf="password.invalid">Password is required</mat-error>
      </mat-form-field>
      <div class="options-row d-flex justify-content-space-between">
        <mat-checkbox
          class="example-margin"
          name="rememberMe"
          [(ngModel)]="formData.rememberMe"
          color="primary"
          >Remember me</mat-checkbox
        >
        <a [routerLink]="['/reset-password']">Forgot Password?</a>
      </div>
      <button
        mat-flat-button
        type="submit"
        color="primary"
        [disabled]="!loginForm.form.valid || loading"
      >
        Log In
      </button>
      <div class="spinner-wrapper" *ngIf="loading">
        <mat-progress-spinner color="primary" mode="indeterminate" diameter="30">
        </mat-progress-spinner>  
      </div>  
      <span class="message error-text">{{ error }}</span>
    </form>
    <span class="message success-text">{{ success }}</span>
  </div>
</div>
