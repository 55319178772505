import { Component, OnInit, Renderer2, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { FilterNode, Provider, Patient } from 'src/app/models';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import { FiltersSelectors } from 'src/app/store/selectors';
import { Subscription } from 'rxjs';
import { FiltersState } from 'src/app/store/reducers';
import lodash from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { MatchFacade } from 'src/app/services/facades/match.facade';
import { ProvidersFacade } from 'src/app/services/facades/providers.facade';
import { FiltersFacade } from 'src/app/services/facades/filters.facade';
import { take } from 'rxjs/operators';
import { PatientsFacade } from 'src/app/services/facades/patients.facade';
import { AddEditProviderFacade } from 'src/app/services/facades/addEditProvider.facade';

@Component({
  selector: 'wtls-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.scss']
})
export class ProvidersComponent implements OnInit, OnDestroy {
  @ViewChild('fileUpload') fileUpload: ElementRef;

  @Input() providers: Provider[];
  @Input() matchProvider: Provider;
  @Input() loading: boolean;
  patient: Patient;
  filters: FiltersState;

  treeData: FilterNode[] = [
    {
      name: 'Filters',
      children: [
        {
          name: 'Employee Status',
          children: [
            {
              name: '',
              field: 'employeeStatus',
              filterType: 'checkBoxes',
              data: []
            },
          ]
        }, {
          name: 'Onboarding Stage',
          children: [
            {
              name: '',
              field: 'onboardingStage',
              filterType: 'checkBoxes',
              data: []
            },
          ]
        }, {
          name: 'Credentialing stage',
          children: [
            {
              name: '',
              field: 'credentialingStage',
              filterType: 'checkBoxes',
              data: []
            }
          ]
        }
      ]
    },
  ];

  selected;

  subscriptions = new Subscription();

  constructor(
    private ren: Renderer2, 
    private store: Store<AppState>, 
    private dialog: MatDialog,
    private filtersFacade: FiltersFacade,
    private providersFacade: ProvidersFacade,
    private patientsFacade: PatientsFacade,
    private matchFacade: MatchFacade,
    private addEditProviderFacade: AddEditProviderFacade
    ) { 
    

    const sub1 = providersFacade
      .getAllProviders()
      .subscribe(
        providers => {
          this.store
            .select(FiltersSelectors.selectEmployeeStatusesFilter)
            .pipe(take(1))
            .subscribe(
              employeeStatuses => {
                let distinct = [ ...new Set(providers.map(p => p.employeeStatus)) ];
                
                const filters = distinct.map(filter => {
                  if (employeeStatuses.includes(filter)) {
                    return {
                      name: filter,
                      value: true
                    }
                  } else {
                    return {
                      name: filter,
                      value: false
                    }
                  }
                })

                this.treeData[0].children[0].children[0].data = filters.filter(filter => !(filter.name == ''));
              }
            )

          this.store
            .select(FiltersSelectors.selectOnboardingStagesFilter)
            .pipe(take(1))
            .subscribe(
              onboardingStages => {
                let distinct = [ ...new Set(providers.map(p => p.onboardingStage)) ];
                
                const filters = distinct.map(filter => {
                  if (onboardingStages.includes(filter)) {
                    return {
                      name: filter,
                      value: true
                    }
                  } else {
                    return {
                      name: filter,
                      value: false
                    }
                  }
                })

                this.treeData[0].children[1].children[0].data = filters.filter(filter => !(filter.name == ''));
              }
            )

          this.store
            .select(FiltersSelectors.selectCredentialingStagesFilter)
            .pipe(take(1))
            .subscribe(
              credentialingStages => {
                let distinct = [ ...new Set(providers.map(p => p.credentialingStage)) ];
                
                const filters = distinct.map(filter => {
                  if (credentialingStages.includes(filter)) {
                    return {
                      name: filter,
                      value: true
                    }
                  } else {
                    return {
                      name: filter,
                      value: false
                    }
                  }
                })

                this.treeData[0].children[2].children[0].data = filters.filter(filter => !(filter.name == ''));
              }
            )
        }
      )

    const sub2 = this.store.select('filters').subscribe(filters => {
      this.filters = lodash.cloneDeep(filters);
    })

    this.subscriptions.add(sub1);
    this.subscriptions.add(sub2);
  }

  ngOnInit(): void {
    console.log('cureenthgfjhg')
    this.matchFacade
      .getPatient()
      .subscribe((patient) => {
        this.patient = patient;
      })

      this.setHasSchedule(false)
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  filterChanged(filter): void {
    console.log(filter);
    switch(filter.field) {
      case 'employeeStatus':
        if (this.filters.employeeStatuses.includes(filter.name)) {
          this.filters.employeeStatuses = this.filters.employeeStatuses.filter(status => status !== filter.name);
        } else {
          this.filters.employeeStatuses.push(filter.name);
        }
      break;

      case 'onboardingStage':
        if (this.filters.onboardingStages.includes(filter.name)) {
          this.filters.onboardingStages = this.filters.onboardingStages.filter(stage => stage !== filter.name);
        } else {
          this.filters.onboardingStages.push(filter.name);
        }
        break;

      case 'credentialingStage':
        if (this.filters.credentialingStages.includes(filter.name)) {
          this.filters.credentialingStages = this.filters.credentialingStages.filter(stage => stage !== filter.name);
        } else {
          this.filters.credentialingStages.push(filter.name);
        }
        break;
    }

    this.updateAndLoadProviders();
  }

  filterNameChange(name: string) {
    this.filters.providerName = name;

    this.updateAndLoadProviders();
  }

  setHasSchedule(hasShedule: boolean): void {
    
    if (this.filters.providersHasSchedule != hasShedule) {
      this.filters.providersHasSchedule = hasShedule;

      this.updateAndLoadProviders();
    }
  }

  updateAndLoadProviders(): void {
    this.filtersFacade.updateUnassignedFilters(this.filters);
    this.providersFacade.loadProviders();
  }

  changeProvider(provider: Provider): void {
    if (!this.loading)
      this.matchFacade.selectProvider(provider);
  }

  editProvider(provider: Provider): void {

    this.addEditProviderFacade.setProvider(provider);
    this.addEditProviderFacade.openPanel();
  }

  deleteProvider(provider: Provider): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: provider
    })

    dialogRef.beforeClosed().subscribe(result => {
      // TODO dispatch delete
      if (result) {
        this.providersFacade.deleteProvider(provider).subscribe(result => {
          console.log("DELETED PROVIDER");
        });
      }
    })
  }

  csvUpload(fileList: FileList): void {
    if (fileList.length > 0) {
      const files = Array.from(fileList);
      if (!files.some((file) => file.name.endsWith('.csv'))) {
        alert('One or more files selected were not of type CSV.');
      } else {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: 'uploadCSVproviders'
        });

        dialogRef.beforeClosed().subscribe(result => {
          if (result) {
            this.providersFacade
              .uploadCSV(files)
              .subscribe((result) => {
                this.providersFacade
                  .loadNewProviders()
                  .pipe(take(1))
                  .subscribe(() => {
                    this.providersFacade.clearAllProviders();
                    this.patientsFacade.clearAllPatients();

                    this.patientsFacade.loadPatients();
                  },
                  error => {
                    this.patientsFacade.loadPatients();
                  });
                console.log('results',result)
                if(result.flag == false){
                  this.dialog.open(ConfirmDialogComponent, {
                    data: 'uploadFailure,'+result.field+','+result.row
                  })
                }
                this.setHasSchedule(false)
                this.setHasSchedule(false)
                this.fileUpload.nativeElement.value = '';
              },
              error => {
                alert(error);
                //console.log('error',error)
                this.fileUpload.nativeElement.value = '';
              });
          } else {
            this.fileUpload.nativeElement.value = '';
          }
        });
      }
    }
  }
}
