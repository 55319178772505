import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { EndPoints } from 'src/app/constants/endpoints';

@Component({
  selector: 'wtls-complete',
  templateUrl: './pcomplete.component.html',
  styleUrls: ['./pcomplete.component.scss']
})
export class PcompleteComponent {

  constructor(private router: Router, private route: ActivatedRoute) { }

  newForm(): void {
    this.route.queryParams.pipe(
      take(1)
    ).subscribe((params) => {
      //this.router.navigate([params['dashboard/providers-form']]);
      //alert('ok');
      window.location.href = EndPoints.BASE_URL+'dashboard/providers-form';
      //window.location.href = 'https://abaschedulezipper.com/dashboard/providers-form';
    })
  }

  dashboard() {
    window.location.href = EndPoints.BASE_URL+'dashboard/unassigned';
  }
}
//http://localhost:4200/dashboard/providers-form