import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services';
import { take } from 'rxjs/operators';
import { AuthFacade } from 'src/app/services/facades/auth.facade';

@Component({
  selector: 'wtls-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  loading: boolean;
  error: string;

  formData = {
    email: ''
  }

  constructor(
    private router: Router,
    private authFacade: AuthFacade,
    ) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.loading = true;
    this.error = '';
    
    this.authFacade.resetPassword(this.formData.email)
      .subscribe(
        result => {
          this.router.navigate(['/login'], { queryParams: { resetPassword: true } });
        },
        error => {
          this.loading = false;
          this.error = error;
        }
      )
  }
}
