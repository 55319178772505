import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup,Validators  } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/services';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../dashboard/confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EndPoints } from 'src/app/constants/endpoints';


@Component({
  selector: 'wtls-editclient',
  templateUrl: './editclient.component.html',
  styleUrls: ['./editclient.component.scss']
})
export class EditclientComponent implements OnInit {
  form: FormGroup;
  id: number;
  li:any;
  lis=[];
  selectlist:any;
  selectlistorders=[];
  dataset = [];
  data:any;
  locationlist:any;
  locationsselectlistorders=[];
  submitted = false;
  constructor(private router: Router,private snackbar: MatSnackBar,private dialog: MatDialog, private route: ActivatedRoute, private formBuilder: FormBuilder,private http: HttpClient, private apiService: ApiService) { 
    this.form = this.formBuilder.group({
      first_name: [''],
      last_name: [''],
      dob: [''],
      gender:[''],
      message:[''],
      phone:[''],
      email:[''],
      found_us:[''],
      contact_time_of_day:[''],
      contact_preferred_method:[''],
      patient_type:[''],
      insurance_type:[''],
      intake_stage:[''],
      addressline1:[''],
      addressline2:[''],
      city:[''],
      state_abbr:[''],
      country:[''],
      zipcode:[''], 
      clinic_name:[''], 
      mon_startTime:[''], 
      mon_endTime:[''], 
      tue_startTime:[''], 
      tue_endTime:[''], 
      wed_startTime:[''], 
      wed_endTime:[''], 
      thu_startTime:[''], 
      thu_endTime:[''], 
      fri_startTime:[''], 
      fri_endTime:[''], 
      sat_startTime:[''], 
      sat_endTime:[''], 
      sun_startTime:[''], 
      sun_endTime:[''], 
    })
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id']; 
    });

    this.apiService.get('/clientshow/'+this.id)
    .subscribe(Response => {
 
      if(Response){ 
        console.log("testingdata=>",Response)
      }
      console.log("testing data2=>",Response)
      this.li=Response;
      this.lis=this.li;
     this.dataset = this.lis;
     this.data = this.lis;
      console.log("datatest1=>",this.data)
      return this.dataset;
      
    });

//     this.apiService.get('/cliniclocations/')
// .subscribe(Response => {
//   if(Response){ 
//   }
//   console.log("datatest=>",Response)
//   this.locationlist=Response;
//   this.locationsselectlistorders=this.locationlist;
//   console.log("datatest1=>",this.locationsselectlistorders)
// });

  }
  
  

  get f() { return this.form.controls; }
  showMsg: boolean = false;
  submitForm() {
    this.showMsg=false
      let Formpostdata = this.form.value;
      console.log("test=>",typeof this.id)
            this.apiService.put('/clientshow/'+this.id,Formpostdata).subscribe(
          
        
              (response) =>{
                if(response){
                  console.log('response',response)
                this.snackbar.open('Client Update Successful!', 'Dismiss', {
                verticalPosition: 'bottom',
                horizontalPosition: 'left',
                duration:2750,
              }); 
              this.router.navigate(['dashboard/clientsshow'])
              //window.location.href = EndPoints.BASE_URL+'/form-complete';
              }
              else{
                this.snackbar.open('Error while saving client', 'Dismiss', {
                  verticalPosition: 'bottom',
                  horizontalPosition: 'left',
                  duration:2750,
                }); 
              }
              },
            
            (error) => console.log('error',error)
          );  
   
  }


  cancelProviderForm(): void {
    this.router.navigate(['/dashboard/clientsshow'])
  }
  deleteclient(){
    let data = this.id;
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: 'clientdelete',
      disableClose: true
    })
    dialogRef.beforeClosed().subscribe(
      ()=>{
        let deleteid = this.id;
      
        this.apiService.delete('/clientshow/'+ this.id).subscribe(
     
          (response) =>{
            this.snackbar.open('Client Delete Successful!', 'Dismiss', {
            verticalPosition: 'bottom',
            horizontalPosition: 'left',
          }); 
          window.location.href = EndPoints.BASE_URL+'/dashboard/clientsshow';
          },
         
         (error) => console.log(error)
       );  
      },
      ()=>{
        console.error("this is error");
        
      }
    )
  }

}
