import { createAction, props } from "@ngrx/store";
import { Match, TimeSlot } from "src/app/models";
import { MatchesActions } from '.';

export enum MatchesActionTypes {
  LOAD_MATCHES = "[Matches] Load Matches",
  LOAD_MATCHES_SUCCESS = "[Matches] Load Matches Success",
  LOAD_MATCHES_ERROR = "[Matches] Load Matches Error",
  ASSIGN_MATCH = '[Matches] Assign Match',
  ASSIGN_MATCH_SUCCESS = '[Matches] Assign Match Success',
  ASSIGN_MATCH_ERROR = '[Matches] Assign Match Error',
  UNASSIGN_MATCH = '[Matches] Unassign Match',
  UNASSIGN_MATCH_SUCCESS = '[Matches] Unassign Match Success',
  UNASSIGN_MATCH_ERROR = '[Matches] Unassign Match Error',
  EXPORT_CSV = '[Matches] Export CSV',
  EXPORT_CSV_SUCCESS = '[Matches] Export CSV Success',
  EXPORT_CSV_ERROR = '[Matches] Export CSV Error',
  CLEAR_MATCHES = "[Matches] Clear Matches",
  CLEAR_ERROR = "[Matches] Clear Error"
}

export const loadMatches = createAction(
  MatchesActionTypes.LOAD_MATCHES
);

export const loadMatchesSuccess = createAction(
  MatchesActionTypes.LOAD_MATCHES_SUCCESS,
  props<{ matches: Match[] }>()
);

export const loadMatchesError = createAction(
  MatchesActionTypes.LOAD_MATCHES_ERROR,
  props<{ error }>()
);

export const assignMatch = createAction(
  MatchesActionTypes.ASSIGN_MATCH
);

export const assignMatchSuccess = createAction(
  MatchesActionTypes.ASSIGN_MATCH_SUCCESS,
)

export const assignMatchError = createAction(
  MatchesActionTypes.ASSIGN_MATCH_ERROR,
  props<{ error: string }>()
)

export const unassignMatch = createAction(
  MatchesActionTypes.UNASSIGN_MATCH,
  props<{ matchId: number }>()
)

export const unassignMatchSuccess = createAction(
  MatchesActionTypes.UNASSIGN_MATCH_SUCCESS
)

export const unassignMatchError = createAction(
  MatchesActionTypes.UNASSIGN_MATCH_ERROR,
  props<{ error: string }>()
)

export const exportCsv = createAction(
  MatchesActionTypes.EXPORT_CSV
)

export const exportCsvSuccess = createAction(
  MatchesActionTypes.EXPORT_CSV_SUCCESS
)

export const exportCsvError = createAction(
  MatchesActionTypes.EXPORT_CSV_ERROR,
  props<{ error: string }>()
)

export const clearMatches = createAction(MatchesActionTypes.CLEAR_MATCHES);

export const clearError = createAction(MatchesActionTypes.CLEAR_ERROR);