import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/services';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator'; 
import { NewClientsFacade } from 'src/app/services/facades/newClients.facade';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'wtls-clientsshow',
  templateUrl: './clientsshow.component.html',
  styleUrls: ['./clientsshow.component.scss']
})
export class ClientsshowComponent implements OnInit {
  li:any;
  dataSource = new MatTableDataSource<any>();
  loading = true
  displayedColumns: string[] = ["Id","first_name", "last_name","ClinicLocation",	"ClientType",	"InsuranceType",	
  "Dob",	"IntakeStage",	"Address",	"City"	, "State","Zip", "Gender", "Your_message_concerns", "Cell_Phone", "Email", "How_did_you_hear_about_us","What_is_the_best_time_of_day_to_reach_you",
  "Preferred_method_of_contact","mon_startTime","mon_endTime","tue_startTime","tue_endTime","wed_startTime","wed_endTime","thu_startTime","thu_endTime","fri_startTime","fri_endTime","sat_startTime","sat_endTime","sun_startTime","sun_endTime","Edit", "Delete"];
  pageSize = 10
  pageSizeOptions = [5, 10, 50, 500];
  @ViewChild(MatPaginator) paginator: MatPaginator
  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private http: HttpClient,
    private apiService: ApiService,
    private changeDetector: ChangeDetectorRef,
    private newClientsFacade: NewClientsFacade,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.apiService.get('/clientshow')
    .subscribe(Response => {
 
      // If response comes hideloader() function is called
      // to hide that loader
      if(Response){ 
        this.loading = false
      }
      console.log("datatest=>",Response)
      this.li=Response;
      this.dataSource.data =this.li;
      console.log("datatest1=>",this.dataSource)
      
    });
    //this.changeDetector.detectChanges()
    //this.dataSource.paginator = this.paginator
    function hideloader(){
      document.getElementById('loading').style.display = 'none';}
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator
  }

  exportCSV(): void {
    this.newClientsFacade.exportCSV();
  }

  edit(id: any){
    this.router.navigate(['/dashboard/editclient/'+id])
  }

  delete(id: any){
    console.log("delete=>",id);
    let deleteid = id;
    
         this.apiService.delete('/clientshow/'+ deleteid).subscribe(
          (response) => {
            this.snackbar.open('Delete Client Successful','Dismiss',{
              verticalPosition: 'bottom',
              horizontalPosition: 'left',
              duration: 2750
            })
            window.location.reload()
          },
          
          (error) => console.log(error)
        );  
  }

}
