import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/services';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator'; 
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../dashboard/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'wtls-auditlogs',
  templateUrl: './auditlogs.component.html',
  styleUrls: ['./auditlogs.component.scss']
})
export class AuditlogsComponent implements OnInit {
  li:any;
  dataSource = new MatTableDataSource<any>();
  loading = true
 // displayedColumns: string[] = ["Users","Company Name",	"Login Date",	"Login Time",	"Logout Time",	"View company",	"Another Logs","Admin Permissions"];
 displayedColumns: string[] = ["S NO","Company Name",	"Email",	"Contact No",	"Company Opening Time",	"Company Closing Time",	"Permission","Permission Request"];
  pageSize = 10
  pageSizeOptions = [10, 25, 50, 500];
  @ViewChild(MatPaginator) paginator: MatPaginator

constructor(
  private router: Router,
  private route: ActivatedRoute,
  private http: HttpClient,
  private apiService: ApiService, 
  private changeDetector: ChangeDetectorRef,
  private dialogRef: MatDialog
  ) { }

lis=[];
  ngOnInit(): void {

    this.apiService.get('/createcompany')
    .subscribe(Response => {
 
      // If response comes hideloader() function is called
      // to hide that loader
      if(Response){ 
        //hideloader();
      this.loading = false
      console.log("datatest=>",Response)
      this.li=Response;
      this.lis=this.li;
      this.dataSource.data =this.li;
      console.log("datatest1=>",this.lis)
      }
      
    },
    error => {
      this.loading = false
      this.dialogRef.open(ConfirmDialogComponent, {
        data: 'AccessDenied'
      })
    });
    function hideloader(){
      document.getElementById('loading').style.display = 'none';}
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator
  }

}
