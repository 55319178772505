<!-- <!DOCTYPE html> -->
<!-- <html lang="en"> -->

<!-- <body>  -->
  <div class="page-container">
    <div class="content-container">
      
      <div class="container"> 
        <div class="company-header">
          <h2>
            <a href="#"  mat-flat-button class="tab"  [routerLink]="['/dashboard/locations']"  routerLinkActive="active"   type="button"  color="primary"> Add Clinic location</a> 
            &nbsp;&nbsp;

          <div class="spinner-wrapper" *ngIf="loading">
            <mat-progress-spinner
              color="primary"
              mode="indeterminate"
              diameter="30"
            >
            </mat-progress-spinner>
          </div>
         
        </h2>
          
        </div>
        
    
<div class="client-table">
  <div class="table-wrapper">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!-- Position Column -->
      
     <!--  <ng-container matColumnDef="S No.">
        <th mat-header-cell *matHeaderCellDef> S No.</th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container> -->
    
      <!-- Name Column -->
      <ng-container matColumnDef="Location Name">
        <th mat-header-cell *matHeaderCellDef> Location Name </th>
        <td mat-cell *matCellDef="let element"> {{element.location_name}} </td>
      </ng-container>
    
      <ng-container matColumnDef="State">
        <th mat-header-cell *matHeaderCellDef> State </th>
        <td mat-cell *matCellDef="let element"> {{element.state}} </td>
      </ng-container>

      <ng-container matColumnDef="City">
        <th mat-header-cell *matHeaderCellDef> City </th>
        <td mat-cell *matCellDef="let element"> {{element.city}} </td>
      </ng-container>

     
      

      <!-- Weight Column -->
      <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element"> 
          <button 
            mat-icon-button 
            matTooltip="Click to Edit"  
            class="iconbutton"
            (click)="edit(element.id)" 
            color="primary"
          >
          <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>
     
      
     
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions">
  </mat-paginator>
  
</div>

      </div>
    </div>
  </div>




