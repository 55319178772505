<!-- <!DOCTYPE html> -->
<!-- <html lang="en"> -->

<!-- <body>  -->
    <div class="page-container">
        <div class="content-container">
          
          <div class="container"> 
            <div class="company-header">
              <h2>
            
              <a href="#"  mat-flat-button class="tab"  [routerLink]="['/dashboard/providers-form']"  routerLinkActive="active"   type="button"  color="primary">New Provider</a> 
    
              <div class="spinner-wrapper" *ngIf="loading">
                <mat-progress-spinner
                  color="primary"
                  mode="indeterminate"
                  diameter="30"
                >
                </mat-progress-spinner>
              </div>
             
            </h2>

            <button
             mat-stroked-button
             class="export-button"
             type="button"
             (click)="exportCsv()"
            >
            Export CSV
            </button>
              
            </div>
            
          <!-- <br> -->
            <!-- <a href="#"  mat-flat-button class="tab"  [routerLink]="['/dashboard/createcompanys']"  routerLinkActive="active"   type="button"  color="primary">New company</a> 
            &nbsp;&nbsp;&nbsp;<a href="#"  mat-flat-button class="tab"  [routerLink]="['/dashboard/locations']"  routerLinkActive="active"   type="button"  color="primary">Clinic location & Sub headings</a> 
    
            <br> <br>
            <div class="d-flex justify-content-center"> -->
    <!-- <div class="spinner-border" role="status" >
        <span class="sr-only" id="loading">Loading...</span>
      
    </div> -->
    <!-- </div> --> 
    <div class="client-table">
      <div class="table-wrapper">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <!-- Position Column -->
          
          <ng-container matColumnDef="Id">
            <th mat-header-cell *matHeaderCellDef> Id</th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container>
        
          <!-- Name Column -->
          <ng-container matColumnDef="First Name">
            <th mat-header-cell *matHeaderCellDef> First Name </th>
            <td mat-cell *matCellDef="let element"> {{element.first_name}} </td>
          </ng-container>

          <ng-container matColumnDef="Last Name">
            <th mat-header-cell *matHeaderCellDef> Last Name </th>
            <td mat-cell *matCellDef="let element"> {{element.last_name}} </td>
          </ng-container>

          <ng-container matColumnDef="Cell_Phone">
            <th mat-header-cell *matHeaderCellDef> Phone </th>
            <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
          </ng-container>

          <ng-container matColumnDef="Email">
            <th mat-header-cell *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
          </ng-container>
        
          <ng-container matColumnDef="ClinicLocation">
            <th mat-header-cell *matHeaderCellDef> Clinic Location </th>
            <td mat-cell *matCellDef="let element"> {{element.clinicLocation}} </td>
          </ng-container>
    
          <ng-container matColumnDef="EmployeeStatus">
            <th mat-header-cell *matHeaderCellDef> Employee Status </th>
            <td mat-cell *matCellDef="let element"> {{element.employeeStatus}} </td>
          </ng-container>
    
          <ng-container matColumnDef="OnboardingStage">
            <th mat-header-cell *matHeaderCellDef> Onboarding Stage </th>
            <td mat-cell *matCellDef="let element"> {{element.onboardingStage}} </td>
          </ng-container>
    
          <ng-container matColumnDef="CredentialingStage">
            <th mat-header-cell *matHeaderCellDef> Credentialing Stage </th>
            <td mat-cell *matCellDef="let element"> {{element.credentialingStage}} </td>
          </ng-container>
    
          <ng-container matColumnDef="Address">
            <th mat-header-cell *matHeaderCellDef> Address </th>
            <td mat-cell *matCellDef="let element"> {{element.address.addressline1}} </td>
          </ng-container>
    
          <ng-container matColumnDef="City">
            <th mat-header-cell *matHeaderCellDef> City</th>
            <td mat-cell *matCellDef="let element"> {{element.address.city}} </td>
          </ng-container>
    
          <ng-container matColumnDef="State">
            <th mat-header-cell *matHeaderCellDef> State </th>
            <td mat-cell *matCellDef="let element"> {{element.address.state_abbr}} </td>
          </ng-container>

          <ng-container matColumnDef="Zip">
            <th mat-header-cell *matHeaderCellDef> Zip </th>
            <td mat-cell *matCellDef="let element"> {{element.address.zipcode}} </td>
          </ng-container>

          <ng-container matColumnDef="mon_startTime">
            <th mat-header-cell *matHeaderCellDef> Monday Starttime </th>
            <td mat-cell *matCellDef="let element"> {{element.availability.mon_startTime}} </td>
          </ng-container>

          <ng-container matColumnDef="mon_endTime">
            <th mat-header-cell *matHeaderCellDef> Monday Endtime </th>
            <td mat-cell *matCellDef="let element"> {{element.availability.mon_endTime}} </td>
          </ng-container>

          <ng-container matColumnDef="tue_startTime">
            <th mat-header-cell *matHeaderCellDef> Tuesday Starttime </th>
            <td mat-cell *matCellDef="let element"> {{element.availability.tue_startTime}} </td>
          </ng-container>

          <ng-container matColumnDef="tue_endTime">
            <th mat-header-cell *matHeaderCellDef> Tuesday Endtime </th>
            <td mat-cell *matCellDef="let element"> {{element.availability.tue_endTime}} </td>
          </ng-container>

          <ng-container matColumnDef="wed_startTime">
            <th mat-header-cell *matHeaderCellDef> Wednesday Starttime </th>
            <td mat-cell *matCellDef="let element"> {{element.availability.wed_startTime}} </td>
          </ng-container>

          <ng-container matColumnDef="wed_endTime">
            <th mat-header-cell *matHeaderCellDef> Wednesday Endtime </th>
            <td mat-cell *matCellDef="let element"> {{element.availability.wed_endTime}} </td>
          </ng-container>

          <ng-container matColumnDef="thu_startTime">
            <th mat-header-cell *matHeaderCellDef> Thursday Starttime </th>
            <td mat-cell *matCellDef="let element"> {{element.availability.thu_startTime}} </td>
          </ng-container>

          <ng-container matColumnDef="thu_endTime">
            <th mat-header-cell *matHeaderCellDef> Thursday Endtime </th>
            <td mat-cell *matCellDef="let element"> {{element.availability.thu_endTime}} </td>
          </ng-container>

          <ng-container matColumnDef="fri_startTime">
            <th mat-header-cell *matHeaderCellDef> Friday StartTime </th>
            <td mat-cell *matCellDef="let element"> {{element.availability.fri_startTime}} </td>
          </ng-container>

          <ng-container matColumnDef="fri_endTime">
            <th mat-header-cell *matHeaderCellDef> Friday Endtime </th>
            <td mat-cell *matCellDef="let element"> {{element.availability.fri_endTime}} </td>
          </ng-container>

          <ng-container matColumnDef="sat_startTime">
            <th mat-header-cell *matHeaderCellDef> Saturday StartTime </th>
            <td mat-cell *matCellDef="let element"> {{element.availability.sat_startTime}} </td>
          </ng-container>

          <ng-container matColumnDef="sat_endTime">
            <th mat-header-cell *matHeaderCellDef> Saturday Endtime </th>
            <td mat-cell *matCellDef="let element"> {{element.availability.sat_endTime}} </td>
          </ng-container>

          <ng-container matColumnDef="sun_startTime">
            <th mat-header-cell *matHeaderCellDef> Sunday StartTime </th>
            <td mat-cell *matCellDef="let element"> {{element.availability.sun_startTime}} </td>
          </ng-container>

          <ng-container matColumnDef="sun_endTime">
            <th mat-header-cell *matHeaderCellDef> Sunday Endtime </th>
            <td mat-cell *matCellDef="let element"> {{element.availability.sun_endTime}} </td>
          </ng-container>

          <!-- <ng-container matColumnDef="monstarttime">
            <th mat-header-cell *matHeaderCellDef> Mon Start Time</th>
            <td mat-cell *matCellDef="let element"> {{element.monstarttime}} </td>
          </ng-container>


          <ng-container matColumnDef="monendtime">
            <th mat-header-cell *matHeaderCellDef> Mon End Time </th>
            <td mat-cell *matCellDef="let element"> {{element.monendtime}} </td>
          </ng-container>

          <ng-container matColumnDef="tuestarttime">
            <th mat-header-cell *matHeaderCellDef> Tue Start Time</th>
            <td mat-cell *matCellDef="let element"> {{element.tuestarttime}} </td>
          </ng-container>


          <ng-container matColumnDef="tueendtime">
            <th mat-header-cell *matHeaderCellDef> Tue End Time </th>
            <td mat-cell *matCellDef="let element"> {{element.tueendtime}} </td>
          </ng-container>

          <ng-container matColumnDef="wedstarttime">
            <th mat-header-cell *matHeaderCellDef> Wed Start Time</th>
            <td mat-cell *matCellDef="let element"> {{element.wedstarttime}} </td>
          </ng-container>


          <ng-container matColumnDef="wedendtime">
            <th mat-header-cell *matHeaderCellDef> Wed End Time </th>
            <td mat-cell *matCellDef="let element"> {{element.wedendtime}} </td>
          </ng-container>


          <ng-container matColumnDef="thustarttime">
            <th mat-header-cell *matHeaderCellDef> Thu Start Time</th>
            <td mat-cell *matCellDef="let element"> {{element.thustarttime}} </td>
          </ng-container>


          <ng-container matColumnDef="thuendtime">
            <th mat-header-cell *matHeaderCellDef> Thu End Time </th>
            <td mat-cell *matCellDef="let element"> {{element.thuendtime}} </td>
          </ng-container>


          <ng-container matColumnDef="fristarttime">
            <th mat-header-cell *matHeaderCellDef> Fri Start Time</th>
            <td mat-cell *matCellDef="let element"> {{element.fristarttime}} </td>
          </ng-container>


          <ng-container matColumnDef="friendtime">
            <th mat-header-cell *matHeaderCellDef> Fri End Time </th>
            <td mat-cell *matCellDef="let element"> {{element.friendtime}} </td>
          </ng-container>


          <ng-container matColumnDef="satstarttime">
            <th mat-header-cell *matHeaderCellDef> Sat Start Time</th>
            <td mat-cell *matCellDef="let element"> {{element.satstarttime}} </td>
          </ng-container>


          <ng-container matColumnDef="satendtime">
            <th mat-header-cell *matHeaderCellDef> Sat End Time </th>
            <td mat-cell *matCellDef="let element"> {{element.satendtime}} </td>
          </ng-container>


          <ng-container matColumnDef="sunstarttime">
            <th mat-header-cell *matHeaderCellDef> Sun Start Time</th>
            <td mat-cell *matCellDef="let element"> {{element.sunstarttime}} </td>
          </ng-container>


          <ng-container matColumnDef="sunendtime">
            <th mat-header-cell *matHeaderCellDef> Sun End Time </th>
            <td mat-cell *matCellDef="let element"> {{element.sunendtime}} </td>
          </ng-container> -->




    
          <!-- Weight Column -->
         <!--  <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element"> 
              <span *ngIf="element.status == 0" style="color: green">Active</span>
              <span *ngIf="element.status == 1" style="color: red">Inactive</span>
            </td>
          </ng-container> -->
        
          <ng-container matColumnDef="Edit">
            <th mat-header-cell *matHeaderCellDef> Edit </th>
            <td mat-cell *matCellDef="let element"> 
              <button 
                mat-icon-button 
                matTooltip="Click to Edit"  
                class="iconbutton"
                (click)="edit(element.id)" 
                color="primary"
              >
              <mat-icon>edit</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="Delete">
            <th mat-header-cell *matHeaderCellDef> Delete </th>
            <td mat-cell *matCellDef="let element"> 
              <button 
                mat-icon-button 
                matTooltip="delete"  
                class="iconbutton"
                (click)="delete(element.id)" 
                color="primary"
              >
              <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>

        
        
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>
      <!-- <mat-paginator
      class="paginator"
      [length]="totalElements"
      [pageSize]="size"
      [pageSizeOptions]="pageSizeOptions"
      >
      </mat-paginator> -->
    </div>
    <!-- <table class="table" id='tab' style="border:1px">
        <thead>
        <tr>
          <th scope="col">S.No</th>
            <th scope="col">Fullname</th>
            <th scope="col">Company Name</th>
            <th scope="col"> Email</th>
            <th scope="col"> Address</th>
            <th scope="col"> Contact No</th>
            <th scope="col"> Opening time</th>
            <th scope="col"> Closing time</th>
            <th scope="col"> Url</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
           
           
        </tr>
        </thead>
        <tbody>
        <tr  *ngFor="let e of lis;">
          <td>{{ e.id }}</td>
          <td>{{ e.fullname }}</td>
            <td>{{ e.comapny_name }}</td>
            <td>{{ e.company_email }}</td>
            <td>{{ e.company_address }}</td>
            <td>{{ e.company_contactno }}</td>
            <td>{{ e.companay_opening_time }}</td>
            <td>{{ e.company_closing_time }}</td>
            <td>{{ e.company_url }}</td>
            <td>
              <span *ngIf="e.status == 0" style="color: green">Active</span>
              <span *ngIf="e.status == 1" style="color: red">Inactive</span>
            </td>
            <td>
               
             
              &nbsp; <a href="/dashboard/editcompany/{{ e.id }}"><span class="glyphicon glyphicon-pencil btn btn-primary" ></span></a>
                &nbsp;<span class="	glyphicon glyphicon-trash btn btn-danger" (click)="delete(e.id)"></span>
             </td>
           
        </tr>
        </tbody>
        
    </table>     -->
          <!-- </div> -->
        </div>
      </div>
    
    <!-- </body> -->
    <!-- </html> -->
    
    
    