import { AfterContentInit, Component, ContentChildren, ElementRef, forwardRef, Input, QueryList, ViewChild } from '@angular/core';
import { DropDownService } from './drop-down.service';
import { DropDownComponent } from './drop-down/drop-down.component';
import { SelectOptionComponent } from './select-option/select-option.component';
import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'wtls-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    },
    DropDownService]
})
export class SelectComponent implements AfterContentInit, ControlValueAccessor {
  @ViewChild('input')
  public input: ElementRef;
 
  @ViewChild(DropDownComponent)
  public dropdown: DropDownComponent;

  @ContentChildren(SelectOptionComponent)
  public options: QueryList<SelectOptionComponent>;

  selectedOption: SelectOptionComponent;

  displayText: string;
 
  public showDropdown() {
    
    if(!this.disabled)
    this.dropdown.show();

    if (!this.options.length) {
      return;
    }
    
    this.selected ? this.keyManager.setActiveItem(this.selectedOption) : this.keyManager.setFirstItemActive();
    console.log("this.selectedOption",this.selectedOption);
    console.log(" this.selected ", this.selected );
  }

  @Input()
  public label: string;
 
  @Input()
  public placeholder: string;
 
  @Input()
  public selected: string;

  @Input()
  public required: boolean = false;
 
  @Input()
  public disabled: boolean = false;  

  private keyManager: ActiveDescendantKeyManager<SelectOptionComponent>;

  constructor(private dropDownService: DropDownService) { 
    dropDownService.register(this);
   
  }

  ngAfterContentInit(): void {
    console.log(" this.selected 2", this.selected );
     this.selected = this.options.toArray()[0].key
     this.onChangeFn(this.selected);
      this.selectedOption = this.options.toArray().find(option => option.key === this.selected);

     // this.selectedOption = this.options.toArray()[0];
      this.displayText = this.selectedOption ? this.selectedOption.value : '';

      this.keyManager = new ActiveDescendantKeyManager(this.options)
        .withHorizontalOrientation('ltr')
        .withVerticalOrientation()
        .withWrap();
  }

  selectOption(option: SelectOptionComponent) {
    console.log(" this.selected 3", this.selected );
    this.keyManager.setActiveItem(option);
    this.selected = option.key;
    this.selectedOption = option;
    this.displayText = this.selectedOption ? this.selectedOption.value : '';
    this.hideDropdown();
    this.input.nativeElement.focus();
    this.onChange();
  }

  hideDropdown() {
    this.dropdown.hide();
  }

  onDropMenuIconClick(event: UIEvent) {
    console.log(" this.selected 4", this.selected );
    event.stopPropagation();
    setTimeout(() => {
      this.input.nativeElement.focus();
      this.input.nativeElement.click();
    }, 10);
  }

  public onKeyDown(event: KeyboardEvent) {
    if (['Enter', ' ', 'ArrowDown', 'Down', 'ArrowUp', 'Up'].indexOf(event.key) > -1) {
      if (!this.dropdown.showing && !this.disabled) {        
        this.showDropdown();
        return;
      }
    
      if (!this.options.length) {
        event.preventDefault();
        return;
      }
    }
    
    if (event.key === 'Enter' || event.key === ' ') {
      this.selectedOption = this.keyManager.activeItem;
      this.selected = this.selectedOption.key;
      this.displayText = this.selectedOption ? this.selectedOption.value : '';
      this.hideDropdown();
      this.onChange()
    } else if (event.key === 'Escape' || event.key === 'Esc') {
      this.dropdown.showing && this.hideDropdown();
    } else if (['ArrowUp', 'Up', 'ArrowDown', 'Down', 'ArrowRight', 'Right', 'ArrowLeft', 'Left']
      .indexOf(event.key) > -1) {
      this.keyManager.onKeydown(event);
    } else if (event.key === 'PageUp' || event.key === 'PageDown' || event.key === 'Tab') {
      this.dropdown.showing && event.preventDefault();
    }
  }

  public onChangeFn = (_: any) => {};
 
  public onTouchedFn = () => {};
 
  public registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }
 
  public registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }
 
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
 
  public writeValue(obj: any): void {
    console.log("selectedObj=>",obj);
    this.selected = obj;
  }
 
  public onTouched() {
    this.onTouchedFn();
  }
 
  public onChange() {
    
    this.onChangeFn(this.selected);
    console.log("this.selected=>",this.selected);
  }


}
