<div class="form-section">
  <div class="section-header">
    <h6 class="title">{{ formSection.title | uppercase }}</h6>
    <span class="hint-text" *ngIf="hasRequired()">(Fields marked with * are required)</span>
  </div>
  <hr />
  <div class="row">
    <div
      wtls-form-field
      *ngFor="let field of formSection.fields"
      [ngClass]="field.cssClass"
      [parentGroup]="parentGroup"
      [formField]="field"
    ></div>
  </div>
</div>
