import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import { Observable, BehaviorSubject } from 'rxjs';
import { Match } from 'src/app/models';
import { MatchesActions, MatchActions } from 'src/app/store/actions';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/components/dashboard/confirm-dialog/confirm-dialog.component';
import { MatchesSelectors } from 'src/app/store/selectors';


@Injectable({
    providedIn: 'root'
})
export class MatchesFacade {

    private matches$ = new BehaviorSubject<Match[]>([]);

    private allMatches$ = new BehaviorSubject<Match[]>([]);
    
    private loading$ = new BehaviorSubject<boolean>(false);

    private error$ = new BehaviorSubject<string>('');

    constructor(
        private store: Store<AppState>,
    ) {
        this.store.select(MatchesSelectors.selectMatchesList).subscribe(
            matches => {
                this.matches$.next(matches); // create list selector

                if (this.allMatches$.getValue().length < matches.length)
                    this.allMatches$.next(matches);
            }
        )

        this.store.select(MatchesSelectors.selectMatchesLoading).subscribe(
            loading => {
                this.loading$.next(loading);
            }
        )

        this.store.select(MatchesSelectors.selectMatchesError).subscribe(
            error => {
                this.error$.next(error);
            }
        )
     }

    loadMatches(): void {
        this.store.dispatch(MatchesActions.loadMatches());
    }

    getMatches(): Observable<Match[]> {
        if (this.matches$.getValue().length == 0)
            this.loadMatches();

        return this.matches$.asObservable();
    }

    getAllMatches(): Observable<Match[]> {
        return this.allMatches$.asObservable();
    }

    assignMatch(): void {
        this.store.dispatch(MatchesActions.assignMatch());
    }    

    unassignedMatch(match: Match): void {
        this.store.dispatch(MatchesActions.unassignMatch({ matchId: match.id }));
    }

    getLoading(): Observable<boolean> {
        return this.loading$.asObservable();
    }

    getError(): Observable<string> {
        return this.error$.asObservable();
    }

    clearError(): void {
        if (this.error$.getValue())
            this.store.dispatch(MatchesActions.clearError());
    }

    getCSVExport(): void {
        this.store.dispatch(MatchesActions.exportCsv());
    }
}