import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { Observable, throwError, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { AuthService } from "../../services";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((res) => {
        if (res instanceof HttpResponse) {
          if (res.status >= 400)
            throw new HttpErrorResponse({
              status: res.status,
              statusText: res.statusText,
              error: { message: res.body.message },
              url: res.url,
            });
        }

        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401 && !err.url.endsWith('/login')) {
          console.log(err);
          // Auto logout if 401 response returned from api
          this.authService.logout();
          location.reload(true);
          return throwError(err.error.msg);
        } else {
          console.error(
            `Backend returned code ${err.status}, ` +
              `message was: ${err.message}`
          );

          const error = err.error.msg || err.statusText;
          return throwError(error);  
        }
      })
    );
  }
}
