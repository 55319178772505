import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ProvidersService } from 'src/app/services/providers/providers.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { EndPoints } from 'src/app/constants/endpoints';
import { ValidatorsService } from 'src/app/services/validators/validators.service';
import { ApiService } from 'src/app/services';
@Component({
  selector: 'wtls-providers-form',
  templateUrl: './providers-form.component.html',
  styleUrls: ['./providers-form.component.scss']
})
export class ProvidersFormComponent implements OnInit {

	providerForm: FormGroup;
  submitted = false;

  
  constructor(
  	public router: Router,
    public formbuilder: FormBuilder,
    private providersService: ProvidersService,
    private snackbar: MatSnackBar,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private validationService: ValidatorsService
    ) {

    this.providerForm = this.formbuilder.group({
      first_name: ['',Validators.required ],
      last_name: ['',Validators.required ],
      phone:  ['',Validators.required ],
      email:['', [Validators.required, Validators.email] ],
      addressline1:['',Validators.required ],
      addressline2: ['',Validators.required ],
      city: ['',Validators.required ],
      state_abbr: ['',Validators.required ],
      country: ['',Validators.required ],
      zipcode: ['',Validators.required ],
      clinic_name:['',Validators.required ],
      status: ['',Validators.required ],
      stage_name: ['',Validators.required ],
      stage_name1: ['',Validators.required ],
      mon_startTime: [''],
      mon_endTime: [''],
      tue_startTime: [''],
      tue_endTime: [''],
      wed_startTime: [''],
      wed_endTime: [''],
      thu_startTime: [''],
      thu_endTime: [''],
      fri_startTime: [''],
      fri_endTime: [''],
      sat_startTime: [''],
      sat_endTime: [''],
      sun_startTime	: [''],
      sun_endTime: ['']
    
     
                      }); 
    
  }

  get f() { return this.providerForm.controls; }


  ngOnInit(): void {
  }

  cancelProviderForm() {
    //this.router.navigate(['/dashboard/unassigned'])
    window.location.href = EndPoints.BASE_URL+'dashboard/providersshow'
  }

  submitForm(){
    this.submitted = true
      console.log('profileSave')
      if(this.providerForm.invalid){
        console.log('Required value is blank',this.providerForm)
        return;
      }
      else{
        console.log("testproviders=>",this.providerForm);
        this.apiService.post('/providers/save-providers',this.providerForm.value).subscribe(
        //  this.http.post('http://localhost:4000/providers/csv1', this.providerForm.value).subscribe(
        (response: any) => {
            console.log('response',response)
            if(response){
            this.snackbar.open('Providers Saved Successfully', 'Dismiss', {
            verticalPosition: 'bottom',
            horizontalPosition: 'left',
            duration: 2750,
          }); 
          this.router.navigate(['/complete-providers'])
          //window.location.href = 'https://abaschedulezipper.com/dashboard/complete-providers';
          //window.location.href = EndPoints.BASE_URL+'complete-providers';
          }
          else{
            let alertMessage = `The field does not match a valid pattern`
            alert(alertMessage)
          }
        },
        error => {
          console.log('error', error)
        }
        );
      }
  }
  

}
