import { Pipe, PipeTransform } from '@angular/core';
import { TimeSlot } from '../models';
//import { Clienttime } from '../models';
import * as moment from 'moment';

@Pipe({ name: 'timeSlots' })
//@Pipe({ name: 'clienttime' })
export class TimeSlotsPipe implements PipeTransform {
  transform(timeSlots: TimeSlot[]): string {
    let str = '';
   // let line ='';
   
    console.log(timeSlots)
    timeSlots.forEach((timeSlot, index) => {


      if (!(index == 0)) str += ', ';
       
      switch (timeSlot.dayOfWeek) {
        case 1:
          str += 'Monday ' + moment(timeSlot.startTime).format("hh:mm a") +" - "+ moment(timeSlot.endTime).format("hh:mm a");
    
          break;
        case 2:
          str += 'Tuesday '+ moment(timeSlot.startTime).format("hh:mm a")  +" - "+ moment(timeSlot.endTime).format("hh:mm a");
         
          break;
        case 3:
          str += 'Wednesday '+ moment(timeSlot.startTime).format("hh:mm a")  +" - "+ moment(timeSlot.endTime).format("hh:mm a");
         
          break;
        case 4:
          str += 'Thursday '+ moment(timeSlot.startTime).format("hh:mm a") +" - "+ moment(timeSlot.endTime).format("hh:mm a");
        
          break;
        case 5:
          str += 'Friday '+ moment(timeSlot.startTime).format("hh:mm a") +" - "+ moment(timeSlot.endTime).format("hh:mm a");
         
          break;
     /*    case 6:
          str += 'Saturday '+ moment(timeSlot.startTime).format("hh:mm a") +" - "+ moment(timeSlot.endTime).format("hh:mm a");
          break;
        case 7:
          str += 'Sunday '+ moment(timeSlot.startTime).format("hh:mm a")  +" - "+ moment(timeSlot.endTime).format("hh:mm a");
          break; */
      }
      
      switch (timeSlot.startTime.getHours()) {
        case 4:
          //str += 'morning';
          break;

        case 11:
          //str += 'afternoon';
          break;
      }
    });
    
    
   let str1 =str;
   let split_string = str1.split(",");  
   console.log("asd=>", split_string);
   

    return str1;
   
  }
}
