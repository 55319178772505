<div class="form-field" [formGroup]="parentGroup">
  <label [for]="formField.id">{{
    formField.isRequired
      ? (formField.label | uppercase) + "*"
      : (formField.label | uppercase)
  }}</label>
  <div *ngIf="formField.type == 'text'" class="field">
    <input
      type="text"
      [id]="formField.id"
      class="text"
      [formControlName]="formField.id"
    />
  </div>
  <div *ngIf="formField.type == 'textbox'" class="field">
    <textarea class="textbox" [id]="formField.id"></textarea>
  </div>
  <div *ngIf="formField.type == 'date'" class="field">
    <div
      class="date-box"
      [ngClass]="{
        'ng-invalid ng-touched':
          parentGroup.get('' + formField.id).invalid &&
          parentGroup.get('' + formField.id).errors &&
          (parentGroup.get('' + formField.id).dirty ||
            parentGroup.get('' + formField.id).touched)
      }"
    >
      <input
        matInput
        type="text"
        [id]="formField.id"
        [max]="endDate"
        [matDatepicker]="picker"
        [formControlName]="formField.id"
      />
      <mat-datepicker #picker></mat-datepicker>
      <button
        type="button"
        class="icon-button ms-Fabric"
        aria-label="Open date picker"
        (click)="picker.open()"
      >
        <i class="ms-Icon ms-Icon--CalendarWeek"></i>
      </button>
    </div>
  </div>
  <div *ngIf="formField.type == 'email'" class="field">
    <input
      type="email"
      [id]="formField.id"
      class="text"
      [formControlName]="formField.id"
    />
  </div>
  <div *ngIf="formField.type == 'phone'" class="field">
    <div class="phone-field">
      <span class="seperator">(</span>
      <input
        type="text"
        class="phone"
        [ngClass]="{
          'ng-invalid ng-touched':
            parentGroup.controls['' + formField.id].invalid &&
            (parentGroup.controls['' + formField.id].dirty ||
              parentGroup.controls['' + formField.id].touched)
        }"
        (input)="
          phoneChange(
            phoneFirst.value,
            phoneFirst.attributes['maxlength'].value,
            null,
            phoneSecond
          )
        "
        (blur)="phoneBlur($event)"
        maxlength="3"
        #phoneFirst
      />
      <span class="seperator">)</span>
      <input
        type="text"
        class="phone"
        [ngClass]="{
          'ng-invalid ng-touched':
            parentGroup.controls['' + formField.id].invalid &&
            (parentGroup.controls['' + formField.id].dirty ||
              parentGroup.controls['' + formField.id].touched)
        }"
        (input)="
          phoneChange(
            phoneSecond.value,
            phoneSecond.attributes['maxlength'].value,
            phoneFirst,
            phoneThird
          )
        "
        (blur)="phoneBlur($event)"
        maxlength="3"
        #phoneSecond
      />
      <span class="seperator">-</span>
      <input
        type="text"
        class="phone"
        [ngClass]="{
          'ng-invalid ng-touched':
            parentGroup.controls['' + formField.id].invalid &&
            (parentGroup.controls['' + formField.id].dirty ||
              parentGroup.controls['' + formField.id].touched)
        }"
        (input)="
          phoneChange(
            phoneThird.value,
            phoneThird.attributes['maxlength'].value,
            phoneSecond,
            null
          )
        "
        (blur)="phoneBlur($event)"
        maxlength="4"
        #phoneThird
      />
    </div>
    
    <input
      type="hidden"
      [id]="formField.id"
      class="text"
      [formControlName]="formField.id"
      #phoneFull
    />
  </div>
  
  <div *ngIf="formField.type == 'select' && formField.options[0].id" class="field" >
    <wtls-select [id]="formField.id" 
   
    [formControlName]="formField.id"  >
     
      <wtls-select-option
        *ngFor="let option of formField.options"
        [key]="option.id"      
        [value]="option.text"      
      ></wtls-select-option>
     
    </wtls-select>

  </div>
 

  <div *ngIf="formField.type == 'file'" class="field">
    <div class="ms-Fabic file-field">
      <input
        type="file"
        (change)="upload($event.target.files)"
        [id]="formField.id"
        style="display: none"
        #fileUpload
      />
      <div class="file-upload">
        <button
          type="button"
          (click)="fileUpload.click()"
          class="button upload-button"
          title="Upload a file"
        >
        <div class="upload-text">
          <i class="ms-Icon ms-Icon--Add"></i>
          <span class="button-text">Upload File</span>
        </div>
        <div class="file-name-text">
          <div *ngIf="parentGroup.controls['' + formField.id].value; else elseBlock" class="name">
            {{ parentGroup.controls["" + formField.id].value?.name | truncateEllipsis: 17 }}
          </div>
          <ng-template #elseBlock>
            <i class="ms-Icon ms-Icon--Add"></i>
            <span class="button-text">Upload File</span>
          </ng-template>
        </div>
        </button>
        <span
          class="file-name"
          [title]="parentGroup.controls['' + formField.id].value?.name"
          >{{ parentGroup.controls["" + formField.id].value?.name }}</span
        >
      </div>
      <div class="file-size">
        <span>{{
          parentGroup.controls["" + formField.id].value?.size | bytes
        }}</span>
      </div>
    </div>
  </div>
  <div class="error">
    <div
      *ngIf="
        parentGroup.controls['' + formField.id].invalid &&
        (parentGroup.controls['' + formField.id].dirty ||
          parentGroup.controls['' + formField.id].touched)
      "
    >
      <span
        *ngIf="parentGroup.controls['' + formField.id].errors.required"
        class="error-text"
      >
        This field is required
      </span>
      <span
        *ngIf="parentGroup.controls['' + formField.id].errors.pattern"
        class="error-text"
      >
        Invalid phone number
      </span>
    </div>
  </div>
</div>
