<wtls-top-bar></wtls-top-bar>
<div class="page-container" >
  <div class="content-container">
    <div class="spinner-wrapper" *ngIf="!formGroup">
      <mat-progress-spinner color="primary" mode="indeterminate">
      </mat-progress-spinner>
    </div>
    <div *ngIf="formGroup" class="container">
      <form
        class="form"
        (keydown.enter)="$event.preventDefault()"
        [formGroup]="formGroup"
        (ngSubmit)="submitForm()"
        #mform="ngForm"
      >
        <div class="header-section" >
          <h2 class="title">{{ form?.title }}</h2>
          <span class="subtitle">{{ form?.subtitle | uppercase }}</span>
        </div>
        <wtls-form-section
          *ngFor="let section of form?.sections"
          [parentGroup]="formGroup"
          [formSection]="section"
        ></wtls-form-section>
        <div class="footer-section">
          <hr />
          <re-captcha (resolved)="resolveCaptcha($event)" formControlName="recaptcha"></re-captcha>
          <div
            *ngIf="formGroup.controls['recaptcha'].invalid && (formGroup.controls['recaptcha'].dirty || formGroup.controls['recaptcha'].touched)"
            class="error"
          >
            <div *ngIf="formGroup.controls['recaptcha'].errors.required" class="error-text">Complete the reCAPTCHA</div>
          </div>
          <button
            type="submit"
            [disabled]="submitting$ | async"
            class="button submit-button"
          >
            <span class="button-text">Submit Form</span>
          </button>
          <button
            type="button"
            (click)="cancelForm()"
            class="button cancel-button"
          >
            <span class="button-text">Cancel</span>
          </button>
          <div class="spinner-wrapper" *ngIf="submitting$ | async">
            <mat-progress-spinner
              color="primary"
              mode="indeterminate"
              diameter="40"
            >
            </mat-progress-spinner>

            
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
