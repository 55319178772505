import { createReducer, on, Action } from '@ngrx/store';
import { FormResponse, Page } from 'src/app/models';
import { NewClientsActions } from '../actions';

export interface NewClientsState {
    page: Page<FormResponse>,
    loading: boolean
}

const initialState: NewClientsState = {
    page: null,
    loading: false
}

const reducer = createReducer(
    initialState,
    on(NewClientsActions.loadNewClients, (state) => ({ ...state, loading: true })),
    on(NewClientsActions.loadNewClientsSuccess, (state, { page }) => ({ ...state, page, loading: false })),
    on(NewClientsActions.loadNewClientsError, (state) => ({ ...state, loading: false })),
    on(NewClientsActions.exportCSV, (state) => ({ ...state, loading: true })),
    on(NewClientsActions.exportCSVSuccess, (state) => ({ ...state, loading: false })),
    on(NewClientsActions.exportCSVError, (state) => ({ ...state, loading: false })),
)

export function newClientsReducer(state: NewClientsState | undefined, action: Action): NewClientsState {
    return reducer(state, action);
}