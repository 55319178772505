<div class="map-container" [ngClass]="{'map-border': !mapFullScreen}">
    <!-- <div #map class="map"></div> -->
    <agm-map class="map"  style="width:100%;" [latitude]="37.0902" [longitude]="-95.7129" (mapReady)="mapReady($event)" [fitBounds]="true" zoom="1"> 
        <wtls-patient-map-marker *ngFor="let patient of patientsToDisplay" [patient]="patient"></wtls-patient-map-marker>
        <wtls-provider-map-marker *ngFor="let provider of providersToDisplay" [provider]="provider"></wtls-provider-map-marker>
        <wtls-match-map-marker *ngFor="let match of matches; index as i" [match]="match" [index]="i + 1"></wtls-match-map-marker>
    </agm-map>
    <div class="controls">
        <div #legend class="control legend">
            <div class="legend-key">
                <div style="background-color: #E3008C;" class="color-block"></div>
                <div class="key-name">
                    Client
                </div>
            </div>
            <div class="legend-key">
                <div style="background-color: #106EBE;" class="color-block"></div>
                <div class="key-name">
                    Provider
                </div>
            </div>
        </div>
        <div #fullScreenControl class="control fullscreen-control">
            <button type="button" class="icon-btn" (click)="toggleFullScreen()">
                <mat-icon *ngIf="!mapFullScreen">fullscreen</mat-icon>
                <mat-icon *ngIf="mapFullScreen">fullscreen_exit</mat-icon>
            </button>
        </div>
        <div #fixedControl class="control fixed-control">
            <button type="button" class="icon-btn border-light-bottom" (click)="centerMap()">
                <mat-icon>gps_fixed</mat-icon>
            </button>
        </div>
        <div #zoomControl class="control zoom-control">
            <button type="button" class="icon-btn border-light-bottom" (click)="zoomIn()">
                <mat-icon>add</mat-icon>
            </button>
            <button type="button" class="icon-btn" (click)="zoomOut()">
                <mat-icon>remove</mat-icon>
            </button>
        </div>
        <!-- <div #matchControl class="control match-control">
            <wtls-match *ngIf="mapFullScreen"></wtls-match>
        </div> -->
        <div #matchSideWindow class="control match-side-window-control">
            <h2>Client/Provider #1</h2>
            <p>Hello!</p>
        </div>
    </div>
</div>
