import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup,Validators  } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EndPoints } from 'src/app/constants/endpoints';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../dashboard/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'wtls-editproviders',
  templateUrl: './editproviders.component.html',
  styleUrls: ['./editproviders.component.scss']
})
export class EditprovidersComponent implements OnInit {
  form: FormGroup;
  id: number;
  li:any;
  lis=[];
  selectlist:any;
  selectlistorders=[];
  dataset = [];
  data:any;
  submitted = false;
  constructor(private router: Router,
     private route: ActivatedRoute,
      private formBuilder: FormBuilder,
      private http: HttpClient,
       private apiService: ApiService,
       private snackbar: MatSnackBar,
       private dialog: MatDialog
       ) { 
  
  this.form = this.formBuilder.group({
    first_name: ['',Validators.required ],
    last_name: ['',Validators.required ],
    phone:  ['',Validators.required ],
    email:['', [Validators.required, Validators.email] ],
    addressline1:['',Validators.required ],
    addressline2: ['',Validators.required ],
    city: ['',Validators.required ],
    state_abbr: ['',Validators.required ],
    country: ['',Validators.required ],
    zipcode: ['',Validators.required ],
    clinic_name:['',Validators.required ],
    status: ['',Validators.required ],
    stage_name: ['',Validators.required ],
    stage_name1: ['',Validators.required ],
    mon_startTime: [''],
    mon_endTime: [''],
    tue_startTime: [''],
    tue_endTime: [''],
    wed_startTime: [''],
    wed_endTime: [''],
    thu_startTime: [''],
    thu_endTime: [''],
    fri_startTime: [''],
    fri_endTime: [''],
    sat_startTime: [''],
    sat_endTime: [''],
    sun_startTime	: [''],
    sun_endTime: ['']

  })
}
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id']; 
    });
   

    this.apiService.get('/providersshow/'+this.id)
    .subscribe(Response => {
 
      if(Response){ 
        console.log("edit=>",Response)
      }
      console.log("edit=>",Response)
      this.li=Response;
      this.lis=this.li;
     
      
    });
////get users
   // this.http.get('http://localhost:4000/createusers/')
   this.apiService.get('/providersshow/')
    .subscribe(Response => {
      if(Response){ 
      }
      console.log("datatest=>",Response)
      this.selectlist=Response;
      this.selectlistorders=this.selectlist;
      console.log("datatest1=>",this.selectlistorders)
    });
  /// get users
 
  }
  get f() { return this.form.controls; }
  showMsg: boolean = false;
  submitForm() {
    this.showMsg=false
      let Formpostdata = this.form.value;
      console.log("test=>",this.form.value)
         // this.http.put('http://localhost:4000/createcompany/'+this.id,Formpostdata).subscribe(
            this.apiService.put('/providersshow/'+this.id,Formpostdata).subscribe(
          
        
            /* (response) => {
              //this.router.navigate(['dashboard/editproviders/'+this.id])
              console.log('response',response)
              this.showMsg= true
            }, */
            (response) =>{
              console.log("upadelocation",response)
              this.snackbar.open('Provider Update Successful', 'Dismiss', {
              verticalPosition: 'bottom',
              horizontalPosition: 'left',
            }); 
            window.location.href = EndPoints.BASE_URL+'dashboard/providersshow';
            },
            
            (error) => console.log('error',error)
          );  
   
  }
  selectusers()
{
  //this.http.get('http://localhost:4000/createusers/')
  this.apiService.get('/providersshow/')

    .subscribe(Response => {
 
      // If response comes hideloader() function is called
      // to hide that loader
      if(Response){ 
        hideloader();
      }
      console.log("datatest=>",Response)
      this.li=Response;
      this.lis=this.li;
      console.log("datatest1=>",this.lis)
     
      
    });
    function hideloader(){
      document.getElementById('loading').style.display = 'none';}

}
cancelForm(): void {
  this.router.navigate(['/dashboard/providersshow'])
}
deleteProviderForm()
{
  let data = this.id;
  let dialogRef = this.dialog.open(ConfirmDialogComponent, {
    data: 'providersdelete',
    disableClose: true
  })
  dialogRef.beforeClosed().subscribe(
    ()=>{
      let deleteid = this.id;
    
      this.apiService.delete('/providersshow/'+ this.id).subscribe(
   
        (response) =>{
          this.snackbar.open('Providers Delete Successful!', 'Dismiss', {
          verticalPosition: 'bottom',
          horizontalPosition: 'left',
        }); 
        window.location.href = EndPoints.BASE_URL+'/providersshow';
        },
       
       (error) => console.log(error)
     );  
    },
    ()=>{
      console.error("this is error");
      
    }
  )

}

}
