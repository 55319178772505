import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Answer, Form, FormField } from 'src/app/models';
import { ApiService } from 'src/app/services';
import { FormFacade } from 'src/app/services/facades/form.facade';

@Component({
  selector: 'wtls-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.scss']
})
export class ClientFormComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;
  form: Form;
  li:any;
  lis=[];
  fields: FormField[] = [];

  loading$: Observable<boolean>;
  submitting$: Observable<boolean>;

  recaptchaResponse: string;

  subscriptions = new Subscription();

  private _formID: number

  constructor(
    private route: ActivatedRoute, 
    private formFacade: FormFacade, 
    private snackbar: MatSnackBar,
    private router: Router,
    private apiService: ApiService,
  ) { }

  
  ngOnInit(): void {
    this._formID = this.route.snapshot.data['formID'];

    const sub1 = this.formFacade.getForm().subscribe(
      (form) => {
        this.form = form;
        
        if (form) {
          //TODO create form group
          let controls: { [key: string]: FormControl } = {};
  
          form.sections.forEach((section) => {
            section.fields.forEach((field) => {
              // Check types and assign validation
              this.fields.push(field);
  
              let validators: ValidatorFn[] = [];
  
              if (field.isRequired) {
                validators.push(Validators.required);
              }
  
              if (field.type == 'phone') {
                validators.push(
                  Validators.pattern(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/)
                );
              }
  
              controls[field.id] = new FormControl('', validators);
            });
          });

          controls['recaptcha']= new FormControl('', Validators.required);
  
          this.formGroup = new FormGroup(controls);
        }
      }
    )
    this.loading$ = this.formFacade.getLoading();
    this.submitting$ = this.formFacade.getSubmitting();

    const sub2 = this.formFacade.getError().subscribe((err) => {
      if (err) {
        this.snackbar.open(`ERROR: ${err}`, 'Dismiss', {
          verticalPosition: 'bottom',
          horizontalPosition: 'left',
        });

        //TODO clear error
      }
    });

    this.subscriptions.add(sub1);
    this.subscriptions.add(sub2);

    this.formFacade.loadForm(this._formID);

    //form title from company n
    this.apiService.get('/createcompany')
    .subscribe(Response => {
 
      // If response comes hideloader() function is called
      // to hide that loader
      if(Response){ 
       
      }
    
      console.log("datatest=>",Response)
      this.li=Response;
      this.lis=this.li;
  
    });
   

    /////code form tile
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  resolveCaptcha(res: string): void {
    this.recaptchaResponse = res;
  }

  cancelForm(): void {
    //this.router.navigate(['/dashboard/new-clients'])
    this.router.navigate(['/dashboard/clientsshow'])
  }

  submitForm(): void {
   
   // console.log(this.formGroup.value);

    if (this.formGroup.valid && this.recaptchaResponse) {
      let answers: Answer[] = [];

      this.fields.forEach((field) => {
        const value = this.formGroup.value[field.id];
        console.log('value', value)
        if (value) {
          if (field.type == 'date') {
            answers.push({
              formFieldId: field.id,
              value: `${value.getTime()}`,
              selectOptionId: null,
            });

            if (answers.length == this.fields.length) {
              this.formFacade.submitAnswers(this._formID, answers, this.recaptchaResponse);
            }
          } else if (field.type == 'select') {
            answers.push({
              formFieldId: field.id,
              value: '',
              selectOptionId: this.formGroup.value[field.id],
            });

            if (answers.length == this.fields.length) {
              this.formFacade.submitAnswers(this._formID, answers, this.recaptchaResponse);
            }
          } else if (field.type == 'file') {
            this.formFacade
              .uploadDocument(this.formGroup.value[field.id])
              .subscribe((url) => {
                answers.push({
                  formFieldId: field.id,
                  value: url,
                  selectOptionId: null,
                });

                if (answers.length == this.fields.length) {
                  this.formFacade.submitAnswers(this._formID, answers, this.recaptchaResponse);
                }
              });
          } else {
            answers.push({
              formFieldId: field.id,
              value: this.formGroup.value[field.id],
              selectOptionId: null,
            });

            if (answers.length == this.fields.length) {
              this.formFacade.submitAnswers(this._formID, answers, this.recaptchaResponse);
            }
          }
        } else {
          answers.push({
            formFieldId: field.id,
            value: '',
            selectOptionId: null,
          });

          if (answers.length == this.fields.length) {
            this.formFacade.submitAnswers(this._formID, answers, this.recaptchaResponse);
          }
        }
      });
    } else {
      this.formGroup.markAllAsTouched();

      this.snackbar.open('Please make sure all fields are valid', 'Dismiss', {
        verticalPosition: 'bottom',
        horizontalPosition: 'left',
      });
    }
  }


}
