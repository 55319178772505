import { Component, OnInit, Renderer2, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { FilterNode, Patient } from 'src/app/models';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import { FiltersSelectors } from 'src/app/store/selectors';
import { Subscription } from 'rxjs';
import { FiltersState } from 'src/app/store/reducers';
import lodash from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { PatientsFacade } from 'src/app/services/facades/patients.facade';
import { MatchFacade } from 'src/app/services/facades/match.facade';
import { FiltersFacade } from 'src/app/services/facades/filters.facade';
import { take } from 'rxjs/operators';
import { ProvidersFacade } from 'src/app/services/facades/providers.facade';
import { AddEditPatientFacade } from 'src/app/services/facades/addEditPatient.facade';

@Component({
  selector: 'wtls-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss']
})
export class PatientsComponent implements OnInit, OnDestroy {
  @ViewChild('fileUpload') fileUpload: ElementRef;
  
  @Input() patients: Patient[];
  @Input() matchPatient: Patient;
  @Input() loading: boolean;

  // patients: Patient[];
  
  filters: FiltersState;

  treeData: FilterNode[] = [
    {
      name: 'Filters',
      children: [
        {
          name: 'Age',
          children: [
            {
              name: '',
              field: 'age',
              filterType: 'minMax',
              data: []
            },
          ]
        }, {
          name: 'Client Type',
          children: [
            {
              name: '',
              field: 'clientType',
              filterType: 'checkBoxes',
              data: []
            },
          ]
        }, {
          name: 'Insurance Type',
          children: [
            {
              name: '',
              field: 'insuranceType',
              filterType: 'checkBoxes',
              data: []
            }
          ]
        }, {
          name: 'Intake Stage',
          children: [
            {
              name: '',
              field: 'intakeStage',
              filterType: 'checkBoxes',
              data: []
            }
          ]
        }
      ]
    },
  ];

  selected;

  subscriptions = new Subscription();

  constructor(
    private ren: Renderer2, 
    private store: Store<AppState>,
    private dialog: MatDialog,
    private filtersFacade: FiltersFacade,
    private patientsFacade: PatientsFacade,
    private providersFacade: ProvidersFacade,
    private matchFacade: MatchFacade,
    private addEditPatientFacade: AddEditPatientFacade
  ) {    
    const sub1 = this.store.select(FiltersSelectors.selectAgeFilter).subscribe(age => {
      this.treeData[0].children[0].children[0].data = [ { 
        min: age.min, 
        max: age.max 
      }];
    });

    const sub2 = patientsFacade
      .getAllPatients()
      .subscribe(
        patients => {
          this.store
            .select(FiltersSelectors.selectClientTypesFilter)
            .pipe(take(1))
            .subscribe(
              clientTypes => {
                let distinct = [ ...new Set(patients.map(p => p.clientType)) ];
                
                const filters = distinct.map(filter => {
                  if (clientTypes.includes(filter)) {
                    return {
                      name: filter,
                      value: true
                    }
                  } else {
                    return {
                      name: filter,
                      value: false
                    }
                  }
                })

                this.treeData[0].children[1].children[0].data = filters.filter(filter => !(filter.name == ''));
              }
            )

          this.store
            .select(FiltersSelectors.selectInsuranceTypesFilter)
            .pipe(take(1))
            .subscribe(
              insuranceTypes => {
                let distinct = [ ...new Set(patients.map(p => p.insuranceType)) ];
                
                const filters = distinct.map(filter => {
                  if (insuranceTypes.includes(filter)) {
                    return {
                      name: filter,
                      value: true
                    }
                  } else {
                    return {
                      name: filter,
                      value: false
                    }
                  }
                })

                this.treeData[0].children[2].children[0].data = filters.filter(filter => !(filter.name == ''));
              }
            )

          this.store
            .select(FiltersSelectors.selectIntakeStagesFilter)
            .pipe(take(1))
            .subscribe(
              intakeStages => {
                let distinct = [ ...new Set(patients.map(p => p.intakeStage)) ];
                
                const filters = distinct.map(filter => {
                  if (intakeStages.includes(filter)) {
                    return {
                      name: filter,
                      value: true
                    }
                  } else {
                    return {
                      name: filter,
                      value: false
                    }
                  }
                })

                this.treeData[0].children[3].children[0].data = filters.filter(filter => !(filter.name == ''));
              }
            )
        }
      )

    const sub3 = this.store.select('filters').subscribe(filters => {
      this.filters = lodash.cloneDeep(filters);
    });

    this.subscriptions.add(sub1);
    this.subscriptions.add(sub2);
    this.subscriptions.add(sub3);
  }

  ngOnInit(): void {
     this.setHasSchedule(false)
    //this.setHasSchedule(true)
     //this.setHasSchedule(false)
  }

  ngOnDestroy(): void {

    console.log('test');
    this.subscriptions.unsubscribe();
  }

  checkStateChange(el): void {
    setTimeout(() => {
      if (this.selected && this.selected === el.value) {
        el.checked = false;
        this.ren.removeClass(el['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(el['_elementRef'].nativeElement, 'cdk-program-focused');
        this.selected = null;
      } else {
        this.selected = el.value
      }
    })
  }

  filterChanged(filter): void {
   
    switch(filter.field) {
      case 'age':
          if (filter.name === 'minAge') {
            this.filters.age.min = filter.value;
          } else if (filter.name === 'maxAge') {
            this.filters.age.max = filter.value;
          }
        break;

      case 'clientType':
          if (this.filters.clientTypes.includes(filter.name)) {
            this.filters.clientTypes = this.filters.clientTypes.filter(type => type !== filter.name);
          } else {
            this.filters.clientTypes.push(filter.name);
          }
        break;

      case 'insuranceType':
        if (this.filters.insuranceTypes.includes(filter.name)) {
          this.filters.insuranceTypes = this.filters.insuranceTypes.filter(type => type !== filter.name);
        } else {
          this.filters.insuranceTypes.push(filter.name);
        }
        break;

      case 'intakeStage':
        if (this.filters.intakeStages.includes(filter.name)) {
          this.filters.intakeStages = this.filters.intakeStages.filter(type => type !== filter.name);
        } else {
          this.filters.intakeStages.push(filter.name);
        }
        break;
      
   
      
    }

    this.updateAndLoadPatients();
  }

  filterNameChange(name: string) {
    this.filters.patientName = name;

    this.updateAndLoadPatients();
  }

  setHasSchedule(hasShedule: boolean): void {
    console.log(hasShedule);
    if (this.filters.patientsHasSchedule != hasShedule) {
      this.filters.patientsHasSchedule = hasShedule;

      this.updateAndLoadPatients();
    }
  }

  updateAndLoadPatients(): void {
    this.filtersFacade.updateUnassignedFilters(this.filters);
    this.patientsFacade.loadPatients();
  }

  changePatient(patient: Patient): void {
    if (!this.loading)
      this.matchFacade.selectPatient(patient);
  }

  editPatient(patient: Patient): void {
    // open sidebar
    
    this.addEditPatientFacade.setPatient(patient);
    this.addEditPatientFacade.openPanel();
  }

  deletePatient(patient: Patient): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: patient
    })

    dialogRef.beforeClosed().subscribe(result => {
      if (result) {      
        this.patientsFacade.deletePatient(patient).subscribe(result => {
          console.log("DELETED PATIENT");
        })
      }
    })
  }

  csvUpload(fileList: FileList): void {
    if (fileList.length > 0) {
      const files = Array.from(fileList);
      if (!files.some((file) => file.name.endsWith('.csv'))) {
        alert('One or more files selected were not of type CSV.');
      } else {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: 'uploadCSVpatients'
        });

        dialogRef.beforeClosed().subscribe(result => {
          if (result) {
            this.patientsFacade
              .uploadCSV(files)
              .subscribe((result) => {
                this.patientsFacade
                  .loadNewPatients()
                  .pipe(take(1))
                  .subscribe(() => {
                    this.patientsFacade.clearAllPatients();
                    this.providersFacade.clearAllProviders();

                    this.providersFacade.loadProviders();
                  },
                  error => {
                    this.providersFacade.loadProviders();
                  });
                  console.log('results',result)
                if(result.flag == false){
                  this.dialog.open(ConfirmDialogComponent, {
                    data: 'uploadFailure,'+result.field+','+result.row
                  })
                }
                this.setHasSchedule(false)

                this.fileUpload.nativeElement.value = '';
              },
              error => {
                alert(error);

                this.fileUpload.nativeElement.value = '';
              });

            // Clear files
          } else {
            this.fileUpload.nativeElement.value = '';
          }
        });
      }
    }
  }
}
