import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { Observable, throwError, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { Params } from "src/app/models";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private baseUrl: string = environment.apiUrl;
  // headers: HttpHeaders = new HttpHeaders({
  //   'Content-Type': 'application/x-www-form-urlencoded',
  //   Authorization:
  //     'Basic ' + btoa(environment.apiKey + ':' + environment.secretKey),
  // });

  constructor(private http: HttpClient) {}

  get<T>(endpoint: string, options?: { params?: HttpParams | { [param: string]: string | string[] } }): Observable<T> {
    if (options && options.params) {
      return this.http.get<T>(this.baseUrl + endpoint, {
        params: options.params,
      });
    } else {
      return this.http.get<T>(this.baseUrl + endpoint);
    }
  }

  getCSV(endpoint: string): Observable<string> {
    return this.http.get(this.baseUrl + endpoint, {
      responseType: 'text'
    })
  }

  exportProviders(endpoint: string): Observable<any> {
    return this.http.get(this.baseUrl+endpoint, {
      responseType: 'text'
    })
  }

  // getCSV(endpoint: string): Observable<string> {
  //   return this.http.get(this.baseUrl + endpoint, {
  //     responseType: 'text'
  //   })
  // }

  post<T>(
    endpoint: string,
    data: any,
    options?: { headers?: { [header: string]: string | string[] } }
  ): Observable<T> {
    if (options && options.headers) {
      return this.http.post<T>(this.baseUrl + endpoint, data, {
        headers: options.headers,
      });
    } else {
      return this.http.post<T>(this.baseUrl + endpoint, data);
    }
  }

  put<T>(
    endpoint: string,
    data: any,
    options?: { headers?: { [header: string]: string | string[] } }
  ): Observable<T> {
    if (options && options.headers) {
      return this.http.put<T>(this.baseUrl + endpoint, data, {
        headers: options.headers,
      });
    } else {
      console.log(endpoint);
      return this.http.put<T>(this.baseUrl + endpoint, data);
    }
  }

  delete<T>(endpoint: string): Observable<T> {
    return this.http.delete<T>(this.baseUrl + endpoint);
  }

  getproviderdetails<T>(endpoint: string,selected_values: any): Observable<T> {
    console.log("test=>",endpoint);
    console.log("this.baseUrl + endpoint => ",this.baseUrl + endpoint);
    return this.http.post<T>(this.baseUrl + endpoint,selected_values);
  }
  getappionmentdetails(endpoint: string): Observable<string>
  {
    console.log("rag=>",this.baseUrl + endpoint);

    return this.http.get<string>(this.baseUrl + endpoint);
  }
 
  updateproviderdetail<T>(endpoint: string,selected_values: any): Observable<T> {
    console.log("test=>",endpoint);
    console.log("this.baseUrl + endpoint => ",this.baseUrl + endpoint);
    return this.http.post<T>(this.baseUrl + endpoint,selected_values);
  }
 saveappionmentdetails<T>(endpoint: string,data: any): Observable<T> {
    console.log("SAVE APPOINMENT PROVIDER_ID CLIENT_ID=>",data);
    console.log("this.baseUrl + endpoint => ",this.baseUrl + endpoint);
    return this.http.post<T>(this.baseUrl + endpoint,data);
  } 

  
  

  // callApi<T>(endpoint: string, params?: Params): Observable<any> {
  //   params.append('params[authToken]', localStorage.getItem('token'));

  //   return this.http
  //     .post<KumulosResponse<T>>(
  //       this.baseUrl + endpoint,
  //       params.createString(),
  //       { headers: this.headers }
  //     )
  //     .pipe(
  //       map((response) => {
  //         if (!response.payload.success) {
  //           throwError(response.payload.message).subscribe();
  //           if(response.payload.message != 'Unauthorized') {
  //             alert(response.payload.message);
  //           }
  //         }
  //         return response.payload.data;
  //       }),
  //       // catchError((error) => {
  //       //   console.log('ERROR!');
  //       //   return this.handleError(endpoint, error);
  //       // })
  //     );
  // }

  private handleError(error: HttpErrorResponse) {
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
          `message was: ${error.message}`
      );
    }
    // Return an observable with a user-facing error message.
    return throwError(error);
  }
}
