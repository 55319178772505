import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FilterNode } from 'src/app/models';
import { NestedTreeControl } from '@angular/cdk/tree';
import { ArrayDataSource } from '@angular/cdk/collections';

@Component({
  selector: 'wtls-filter-tree',
  templateUrl: './filter-tree.component.html',
  styleUrls: ['./filter-tree.component.scss']
})
export class FilterTreeComponent implements OnInit {
  @Input() treeData: FilterNode[];
  @Output() filterChange = new EventEmitter();

  treeControl: NestedTreeControl<FilterNode>;
  dataSource: ArrayDataSource<FilterNode>;

  hasChild = (_: number, node: FilterNode) => !!node.children && node.children.length > 0;
  isFilter = (_: number, node: FilterNode) => node.filterType && node.filterType.length > 0;

  isMinMax = (_: number, node: FilterNode) => this.isFilter(_, node) && node.filterType == 'minMax';
  isCheckboxes = (_: number, node: FilterNode) => this.isFilter(_, node) && node.filterType == 'checkBoxes';

  constructor() { }

  ngOnInit(): void {
    // Must set after init, when treeData is loaded
    this.treeControl = new NestedTreeControl<FilterNode> (node => node.children);
    this.dataSource = new ArrayDataSource(this.treeData);
  }

  filterChanged(node, value, field?): void {
    this.filterChange.emit({ field: node.field, name: field, value: value });
  }
}
