
<div class="page-container">
    <div class="content-container">
        <div class="jumbotron">
            <div class="container-fluid">
      <div class="container">

        
        <form [formGroup]="form" (ngSubmit)="submitForm()" autocomplete="on"
        >

          <div class="header-section">
            <h2 class="title">Book Appointment</h2>
          </div>
 

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                      <label for="name"> Client </label>
                      <input type="text" formControlName="patientName"  [value]="patientName"   class="form-control"   placeholder="Full Name">
                      <!-- <div *ngIf="submitted && f.patientName.errors" class="invalid-feedback">
                        <div *ngIf="f.patientName.errors.required">This field is required</div>
                    </div> -->
                </div>
              </div>
            <div class="col-md-6">
                <div class="form-group">
                      <label for="name"> Provider </label>
                      <input type="text" formControlName="providerName"  [value]="providerName"   class="form-control" placeholder="Full Name" >
                      <!-- <div *ngIf="submitted && f.providerName.errors" class="invalid-feedback">
                        <div *ngIf="f.providerName.errors.required">This field is required</div>
                    </div> -->
                    </div>
            </div>
                

                  
              </div>

              
              <div class="row">
                   <!--Monday is open 11:30 AM  to  05:00 PM--->
              
              <div class="col-md-4">
                <div class="form-group" >
                  Day            
                   <input type="text"    class="form-control" value="Monday"  style="background-color: #f2f2f2">
                  </div>  
                  </div>  
                 
                  <div class="col-md-4">
                    <div class="form-group" >
                      Start
                      <select class="select-input form-control" formControlName="mon_startTime"  style="background-color: #f2f2f2">
                       
                        <option value="00:00:00" [selected]="1">12:00 AM</option>
            <option value="00:30:00" selected="selected">12:30 AM</option>
            <option value="01:00:00" selected="selected">1:00 AM</option>
            <option value="01:30:00" selected="selected">1:30 AM</option>
            <option value="02:00:00" selected="selected">2:00 AM</option>
            <option value="02:30:00" selected="selected">2:30 AM</option>
            <option value="03:00:00" selected="selected">3:00 AM</option>
            <option value="03:30:00" selected="selected">3:30 AM</option>
            <option value="04:00:00" selected="selected">4:00 AM</option>
            <option value="04:30:00" selected="selected">4:30 AM</option>
            <option value="05:00:00" selected="selected">5:00 AM</option>
            <option value="05:30:00" selected="selected">5:30 AM</option>
            <option value="06:00:00" selected="selected">6:00 AM</option>
            <option value="06:30:00" selected="selected">6:30 AM</option>
            <option value="07:00:00" selected="selected">7:00 AM</option>
            <option value="07:30:00" selected="selected">7:30 AM</option>
            <option value="08:00:00" selected="selected">8:00 AM</option>
            <option value="08:30:00" selected="selected">8:30 AM</option>
            <option value="09:00:00" selected="selected">9:00 AM</option>
            <option value="09:30:00" selected="selected">9:30 AM</option>
            <option value="10:00:00" selected="selected">10:00 AM</option>
            <option value="10:30:00" selected="selected">10:30 AM</option>
            <option value="11:00:00" selected="selected">11:00 AM</option>
            <option value="11:30:00" selected="selected">11:30 AM</option>
            <option value="12:00:00" selected="selected">12:00 PM</option>
            <option value="12:30:00" selected="selected">12:30 PM</option>
            <option value="13:00:00" selected="selected">1:00 PM</option>
            <option value="13:30:00" selected="selected">1:30 PM</option>
            <option value="14:00:00" selected="selected">2:00 PM</option>
            <option value="14:30:00" selected="selected">2:30 PM</option>
            <option value="15:00:00" selected="selected">3:00 PM</option>
            <option value="15:30:00" selected="selected">3:30 PM</option>
            <option value="16:00:00" selected="selected">4:00 PM</option>
            <option value="16:30:00" selected="selected">4:30 PM</option>
            <option value="17:00:00" selected="selected">5:00 PM</option>
            <option value="17:30:00" selected="selected">5:30 PM</option>
            <option value="18:00:00" selected="selected">6:00 PM</option>
            <option value="18:30:00" selected="selected">6:30 PM</option>
            <option value="19:00:00" selected="selected">7:00 PM</option>
            <option value="19:30:00" selected="selected">7:30 PM</option>
            <option value="20:00:00" selected="selected">8:00 PM</option>
            <option value="20:30:00" selected="selected">8:30 PM</option>
            <option value="21:00:00" selected="selected">9:00 PM</option>
            <option value="21:30:00" selected="selected">9:30 PM</option>
            <option value="22:00:00" selected="selected">10:00 PM</option>
            <option value="22:30:00" selected="selected">10:30 PM</option>
            <option value="23:00:00" selected="selected">11:00 PM</option>
            <option value="23:30:00" selected="selected">11:30 PM</option>



                       </select>
                     
                      </div>  
                      </div>  

                      <div class="col-md-4">
                        <div class="form-group" >
                          End
                          <select class="select-input form-control" formControlName="mon_endTime" style="background-color: #f2f2f2">
                           
                            <option value="00:00:00" selected="selected">12:00 AM</option>
            <option value="00:30:00" selected="selected">12:30 AM</option>
            <option value="01:00:00" selected="selected">1:00 AM</option>
            <option value="01:30:00" selected="selected">1:30 AM</option>
            <option value="02:00:00" selected="selected">2:00 AM</option>
            <option value="02:30:00" selected="selected">2:30 AM</option>
            <option value="03:00:00" selected="selected">3:00 AM</option>
            <option value="03:30:00" selected="selected">3:30 AM</option>
            <option value="04:00:00" selected="selected">4:00 AM</option>
            <option value="04:30:00" selected="selected">4:30 AM</option>
            <option value="05:00:00" selected="selected">5:00 AM</option>
            <option value="05:30:00" selected="selected">5:30 AM</option>
            <option value="06:00:00" selected="selected">6:00 AM</option>
            <option value="06:30:00" selected="selected">6:30 AM</option>
            <option value="07:00:00" selected="selected">7:00 AM</option>
            <option value="07:30:00" selected="selected">7:30 AM</option>
            <option value="08:00:00" selected="selected">8:00 AM</option>
            <option value="08:30:00" selected="selected">8:30 AM</option>
            <option value="09:00:00" selected="selected">9:00 AM</option>
            <option value="09:30:00" selected="selected">9:30 AM</option>
            <option value="10:00:00" selected="selected">10:00 AM</option>
            <option value="10:30:00" selected="selected">10:30 AM</option>
            <option value="11:00:00" selected="selected">11:00 AM</option>
            <option value="11:30:00" selected="selected">11:30 AM</option>
            <option value="12:00:00" selected="selected">12:00 PM</option>
            <option value="12:30:00" selected="selected">12:30 PM</option>
            <option value="13:00:00" selected="selected">1:00 PM</option>
            <option value="13:30:00" selected="selected">1:30 PM</option>
            <option value="14:00:00" selected="selected">2:00 PM</option>
            <option value="14:30:00" selected="selected">2:30 PM</option>
            <option value="15:00:00" selected="selected">3:00 PM</option>
            <option value="15:30:00" selected="selected">3:30 PM</option>
            <option value="16:00:00" selected="selected">4:00 PM</option>
            <option value="16:30:00" selected="selected">4:30 PM</option>
            <option value="17:00:00" [selected]="1">5:00 PM</option>
            <option value="17:30:00" selected="selected">5:30 PM</option>
            <option value="18:00:00" selected="selected">6:00 PM</option>
            <option value="18:30:00" selected="selected">6:30 PM</option>
            <option value="19:00:00" selected="selected">7:00 PM</option>
            <option value="19:30:00" selected="selected">7:30 PM</option>
            <option value="20:00:00" selected="selected">8:00 PM</option>
            <option value="20:30:00" selected="selected">8:30 PM</option>
            <option value="21:00:00" selected="selected">9:00 PM</option>
            <option value="21:30:00" selected="selected">9:30 PM</option>
            <option value="22:00:00" selected="selected">10:00 PM</option>
            <option value="22:30:00" selected="selected">10:30 PM</option>
            <option value="23:00:00" selected="selected">11:00 PM</option>
            <option value="23:30:00" selected="selected">11:30 PM</option>
                           </select>
                          
                          </div>  
                          </div>  


                          <!----MONDAY END-->

                   
   <!--tueday is open 11:30 AM  to  05:00 PM--->
              
   <div class="col-md-4">
    <div class="form-group">
     <!-- <b>DAY</b>-->
     
       <input type="text"   class="form-control" value="Tuesday" style="background-color: #f2f2f2">
      </div>  
      </div>  
     
      <div class="col-md-4">
        <div class="form-group">
         <!--<b>START</b>--> 
          <select class="select-input form-control" formControlName="tue_startTime" name="tue_startTime" style="background-color: #f2f2f2">
            
            <option value="00:00:00" [selected]="1">12:00 AM</option>
            <option value="00:30:00" selected="selected">12:30 AM</option>
            <option value="01:00:00" selected="selected">1:00 AM</option>
            <option value="01:30:00" selected="selected">1:30 AM</option>
            <option value="02:00:00" selected="selected">2:00 AM</option>
            <option value="02:30:00" selected="selected">2:30 AM</option>
            <option value="03:00:00" selected="selected">3:00 AM</option>
            <option value="03:30:00" selected="selected">3:30 AM</option>
            <option value="04:00:00" selected="selected">4:00 AM</option>
            <option value="04:30:00" selected="selected">4:30 AM</option>
            <option value="05:00:00" selected="selected">5:00 AM</option>
            <option value="05:30:00" selected="selected">5:30 AM</option>
            <option value="06:00:00" selected="selected">6:00 AM</option>
            <option value="06:30:00" selected="selected">6:30 AM</option>
            <option value="07:00:00" selected="selected">7:00 AM</option>
            <option value="07:30:00" selected="selected">7:30 AM</option>
            <option value="08:00:00" selected="selected">8:00 AM</option>
            <option value="08:30:00" selected="selected">8:30 AM</option>
            <option value="09:00:00" selected="selected">9:00 AM</option>
            <option value="09:30:00" selected="selected">9:30 AM</option>
            <option value="10:00:00" selected="selected">10:00 AM</option>
            <option value="10:30:00" selected="selected">10:30 AM</option>
            <option value="11:00:00" selected="selected">11:00 AM</option>
            <option value="11:30:00" selected="selected">11:30 AM</option>
            <option value="12:00:00" selected="selected">12:00 PM</option>
            <option value="12:30:00" selected="selected">12:30 PM</option>
            <option value="13:00:00" selected="selected">1:00 PM</option>
            <option value="13:30:00" selected="selected">1:30 PM</option>
            <option value="14:00:00" selected="selected">2:00 PM</option>
            <option value="14:30:00" selected="selected">2:30 PM</option>
            <option value="15:00:00" selected="selected">3:00 PM</option>
            <option value="15:30:00" selected="selected">3:30 PM</option>
            <option value="16:00:00" selected="selected">4:00 PM</option>
            <option value="16:30:00" selected="selected">4:30 PM</option>
            <option value="17:00:00" selected="selected">5:00 PM</option>
            <option value="17:30:00" selected="selected">5:30 PM</option>
            <option value="18:00:00" selected="selected">6:00 PM</option>
            <option value="18:30:00" selected="selected">6:30 PM</option>
            <option value="19:00:00" selected="selected">7:00 PM</option>
            <option value="19:30:00" selected="selected">7:30 PM</option>
            <option value="20:00:00" selected="selected">8:00 PM</option>
            <option value="20:30:00" selected="selected">8:30 PM</option>
            <option value="21:00:00" selected="selected">9:00 PM</option>
            <option value="21:30:00" selected="selected">9:30 PM</option>
            <option value="22:00:00" selected="selected">10:00 PM</option>
            <option value="22:30:00" selected="selected">10:30 PM</option>
            <option value="23:00:00" selected="selected">11:00 PM</option>
            <option value="23:30:00" selected="selected">11:30 PM</option>



           </select>
          
          </div>  
          </div>  

          <div class="col-md-4">
            <div class="form-group">
              <!--<b>END</b>-->
              <select class="select-input form-control" formControlName="tue_endTime" name="tue_endTime" style="background-color: #f2f2f2">
               
                <option value="00:00:00" selected="selected">12:00 AM</option>
                <option value="00:30:00" selected="selected">12:30 AM</option>
                <option value="01:00:00" selected="selected">1:00 AM</option>
                <option value="01:30:00" selected="selected">1:30 AM</option>
                <option value="02:00:00" selected="selected">2:00 AM</option>
                <option value="02:30:00" selected="selected">2:30 AM</option>
                <option value="03:00:00" selected="selected">3:00 AM</option>
                <option value="03:30:00" selected="selected">3:30 AM</option>
                <option value="04:00:00" selected="selected">4:00 AM</option>
                <option value="04:30:00" selected="selected">4:30 AM</option>
                <option value="05:00:00" selected="selected">5:00 AM</option>
                <option value="05:30:00" selected="selected">5:30 AM</option>
                <option value="06:00:00" selected="selected">6:00 AM</option>
                <option value="06:30:00" selected="selected">6:30 AM</option>
                <option value="07:00:00" selected="selected">7:00 AM</option>
                <option value="07:30:00" selected="selected">7:30 AM</option>
                <option value="08:00:00" selected="selected">8:00 AM</option>
                <option value="08:30:00" selected="selected">8:30 AM</option>
                <option value="09:00:00" selected="selected">9:00 AM</option>
                <option value="09:30:00" selected="selected">9:30 AM</option>
                <option value="10:00:00" selected="selected">10:00 AM</option>
                <option value="10:30:00" selected="selected">10:30 AM</option>
                <option value="11:00:00" selected="selected">11:00 AM</option>
                <option value="11:30:00" selected="selected">11:30 AM</option>
                <option value="12:00:00" selected="selected">12:00 PM</option>
                <option value="12:30:00" selected="selected">12:30 PM</option>
                <option value="13:00:00" selected="selected">1:00 PM</option>
                <option value="13:30:00" selected="selected">1:30 PM</option>
                <option value="14:00:00" selected="selected">2:00 PM</option>
                <option value="14:30:00" selected="selected">2:30 PM</option>
                <option value="15:00:00" selected="selected">3:00 PM</option>
                <option value="15:30:00" selected="selected">3:30 PM</option>
                <option value="16:00:00" selected="selected">4:00 PM</option>
                <option value="16:30:00" selected="selected">4:30 PM</option>
                <option value="17:00:00" [selected]="1">5:00 PM</option>
                <option value="17:30:00" selected="selected">5:30 PM</option>
                <option value="18:00:00" selected="selected">6:00 PM</option>
                <option value="18:30:00" selected="selected">6:30 PM</option>
                <option value="19:00:00" selected="selected">7:00 PM</option>
                <option value="19:30:00" selected="selected">7:30 PM</option>
                <option value="20:00:00" selected="selected">8:00 PM</option>
                <option value="20:30:00" selected="selected">8:30 PM</option>
                <option value="21:00:00" selected="selected">9:00 PM</option>
                <option value="21:30:00" selected="selected">9:30 PM</option>
                <option value="22:00:00" selected="selected">10:00 PM</option>
                <option value="22:30:00" selected="selected">10:30 PM</option>
                <option value="23:00:00" selected="selected">11:00 PM</option>
                <option value="23:30:00" selected="selected">11:30 PM</option>
                
               </select>
             
              </div>  
              </div>  
              <!----tueday END-->
            
                  <!--Wednesday is open 11:30 AM  to  05:00 PM--->
              
   <div class="col-md-4">
    <div class="form-group">
     <!--<b>DAY</b>---> 
     
       <input type="text"   class="form-control" value="Wednesday" style="background-color: #f2f2f2">
      </div>  
      </div>  
    
      <div class="col-md-4">
        <div class="form-group">
         <!--<b>START</b>---> 
          <select class="select-input form-control" formControlName="wed_startTime" name="wed_startTime" style="background-color: #f2f2f2">
            

            <option value="00:00:00" [selected]="1">12:00 AM</option>
            <option value="00:30:00" selected="selected">12:30 AM</option>
            <option value="01:00:00" selected="selected">1:00 AM</option>
            <option value="01:30:00" selected="selected">1:30 AM</option>
            <option value="02:00:00" selected="selected">2:00 AM</option>
            <option value="02:30:00" selected="selected">2:30 AM</option>
            <option value="03:00:00" selected="selected">3:00 AM</option>
            <option value="03:30:00" selected="selected">3:30 AM</option>
            <option value="04:00:00" selected="selected">4:00 AM</option>
            <option value="04:30:00" selected="selected">4:30 AM</option>
            <option value="05:00:00" selected="selected">5:00 AM</option>
            <option value="05:30:00" selected="selected">5:30 AM</option>
            <option value="06:00:00" selected="selected">6:00 AM</option>
            <option value="06:30:00" selected="selected">6:30 AM</option>
            <option value="07:00:00" selected="selected">7:00 AM</option>
            <option value="07:30:00" selected="selected">7:30 AM</option>
            <option value="08:00:00" selected="selected">8:00 AM</option>
            <option value="08:30:00" selected="selected">8:30 AM</option>
            <option value="09:00:00" selected="selected">9:00 AM</option>
            <option value="09:30:00" selected="selected">9:30 AM</option>
            <option value="10:00:00" selected="selected">10:00 AM</option>
            <option value="10:30:00" selected="selected">10:30 AM</option>
            <option value="11:00:00" selected="selected">11:00 AM</option>
            <option value="11:30:00" selected="selected">11:30 AM</option>
            <option value="12:00:00" selected="selected">12:00 PM</option>
            <option value="12:30:00" selected="selected">12:30 PM</option>
            <option value="13:00:00" selected="selected">1:00 PM</option>
            <option value="13:30:00" selected="selected">1:30 PM</option>
            <option value="14:00:00" selected="selected">2:00 PM</option>
            <option value="14:30:00" selected="selected">2:30 PM</option>
            <option value="15:00:00" selected="selected">3:00 PM</option>
            <option value="15:30:00" selected="selected">3:30 PM</option>
            <option value="16:00:00" selected="selected">4:00 PM</option>
            <option value="16:30:00" selected="selected">4:30 PM</option>
            <option value="17:00:00" selected="selected">5:00 PM</option>
            <option value="17:30:00" selected="selected">5:30 PM</option>
            <option value="18:00:00" selected="selected">6:00 PM</option>
            <option value="18:30:00" selected="selected">6:30 PM</option>
            <option value="19:00:00" selected="selected">7:00 PM</option>
            <option value="19:30:00" selected="selected">7:30 PM</option>
            <option value="20:00:00" selected="selected">8:00 PM</option>
            <option value="20:30:00" selected="selected">8:30 PM</option>
            <option value="21:00:00" selected="selected">9:00 PM</option>
            <option value="21:30:00" selected="selected">9:30 PM</option>
            <option value="22:00:00" selected="selected">10:00 PM</option>
            <option value="22:30:00" selected="selected">10:30 PM</option>
            <option value="23:00:00" selected="selected">11:00 PM</option>
            <option value="23:30:00" selected="selected">11:30 PM</option>



           </select>
         
          </div>  
          </div>  

          <div class="col-md-4">
            <div class="form-group">
             <!---<b>END</b>---> 
              <select class="select-input form-control" formControlName="wed_endTime" name="wed_endTime" style="background-color: #f2f2f2">
                
                <option value="00:00:00" selected="selected">12:00 AM</option>
                <option value="00:30:00" selected="selected">12:30 AM</option>
                <option value="01:00:00" selected="selected">1:00 AM</option>
                <option value="01:30:00" selected="selected">1:30 AM</option>
                <option value="02:00:00" selected="selected">2:00 AM</option>
                <option value="02:30:00" selected="selected">2:30 AM</option>
                <option value="03:00:00" selected="selected">3:00 AM</option>
                <option value="03:30:00" selected="selected">3:30 AM</option>
                <option value="04:00:00" selected="selected">4:00 AM</option>
                <option value="04:30:00" selected="selected">4:30 AM</option>
                <option value="05:00:00" selected="selected">5:00 AM</option>
                <option value="05:30:00" selected="selected">5:30 AM</option>
                <option value="06:00:00" selected="selected">6:00 AM</option>
                <option value="06:30:00" selected="selected">6:30 AM</option>
                <option value="07:00:00" selected="selected">7:00 AM</option>
                <option value="07:30:00" selected="selected">7:30 AM</option>
                <option value="08:00:00" selected="selected">8:00 AM</option>
                <option value="08:30:00" selected="selected">8:30 AM</option>
                <option value="09:00:00" selected="selected">9:00 AM</option>
                <option value="09:30:00" selected="selected">9:30 AM</option>
                <option value="10:00:00" selected="selected">10:00 AM</option>
                <option value="10:30:00" selected="selected">10:30 AM</option>
                <option value="11:00:00" selected="selected">11:00 AM</option>
                <option value="11:30:00" selected="selected">11:30 AM</option>
                <option value="12:00:00" selected="selected">12:00 PM</option>
                <option value="12:30:00" selected="selected">12:30 PM</option>
                <option value="13:00:00" selected="selected">1:00 PM</option>
                <option value="13:30:00" selected="selected">1:30 PM</option>
                <option value="14:00:00" selected="selected">2:00 PM</option>
                <option value="14:30:00" selected="selected">2:30 PM</option>
                <option value="15:00:00" selected="selected">3:00 PM</option>
                <option value="15:30:00" selected="selected">3:30 PM</option>
                <option value="16:00:00" selected="selected">4:00 PM</option>
                <option value="16:30:00" selected="selected">4:30 PM</option>
                <option value="17:00:00" [selected]="1">5:00 PM</option>
                <option value="17:30:00" selected="selected">5:30 PM</option>
                <option value="18:00:00" selected="selected">6:00 PM</option>
                <option value="18:30:00" selected="selected">6:30 PM</option>
                <option value="19:00:00" selected="selected">7:00 PM</option>
                <option value="19:30:00" selected="selected">7:30 PM</option>
                <option value="20:00:00" selected="selected">8:00 PM</option>
                <option value="20:30:00" selected="selected">8:30 PM</option>
                <option value="21:00:00" selected="selected">9:00 PM</option>
                <option value="21:30:00" selected="selected">9:30 PM</option>
                <option value="22:00:00" selected="selected">10:00 PM</option>
                <option value="22:30:00" selected="selected">10:30 PM</option>
                <option value="23:00:00" selected="selected">11:00 PM</option>
                <option value="23:30:00" selected="selected">11:30 PM</option>
                
               </select>
              </div>  
              </div>  
              
              <!----tueday END-->
            <!--Thursday is open  11:30 AM  to  05:00 PM--->
              
   <div class="col-md-4">
    <div class="form-group">
      <!--<b>DAY</b>-->
     
       <input type="text"    class="form-control" value="Thursday" style="background-color: #f2f2f2">
      </div>  
      </div>  
  
      <div class="col-md-4">
        <div class="form-group">
          <!--<b>START</b>--->
          <select class="select-input form-control" formControlName="thu_startTime" name="thu_startTime" style="background-color: #f2f2f2">
            
            
            <option value="00:00:00" [selected]="1">12:00 AM</option>
            <option value="00:30:00" selected="selected">12:30 AM</option>
            <option value="01:00:00" selected="selected">1:00 AM</option>
            <option value="01:30:00" selected="selected">1:30 AM</option>
            <option value="02:00:00" selected="selected">2:00 AM</option>
            <option value="02:30:00" selected="selected">2:30 AM</option>
            <option value="03:00:00" selected="selected">3:00 AM</option>
            <option value="03:30:00" selected="selected">3:30 AM</option>
            <option value="04:00:00" selected="selected">4:00 AM</option>
            <option value="04:30:00" selected="selected">4:30 AM</option>
            <option value="05:00:00" selected="selected">5:00 AM</option>
            <option value="05:30:00" selected="selected">5:30 AM</option>
            <option value="06:00:00" selected="selected">6:00 AM</option>
            <option value="06:30:00" selected="selected">6:30 AM</option>
            <option value="07:00:00" selected="selected">7:00 AM</option>
            <option value="07:30:00" selected="selected">7:30 AM</option>
            <option value="08:00:00" selected="selected">8:00 AM</option>
            <option value="08:30:00" selected="selected">8:30 AM</option>
            <option value="09:00:00" selected="selected">9:00 AM</option>
            <option value="09:30:00" selected="selected">9:30 AM</option>
            <option value="10:00:00" selected="selected">10:00 AM</option>
            <option value="10:30:00" selected="selected">10:30 AM</option>
            <option value="11:00:00" selected="selected">11:00 AM</option>
            <option value="11:30:00" selected="selected">11:30 AM</option>
            <option value="12:00:00" selected="selected">12:00 PM</option>
            <option value="12:30:00" selected="selected">12:30 PM</option>
            <option value="13:00:00" selected="selected">1:00 PM</option>
            <option value="13:30:00" selected="selected">1:30 PM</option>
            <option value="14:00:00" selected="selected">2:00 PM</option>
            <option value="14:30:00" selected="selected">2:30 PM</option>
            <option value="15:00:00" selected="selected">3:00 PM</option>
            <option value="15:30:00" selected="selected">3:30 PM</option>
            <option value="16:00:00" selected="selected">4:00 PM</option>
            <option value="16:30:00" selected="selected">4:30 PM</option>
            <option value="17:00:00" selected="selected">5:00 PM</option>
            <option value="17:30:00" selected="selected">5:30 PM</option>
            <option value="18:00:00" selected="selected">6:00 PM</option>
            <option value="18:30:00" selected="selected">6:30 PM</option>
            <option value="19:00:00" selected="selected">7:00 PM</option>
            <option value="19:30:00" selected="selected">7:30 PM</option>
            <option value="20:00:00" selected="selected">8:00 PM</option>
            <option value="20:30:00" selected="selected">8:30 PM</option>
            <option value="21:00:00" selected="selected">9:00 PM</option>
            <option value="21:30:00" selected="selected">9:30 PM</option>
            <option value="22:00:00" selected="selected">10:00 PM</option>
            <option value="22:30:00" selected="selected">10:30 PM</option>
            <option value="23:00:00" selected="selected">11:00 PM</option>
            <option value="23:30:00" selected="selected">11:30 PM</option>



           </select>
          </div>  
          </div>  

          <div class="col-md-4">
            <div class="form-group">
             <!-- <b>END</b>--> 
              <select class="select-input form-control" formControlName="thu_endTime" name="thu_endTime" style="background-color: #f2f2f2">
                
                <option value="00:00:00" selected="selected">12:00 AM</option>
                <option value="00:30:00" selected="selected">12:30 AM</option>
                <option value="01:00:00" selected="selected">1:00 AM</option>
                <option value="01:30:00" selected="selected">1:30 AM</option>
                <option value="02:00:00" selected="selected">2:00 AM</option>
                <option value="02:30:00" selected="selected">2:30 AM</option>
                <option value="03:00:00" selected="selected">3:00 AM</option>
                <option value="03:30:00" selected="selected">3:30 AM</option>
                <option value="04:00:00" selected="selected">4:00 AM</option>
                <option value="04:30:00" selected="selected">4:30 AM</option>
                <option value="05:00:00" selected="selected">5:00 AM</option>
                <option value="05:30:00" selected="selected">5:30 AM</option>
                <option value="06:00:00" selected="selected">6:00 AM</option>
                <option value="06:30:00" selected="selected">6:30 AM</option>
                <option value="07:00:00" selected="selected">7:00 AM</option>
                <option value="07:30:00" selected="selected">7:30 AM</option>
                <option value="08:00:00" selected="selected">8:00 AM</option>
                <option value="08:30:00" selected="selected">8:30 AM</option>
                <option value="09:00:00" selected="selected">9:00 AM</option>
                <option value="09:30:00" selected="selected">9:30 AM</option>
                <option value="10:00:00" selected="selected">10:00 AM</option>
                <option value="10:30:00" selected="selected">10:30 AM</option>
                <option value="11:00:00" selected="selected">11:00 AM</option>
                <option value="11:30:00" selected="selected">11:30 AM</option>
                <option value="12:00:00" selected="selected">12:00 PM</option>
                <option value="12:30:00" selected="selected">12:30 PM</option>
                <option value="13:00:00" selected="selected">1:00 PM</option>
                <option value="13:30:00" selected="selected">1:30 PM</option>
                <option value="14:00:00" selected="selected">2:00 PM</option>
                <option value="14:30:00" selected="selected">2:30 PM</option>
                <option value="15:00:00" selected="selected">3:00 PM</option>
                <option value="15:30:00" selected="selected">3:30 PM</option>
                <option value="16:00:00" selected="selected">4:00 PM</option>
                <option value="16:30:00" selected="selected">4:30 PM</option>
                <option value="17:00:00" [selected]="1">5:00 PM</option>
                <option value="17:30:00" selected="selected">5:30 PM</option>
                <option value="18:00:00" selected="selected">6:00 PM</option>
                <option value="18:30:00" selected="selected">6:30 PM</option>
                <option value="19:00:00" selected="selected">7:00 PM</option>
                <option value="19:30:00" selected="selected">7:30 PM</option>
                <option value="20:00:00" selected="selected">8:00 PM</option>
                <option value="20:30:00" selected="selected">8:30 PM</option>
                <option value="21:00:00" selected="selected">9:00 PM</option>
                <option value="21:30:00" selected="selected">9:30 PM</option>
                <option value="22:00:00" selected="selected">10:00 PM</option>
                <option value="22:30:00" selected="selected">10:30 PM</option>
                <option value="23:00:00" selected="selected">11:00 PM</option>
                <option value="23:30:00" selected="selected">11:30 PM</option>
                
               </select>
              </div>  
              </div>  
              
               <!--Friday is open 11:30 AM  to  05:00 PM--->
              
   <div class="col-md-4">
    <div class="form-group">
      <!--<b>DAY</b>-->
     
       <input type="text"    class="form-control" value="Friday" style="background-color: #f2f2f2">
      </div>  
      </div>  
  
      <div class="col-md-4">
        <div class="form-group">
          <!--<b>START</b>-->
          <select class="select-input form-control" formControlName="fri_startTime" style="background-color: #f2f2f2">
         
            
            <option value="00:00:00" [selected]="1">12:00 AM</option>
            <option value="00:30:00" selected="selected">12:30 AM</option>
            <option value="01:00:00" selected="selected">1:00 AM</option>
            <option value="01:30:00" selected="selected">1:30 AM</option>
            <option value="02:00:00" selected="selected">2:00 AM</option>
            <option value="02:30:00" selected="selected">2:30 AM</option>
            <option value="03:00:00" selected="selected">3:00 AM</option>
            <option value="03:30:00" selected="selected">3:30 AM</option>
            <option value="04:00:00" selected="selected">4:00 AM</option>
            <option value="04:30:00" selected="selected">4:30 AM</option>
            <option value="05:00:00" selected="selected">5:00 AM</option>
            <option value="05:30:00" selected="selected">5:30 AM</option>
            <option value="06:00:00" selected="selected">6:00 AM</option>
            <option value="06:30:00" selected="selected">6:30 AM</option>
            <option value="07:00:00" selected="selected">7:00 AM</option>
            <option value="07:30:00" selected="selected">7:30 AM</option>
            <option value="08:00:00" selected="selected">8:00 AM</option>
            <option value="08:30:00" selected="selected">8:30 AM</option>
            <option value="09:00:00" selected="selected">9:00 AM</option>
            <option value="09:30:00" selected="selected">9:30 AM</option>
            <option value="10:00:00" selected="selected">10:00 AM</option>
            <option value="10:30:00" selected="selected">10:30 AM</option>
            <option value="11:00:00" selected="selected">11:00 AM</option>
            <option value="11:30:00" selected="selected">11:30 AM</option>
            <option value="12:00:00" selected="selected">12:00 PM</option>
            <option value="12:30:00" selected="selected">12:30 PM</option>
            <option value="13:00:00" selected="selected">1:00 PM</option>
            <option value="13:30:00" selected="selected">1:30 PM</option>
            <option value="14:00:00" selected="selected">2:00 PM</option>
            <option value="14:30:00" selected="selected">2:30 PM</option>
            <option value="15:00:00" selected="selected">3:00 PM</option>
            <option value="15:30:00" selected="selected">3:30 PM</option>
            <option value="16:00:00" selected="selected">4:00 PM</option>
            <option value="16:30:00" selected="selected">4:30 PM</option>
            <option value="17:00:00" selected="selected">5:00 PM</option>
            <option value="17:30:00" selected="selected">5:30 PM</option>
            <option value="18:00:00" selected="selected">6:00 PM</option>
            <option value="18:30:00" selected="selected">6:30 PM</option>
            <option value="19:00:00" selected="selected">7:00 PM</option>
            <option value="19:30:00" selected="selected">7:30 PM</option>
            <option value="20:00:00" selected="selected">8:00 PM</option>
            <option value="20:30:00" selected="selected">8:30 PM</option>
            <option value="21:00:00" selected="selected">9:00 PM</option>
            <option value="21:30:00" selected="selected">9:30 PM</option>
            <option value="22:00:00" selected="selected">10:00 PM</option>
            <option value="22:30:00" selected="selected">10:30 PM</option>
            <option value="23:00:00" selected="selected">11:00 PM</option>
            <option value="23:30:00" selected="selected">11:30 PM</option>



           </select>
          </div>  
          </div>  

          <div class="col-md-4">
            <div class="form-group">
             <!--<b>END</b>---> 
              <select class="select-input form-control" formControlName="fri_endTime" name="fri_endTime" style="background-color: #f2f2f2">
                
                  
                <option value="00:00:00" selected="selected">12:00 AM</option>
                <option value="00:30:00" selected="selected">12:30 AM</option>
                <option value="01:00:00" selected="selected">1:00 AM</option>
                <option value="01:30:00" selected="selected">1:30 AM</option>
                <option value="02:00:00" selected="selected">2:00 AM</option>
                <option value="02:30:00" selected="selected">2:30 AM</option>
                <option value="03:00:00" selected="selected">3:00 AM</option>
                <option value="03:30:00" selected="selected">3:30 AM</option>
                <option value="04:00:00" selected="selected">4:00 AM</option>
                <option value="04:30:00" selected="selected">4:30 AM</option>
                <option value="05:00:00" selected="selected">5:00 AM</option>
                <option value="05:30:00" selected="selected">5:30 AM</option>
                <option value="06:00:00" selected="selected">6:00 AM</option>
                <option value="06:30:00" selected="selected">6:30 AM</option>
                <option value="07:00:00" selected="selected">7:00 AM</option>
                <option value="07:30:00" selected="selected">7:30 AM</option>
                <option value="08:00:00" selected="selected">8:00 AM</option>
                <option value="08:30:00" selected="selected">8:30 AM</option>
                <option value="09:00:00" selected="selected">9:00 AM</option>
                <option value="09:30:00" selected="selected">9:30 AM</option>
                <option value="10:00:00" selected="selected">10:00 AM</option>
                <option value="10:30:00" selected="selected">10:30 AM</option>
                <option value="11:00:00" selected="selected">11:00 AM</option>
                <option value="11:30:00" selected="selected">11:30 AM</option>
                <option value="12:00:00" selected="selected">12:00 PM</option>
                <option value="12:30:00" selected="selected">12:30 PM</option>
                <option value="13:00:00" selected="selected">1:00 PM</option>
                <option value="13:30:00" selected="selected">1:30 PM</option>
                <option value="14:00:00" selected="selected">2:00 PM</option>
                <option value="14:30:00" selected="selected">2:30 PM</option>
                <option value="15:00:00" selected="selected">3:00 PM</option>
                <option value="15:30:00" selected="selected">3:30 PM</option>
                <option value="16:00:00" selected="selected">4:00 PM</option>
                <option value="16:30:00" selected="selected">4:30 PM</option>
                <option value="17:00:00" [selected]="1">5:00 PM</option>
                <option value="17:30:00" selected="selected">5:30 PM</option>
                <option value="18:00:00" selected="selected">6:00 PM</option>
                <option value="18:30:00" selected="selected">6:30 PM</option>
                <option value="19:00:00" selected="selected">7:00 PM</option>
                <option value="19:30:00" selected="selected">7:30 PM</option>
                <option value="20:00:00" selected="selected">8:00 PM</option>
                <option value="20:30:00" selected="selected">8:30 PM</option>
                <option value="21:00:00" selected="selected">9:00 PM</option>
                <option value="21:30:00" selected="selected">9:30 PM</option>
                <option value="22:00:00" selected="selected">10:00 PM</option>
                <option value="22:30:00" selected="selected">10:30 PM</option>
                <option value="23:00:00" selected="selected">11:00 PM</option>
                <option value="23:30:00" selected="selected">11:30 PM</option>
                  
                 </select>
              </div>  
              </div>  
             
              <!----Thursday END-->
                     
              <div class="col-md-4">
                <div class="form-group">
                  <!--<b>DAY</b>-->
                 
                   <input type="text"   class="form-control" value="Saturday" style="background-color: #f2f2f2">
                  </div>  
                  </div>  
              
                  <div class="col-md-4">
                    <div class="form-group">
                      <!--<b>START</b>-->
                      <select class="select-input form-control" formControlName="sat_startTime" style="background-color: #f2f2f2">
                       
                        
                        <option value="00:00:00" [selected]="1">12:00 AM</option>
            <option value="00:30:00" selected="selected">12:30 AM</option>
            <option value="01:00:00" selected="selected">1:00 AM</option>
            <option value="01:30:00" selected="selected">1:30 AM</option>
            <option value="02:00:00" selected="selected">2:00 AM</option>
            <option value="02:30:00" selected="selected">2:30 AM</option>
            <option value="03:00:00" selected="selected">3:00 AM</option>
            <option value="03:30:00" selected="selected">3:30 AM</option>
            <option value="04:00:00" selected="selected">4:00 AM</option>
            <option value="04:30:00" selected="selected">4:30 AM</option>
            <option value="05:00:00" selected="selected">5:00 AM</option>
            <option value="05:30:00" selected="selected">5:30 AM</option>
            <option value="06:00:00" selected="selected">6:00 AM</option>
            <option value="06:30:00" selected="selected">6:30 AM</option>
            <option value="07:00:00" selected="selected">7:00 AM</option>
            <option value="07:30:00" selected="selected">7:30 AM</option>
            <option value="08:00:00" selected="selected">8:00 AM</option>
            <option value="08:30:00" selected="selected">8:30 AM</option>
            <option value="09:00:00" selected="selected">9:00 AM</option>
            <option value="09:30:00" selected="selected">9:30 AM</option>
            <option value="10:00:00" selected="selected">10:00 AM</option>
            <option value="10:30:00" selected="selected">10:30 AM</option>
            <option value="11:00:00" selected="selected">11:00 AM</option>
            <option value="11:30:00" selected="selected">11:30 AM</option>
            <option value="12:00:00" selected="selected">12:00 PM</option>
            <option value="12:30:00" selected="selected">12:30 PM</option>
            <option value="13:00:00" selected="selected">1:00 PM</option>
            <option value="13:30:00" selected="selected">1:30 PM</option>
            <option value="14:00:00" selected="selected">2:00 PM</option>
            <option value="14:30:00" selected="selected">2:30 PM</option>
            <option value="15:00:00" selected="selected">3:00 PM</option>
            <option value="15:30:00" selected="selected">3:30 PM</option>
            <option value="16:00:00" selected="selected">4:00 PM</option>
            <option value="16:30:00" selected="selected">4:30 PM</option>
            <option value="17:00:00" selected="selected">5:00 PM</option>
            <option value="17:30:00" selected="selected">5:30 PM</option>
            <option value="18:00:00" selected="selected">6:00 PM</option>
            <option value="18:30:00" selected="selected">6:30 PM</option>
            <option value="19:00:00" selected="selected">7:00 PM</option>
            <option value="19:30:00" selected="selected">7:30 PM</option>
            <option value="20:00:00" selected="selected">8:00 PM</option>
            <option value="20:30:00" selected="selected">8:30 PM</option>
            <option value="21:00:00" selected="selected">9:00 PM</option>
            <option value="21:30:00" selected="selected">9:30 PM</option>
            <option value="22:00:00" selected="selected">10:00 PM</option>
            <option value="22:30:00" selected="selected">10:30 PM</option>
            <option value="23:00:00" selected="selected">11:00 PM</option>
            <option value="23:30:00" selected="selected">11:30 PM</option>


                       </select>
                      </div>  
                      </div>  
            
                      <div class="col-md-4">
                        <div class="form-group">
                         <!--<b>END</b>---> 
                          <select class="select-input form-control" formControlName="sat_endTime" name="sat_endTime" style="background-color: #f2f2f2">
                           
                            <option value="00:00:00" selected="selected">12:00 AM</option>
                            <option value="00:30:00" selected="selected">12:30 AM</option>
                            <option value="01:00:00" selected="selected">1:00 AM</option>
                            <option value="01:30:00" selected="selected">1:30 AM</option>
                            <option value="02:00:00" selected="selected">2:00 AM</option>
                            <option value="02:30:00" selected="selected">2:30 AM</option>
                            <option value="03:00:00" selected="selected">3:00 AM</option>
                            <option value="03:30:00" selected="selected">3:30 AM</option>
                            <option value="04:00:00" selected="selected">4:00 AM</option>
                            <option value="04:30:00" selected="selected">4:30 AM</option>
                            <option value="05:00:00" selected="selected">5:00 AM</option>
                            <option value="05:30:00" selected="selected">5:30 AM</option>
                            <option value="06:00:00" selected="selected">6:00 AM</option>
                            <option value="06:30:00" selected="selected">6:30 AM</option>
                            <option value="07:00:00" selected="selected">7:00 AM</option>
                            <option value="07:30:00" selected="selected">7:30 AM</option>
                            <option value="08:00:00" selected="selected">8:00 AM</option>
                            <option value="08:30:00" selected="selected">8:30 AM</option>
                            <option value="09:00:00" selected="selected">9:00 AM</option>
                            <option value="09:30:00" selected="selected">9:30 AM</option>
                            <option value="10:00:00" selected="selected">10:00 AM</option>
                            <option value="10:30:00" selected="selected">10:30 AM</option>
                            <option value="11:00:00" selected="selected">11:00 AM</option>
                            <option value="11:30:00" selected="selected">11:30 AM</option>
                            <option value="12:00:00" selected="selected">12:00 PM</option>
                            <option value="12:30:00" selected="selected">12:30 PM</option>
                            <option value="13:00:00" selected="selected">1:00 PM</option>
                            <option value="13:30:00" selected="selected">1:30 PM</option>
                            <option value="14:00:00" selected="selected">2:00 PM</option>
                            <option value="14:30:00" selected="selected">2:30 PM</option>
                            <option value="15:00:00" selected="selected">3:00 PM</option>
                            <option value="15:30:00" selected="selected">3:30 PM</option>
                            <option value="16:00:00" selected="selected">4:00 PM</option>
                            <option value="16:30:00" selected="selected">4:30 PM</option>
                            <option value="17:00:00" [selected]="1">5:00 PM</option>
                            <option value="17:30:00" selected="selected">5:30 PM</option>
                            <option value="18:00:00" selected="selected">6:00 PM</option>
                            <option value="18:30:00" selected="selected">6:30 PM</option>
                            <option value="19:00:00" selected="selected">7:00 PM</option>
                            <option value="19:30:00" selected="selected">7:30 PM</option>
                            <option value="20:00:00" selected="selected">8:00 PM</option>
                            <option value="20:30:00" selected="selected">8:30 PM</option>
                            <option value="21:00:00" selected="selected">9:00 PM</option>
                            <option value="21:30:00" selected="selected">9:30 PM</option>
                            <option value="22:00:00" selected="selected">10:00 PM</option>
                            <option value="22:30:00" selected="selected">10:30 PM</option>
                            <option value="23:00:00" selected="selected">11:00 PM</option>
                            <option value="23:30:00" selected="selected">11:30 PM</option>
                              
                             </select>
                          </div>  
                          </div>  

                          <div class="col-md-4">
                            <div class="form-group">
                              <!--<b>DAY</b>-->
                             
                               <input type="text"    class="form-control" value="Sunday" style="background-color: #f2f2f2">
                              </div>  
                              </div>  
                          
                              <div class="col-md-4">
                                <div class="form-group">
                                  <!--<b>START</b>-->
                                  <select class="select-input form-control" formControlName="sun_startTime" style="background-color: #f2f2f2">
                                    
                                    
                                    <option value="00:00:00" [selected]="1">12:00 AM</option>
            <option value="00:30:00" selected="selected">12:30 AM</option>
            <option value="01:00:00" selected="selected">1:00 AM</option>
            <option value="01:30:00" selected="selected">1:30 AM</option>
            <option value="02:00:00" selected="selected">2:00 AM</option>
            <option value="02:30:00" selected="selected">2:30 AM</option>
            <option value="03:00:00" selected="selected">3:00 AM</option>
            <option value="03:30:00" selected="selected">3:30 AM</option>
            <option value="04:00:00" selected="selected">4:00 AM</option>
            <option value="04:30:00" selected="selected">4:30 AM</option>
            <option value="05:00:00" selected="selected">5:00 AM</option>
            <option value="05:30:00" selected="selected">5:30 AM</option>
            <option value="06:00:00" selected="selected">6:00 AM</option>
            <option value="06:30:00" selected="selected">6:30 AM</option>
            <option value="07:00:00" selected="selected">7:00 AM</option>
            <option value="07:30:00" selected="selected">7:30 AM</option>
            <option value="08:00:00" selected="selected">8:00 AM</option>
            <option value="08:30:00" selected="selected">8:30 AM</option>
            <option value="09:00:00" selected="selected">9:00 AM</option>
            <option value="09:30:00" selected="selected">9:30 AM</option>
            <option value="10:00:00" selected="selected">10:00 AM</option>
            <option value="10:30:00" selected="selected">10:30 AM</option>
            <option value="11:00:00" selected="selected">11:00 AM</option>
            <option value="11:30:00" selected="selected">11:30 AM</option>
            <option value="12:00:00" selected="selected">12:00 PM</option>
            <option value="12:30:00" selected="selected">12:30 PM</option>
            <option value="13:00:00" selected="selected">1:00 PM</option>
            <option value="13:30:00" selected="selected">1:30 PM</option>
            <option value="14:00:00" selected="selected">2:00 PM</option>
            <option value="14:30:00" selected="selected">2:30 PM</option>
            <option value="15:00:00" selected="selected">3:00 PM</option>
            <option value="15:30:00" selected="selected">3:30 PM</option>
            <option value="16:00:00" selected="selected">4:00 PM</option>
            <option value="16:30:00" selected="selected">4:30 PM</option>
            <option value="17:00:00" selected="selected">5:00 PM</option>
            <option value="17:30:00" selected="selected">5:30 PM</option>
            <option value="18:00:00" selected="selected">6:00 PM</option>
            <option value="18:30:00" selected="selected">6:30 PM</option>
            <option value="19:00:00" selected="selected">7:00 PM</option>
            <option value="19:30:00" selected="selected">7:30 PM</option>
            <option value="20:00:00" selected="selected">8:00 PM</option>
            <option value="20:30:00" selected="selected">8:30 PM</option>
            <option value="21:00:00" selected="selected">9:00 PM</option>
            <option value="21:30:00" selected="selected">9:30 PM</option>
            <option value="22:00:00" selected="selected">10:00 PM</option>
            <option value="22:30:00" selected="selected">10:30 PM</option>
            <option value="23:00:00" selected="selected">11:00 PM</option>
            <option value="23:30:00" selected="selected">11:30 PM</option>
                                   </select>
                                  </div>  
                                  </div>  
                        
                                  <div class="col-md-4">
                                    <div class="form-group">
                                     <!--<b>END</b>---> 
                                      <select class="select-input form-control" formControlName="sun_endTime" name="sun_endTime" style="background-color: #f2f2f2">
                                    
                                    
                                        <option value="00:00:00" selected="selected">12:00 AM</option>
            <option value="00:30:00" selected="selected">12:30 AM</option>
            <option value="01:00:00" selected="selected">1:00 AM</option>
            <option value="01:30:00" selected="selected">1:30 AM</option>
            <option value="02:00:00" selected="selected">2:00 AM</option>
            <option value="02:30:00" selected="selected">2:30 AM</option>
            <option value="03:00:00" selected="selected">3:00 AM</option>
            <option value="03:30:00" selected="selected">3:30 AM</option>
            <option value="04:00:00" selected="selected">4:00 AM</option>
            <option value="04:30:00" selected="selected">4:30 AM</option>
            <option value="05:00:00" selected="selected">5:00 AM</option>
            <option value="05:30:00" selected="selected">5:30 AM</option>
            <option value="06:00:00" selected="selected">6:00 AM</option>
            <option value="06:30:00" selected="selected">6:30 AM</option>
            <option value="07:00:00" selected="selected">7:00 AM</option>
            <option value="07:30:00" selected="selected">7:30 AM</option>
            <option value="08:00:00" selected="selected">8:00 AM</option>
            <option value="08:30:00" selected="selected">8:30 AM</option>
            <option value="09:00:00" selected="selected">9:00 AM</option>
            <option value="09:30:00" selected="selected">9:30 AM</option>
            <option value="10:00:00" selected="selected">10:00 AM</option>
            <option value="10:30:00" selected="selected">10:30 AM</option>
            <option value="11:00:00" selected="selected">11:00 AM</option>
            <option value="11:30:00" selected="selected">11:30 AM</option>
            <option value="12:00:00" selected="selected">12:00 PM</option>
            <option value="12:30:00" selected="selected">12:30 PM</option>
            <option value="13:00:00" selected="selected">1:00 PM</option>
            <option value="13:30:00" selected="selected">1:30 PM</option>
            <option value="14:00:00" selected="selected">2:00 PM</option>
            <option value="14:30:00" selected="selected">2:30 PM</option>
            <option value="15:00:00" selected="selected">3:00 PM</option>
            <option value="15:30:00" selected="selected">3:30 PM</option>
            <option value="16:00:00" selected="selected">4:00 PM</option>
            <option value="16:30:00" selected="selected">4:30 PM</option>
            <option value="17:00:00" [selected]="1">5:00 PM</option>
            <option value="17:30:00" selected="selected">5:30 PM</option>
            <option value="18:00:00" selected="selected">6:00 PM</option>
            <option value="18:30:00" selected="selected">6:30 PM</option>
            <option value="19:00:00" selected="selected">7:00 PM</option>
            <option value="19:30:00" selected="selected">7:30 PM</option>
            <option value="20:00:00" selected="selected">8:00 PM</option>
            <option value="20:30:00" selected="selected">8:30 PM</option>
            <option value="21:00:00" selected="selected">9:00 PM</option>
            <option value="21:30:00" selected="selected">9:30 PM</option>
            <option value="22:00:00" selected="selected">10:00 PM</option>
            <option value="22:30:00" selected="selected">10:30 PM</option>
            <option value="23:00:00" selected="selected">11:00 PM</option>
            <option value="23:30:00" selected="selected">11:30 PM</option>
                                         </select>
                                      </div>  
                                      </div>  
              
              </div>

             
          
          <div class="footer-section">
            <hr />
            <button
              type="submit"
              class="button submit-button"
             
            >
              <span class="button-text">Book Now</span>
            </button>
            <button
            type="button"
            (click)="cancelbookingForm()"
            class="button cancel-button"
          >
            <span class="button-text">Cancel</span>
          </button> 

        
          </div>
        </form>


       
      
      </div>

    </div>
</div>

    </div>
  </div>
  