<div *ngIf="open$ | async" class="panel-container">
  <div (click)="closePanel()" class="overlay"></div>
  <form class="form" (ngSubmit)="submitForm()" #patientForm="ngForm">
    <div class="content mat-elevation-z9">
      <div class="header">
        <button
          (click)="closePanel()"
          type="button"
          class="close-button"
          mat-icon-button
          aria-label="Open menu"
        >
          <mat-icon>close</mat-icon>
        </button>
        <h2>Edit Client</h2>
      </div>
      <div class="form-section">
        <div class="row">
          <div class="column small">
            <mat-form-field appearance="outline">
              <mat-label>First Name</mat-label>
              <input matInput [(ngModel)]="patient.first_name" name="first_name" required/>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="column small">
            <mat-form-field appearance="outline">
              <mat-label>Last Name</mat-label>
              <input matInput [(ngModel)]="patient.last_name" name="last_name" required/>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="column small">
            <mat-form-field appearance="outline">
              <mat-label>Street Address</mat-label>
              <input matInput [(ngModel)]="patient.address.street" name="street" />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="column small">
            <mat-form-field appearance="outline">
              <mat-label>City</mat-label>
              <input matInput [(ngModel)]="patient.address.city" name="city" />
            </mat-form-field>
          </div>
          <div class="column small sm">
            <mat-form-field appearance="outline">
              <mat-label>State</mat-label>
              <mat-select [(ngModel)]="patient.address.state" name="state">
                <mat-option *ngFor="let state of usStates" [value]="state">{{ state }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="column small md">
            <mat-form-field class="medium" appearance="outline">
              <mat-label>Zip</mat-label>
              <input matInput [(ngModel)]="patient.address.zip" name="zip" />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="column small lg">
            <mat-form-field appearance="outline">
              <mat-label>Date of Birth</mat-label>
              <input
                name="dob"
                class="date-input"
                placeholder="mm/dd/yyyy"
                matInput
                [max]="endDate"
                
                [matDatepicker]="dobPicker"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="dobPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #dobPicker></mat-datepicker>
            </mat-form-field>
            <!-- <mat-form-field class="medium" appearance="outline">
              <mat-label>Date of Birth</mat-label>
              <input matInput placeholder="MM/DD/YYYY" [(ngModel)]="patient.dob" name="dob" pattern="^[0-1]?[0-9]/[0-3]?[0-9]/[0-9]{4}$" />
            </mat-form-field> -->
          </div>
          <div class="column">
            <h5>Gender</h5>
            <mat-radio-group color="primary" aria-label="Select an gender">
              <mat-radio-button value="male">Male</mat-radio-button>
              <mat-radio-button value="female">Female</mat-radio-button>
              <mat-radio-button value="other">Other</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="row">
          <div class="column small">
            <mat-form-field appearance="outline">
              <mat-label>Clinic Location</mat-label>
              <mat-select [(ngModel)]="patient.clinicLocation" name="clinicLocation">
                <mat-option
                  *ngFor="let location of clinicLocationOptions"
                  [value]="location"
                  >{{ location }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <div class="schedule-wrapper">
              <h5>Schedule (Greyed out times are in use)</h5>
              <div class="time-picker-wrapper">
                <wtls-time-picker [dayOfWeek]="1"
                 [schedule]="patient.availability"
                  [availability]="availability"
                >
                  </wtls-time-picker>
               <!--  <wtls-timepicker-dropdown></wtls-timepicker-dropdown>
                <wtls-timepicker-dropdown></wtls-timepicker-dropdown> -->
                <select class="select-input" id="startappt" [(ngModel)]='selected_start_slot_mon' name="slot">
                  <option [ngValue]="undefined" selected>12:00 AM</option>
                  <option *ngFor="let slot of timeslot" [value]="slot">{{slot}}</option>
                </select>

                <select class="select-input" id="endappt" [(ngModel)]='selected_end_slot_mon' name="slot">
                  <option [ngValue]="undefined" selected>11:30 PM</option>
                  <option *ngFor="let slot of timeslot" [value]="slot">{{slot}}</option>
                </select>
                <!-- <select class="select-input column"   id="monstarttime"  (change)="changeSchedule($event)" name="time">
                  <option value="12.00 AM" selected="selected" >12.00 AM</option>
                  <option value="12.30 AM">12.30 AM</option>
                  <option value="1.00 AM">1.00 AM</option>
                  <option value="1.30 AM">1.30 AM</option>
                  <option value="2.00 AM">2.00 AM</option>
                  <option value="2.30 AM">2.30 AM</option>
                  <option value="3.00 AM">3.00 AM</option>
                  <option value="3.30 AM">3.30 AM</option>
                  <option value="4.00 AM">4.00 AM</option>
                  <option value="4.30 AM">4.30 AM</option>
                  <option value="5.00 AM">5.00 AM</option>
                  <option value="5.30 AM">5.30 AM</option>
                  <option value="6.00 AM">6.00 AM</option>
                  <option value="6.30 AM">6.30 AM</option>
                  <option value="7.00 AM">7.00 AM</option>
                  <option value="7.30 AM">7.30 AM</option>
                  <option value="8.00 AM">8.00 AM</option>
                  <option value="8.30 AM">8.30 AM</option>
                  <option value="9.00 AM">9.00 AM</option>
                  <option value="9.30 AM">9.30 AM</option>
                  <option value="10.00 AM">10.00 AM</option>
                  <option value="10.30 AM">10.30 AM</option>
                  <option value="11.00 AM">11.00 AM</option>
                  <option value="11.30 AM">11.30 AM</option>
                  <option value="12.00 PM">12.00 PM</option>
                  <option value="12.30 PM">12.30 PM</option>
                  <option value="1.00 PM">1.00 PM</option>
                  <option value="1.30 PM">1.30 PM</option>
                  <option value="2.00 PM">2.00 PM</option>
                  <option value="2.30 PM">2.30 PM</option>
                  <option value="3.00 PM">3.00 PM</option>
                  <option value="3.30 PM">3.30 PM</option>
                  <option value="4.00 PM">4.00 PM</option>
                  <option value="4.30 PM">4.30 PM</option>
                  <option value="5.00 PM">5.00 PM</option>
                  <option value="5.30 PM">5.30 PM</option>
                  <option value="6.00 PM">6.00 PM</option>
                  <option value="6.30 PM">6.30 PM</option>
                  <option value="7.00 PM">7.00 PM</option>
                  <option value="7.30 PM">7.30 PM</option>
                  <option value="8.00 PM">8.00 PM</option>
                  <option value="8.30 PM">8.30 PM</option>
                  <option value="9.00 PM">9.00 PM</option>
                  <option value="9.30 PM">9.30 PM</option>
                  <option value="10.00 PM">10.00 PM</option>
                  <option value="10.30 PM">10.30 PM</option>
                  <option value="11.00 PM">11.00 PM</option>
                  <option value="11.30 PM">11.30 PM</option>
                  <option value="12.00 AM"  >12.00 AM</option>
                 </select>

                 <select class="select-input column" id="endappt1"   (change)="changeSchedule($event)" name="time" >
                  <option value="12.00 AM">12.00 AM</option>
                  <option value="12.30 AM">12.30 AM</option>
                  <option value="1.00 AM" >1.00 AM</option>
                  <option value="1.30 AM" >1.30 AM</option>
                  <option value="2.00 AM" >2.00 AM</option>
                  <option value="2.30 AM" >2.30 AM</option>
                  <option value="3.00 AM" >3.00 AM</option>
                  <option value="3.30 AM" >3.30 AM</option>
                  <option value="4.00 AM" >4.00 AM</option>
                  <option value="4.30 AM" >4.30 AM</option>
                  <option value="5.00 AM" >5.00 AM</option>
                  <option value="5.30 AM" >5.30 AM</option>
                  <option value="6.00 AM" >6.00 AM</option>
                  <option value="6.30 AM" >6.30 AM</option>
                  <option value="7.00 AM" >7.00 AM</option>
                  <option value="7.30 AM" >7.30 AM</option>
                  <option value="8.00 AM" >8.00 AM</option>
                  <option value="8.30 AM" >8.30 AM</option>
                  <option value="9.00 AM" >9.00 AM</option>
                  <option value="9.30 AM" >9.30 AM</option>
                  <option value="10.00 AM">10.00 AM</option>
                  <option value="10.30 AM">10.30 AM</option>
                  <option value="11.00 AM">11.00 AM</option>
                  <option value="11.30 AM">11.30 AM</option>
                  <option value="12.00 PM">12.00 PM</option>
                  <option value="12.30 PM">12.30 PM</option>
                  <option value="1.30 PM" >1.30 PM</option>
                  <option value="2.00 PM" >2.00 PM</option>
                  <option value="2.30 PM" >2.30 PM</option>
                  <option value="3.00 PM" >3.00 PM</option>
                  <option value="3.30 PM" >3.30 PM</option>
                  <option value="4.00 PM" >4.00 PM</option>
                  <option value="4.30 PM" >4.30 PM</option>
                  <option value="5.00 PM" >5.00 PM</option>
                  <option value="5.30 PM" >5.30 PM</option>
                  <option value="6.00 PM" >6.00 PM</option>
                  <option value="6.30 PM" >6.30 PM</option>
                  <option value="7.00 PM" >7.00 PM</option>
                  <option value="7.30 PM" >7.30 PM</option>
                  <option value="8.00 PM" >8.00 PM</option>
                  <option value="8.30 PM" >8.30 PM</option>
                  <option value="9.00 PM" >9.00 PM</option>
                  <option value="9.30 PM" >9.30 PM</option>
                  <option value="10.00 PM">10.00 PM</option>
                  <option value="10.30 PM">10.30 PM</option>
                  <option value="11.00 PM">11.00 PM</option>
                  <option value="11.30 PM" selected="selected">11.30 PM</option>
                  <option value="12.00 AM">12.00 AM</option>
                 </select> -->


                <wtls-time-picker [dayOfWeek]="2" [schedule]="patient.availability" [availability]="availability" ></wtls-time-picker>
                <select class="select-input" id="startappt" [(ngModel)]='selected_start_slot_tue' name="slot" value="8:00 AM">
                  <option [ngValue]="undefined" selected>12:00 AM</option>
                  <option *ngFor="let slot of timeslot" [value]="slot">{{slot}}</option>
                </select>

                <select class="select-input" id="endappt" [(ngModel)]='selected_end_slot_tue' name="slot" value="8:00 PM">
                  <option [ngValue]="undefined" selected>11:30 PM</option>
                  <option *ngFor="let slot of timeslot" [value]="slot">{{slot}}</option>
                </select>
                <!-- <wtls-timepicker-dropdown></wtls-timepicker-dropdown>
                <wtls-timepicker-dropdown></wtls-timepicker-dropdown> -->
                <!-- <select class="select-input column" id="startappt"   (change)="changeSchedule($event)" name="time">
                  <option value="12.00 AM" selected="selected">12.00 AM</option>
                  <option value="12.30 AM">12.30 AM</option>
                  <option value="1.00 AM">1.00 AM</option>
                  <option value="1.30 AM">1.30 AM</option>
                  <option value="2.00 AM">2.00 AM</option>
                  <option value="2.30 AM">2.30 AM</option>
                  <option value="3.00 AM">3.00 AM</option>
                  <option value="3.30 AM">3.30 AM</option>
                  <option value="4.00 AM">4.00 AM</option>
                  <option value="4.30 AM">4.30 AM</option>
                  <option value="5.00 AM">5.00 AM</option>
                  <option value="5.30 AM">5.30 AM</option>
                  <option value="6.00 AM">6.00 AM</option>
                  <option value="6.30 AM">6.30 AM</option>
                  <option value="7.00 AM">7.00 AM</option>
                  <option value="7.30 AM">7.30 AM</option>
                  <option value="8.00 AM">8.00 AM</option>
                  <option value="8.30 AM">8.30 AM</option>
                  <option value="9.00 AM">9.00 AM</option>
                  <option value="9.30 AM">9.30 AM</option>
                  <option value="10.00 AM">10.00 AM</option>
                  <option value="10.30 AM">10.30 AM</option>
                  <option value="11.00 AM">11.00 AM</option>
                  <option value="11.30 AM">11.30 AM</option>
                  <option value="12.00 PM">12.00 PM</option>
                  <option value="12.30 PM">12.30 PM</option>
                  <option value="1.00 PM">1.00 PM</option>
                  <option value="1.30 PM">1.30 PM</option>
                  <option value="2.00 PM">2.00 PM</option>
                  <option value="2.30 PM">2.30 PM</option>
                  <option value="3.00 PM">3.00 PM</option>
                  <option value="3.30 PM">3.30 PM</option>
                  <option value="4.00 PM">4.00 PM</option>
                  <option value="4.30 PM">4.30 PM</option>
                  <option value="5.00 PM">5.00 PM</option>
                  <option value="5.30 PM">5.30 PM</option>
                  <option value="6.00 PM">6.00 PM</option>
                  <option value="6.30 PM">6.30 PM</option>
                  <option value="7.00 PM">7.00 PM</option>
                  <option value="7.30 PM">7.30 PM</option>
                  <option value="8.00 PM">8.00 PM</option>
                  <option value="8.30 PM">8.30 PM</option>
                  <option value="9.00 PM">9.00 PM</option>
                  <option value="9.30 PM">9.30 PM</option>
                  <option value="10.00 PM">10.00 PM</option>
                  <option value="10.30 PM">10.30 PM</option>
                  <option value="11.00 PM">11.00 PM</option>
                  <option value="11.30 PM">11.30 PM</option>
                  <option value="12.00 AM">12.00 AM</option>
                 </select>

                 <select class="select-input column" id="endappt"   (change)="changeSchedule($event)" name="time" >
                  <option value="12.00 AM">12.00 AM</option>
                  <option value="12.30 AM">12.30 AM</option>
                  <option value="1.00 AM" >1.00 AM</option>
                  <option value="1.30 AM" >1.30 AM</option>
                  <option value="2.00 AM" >2.00 AM</option>
                  <option value="2.30 AM" >2.30 AM</option>
                  <option value="3.00 AM" >3.00 AM</option>
                  <option value="3.30 AM" >3.30 AM</option>
                  <option value="4.00 AM" >4.00 AM</option>
                  <option value="4.30 AM" >4.30 AM</option>
                  <option value="5.00 AM" >5.00 AM</option>
                  <option value="5.30 AM" >5.30 AM</option>
                  <option value="6.00 AM" >6.00 AM</option>
                  <option value="6.30 AM" >6.30 AM</option>
                  <option value="7.00 AM" >7.00 AM</option>
                  <option value="7.30 AM" >7.30 AM</option>
                  <option value="8.00 AM" >8.00 AM</option>
                  <option value="8.30 AM" >8.30 AM</option>
                  <option value="9.00 AM" >9.00 AM</option>
                  <option value="9.30 AM" >9.30 AM</option>
                  <option value="10.00 AM">10.00 AM</option>
                  <option value="10.30 AM">10.30 AM</option>
                  <option value="11.00 AM">11.00 AM</option>
                  <option value="11.30 AM">11.30 AM</option>
                  <option value="12.00 PM">12.00 PM</option>
                  <option value="12.30 PM">12.30 PM</option>
                  <option value="1.30 PM" >1.30 PM</option>
                  <option value="2.00 PM" >2.00 PM</option>
                  <option value="2.30 PM" >2.30 PM</option>
                  <option value="3.00 PM" >3.00 PM</option>
                  <option value="3.30 PM" >3.30 PM</option>
                  <option value="4.00 PM" >4.00 PM</option>
                  <option value="4.30 PM" >4.30 PM</option>
                  <option value="5.00 PM" >5.00 PM</option>
                  <option value="5.30 PM" >5.30 PM</option>
                  <option value="6.00 PM" >6.00 PM</option>
                  <option value="6.30 PM" >6.30 PM</option>
                  <option value="7.00 PM" >7.00 PM</option>
                  <option value="7.30 PM" >7.30 PM</option>
                  <option value="8.00 PM" >8.00 PM</option>
                  <option value="8.30 PM" >8.30 PM</option>
                  <option value="9.00 PM" >9.00 PM</option>
                  <option value="9.30 PM" >9.30 PM</option>
                  <option value="10.00 PM">10.00 PM</option>
                  <option value="10.30 PM">10.30 PM</option>
                  <option value="11.00 PM">11.00 PM</option>
                  <option value="11.30 PM" selected="selected">11.30 PM</option>
                  <option value="12.00 AM">12.00 AM</option>
                 </select> -->

                <wtls-time-picker [dayOfWeek]="3" [schedule]="patient.availability" [availability]="availability" ></wtls-time-picker>
                <select class="select-input" id="startappt" [(ngModel)]='selected_start_slot_wed' name="slot" >
                  <option [ngValue]="undefined" selected>12:00 AM</option>
                  <option *ngFor="let slot of timeslot" [value]="slot">{{slot}}</option>
                </select>

                <select class="select-input" id="endappt" [(ngModel)]='selected_end_slot_wed' name="slot" >
                  <option [ngValue]="undefined" selected>11:30 PM</option>
                  <option *ngFor="let slot of timeslot" [value]="slot">{{slot}}</option>
                </select>
               <!--  <wtls-timepicker-dropdown></wtls-timepicker-dropdown>
                <wtls-timepicker-dropdown></wtls-timepicker-dropdown> -->
                <!-- <select class="select-input column" id="startappt"   (change)="changeSchedule($event)" name="time">
                  <option value="12.00 AM" selected="selected">12.00 AM</option>
                  <option value="12.30 AM">12.30 AM</option>
                  <option value="1.00 AM">1.00 AM</option>
                  <option value="1.30 AM">1.30 AM</option>
                  <option value="2.00 AM">2.00 AM</option>
                  <option value="2.30 AM">2.30 AM</option>
                  <option value="3.00 AM">3.00 AM</option>
                  <option value="3.30 AM">3.30 AM</option>
                  <option value="4.00 AM">4.00 AM</option>
                  <option value="4.30 AM">4.30 AM</option>
                  <option value="5.00 AM">5.00 AM</option>
                  <option value="5.30 AM">5.30 AM</option>
                  <option value="6.00 AM">6.00 AM</option>
                  <option value="6.30 AM">6.30 AM</option>
                  <option value="7.00 AM">7.00 AM</option>
                  <option value="7.30 AM">7.30 AM</option>
                  <option value="8.00 AM">8.00 AM</option>
                  <option value="8.30 AM">8.30 AM</option>
                  <option value="9.00 AM">9.00 AM</option>
                  <option value="9.30 AM">9.30 AM</option>
                  <option value="10.00 AM">10.00 AM</option>
                  <option value="10.30 AM">10.30 AM</option>
                  <option value="11.00 AM">11.00 AM</option>
                  <option value="11.30 AM">11.30 AM</option>
                  <option value="12.00 PM">12.00 PM</option>
                  <option value="12.30 PM">12.30 PM</option>
                  <option value="1.00 PM">1.00 PM</option>
                  <option value="1.30 PM">1.30 PM</option>
                  <option value="2.00 PM">2.00 PM</option>
                  <option value="2.30 PM">2.30 PM</option>
                  <option value="3.00 PM">3.00 PM</option>
                  <option value="3.30 PM">3.30 PM</option>
                  <option value="4.00 PM">4.00 PM</option>
                  <option value="4.30 PM">4.30 PM</option>
                  <option value="5.00 PM">5.00 PM</option>
                  <option value="5.30 PM">5.30 PM</option>
                  <option value="6.00 PM">6.00 PM</option>
                  <option value="6.30 PM">6.30 PM</option>
                  <option value="7.00 PM">7.00 PM</option>
                  <option value="7.30 PM">7.30 PM</option>
                  <option value="8.00 PM">8.00 PM</option>
                  <option value="8.30 PM">8.30 PM</option>
                  <option value="9.00 PM">9.00 PM</option>
                  <option value="9.30 PM">9.30 PM</option>
                  <option value="10.00 PM">10.00 PM</option>
                  <option value="10.30 PM">10.30 PM</option>
                  <option value="11.00 PM">11.00 PM</option>
                  <option value="11.30 PM">11.30 PM</option>
                  <option value="12.00 AM">12.00 AM</option>
                 </select>

                 <select class="select-input column" id="endappt"   (change)="changeSchedule($event)" name="time" >
                  <option value="12.00 AM">12.00 AM</option>
                  <option value="12.30 AM">12.30 AM</option>
                  <option value="1.00 AM" >1.00 AM</option>
                  <option value="1.30 AM" >1.30 AM</option>
                  <option value="2.00 AM" >2.00 AM</option>
                  <option value="2.30 AM" >2.30 AM</option>
                  <option value="3.00 AM" >3.00 AM</option>
                  <option value="3.30 AM" >3.30 AM</option>
                  <option value="4.00 AM" >4.00 AM</option>
                  <option value="4.30 AM" >4.30 AM</option>
                  <option value="5.00 AM" >5.00 AM</option>
                  <option value="5.30 AM" >5.30 AM</option>
                  <option value="6.00 AM" >6.00 AM</option>
                  <option value="6.30 AM" >6.30 AM</option>
                  <option value="7.00 AM" >7.00 AM</option>
                  <option value="7.30 AM" >7.30 AM</option>
                  <option value="8.00 AM" >8.00 AM</option>
                  <option value="8.30 AM" >8.30 AM</option>
                  <option value="9.00 AM" >9.00 AM</option>
                  <option value="9.30 AM" >9.30 AM</option>
                  <option value="10.00 AM">10.00 AM</option>
                  <option value="10.30 AM">10.30 AM</option>
                  <option value="11.00 AM">11.00 AM</option>
                  <option value="11.30 AM">11.30 AM</option>
                  <option value="12.00 PM">12.00 PM</option>
                  <option value="12.30 PM">12.30 PM</option>
                  <option value="1.30 PM" >1.30 PM</option>
                  <option value="2.00 PM" >2.00 PM</option>
                  <option value="2.30 PM" >2.30 PM</option>
                  <option value="3.00 PM" >3.00 PM</option>
                  <option value="3.30 PM" >3.30 PM</option>
                  <option value="4.00 PM" >4.00 PM</option>
                  <option value="4.30 PM" >4.30 PM</option>
                  <option value="5.00 PM" >5.00 PM</option>
                  <option value="5.30 PM" >5.30 PM</option>
                  <option value="6.00 PM" >6.00 PM</option>
                  <option value="6.30 PM" >6.30 PM</option>
                  <option value="7.00 PM" >7.00 PM</option>
                  <option value="7.30 PM" >7.30 PM</option>
                  <option value="8.00 PM" >8.00 PM</option>
                  <option value="8.30 PM" >8.30 PM</option>
                  <option value="9.00 PM" >9.00 PM</option>
                  <option value="9.30 PM" >9.30 PM</option>
                  <option value="10.00 PM">10.00 PM</option>
                  <option value="10.30 PM">10.30 PM</option>
                  <option value="11.00 PM">11.00 PM</option>
                  <option value="11.30 PM" selected="selected">11.30 PM</option>
                  <option value="12.00 AM">12.00 AM</option>
                 </select> -->
                <wtls-time-picker [dayOfWeek]="4" [schedule]="patient.availability" [availability]="availability" ></wtls-time-picker>
                <select class="select-input" id="startappt" [(ngModel)]='selected_start_slot_thu' name="slot" value="8:00 AM" >
                  <option [ngValue]="undefined" selected>12:00 AM</option>
                  <option *ngFor="let slot of timeslot" [value]="slot">{{slot}}</option>
                </select>

                <select class="select-input" id="endappt" [(ngModel)]='selected_end_slot_thu' name="slot" value="8:00 PM">
                  <option [ngValue]="undefined" selected>11:30 PM</option>
                  <option *ngFor="let slot of timeslot" [value]="slot">{{slot}}</option>
                </select>
               <!--  <wtls-timepicker-dropdown></wtls-timepicker-dropdown>
                <wtls-timepicker-dropdown></wtls-timepicker-dropdown> -->
                <!-- <select class="select-input column" id="startappt"   (change)="changeSchedule($event)" name="time" >
                  <option value="12.00 AM" selected="selected">12.00 AM</option>
                  <option value="12.30 AM">12.30 AM</option>
                  <option value="1.00 AM">1.00 AM</option>
                  <option value="1.30 AM">1.30 AM</option>
                  <option value="2.00 AM">2.00 AM</option>
                  <option value="2.30 AM">2.30 AM</option>
                  <option value="3.00 AM">3.00 AM</option>
                  <option value="3.30 AM">3.30 AM</option>
                  <option value="4.00 AM">4.00 AM</option>
                  <option value="4.30 AM">4.30 AM</option>
                  <option value="5.00 AM">5.00 AM</option>
                  <option value="5.30 AM">5.30 AM</option>
                  <option value="6.00 AM">6.00 AM</option>
                  <option value="6.30 AM">6.30 AM</option>
                  <option value="7.00 AM">7.00 AM</option>
                  <option value="7.30 AM">7.30 AM</option>
                  <option value="8.00 AM">8.00 AM</option>
                  <option value="8.30 AM">8.30 AM</option>
                  <option value="9.00 AM">9.00 AM</option>
                  <option value="9.30 AM">9.30 AM</option>
                  <option value="10.00 AM">10.00 AM</option>
                  <option value="10.30 AM">10.30 AM</option>
                  <option value="11.00 AM">11.00 AM</option>
                  <option value="11.30 AM">11.30 AM</option>
                  <option value="12.00 PM">12.00 PM</option>
                  <option value="12.30 PM">12.30 PM</option>
                  <option value="1.00 PM">1.00 PM</option>
                  <option value="1.30 PM">1.30 PM</option>
                  <option value="2.00 PM">2.00 PM</option>
                  <option value="2.30 PM">2.30 PM</option>
                  <option value="3.00 PM">3.00 PM</option>
                  <option value="3.30 PM">3.30 PM</option>
                  <option value="4.00 PM">4.00 PM</option>
                  <option value="4.30 PM">4.30 PM</option>
                  <option value="5.00 PM">5.00 PM</option>
                  <option value="5.30 PM">5.30 PM</option>
                  <option value="6.00 PM">6.00 PM</option>
                  <option value="6.30 PM">6.30 PM</option>
                  <option value="7.00 PM">7.00 PM</option>
                  <option value="7.30 PM">7.30 PM</option>
                  <option value="8.00 PM">8.00 PM</option>
                  <option value="8.30 PM">8.30 PM</option>
                  <option value="9.00 PM">9.00 PM</option>
                  <option value="9.30 PM">9.30 PM</option>
                  <option value="10.00 PM">10.00 PM</option>
                  <option value="10.30 PM">10.30 PM</option>
                  <option value="11.00 PM">11.00 PM</option>
                  <option value="11.30 PM">11.30 PM</option>
                  <option value="12.00 AM">12.00 AM</option>
                 </select>

                 <select class="select-input column" id="endappt"    (change)="changeSchedule($event)" name="time">
                  <option value="12.00 AM">12.00 AM</option>
                  <option value="12.30 AM">12.30 AM</option>
                  <option value="1.00 AM" >1.00 AM</option>
                  <option value="1.30 AM" >1.30 AM</option>
                  <option value="2.00 AM" >2.00 AM</option>
                  <option value="2.30 AM" >2.30 AM</option>
                  <option value="3.00 AM" >3.00 AM</option>
                  <option value="3.30 AM" >3.30 AM</option>
                  <option value="4.00 AM" >4.00 AM</option>
                  <option value="4.30 AM" >4.30 AM</option>
                  <option value="5.00 AM" >5.00 AM</option>
                  <option value="5.30 AM" >5.30 AM</option>
                  <option value="6.00 AM" >6.00 AM</option>
                  <option value="6.30 AM" >6.30 AM</option>
                  <option value="7.00 AM" >7.00 AM</option>
                  <option value="7.30 AM" >7.30 AM</option>
                  <option value="8.00 AM" >8.00 AM</option>
                  <option value="8.30 AM" >8.30 AM</option>
                  <option value="9.00 AM" >9.00 AM</option>
                  <option value="9.30 AM" >9.30 AM</option>
                  <option value="10.00 AM">10.00 AM</option>
                  <option value="10.30 AM">10.30 AM</option>
                  <option value="11.00 AM">11.00 AM</option>
                  <option value="11.30 AM">11.30 AM</option>
                  <option value="12.00 PM">12.00 PM</option>
                  <option value="12.30 PM">12.30 PM</option>
                  <option value="1.30 PM" >1.30 PM</option>
                  <option value="2.00 PM" >2.00 PM</option>
                  <option value="2.30 PM" >2.30 PM</option>
                  <option value="3.00 PM" >3.00 PM</option>
                  <option value="3.30 PM" >3.30 PM</option>
                  <option value="4.00 PM" >4.00 PM</option>
                  <option value="4.30 PM" >4.30 PM</option>
                  <option value="5.00 PM" >5.00 PM</option>
                  <option value="5.30 PM" >5.30 PM</option>
                  <option value="6.00 PM" >6.00 PM</option>
                  <option value="6.30 PM" >6.30 PM</option>
                  <option value="7.00 PM" >7.00 PM</option>
                  <option value="7.30 PM" >7.30 PM</option>
                  <option value="8.00 PM" >8.00 PM</option>
                  <option value="8.30 PM" >8.30 PM</option>
                  <option value="9.00 PM" >9.00 PM</option>
                  <option value="9.30 PM" >9.30 PM</option>
                  <option value="10.00 PM">10.00 PM</option>
                  <option value="10.30 PM">10.30 PM</option>
                  <option value="11.00 PM">11.00 PM</option>
                  <option value="11.30 PM" selected="selected">11.30 PM</option>
                  <option value="12.00 AM">12.00 AM</option>
                 </select> -->
                <wtls-time-picker [dayOfWeek]="5" [schedule]="patient.availability" [availability]="availability" ></wtls-time-picker>
                <select class="select-input" id="startappt" [(ngModel)]='selected_start_slot_fri' name="slot" >
                  <option [ngValue]="undefined" selected>12:00 AM</option>
                  <option *ngFor="let slot of timeslot" [value]="slot">{{slot}}</option>
                </select>

                <select class="select-input" id="endappt" [(ngModel)]='selected_end_slot_fri' name="slot" >
                  <option [ngValue]="undefined" selected>11:30 PM</option>
                  <option *ngFor="let slot of timeslot" [value]="slot">{{slot}}</option>
                </select>
                <!-- <wtls-timepicker-dropdown></wtls-timepicker-dropdown>
                <wtls-timepicker-dropdown></wtls-timepicker-dropdown> -->
                <!-- <select class="select-input column" id="startappt"   (change)="changeSchedule($event)" name="time">
                  <option value="12.00 AM" selected="selected">12.00 AM</option>
                  <option value="12.30 AM">12.30 AM</option>
                  <option value="1.00 AM">1.00 AM</option>
                  <option value="1.30 AM">1.30 AM</option>
                  <option value="2.00 AM">2.00 AM</option>
                  <option value="2.30 AM">2.30 AM</option>
                  <option value="3.00 AM">3.00 AM</option>
                  <option value="3.30 AM">3.30 AM</option>
                  <option value="4.00 AM">4.00 AM</option>
                  <option value="4.30 AM">4.30 AM</option>
                  <option value="5.00 AM">5.00 AM</option>
                  <option value="5.30 AM">5.30 AM</option>
                  <option value="6.00 AM">6.00 AM</option>
                  <option value="6.30 AM">6.30 AM</option>
                  <option value="7.00 AM">7.00 AM</option>
                  <option value="7.30 AM">7.30 AM</option>
                  <option value="8.00 AM">8.00 AM</option>
                  <option value="8.30 AM">8.30 AM</option>
                  <option value="9.00 AM">9.00 AM</option>
                  <option value="9.30 AM">9.30 AM</option>
                  <option value="10.00 AM">10.00 AM</option>
                  <option value="10.30 AM">10.30 AM</option>
                  <option value="11.00 AM">11.00 AM</option>
                  <option value="11.30 AM">11.30 AM</option>
                  <option value="12.00 PM">12.00 PM</option>
                  <option value="12.30 PM">12.30 PM</option>
                  <option value="1.00 PM">1.00 PM</option>
                  <option value="1.30 PM">1.30 PM</option>
                  <option value="2.00 PM">2.00 PM</option>
                  <option value="2.30 PM">2.30 PM</option>
                  <option value="3.00 PM">3.00 PM</option>
                  <option value="3.30 PM">3.30 PM</option>
                  <option value="4.00 PM">4.00 PM</option>
                  <option value="4.30 PM">4.30 PM</option>
                  <option value="5.00 PM">5.00 PM</option>
                  <option value="5.30 PM">5.30 PM</option>
                  <option value="6.00 PM">6.00 PM</option>
                  <option value="6.30 PM">6.30 PM</option>
                  <option value="7.00 PM">7.00 PM</option>
                  <option value="7.30 PM">7.30 PM</option>
                  <option value="8.00 PM">8.00 PM</option>
                  <option value="8.30 PM">8.30 PM</option>
                  <option value="9.00 PM">9.00 PM</option>
                  <option value="9.30 PM">9.30 PM</option>
                  <option value="10.00 PM">10.00 PM</option>
                  <option value="10.30 PM">10.30 PM</option>
                  <option value="11.00 PM">11.00 PM</option>
                  <option value="11.30 PM">11.30 PM</option>
                  <option value="12.00 AM">12.00 AM</option>
                 </select>

                 <select class="select-input column" id="endappt"   (change)="changeSchedule($event)" name="time">
                  <option value="12.00 AM">12.00 AM</option>
                  <option value="12.30 AM">12.30 AM</option>
                  <option value="1.00 AM" >1.00 AM</option>
                  <option value="1.30 AM" >1.30 AM</option>
                  <option value="2.00 AM" >2.00 AM</option>
                  <option value="2.30 AM" >2.30 AM</option>
                  <option value="3.00 AM" >3.00 AM</option>
                  <option value="3.30 AM" >3.30 AM</option>
                  <option value="4.00 AM" >4.00 AM</option>
                  <option value="4.30 AM" >4.30 AM</option>
                  <option value="5.00 AM" >5.00 AM</option>
                  <option value="5.30 AM" >5.30 AM</option>
                  <option value="6.00 AM" >6.00 AM</option>
                  <option value="6.30 AM" >6.30 AM</option>
                  <option value="7.00 AM" >7.00 AM</option>
                  <option value="7.30 AM" >7.30 AM</option>
                  <option value="8.00 AM" >8.00 AM</option>
                  <option value="8.30 AM" >8.30 AM</option>
                  <option value="9.00 AM" >9.00 AM</option>
                  <option value="9.30 AM" >9.30 AM</option>
                  <option value="10.00 AM">10.00 AM</option>
                  <option value="10.30 AM">10.30 AM</option>
                  <option value="11.00 AM">11.00 AM</option>
                  <option value="11.30 AM">11.30 AM</option>
                  <option value="12.00 PM">12.00 PM</option>
                  <option value="12.30 PM">12.30 PM</option>
                  <option value="1.30 PM" >1.30 PM</option>
                  <option value="2.00 PM" >2.00 PM</option>
                  <option value="2.30 PM" >2.30 PM</option>
                  <option value="3.00 PM" >3.00 PM</option>
                  <option value="3.30 PM" >3.30 PM</option>
                  <option value="4.00 PM" >4.00 PM</option>
                  <option value="4.30 PM" >4.30 PM</option>
                  <option value="5.00 PM" >5.00 PM</option>
                  <option value="5.30 PM" >5.30 PM</option>
                  <option value="6.00 PM" >6.00 PM</option>
                  <option value="6.30 PM" >6.30 PM</option>
                  <option value="7.00 PM" >7.00 PM</option>
                  <option value="7.30 PM" >7.30 PM</option>
                  <option value="8.00 PM" >8.00 PM</option>
                  <option value="8.30 PM" >8.30 PM</option>
                  <option value="9.00 PM" >9.00 PM</option>
                  <option value="9.30 PM" >9.30 PM</option>
                  <option value="10.00 PM">10.00 PM</option>
                  <option value="10.30 PM">10.30 PM</option>
                  <option value="11.00 PM">11.00 PM</option>
                  <option value="11.30 PM" selected="selected">11.30 PM</option>
                  <option value="12.00 AM">12.00 AM</option>
                 </select> -->
               <!--  <wtls-time-picker [dayOfWeek]="6" [schedule]="patient.availability" [availability]="availability" (change)="changeSchedule($event)"></wtls-time-picker> -->
                <!-- <wtls-timepicker-dropdown></wtls-timepicker-dropdown>
                <wtls-timepicker-dropdown></wtls-timepicker-dropdown> -->
                
                <!-- <wtls-time-picker [dayOfWeek]="7" [schedule]="patient.availability" [availability]="availability" (change)="changeSchedule($event)"></wtls-time-picker> -->
               <!--  <wtls-timepicker-dropdown></wtls-timepicker-dropdown>
                <wtls-timepicker-dropdown></wtls-timepicker-dropdown>   -->
                
              </div>  
            </div>
            <!-- <wtls-patient-scheduler [schedule]="patient.availability" [availability]="availability"></wtls-patient-scheduler> -->
          </div>
        </div>
        <div class="row">
          <div class="column small">
            <mat-form-field appearance="outline">
              <mat-label>Client Type</mat-label>
              <mat-select [(ngModel)]="patient.clientType" name="clientType">
                <mat-option
                  *ngFor="let clientType of clientTypeOptions"
                  [value]="clientType"
                  >{{ clientType }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="column small">
            <mat-form-field appearance="outline">
              <mat-label>Insurance Type</mat-label>
              <mat-select [(ngModel)]="patient.insuranceType" name="insuranceType">
                <mat-option
                  *ngFor="let insuranceType of insuranceTypeOptions"
                  [value]="insuranceType"
                  >{{ insuranceType }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="column small">
            <mat-form-field appearance="outline">
              <mat-label>Intake Stage</mat-label>
              <mat-select [(ngModel)]="patient.intakeStage" name="intakeStage">
                <mat-option
                  *ngFor="let intakeStage of intakeStageOptions"
                  [value]="intakeStage"
                  >{{ intakeStage }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="button-group">
          <button mat-flat-button type="submit" [disabled]="patientForm.form.invalid" color="primary">Save</button>
          <button mat-stroked-button type="button" (click)="closePanel()">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
