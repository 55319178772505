import { createAction, props } from '@ngrx/store';

export enum MapFullScreenActionTypes {
    TOGGLE_FULLSCREEN = '[Map] Toggle FullScreen',
    RESET_FULLSCREEN = '[Map] Reset FullScreen'
}

export const toggleFullScreen = createAction(
    MapFullScreenActionTypes.TOGGLE_FULLSCREEN
)

export const resetFullScreen = createAction(
    MapFullScreenActionTypes.RESET_FULLSCREEN
)