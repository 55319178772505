<div>

  <form class="mat-dialog-content" (ngSubmit)="submit" #formControl="ngForm">

    
  <div  *ngFor="let column of data.displayedColumns">
    <div class="form" *ngIf="data.data[column]">
      <mat-form-field color="accent" >
        <input matInput  [type]="data.data[column]['type']" [placeholder]="column" [(ngModel)]="data.data[column]['value']" [name]='column' >
    
      </mat-form-field>
    </div>
  </div>
    <!--Contains mat-hint for characters count and has maxLengt set-->
    <!-- <div class="form">
      <mat-form-field color="accent">
        <input matInput   placeholder="FirstName" [(ngModel)]="data.data['First Name']['value']"  name="FirstName">
      </mat-form-field>
    </div> -->

    <!-- <div class="form">
        <mat-form-field color="accent">
          <input matInput placeholder="LastName" [(ngModel)]="data.data['Last Name']['value']"  name="LastName">
        </mat-form-field>
    </div>

    <div class="form">
      <mat-form-field color="accent">
        <input matInput placeholder="BirthDate" [(ngModel)]="data.data['Birth Date']['value']"  name="BirthDate">
      </mat-form-field>
    </div>

    <div class="form">
      <mat-form-field color="accent">
        <input matInput placeholder="Gender" [(ngModel)]="data.data.Gender.value"  name="Gender">
      </mat-form-field>
    </div>

    <div class="form">
      <mat-form-field color="accent">
        <input matInput placeholder="Diagnosis"  name="Diagnosis">
      </mat-form-field>
    </div>

    <div class="form">
      <mat-form-field color="accent">
        <input matInput placeholder="Date of Diagnosis"  name="Date of Diagnosis">
      </mat-form-field>
    </div>

    <div class="form">
      <mat-form-field color="accent">
        <input matInput placeholder="Clicnic Location"  name="Clicnic Location">
      </mat-form-field>
    </div>

    <div class="form">
      <mat-form-field color="accent">
        <input matInput placeholder="Your Message" [(ngModel)]="data.data['Your message/concerns']['value']"  name="Your Message">
      </mat-form-field>
    </div>

    <div class="form">
      <mat-form-field color="accent">
        <input matInput placeholder="How did you hear about us?" [(ngModel)]="data.data['How did you hear about us?']['value']"  name="How did you hear about us?">
      </mat-form-field>
    </div>

    <div class="form">
      <mat-form-field color="accent">
        <input matInput placeholder="Email" [(ngModel)]="data.data.Email['value']"  name="Email">
      </mat-form-field>
    </div>

    <div class="form">
      <mat-form-field color="accent">
        <input matInput placeholder="Cell Phone" [(ngModel)]="data.data['Cell Phone']['value']"  name="Cell Phone">
      </mat-form-field>
    </div>

    <div class="form">
      <mat-form-field color="accent">
        <input matInput placeholder="What is the best time of day to reach you?" [(ngModel)]="data.data['What is the best time of day to reach you?']['value']"  name="What is the best time of day to reach you?">
      </mat-form-field>
    </div>

    <div class="form">
      <mat-form-field color="accent">
        <input matInput placeholder="Preferred method of contact" [(ngModel)]="data.data['Preferred method of contact']['value']"  name="Preferred method of contact">
      </mat-form-field>
    </div>

    <div class="form">
      <mat-form-field color="accent">
        <input matInput placeholder="Pediatrician name"  name="Pediatrician name">
      </mat-form-field>
    </div>

    <div class="form">
      <mat-form-field color="accent">
        <input matInput placeholder="Diagnosing Provider name"  name="Diagnosing Provider name">
      </mat-form-field>
    </div> -->

    <div mat-dialog-actions>
      <button mat-button [type]="submit" (click)="submit()" >Save</button>
      <button mat-button tabindex="-1" (click)="closeDialog()">Cancel</button>
    </div>
  </form>
</div>