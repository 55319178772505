<div class="tree-container">
  <cdk-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <!-- Tree node template for leaf nodes -->
    <cdk-nested-tree-node *cdkTreeNodeDef="let node" class="tree-node">
      <!-- use a disabled button to provide padding for tree leaf -->
      <button mat-icon-button disabled></button>
      {{ node.name }}
    </cdk-nested-tree-node>
    <!-- Tree node template for MinMax field nodes -->
    <cdk-nested-tree-node
      *cdkTreeNodeDef="let node; when: isMinMax"
      class="tree-node"
    >
      <div class="filter-wrapper">
        <wtls-min-max [min]="node.data[0].min" [max]="node.data[0].max" (maxChange)="filterChanged(node, $event, 'maxAge')" (minChange)="filterChanged(node, $event, 'minAge')"></wtls-min-max>
      </div>
    </cdk-nested-tree-node>
    <!-- Tree node template for checkbox nodes -->
    <cdk-nested-tree-node
      *cdkTreeNodeDef="let node; when: isCheckboxes"
      class="tree-node"
    >
      <div class="filter-wrapper">
        <mat-checkbox
          *ngFor="let field of node.data"
          class="filter-checkbox"
          color="primary"
          [checked]="field.value"
          (change)="filterChanged(node, $event.checked, field.name)"
          >{{ field.name }}</mat-checkbox
        >
      </div>
    </cdk-nested-tree-node>
    <!-- Tree node template for expandable nodes -->
    <cdk-nested-tree-node
      *cdkTreeNodeDef="let node; when: hasChild"
      class="tree-node"
    >
      <button
        mat-icon-button
        [attr.aria-label]="'toggle ' + node.name"
        cdkTreeNodeToggle
      >
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
        </mat-icon>
      </button>
      {{ node.name }}
      <div [class.tree-invisible]="!treeControl.isExpanded(node)">
        <ng-container cdkTreeNodeOutlet></ng-container>
      </div>
    </cdk-nested-tree-node>
  </cdk-tree>
</div>
