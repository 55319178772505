<div class="confirm-dialog-container">
    <div class="confirm-header-container">
        <h2 *ngIf="patient">Delete Client?</h2>
        <h2 *ngIf="provider">Delete Provider?</h2>
        <h2 *ngIf="match">Unassign Match?</h2>
        <h2 *ngIf="type == 'uploadCSVpatients'">Upload Clients CSV?</h2>
        <h2 *ngIf="type == 'uploadCSVproviders'">Upload Providers CSV?</h2>
    </div>
    <div class="confirm-message-container">
        <p *ngIf="patient">Are you sure you want to remove <strong *ngIf="patient.name">{{ patient.name }}</strong><strong *ngIf="!patient.name"><em>No Name</em></strong> from the list of clients?</p>
        <p *ngIf="provider">Are you sure you want to remove <strong *ngIf="provider.name">{{ provider.name }}</strong><strong *ngIf="!provider.name"><em>No Name</em></strong> from the list of providers?</p>
        <p *ngIf="match">Are you sure you want to unassign <strong *ngIf="match.provider.name">{{ match.provider.name }}</strong><strong *ngIf="!match.provider.name"><em>No Name</em></strong> and <strong *ngIf="match.patient.name">{{ match.patient.name }}</strong><strong *ngIf="!match.patient.name"><em>No Name</em></strong></p>
        <p *ngIf="type == 'uploadCSVpatients'">Are you sure you want to upload new client data?</p>
        <p *ngIf="type == 'uploadCSVproviders'">Are you sure you want to upload new provider data?</p>
        <p *ngIf="type == 'uploadFailure'">Field {{field}} is missing at row {{row}}</p>
        <p *ngIf="type == 'MissingApptDetails'">Please select a Provider and a Client to schedule an appointment</p>
    </div>
    <div class="confirm-buttons-container">
      <button 
        type="button" 
        class="confirm-button"
        mat-flat-button
        color="primary"
        (click)="confirm()">
        <span *ngIf="patient || provider">Delete</span>
        <span *ngIf="match">Unassign</span>
        <span *ngIf="type == 'uploadCSVpatients' || type == 'uploadCSVproviders'">Yes</span>
      </button>
      
      <button
      type="button"
      class="confirm-button"
      mat-flat-button
      color="primary"
      (click)="uploadFailure()">
      <span *ngIf="type == 'uploadFailure'">OK</span>
      </button>

      <button
      type="button"
      class="confirm-button"
      mat-flat-button
      color="primary"
      (click)="missingDetails()">
      <span *ngIf="type == 'MissingApptDetails'">OK</span>
      </button>

      <button 
        *ngIf="patient || provider || match"
        type="button" 
        class="cancel-button"
        mat-stroked-button
        (click)="cancel()">
        Cancel
      </button>
      <button 
        *ngIf="type == 'uploadCSVpatients' || type == 'uploadCSVproviders'"
        type="button" 
        class="cancel-button"
        mat-stroked-button
        (click)="cancel()">
        No
      </button>
    </div>
</div>