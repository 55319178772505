<wtls-top-bar></wtls-top-bar>
<div class="page-container">
  <div class="content-container ms-Fabric">
    <i class="ms-Icon ms-Icon--Completed"></i>
    <h2>Form Submitted</h2>
    <button type="button" class=" button new-form-button" (click)="newForm()">
      <span class="button-text">NEW FORM</span>
    </button>
    <button type="button" class=" button dashboard-button" (click)="dashboard()">
      <span class="button-text">DASHBOARD</span>
    </button>
  </div>
</div>
