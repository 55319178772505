import { Component, EventEmitter, Input, OnChanges, OnInit, Output, Inject } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Observable, Subject } from 'rxjs';
import { FormResponse, Page } from 'src/app/models';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {NewClientsService} from '../../../../../services/new-clients/new-clients.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'wtls-edit-clients',
  templateUrl: './edit-clients.component.html',
  styleUrls: ['./edit-clients.component.scss']
})
export class EditClientsComponent implements OnInit {

  constructor(public dialog: MatDialogRef<EditClientsComponent>,
              public newClientsService:NewClientsService,
              public datepipe: DatePipe,
              @Inject(MAT_DIALOG_DATA) public data: any) { }
              
  ngOnInit(): void {
    for(let column of this.data.displayedColumns){
      if(this.data.data[column] && this.data.data[column]['type'] == 'date'){
        let date = new Date(this.data.data[column]['value']);
        this.data.data[column]['value'] = this.datepipe.transform(this.data.data[column]['value'] , 'yyyy-MM-dd');
      }
    }
  }

  submit() {
    console.log("this.data.data => ",this.data.data)
    this.newClientsService.updateClient(this.data.data).subscribe((res)=>{
        if(res.result == 'success')
          this.dialog.close(res)
    });
  }

  closeDialog(){
    this.dialog.close()
  }

}
