import { createReducer, on, Action } from '@ngrx/store';
import { FiltersActions } from '../actions';

export interface FiltersState {
    name: string,
    patientName: string,
    providerName: string,
    address: string,
    city: string,
    state: string,
    zip: string,
    clinicLocation: string,
    day: string,
    startTime: string,
    endTime: string,
    // Patients
    age: {
        min: string,
        max: string
    },
    clientTypes: string[],
    insuranceTypes: string[],
    intakeStages: string[],
    patientsHasSchedule: boolean,

    // Providers
    employeeStatuses: string[],
    onboardingStages: string[],
    credentialingStages: string[],
    providersHasSchedule: boolean,
    
}

const initialState: FiltersState = {
    name: '',
    patientName: '',
    providerName: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    clinicLocation: '',

    // Patients
    age: {
        min: '',
        max: ''
    },
    clientTypes: [],
    insuranceTypes: [],
    intakeStages: [],
    patientsHasSchedule: true,

    // Providers
    employeeStatuses: [],
    onboardingStages: [],
    credentialingStages: [],
    providersHasSchedule: true,
    day:'',
    startTime: '',
    endTime: ''
}

const reducer = createReducer(
    initialState,
    on(FiltersActions.updateUnassignedFilters, (filterState, {
        name,
        patientName,
        providerName,
        address,
        city,
        state,
        zip,
        clinicLocation,
        age,
        clientTypes,
        insuranceTypes,
        intakeStages,
        patientsHasSchedule,
        employeeStatuses,
        onboardingStages,
        credentialingStages,
        providersHasSchedule,
        day,
        startTime,
        endTime
    }) => ({
        // ...filterState,
            name,
            patientName,
            providerName,
            address,
            city,
            state,
            zip,
            clinicLocation,
            age,
            clientTypes,
            insuranceTypes,
            intakeStages,
            patientsHasSchedule,
            employeeStatuses,
            onboardingStages,
            credentialingStages,
            providersHasSchedule,
            day,
            startTime,
            endTime
    })),
    on(FiltersActions.updateAssignedFilters, (filterState, {
        name,
        patientName,
        providerName,
        address,
        city,
        state,
        zip,
        clinicLocation,
        age,
        clientTypes,
        insuranceTypes,
        intakeStages,
        employeeStatuses,
        onboardingStages,
        credentialingStages,
        day,
        startTime,
        endTime
    }) => ({
        // ...filterState,
            name,
            patientName,
            providerName,
            address,
            city,
            state,
            zip,
            clinicLocation,
            age,
            clientTypes,
            insuranceTypes,
            intakeStages,
            employeeStatuses,
            onboardingStages,
            credentialingStages,
            day,
            startTime,
            endTime
    })),
    on(FiltersActions.resetUnassignedFilter, (state) => ({ ...initialState })),
    on(FiltersActions.resetAssignedFilter, (state) => ({ ...initialState }))
)

export function filterReducer(state: FiltersState | undefined, action: Action) {
    return reducer(state, action);
}