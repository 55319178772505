import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { A11yModule } from '@angular/cdk/a11y';
import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';



import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { TopBarComponent } from './components/dashboard/top-bar/top-bar.component';
import { FilterBarComponent } from './components/dashboard/filter-bar/filter-bar.component';
import { TextFieldComponent } from './components/common/text-field/text-field.component';
import { DropdownComponent } from './components/common/dropdown/dropdown.component';
import { UnassignedComponent } from './components/dashboard/unassigned/unassigned.component';
import { AssignedComponent } from './components/dashboard/assigned/assigned.component';
import { MatchComponent } from './components/dashboard/match/match.component';
import { TimePickerComponent } from './components/common/time-picker/time-picker.component';
import { PatientsComponent } from './components/dashboard/unassigned/patients/patients.component';
import { ProvidersComponent } from './components/dashboard/unassigned/providers/providers.component';
import { FilterDialogComponent } from './components/dashboard/filter-dialog/filter-dialog.component';
import { PatientRecordComponent } from './components/dashboard/unassigned/patient-record/patient-record.component';
import { ProviderRecordComponent } from './components/dashboard/unassigned/provider-record/provider-record.component';
import { GoogleMapComponent } from './components/dashboard/google-map/google-map.component';
import { environment } from 'src/environments/environment';
import { MinMaxComponent } from './components/common/min-max/min-max.component';
import { FilterTreeComponent } from './components/dashboard/unassigned/filter-tree/filter-tree.component';
import { httpInterceptorProviders } from './services/http-interceptors';
import { DatePipe } from '@angular/common';


import { reducers } from './store/reducers'
import { effects } from './store/effects';
import { TruncateEllipsisPipe } from './pipes/truncateEllipsis.pipe';
import { ConfirmDialogComponent } from './components/dashboard/confirm-dialog/confirm-dialog.component';
import { MatchMapMarkerComponent } from './components/dashboard/map/match-map-marker/match-map-marker.component';
import { PatientMapMarkerComponent } from './components/dashboard/map/patient-map-marker/patient-map-marker.component';
import { ProviderMapMarkerComponent } from './components/dashboard/map/provider-map-marker/provider-map-marker.component';
import { MatchesComponent } from './components/dashboard/assigned/matches/matches.component';
import { MatchRecordComponent } from './components/dashboard/assigned/matches/match-record/match-record.component';
import { AssignedGoogleMapComponent } from './components/dashboard/map/assigned-google-map/assigned-google-map.component';
import { MatchPanelComponent } from './components/dashboard/map/match-panel/match-panel.component';
import { UnassignedGoogleMapComponent } from './components/dashboard/map/unassigned-google-map/unassigned-google-map.component';
import { UnassignedFilterBarComponent } from './components/dashboard/filter-bar/unassigned-filter-bar/unassigned-filter-bar.component';
import { AssignedFilterBarComponent } from './components/dashboard/filter-bar/assigned-filter-bar/assigned-filter-bar.component';
import { SpinnerOverlayComponent } from './components/common/spinner-overlay/spinner-overlay.component';
import { TimeSlotsPipe } from './pipes/timeSlots.pipe';
import { AddEditPatientComponent } from './components/dashboard/unassigned/add-edit-patient/add-edit-patient.component';
import { PatientSchedulerComponent } from './components/dashboard/unassigned/add-edit-patient/patient-scheduler/patient-scheduler.component';
import { AddEditProviderComponent } from './components/dashboard/unassigned/add-edit-provider/add-edit-provider.component';
import { NewClientsComponent } from './components/dashboard/new-clients/new-clients.component';
import { ClientsComponent } from './components/dashboard/new-clients/clients/clients.component';
import { FilePipe } from './pipes/file/file.pipe';
import { ClientFormComponent } from './components/client-form/client-form.component';
import { BytesPipe } from './pipes/bytes/bytes.pipe';
import { FormFieldComponent } from './components/client-form/form-field/form-field.component';
import { FormSectionComponent } from './components/client-form/form-section/form-section.component';
import { SelectComponent } from './components/common/select/select.component';
import { DropDownComponent } from './components/common/select/drop-down/drop-down.component';
import { SelectOptionComponent } from './components/common/select/select-option/select-option.component';
import { CompleteComponent } from './components/complete/complete.component';

import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDialogModule } from '@angular/material/dialog';
import { EditClientsComponent } from './components/dashboard/new-clients/clients/edit-clients/edit-clients.component';
import { HammerModule } from "@angular/platform-browser";
import { IgxTimePickerModule,IgxInputGroupModule,IgxIconModule } from 'igniteui-angular';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { TimepickerDropdownComponent } from './components/common/timepicker-dropdown/timepicker-dropdown.component';
import { ProvidersFormComponent } from './components/providers-form/providers-form.component';
import { PcompleteComponent } from './components/pcomplete/pcomplete.component';
import { ProviderdetailsComponent } from './components/dashboard/providerdetails/providerdetails.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppointmentComponent } from './components/dashboard/appointment/appointment.component';
import { UsersComponent } from './components/users/users.component';
import { AuditlogsComponent } from './components/auditlogs/auditlogs.component';
import { UsersdetailsComponent } from './components/usersdetails/usersdetails.component';
import { EditusersComponent } from './components/editusers/editusers.component';
import { BlocksComponent } from './components/blocks/blocks.component';
import { CompanyComponent } from './components/company/company.component';
import { CreatecompanysComponent } from './components/createcompanys/createcompanys.component';
import { EditcompanyComponent } from './components/editcompany/editcompany.component';
import { CliniclocationsComponent } from './components/cliniclocations/cliniclocations.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CliniclocationshowComponent } from './components/cliniclocationshow/cliniclocationshow.component';
import { EditlocationsComponent } from './components/editlocations/editlocations.component';
import { ProvidersshowComponent } from './components/providersshow/providersshow.component';
import { EditprovidersComponent } from './components/editproviders/editproviders.component';
import { ClientsshowComponent } from './components/clientsshow/clientsshow.component';
import { EditclientComponent } from './components/editclient/editclient.component';
import { ProvidersupdatelogsComponent } from './components/providersupdatelogs/providersupdatelogs.component';
import { CompanylogsComponent } from './components/companylogs/companylogs.component';
import { ClientComponent } from './components/client/client.component';
import { LocationsComponent } from './components/locations/locations.component';
import { AppoinmentbookingComponent } from './components/appoinmentbooking/appoinmentbooking.component';







@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    ResetPasswordComponent,
    TopBarComponent,
    FilterBarComponent,
    TextFieldComponent,
    DropdownComponent,
    UnassignedComponent,
    AssignedComponent,
    MatchComponent,
    TimePickerComponent,
    PatientsComponent,
    ProvidersComponent,
    FilterDialogComponent,
    PatientRecordComponent,
    ProviderRecordComponent,
    GoogleMapComponent,
    MinMaxComponent,
    FilterTreeComponent,
    TruncateEllipsisPipe,
    TimeSlotsPipe,
    BytesPipe,
    ConfirmDialogComponent,
    MatchMapMarkerComponent,
    PatientMapMarkerComponent,
    ProviderMapMarkerComponent,
    MatchesComponent,
    MatchRecordComponent,
    AssignedGoogleMapComponent,
    MatchPanelComponent,
    UnassignedGoogleMapComponent,
    UnassignedFilterBarComponent,
    AssignedFilterBarComponent,
    SpinnerOverlayComponent,
    AddEditPatientComponent,
    PatientSchedulerComponent,
    AddEditProviderComponent,
    NewClientsComponent,
    ClientsComponent,
    FilePipe,
    ClientFormComponent,
    FormSectionComponent, 
    FormFieldComponent,
    SelectComponent, 
    DropDownComponent, 
    SelectOptionComponent,
    CompleteComponent,
    EditClientsComponent,
    TimepickerDropdownComponent,
    ProvidersFormComponent,
    PcompleteComponent,
    ProviderdetailsComponent,
    AppointmentComponent,
    UsersComponent,
    AuditlogsComponent,
    UsersdetailsComponent,
    EditusersComponent,
    BlocksComponent,
    CompanyComponent,
    CreatecompanysComponent,
    EditcompanyComponent,
    CliniclocationsComponent,
    CliniclocationshowComponent,
    EditlocationsComponent,
    ProvidersshowComponent,
    EditprovidersComponent,
    ClientsshowComponent,
    EditclientComponent,
    ProvidersupdatelogsComponent,
    CompanylogsComponent,
    ClientComponent,
    LocationsComponent,
    AppoinmentbookingComponent
   

    
    
    
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    OverlayModule,
    PortalModule,
    A11yModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgxMaterialTimepickerModule,
    MatDialogModule, MatInputModule, MatButtonModule, MatCardModule, MatFormFieldModule,
    HammerModule, IgxTimePickerModule,IgxInputGroupModule,IgxIconModule,NgxDatatableModule,

    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApi
    }),
    AgmSnazzyInfoWindowModule,
    StoreModule.forRoot(
      reducers
    ),
    EffectsModule.forRoot(
      effects
    ),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
    }),
    NgbModule,
  ],
  providers: [
    httpInterceptorProviders,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.siteKey
      } as RecaptchaSettings
    },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
