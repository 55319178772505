import {
  Component,
  OnDestroy,
  OnInit
} from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { CLIENT_TYPES } from "src/app/constants/clientTypes";
import { CLINIC_LOCATIONS } from "src/app/constants/clinicLocations";
import { INSURANCE_TYPES } from "src/app/constants/insuranceTypes";
import { LIST_OF_US_STATES_ABBREVIATIONS } from "src/app/constants/usStates";
import { Provider, TimeSlot } from "src/app/models";
import { AddEditProviderFacade } from "src/app/services/facades/addEditProvider.facade";
import { ProvidersFacade } from "src/app/services/facades/providers.facade";
import loadash from "lodash";
import { ALL_TIMESLOTS } from "src/app/constants/allTimeSlots";
import { map, mergeMap, take } from "rxjs/operators";
import { PatientsFacade } from 'src/app/services/facades/patients.facade';
import lodash from 'lodash';

@Component({
  selector: "wtls-add-edit-provider",
  templateUrl: "./add-edit-provider.component.html",
  styleUrls: ["./add-edit-provider.component.scss"],
})
export class AddEditProviderComponent implements OnInit, OnDestroy {
  open$: Observable<boolean>;

  initialProvider: Provider = {
    id: 0,
    name: '',
    first_name: "",
    last_name: "",
    clinicLocation: "",
    employeeStatus: "",
    onboardingStage: "",
    credentialingStage: "",
    day:"",
    startTime: "",
    endTime: "",
    address: {
      street: "",
      city: "",
      state: "",
      zip: "",
      lat: null,
      lng: null,
    },
    availability: [],
    schedule: [],
  };

  previousProvider: Provider;

  provider: Provider = this.initialProvider;

  availability: TimeSlot[] = [];

  timeslot = ['12:30 AM','01:00 AM','01:30 AM','02:00 AM','02:30 AM','03:00 AM','03:30 AM','04:00 AM','04:30 AM','05:00 AM','05:30 AM',
  '06:00 AM','06:30 AM','07:00 AM','07:30 AM','08:00 AM','08:30 AM','09:00 AM','09:30 AM','10:00 AM','10:30 AM','11:00 AM','11:30 AM','12:00 PM','12:30 PM',
  '01:00 PM','01:30 PM','02:00 PM','02:30 PM','03:00 PM','03:30 PM','04:00 PM','04:30 PM','05:00 PM','05:30 PM','06:00 PM','06:30 PM','07:00 PM','07:30 PM',
  '08:00 PM','08:30 PM','09:00 PM','09:30 PM','10:00 PM','10:30 PM','11:00 PM','11:30 PM']

  months = ['Jan','Feb','Mar','Apr','May','June','July','Aug','Sep','Oct','Nov','Dec']
  days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat']
  selected_start_slot_mon
  selected_end_slot_mon
  selected_start_slot_tue
  selected_end_slot_tue
  selected_start_slot_wed
  selected_end_slot_wed
  selected_start_slot_thu
  selected_end_slot_thu
  selected_start_slot_fri
  selected_end_slot_fri
  selected_start_slot_sat
  selected_end_slot_sat
  selected_start_slot_sun
  selected_end_slot_sun


  loading$: Observable<boolean>;

  usStates = LIST_OF_US_STATES_ABBREVIATIONS;
  clinicLocationOptions;
  employeeStatusOptions;
  onboardingStageOptions;
  credentialingStageOptions;

  subscriptions = new Subscription();

  constructor(
    private providersFacade: ProvidersFacade,
    private patientsFacade: PatientsFacade,
    private addEditProviderFacade: AddEditProviderFacade
  ) {
    this.open$ = addEditProviderFacade.getOpen();

    addEditProviderFacade.getProvider().subscribe((provider) => {
      if (provider) {
        //this.availability = ALL_TIMESLOTS
        let allTimeSlots: TimeSlot[] = provider.availability;
        console.log('provider_details', provider)
        if (provider.schedule.length > 0) {
          allTimeSlots = provider.availability
          // allTimeSlots.map((timeSlot, index, arr) => {
          //   if (
          //     provider.schedule.some(
          //       (ts) =>
          //         ts.dayOfWeek == timeSlot.dayOfWeek &&
          //         ts.startTime.getHours() == timeSlot.startTime.getHours()
          //     )
          //   ) {
          //     allTimeSlots.splice(index, 1);
          //   }
          // });
        }

        this.availability = allTimeSlots;
        console.log('availability',this.availability)
        this.provider = loadash.cloneDeep(provider);
      }

      this.previousProvider = provider;
    });

    const sub1 = this.providersFacade
    .getAllProviders().pipe(
      mergeMap(providers => this.patientsFacade.getAllPatients().pipe(
        map(patients => {
          let employeeStatuses = [ ...new Set(providers.map(p => p.employeeStatus)) ];
          let onboardingStages = [ ...new Set(providers.map(p => p.onboardingStage)) ];
          let credentialingStages = [ ...new Set(providers.map(p => p.credentialingStage)) ];
          
          let c1: string[] = [];
          let c2: string[] = [];

          providers.forEach((provider) => {
            c1.push(provider.clinicLocation);
          });
          patients.forEach((patient) => {
            c2.push(patient.clinicLocation);
          });

          this.clinicLocationOptions = lodash.uniq([ ...c1, ...c2 ].filter(filter => !(filter == '')))
          this.employeeStatusOptions = employeeStatuses;
          this.onboardingStageOptions = onboardingStages;
          this.credentialingStageOptions = credentialingStages;
        })
      ))
    )
    .subscribe()

    this.subscriptions.add(sub1);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  changeSchedule(timeSlot: TimeSlot): void {
    console.log('change timeslot',timeSlot)
    if (
      this.provider.availability.some(
        (ts) =>
          ts.dayOfWeek == timeSlot.dayOfWeek &&
          ts.startTime.getHours() == timeSlot.startTime.getHours()
      )
    ) {
      this.provider.availability = this.provider.availability.filter(
        (ts) =>
          ts.dayOfWeek != timeSlot.dayOfWeek ||
          ts.startTime.getHours() != timeSlot.startTime.getHours()
      );
    } else {
      this.provider.availability.push(timeSlot);
    }
  }

  submitForm(): void {
    if (this.provider.id > 0) {
      // edit
      console.log('provider details', this.provider)
      console.log('mon selected_start_slot',this.selected_start_slot_mon)
      console.log('mon selected_end_slot',this.selected_end_slot_mon)
      console.log('tue selected_start_slot',this.selected_start_slot_tue)
      console.log('tue selected_end_slot',this.selected_end_slot_tue)
      console.log('wed selected_start_slot',this.selected_start_slot_wed)
      console.log('wed selected_end_slot',this.selected_end_slot_wed)
      console.log('thu selected_start_slot',this.selected_start_slot_thu)
      console.log('thu selected_end_slot',this.selected_end_slot_thu)
      console.log('fri selected_start_slot',this.selected_start_slot_fri)
      console.log('fri selected_end_slot',this.selected_end_slot_fri)
      
      let newDateString
      if(this.selected_start_slot_mon != null){
        newDateString = this.convertToDate(this.provider.availability[0].startTime,this.selected_start_slot_mon)
        this.provider.availability[0].startTime = newDateString
        if(this.selected_end_slot_mon){
          newDateString = this.convertToDate(this.provider.availability[0].endTime,this.selected_end_slot_mon)
          this.provider.availability[0].endTime = newDateString
        }
      }
      if(this.selected_start_slot_tue){
        newDateString = this.convertToDate(this.provider.availability[1].startTime,this.selected_start_slot_tue)
        this.provider.availability[1].startTime = newDateString
        if(this.selected_end_slot_tue){
          newDateString = this.convertToDate(this.provider.availability[1].endTime,this.selected_end_slot_tue)
          this.provider.availability[1].endTime = newDateString
        }
      }
      if(this.selected_start_slot_wed){
        newDateString = this.convertToDate(this.provider.availability[2].startTime,this.selected_start_slot_wed)
        this.provider.availability[2].startTime = newDateString
        if(this.selected_end_slot_wed){
          newDateString = this.convertToDate(this.provider.availability[2].endTime,this.selected_end_slot_wed)
          this.provider.availability[2].endTime = newDateString
        }
      }
      if(this.selected_start_slot_thu){
        newDateString = this.convertToDate(this.provider.availability[3].startTime,this.selected_start_slot_thu)
        this.provider.availability[3].startTime = newDateString
        if(this.selected_end_slot_thu){
          newDateString = this.convertToDate(this.provider.availability[3].endTime,this.selected_end_slot_thu)
          this.provider.availability[3].endTime = newDateString
        }
      }
      if(this.selected_start_slot_fri){
        newDateString = this.convertToDate(this.provider.availability[4].startTime,this.selected_start_slot_fri)
        this.provider.availability[4].startTime = newDateString
        if(this.selected_end_slot_fri){
          newDateString = this.convertToDate(this.provider.availability[4].endTime,this.selected_end_slot_fri)
          this.provider.availability[4].endTime = newDateString
        }
      }
      if(this.selected_start_slot_sat){
        newDateString = this.convertToDate(this.provider.availability[5].startTime,this.selected_start_slot_sat)
        this.provider.availability[5].startTime = newDateString
        if(this.selected_end_slot_sat){
          newDateString = this.convertToDate(this.provider.availability[5].endTime,this.selected_end_slot_sat)
          this.provider.availability[5].endTime = newDateString
        }
      }
      if(this.selected_start_slot_sun){
        newDateString = this.convertToDate(this.provider.availability[6].startTime,this.selected_start_slot_sun)
        this.provider.availability[6].startTime = newDateString
        if(this.selected_end_slot_sun){
          newDateString = this.convertToDate(this.provider.availability[6].endTime,this.selected_end_slot_sun)
          this.provider.availability[6].endTime = newDateString
        }
      }

      
      if (
        this.previousProvider.address.street !=
          this.provider.address.street.trim() ||
        this.previousProvider.address.city != this.provider.address.city.trim() ||
        this.previousProvider.address.state !=
          this.provider.address.state.trim() 
        //this.previousProvider.address.zip != this.provider.address.zip.trim()
      ) {
        // address changed, geocode
        this.addEditProviderFacade
          .geocodeProvider(this.provider)
          .pipe(take(1))
          .subscribe((provider) => {
            this.addEditProviderFacade.updateProvider(provider);
          });
      } else {

        this.addEditProviderFacade.updateProvider(this.provider);
      }
    } else {
      // add
      this.addEditProviderFacade
        .geocodeProvider(this.provider)
        .pipe(take(1))
        .subscribe((provider) => {
          this.addEditProviderFacade.addProvider(provider);
        });
    }
  }

  closePanel(): void {
    this.addEditProviderFacade.closePanel();
    this.addEditProviderFacade.clearProvider();
  }


  convertToDate(previousValue, newValue){

    let date
    let day
    let year
    let month
    let timeString = ''
    let splitTimeString = []
    let dateString = ''
    let checkAmPm
    timeString = previousValue.toTimeString()
    date = previousValue.getDate()
    day = previousValue.getDay()
    year = previousValue.getFullYear()
    month = previousValue.getMonth()
    console.log(timeString)
    splitTimeString = timeString.split(' ')
    checkAmPm = newValue.split(' ')[1]
    if(checkAmPm == 'AM') 
      splitTimeString[0] = newValue.split(' ')[0]+':00'
    else{ // convert to 24 hr time for PM
      let splitTime = newValue.split(' ')[0].split(':')
      let firstPart
      if(parseInt(splitTime[0]) != 12)
        firstPart = (parseInt(splitTime[0])+12).toString()
      else
        firstPart = (parseInt(splitTime[0])).toString()
      splitTimeString[0] = firstPart+':'+splitTime[1]+':00'
    }
    timeString = splitTimeString.toString().replace(/,/g,' ')
    // console.log(timeString)
    // console.log(date)
    // console.log(day)
    // console.log(year)
    // console.log(month)
    dateString +=  this.days[day] +' '+ this.months[month] +' '+date+' '+ year +' '+ timeString.replace(',',' ')
    console.log('dateString', dateString) 
    console.log('dateString', new Date(dateString))
    return new Date(dateString) 
  }
}
