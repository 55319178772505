import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input } from '@angular/core';
import { MapControl } from '../../google-map/google-map.component';
import { MapFacade } from 'src/app/services/facades/map.facade';
import { Patient, Provider } from 'src/app/models';
import { PatientsFacade } from 'src/app/services/facades/patients.facade';

@Component({
  selector: 'wtls-unassigned-google-map',
  templateUrl: './unassigned-google-map.component.html',
  styleUrls: ['./unassigned-google-map.component.scss']
})
export class UnassignedGoogleMapComponent implements OnInit, AfterViewInit {
  @ViewChild('matchControl') matchControl: ElementRef;

  @Input() patients: Patient[];
  @Input() providers: Provider[];

  markers: google.maps.Marker[];

  patient: Patient;
  provider: Provider;

  controls: MapControl[] = []

  mapFullScreen: boolean;

  constructor(
    private patientsFacade: PatientsFacade,
    private mapFacade: MapFacade
  ) { 
    mapFacade.getMapFullScreen().subscribe(
      fullScreen => {
        this.mapFullScreen = fullScreen;
      }
    )
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.controls.push({
      elementRef: this.matchControl,
      position: google.maps.ControlPosition.LEFT_BOTTOM
    })
  }
}
