import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup,Validators  } from "@angular/forms";
import { ApiService, AuthService } from 'src/app/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EndPoints } from 'src/app/constants/endpoints';

@Component({
  selector: 'wtls-createcompanys',
  templateUrl: './createcompanys.component.html',
  styleUrls: ['./createcompanys.component.scss']
})
export class CreatecompanysComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  li:any;
  lis=[];
  dataset = [];
  selectlist:any;
  selectlistorders=[];
  locationlist:any;
  locationsselectlistorders=[];
  role = ''
  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private authService: AuthService,
    private snackbar: MatSnackBar,
  ) { 
    this.form = this.formBuilder.group({
      comapny_name: ['',Validators.required ],
      company_email: ['',[Validators.required,Validators.email]],
      company_address: ['',Validators.required],
      companay_opening_time:['',Validators.required],
      clinic_location_id:['',Validators.required],
      form_sub_title:['',Validators.required],
      company_contactno:['',Validators.required],
      company_url:['',Validators.required],
      user_id:['',Validators.required],
      company_closing_time:['',Validators.required],
      form_title:['',Validators.required]

    })
  }
  get f() { return this.form.controls; }
  
  ngOnInit(): void {
    this.role = this.authService.getRoleToken()
    if(this.role != '1') this.router.navigate(['/dashboard/company'])
    this.apiService.get('/createusers/')
    .subscribe(Response => {
 
      // If response comes hideloader() function is called
      // to hide that loader,
      if(Response){ 
        hideloader();
      }
      console.log("datatest=>",Response)
      this.li=Response;
      this.lis=this.li;
     this.dataset = this.lis;
    
      console.log("datatest1=>",this.lis)
      return this.dataset;
      
    });
    function hideloader(){
      document.getElementById('loading').style.display = 'none';}

////get users
this.apiService.get('/createusers/')
.subscribe(Response => {
  if(Response){ 
  }
  console.log("asd=>",Response)
  this.selectlist=Response;
  this.selectlistorders=this.selectlist;
  console.log("asd=>",this.selectlistorders)
});
/// get users

////get locations
this.apiService.get('/cliniclocations/')
.subscribe(Response => {
  if(Response){ 
  }
  console.log("datatest=>",Response)
  this.locationlist=Response;
  this.locationsselectlistorders=this.locationlist;
  console.log("datatest1=>",this.locationsselectlistorders)
});
/// get users

  }
  showMsg: boolean = false;
  submitForm() {
   
    this.submitted = true;

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        else{
          let Formpostdata = this.form.value;
          console.log("testadmin",this.form.value)
          this.apiService.post('/createcompany/', Formpostdata).subscribe(
            (response) =>{
              this.snackbar.open('Company Created Successfull!', 'Dismiss', {
              verticalPosition: 'bottom',
              horizontalPosition: 'left',
            }); 
            window.location.href = EndPoints.BASE_URL+'dashboard/company';
            },
            //(response) => console.log(this.router.navigate(['dashboard/createcompanys']),this.showMsg= true),
            
            (error) => console.log(error)
          ); 
        }
        
        
  }
  cancelForm(): void {
    this.router.navigate(['/dashboard/company'])
  }

}
