
<div class="page-container">
  <div class="content-container" >
    

    <div class="container">
      <form  [formGroup]="form" (ngSubmit)="submitForm()"
      > 
      <div class="header-section">
        <h2 class="title">Providers Form</h2>
      </div>
      <div *ngFor="let e of lis;" >
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                    <label for="name" > First Name* </label>
                    <input type="text" formControlName="first_name" [(ngModel)]="e.first_name" name="first_name" placeholder="First Name" [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }"  class="form-control"   style="background-color: #f2f2f2">
                    <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                      <div *ngIf="f.first_name.errors.required">First Name is required</div>
                  </div>
              </div></div>
              
              <div class="col-md-6">
                  <div class="form-group">
                        <label for="name" >Last Name* </label>
                        <input type="text" formControlName="last_name" [(ngModel)]="e.last_name" name="last_name" placeholder="Last Name" [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }"  class="form-control"   style="background-color: #f2f2f2">
                        <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                          <div *ngIf="f.last_name.errors.required">Last Name is required</div>
                      </div>
                  </div></div>

           
           <!--  </div> -->
           

      <div class="col-md-6">
          <div class="form-group">
                <label for="name" >Phone Number*</label>
                <input type="number" formControlName="phone" [(ngModel)]="e.phone" placeholder="Cell Phone Number" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"  class="form-control"  style="background-color: #f2f2f2">
                
                <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                  <div *ngIf="f.phone.errors.required">Phone Number is required</div>
              </div>
          </div></div>
          
              
                  <div class="col-md-6">
                      <div class="form-group">
                            <label for="name" >Email*</label>
                            <input type="text" formControlName="email" [(ngModel)]="e.email" placeholder="Email"  [ngClass]="{ 'is-invalid': submitted && f.email.errors }" class="form-control"  style="background-color: #f2f2f2">
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                              <div *ngIf="f.email.errors.required">Email is required</div>
                          </div>
                      </div></div>

                              
        <div class="col-md-6">
            <div class="form-group">
                  <label for="name" >Address line 1</label>
                  <input type="text" formControlName="addressline1" [(ngModel)]="e.address.addressline1" placeholder="address Line 1" [ngClass]="{ 'is-invalid': submitted && f.addressline1.errors }"  class="form-control" style="background-color: #f2f2f2" >
                  <div *ngIf="submitted && f.addressline1.errors" class="invalid-feedback">
                    <div *ngIf="f.addressline1.errors.required">Address is required</div>
                </div>
            </div></div>
            <div class="col-md-6">
                <div class="form-group">
                      <label for="name" >Address line 2</label>
                      <input type="text" formControlName="addressline2" [(ngModel)]="e.address.addressline2" placeholder="address Line 2"    class="form-control" style="background-color: #f2f2f2" >
                      
                </div></div>

                <div class="col-md-6">
                    <div class="form-group" >
                          <label for="name" >City</label>
                          <!--  <input type="text" formControlName="name" placeholder="Client Type"  class="form-control"  style="background-color: #f2f2f2"> -->
                          <input type="text" formControlName="city"  [(ngModel)]="e.address.city" placeholder="city"  [ngClass]="{ 'is-invalid': submitted && f.city.errors }"  class="form-control" style="background-color: #f2f2f2" >
                          <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                            <div *ngIf="f.city.errors.required">City is required</div>
                        </div>
                    </div></div>
              <div class="col-md-6">
                  <div class="form-group">
                        <label for="name" >State</label>
                        <!--  <input type="text" formControlName="name"  placeholder="Insurance Type"  class="form-control" style="background-color: #f2f2f2" > -->
                        <select name="cars" formControlName="state_abbr" [(ngModel)]="e.address.state_abbr"  class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.state_abbr.errors }" id="patient_id" style="background-color: #f2f2f2">
                          <option value="AL">AL</option>
                          <option value="AK">AK</option>
                          <option value="AZ">AZ</option>
                          <option value="AR">AR</option>
                          <option value="CA">CA</option>
                          <option value="CO">CO</option>
                          <option value="CT">CT</option>
                          <option value="DE">DE</option>
                          <option value="DC">DC</option>
                          <option value="FL">FL</option>
                          <option value="GA">GA</option>
                          <option value="HI">HI</option>
                          <option value="ID">ID</option>
                          <option value="IL">IL</option>
                          <option value="IN">IN</option>
                          <option value="IA">IA</option>
                          <option value="KS">KS</option>
                          <option value="KY">KY</option>
                          <option value="LA">LA</option>
                          <option value="ME">ME</option>
                          <option value="MD">MD</option>
                          <option value="MA">MA</option>
                          <option value="MI">MI</option>
                          <option value="MN">MN</option>
                          <option value="MS">MS</option>
                          <option value="MO">MO</option>
                          <option value="MT">MT</option>
                          <option value="NE">NE</option>
                          <option value="NV">NV</option>
                          <option value="NH">NH</option>
                          <option value="NJ">NJ</option>
                          <option value="NM">NM</option>
                          <option value="NY">NY</option>
                          <option value="NC">NC</option>
                          <option value="ND">ND</option>
                          <option value="OH">OH</option>
                          <option value="OK">OK</option>
                          <option value="OR">OR</option>
                          <option value="PA">PA</option>
                          <option value="RI">RI</option>
                          <option value="SC">SC</option>
                          <option value="SD">SD</option>
                          <option value="TN">TN</option>
                          <option value="TX">TX</option>
                          <option value="UT">UT</option>
                          <option value="VT">VT</option>
                          <option value="VA">VA</option>
                          <option value="WA">WA</option>
                          <option value="WV">WV</option>
                          <option value="WI">WI</option>
                          <option value="WY">WY</option>
                        
                      </select>
                      <div *ngIf="submitted && f.state_abbr.errors" class="invalid-feedback">
                        <div *ngIf="f.state_abbr.errors.required">State is required</div>
                    </div>
                  </div></div>

                                             
                                                         
                                                                

          <div class="col-md-6">
            <div class="form-group">
                  <label for="name" >Country*</label>
                  <input type="text" formControlName="country" [(ngModel)]="e.address.country"  placeholder="Country" [ngClass]="{ 'is-invalid': submitted && f.country.errors }"  class="form-control"  style="background-color: #f2f2f2">
                  <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                    <div *ngIf="f.country.errors.required">Country is required</div>
                </div>
            </div></div>

            <div class="col-md-6">
                <div class="form-group">
                      <label for="name" >Zipcode*</label>
                      <input type="text" formControlName="zipcode" [(ngModel)]="e.address.zipcode"  placeholder="Zipcode" [ngClass]="{ 'is-invalid': submitted && f.zipcode.errors }"  class="form-control"  style="background-color: #f2f2f2">
                    
                      <div *ngIf="submitted && f.zipcode.errors" class="invalid-feedback">
                        <div *ngIf="f.zipcode.errors.required">Zipcode is required</div>
                    </div>
                </div></div>


            <div class="col-md-6">
                <div class="form-group">
                      <label for="name" >Clinic Location*</label>
                    
                      <select  class="form-control" formControlName="clinic_name" [ngClass]="{ 'is-invalid': submitted && f.clinic_name.errors }" name="clinic_name"  style="background-color: #f2f2f2" >
                        <option *ngFor="let a of lis;" value="a.clinicLocation" selected="selected" >{{a.clinicLocation}}</option>
                      <option value="Orlando ABA">Orlando ABA</option>
                        
                      </select>
                      <div *ngIf="submitted && f.clinic_name.errors" class="invalid-feedback">
                        <div *ngIf="f.clinic_name.errors.required">Clinic Location is required</div>
                    </div>
                </div></div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="name"> Employee Status </label>
                  <select class="select-input form-control" formControlName="status" [ngClass]="{ 'is-invalid': submitted && f.status.errors }" style="background-color: #f2f2f2">
                    <option *ngFor="let a of lis;" value="a.status" selected="selected" >{{a.status}}</option>
                  <option value="Full-Time Hourly" >Full-Time Hourly</option>
                  <option value="Part-Time Hourly" >Part-Time Hourly</option>
                  </select>
                  <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                    <div *ngIf="f.status.errors.required">Employee Status is required</div>
                </div>
                </div></div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name"> Onboarding Stage </label>
                    <select class="select-input form-control" formControlName="stage_name"  [ngClass]="{ 'is-invalid': submitted && f.stage_name.errors }" style="background-color: #f2f2f2">
                      <option *ngFor="let a of lis;" value="a.stage_name" selected="selected" >{{a.stage_name}}</option>
                    <option value="In Credentialing Stage" selected="">In Credentialing Stage</option>
                    <option value="New Hire" selected="selected">New Hire</option>
                    <option value="Onboarding Complete/Ongoing Employee">Onboarding Complete/Ongoing Employee</option>
                  </select>
                  <div *ngIf="submitted && f.stage_name.errors" class="invalid-feedback">
                    <div *ngIf="f.stage_name.errors.required">Onboarding Stage is required</div>
                </div>
                  </div></div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name"> Credentialing Stage </label>
                    <select class="select-input form-control" formControlName="stage_name1"  [ngClass]="{ 'is-invalid': submitted && f.stage_name1.errors }" style="background-color: #f2f2f2">
                      <option *ngFor="let a of lis;" value="a.stage_name1" selected="selected" >{{a.stage_name1}}</option>
                    <option value="CAQH Pending" selected="">CAQH Pending</option>
                    <option value="Credentialing Paperwork Pending" selected="selected">Credentialing Paperwork Pending</option>
                    <option value="Credentialing Complete">Credentialing Complete</option>
                    </select>
                    <div *ngIf="submitted && f.stage_name1.errors" class="invalid-feedback">
                      <div *ngIf="f.stage_name1.errors.required">Credentialing Stage is required</div>
                  </div>
                  </div></div>


            
              <!--Monday is open	11:30 AM	to	05:00 PM--->
              
              <div class="col-md-4">
                <div class="form-group" >
                  Day

                 
                   <input type="text"   class="form-control" value="Monday"  style="background-color: #f2f2f2">
                  </div>  
                  </div>  
                 
                  <div class="col-md-4">
                    <div class="form-group" >
                      Start
                      <select class="select-input form-control" formControlName="mon_startTime"  style="background-color: #f2f2f2">
                        <option *ngFor="let a of lis" value="a.availability.mon_startTime" selected>{{a.availability.mon_startTime}}</option>
                        <option value="00:30:00" selected="selected">12.30 AM</option>
                        <option value="01:00:00" selected="selected">1.00 AM</option>
                        <option value="01:30:00" selected="selected">1.30 AM</option>
                        <option value="02:00:00" selected="selected">2.00 AM</option>
                        <option value="02:30:00" selected="selected">2.30 AM</option>
                        <option value="03:00:00" selected="selected">3.00 AM</option>
                        <option value="03:30:00" selected="selected">3.30 AM</option>
                        <option value="04:00:00" selected="selected">4.00 AM</option>
                        <option value="04:30:00" selected="selected">4.30 AM</option>
                        <option value="05:00:00" selected="selected">5.00 AM</option>
                        <option value="05:30:00" selected="selected">5.30 AM</option>
                        <option value="06:00:00" selected="selected">6.00 AM</option>
                        <option value="06:30:00" selected="selected">6.30 AM</option>
                        <option value="07:00:00" selected="selected">7.00 AM</option>
                        <option value="07:30:00" selected="selected">7.30 AM</option>
                        <option value="08:00:00" selected="selected">8.00 AM</option>
                        <option value="08:30:00" selected="selected">8.30 AM</option>
                        <option value="09:00:00" selected="selected">9.00 AM</option>
                        <option value="09.30:00" selected="selected">9.30 AM</option>
                        <option value="10:00:00" selected="selected">10.00 AM</option>
                        <option value="10:30:00" selected="selected">10.30 AM</option>
                        <option value="11:00:00" selected="selected">11.00 AM</option>
                        <option value="11:30:00" selected="selected">11.30 AM</option>
                        <option value="12:00:00" selected="selected">12.00 PM</option>
                        <option value="12:30:00" selected="selected">12.30 PM</option>
                        <option value="13:00:00" selected="selected">1.00 PM</option>
                        <option value="13:30:00" selected="selected">1.30 PM</option>
                        <option value="14:00:00" selected="selected">2.00 PM</option>
                        <option value="14:30:00" selected="selected">2.30 PM</option>
                        <option value="15:00:00" selected="selected">3.00 PM</option>
                        <option value="15:30:00" selected="selected">3.30 PM</option>
                        <option value="16:00:00" selected="selected">4.00 PM</option>
                        <option value="16:30:00" selected="selected">4.30 PM</option>
                        <option value="17:00:00" selected="selected">5.00 PM</option>
                        <option value="17:30:00" selected="selected">5.30 PM</option>
                        <option value="18:00:00" selected="selected">6.00 PM</option>
                        <option value="18:30:00" selected="selected">6.30 PM</option>
                        <option value="19:00:00" selected="selected">7.00 PM</option>
                        <option value="19:30:00" selected="selected">7.30 PM</option>
                        <option value="20:00:00" selected="selected">8.00 PM</option>
                        <option value="20:30:00" selected="selected">8.30 PM</option>
                        <option value="21:00:00" selected="selected">9.00 PM</option>
                        <option value="21:30:00" selected="selected">9.30 PM</option>
                        <option value="22:00:00" selected="selected">10.00 PM</option>
                        <option value="22:30:00" selected="selected">10.30 PM</option>
                        <option value="23:00:00" selected="selected">11.00 PM</option>
                        <option value="23:30:00" selected="selected">11.30 PM</option>
                        <option value="00:00:00" selected="selected">12.00 AM</option>
                       </select>
                     
                      </div>  
                      </div>  

                      <div class="col-md-4">
                        <div class="form-group" >
                          End
                          <select class="select-input form-control" formControlName="mon_endTime" style="background-color: #f2f2f2">
                            <option *ngFor="let a of lis" value="a.availability.mon_endTime" selected>{{a.availability.mon_endTime}}</option>
                            <option value="00:30:00" selected="selected">12.30 AM</option>
                            <option value="01:00:00" selected="selected">1.00 AM</option>
                            <option value="01:30:00" selected="selected">1.30 AM</option>
                            <option value="02:00:00" selected="selected">2.00 AM</option>
                            <option value="02:30:00" selected="selected">2.30 AM</option>
                            <option value="03:00:00" selected="selected">3.00 AM</option>
                            <option value="03:30:00" selected="selected">3.30 AM</option>
                            <option value="04:00:00" selected="selected">4.00 AM</option>
                            <option value="04:30:00" selected="selected">4.30 AM</option>
                            <option value="05:00:00" selected="selected">5.00 AM</option>
                            <option value="05:30:00" selected="selected">5.30 AM</option>
                            <option value="06:00:00" selected="selected">6.00 AM</option>
                            <option value="06:30:00" selected="selected">6.30 AM</option>
                            <option value="07:00:00" selected="selected">7.00 AM</option>
                            <option value="07:30:00" selected="selected">7.30 AM</option>
                            <option value="08:00:00" selected="selected">8.00 AM</option>
                            <option value="08:30:00" selected="selected">8.30 AM</option>
                            <option value="09:00:00" selected="selected">9.00 AM</option>
                            <option value="09.30:00" selected="selected">9.30 AM</option>
                            <option value="10:00:00" selected="selected">10.00 AM</option>
                            <option value="10:30:00" selected="selected">10.30 AM</option>
                            <option value="11:00:00" selected="selected">11.00 AM</option>
                            <option value="11:30:00" selected="selected">11.30 AM</option>
                            <option value="12:00:00" selected="selected">12.00 PM</option>
                            <option value="12:30:00" selected="selected">12.30 PM</option>
                            <option value="13:00:00" selected="selected">1.00 PM</option>
                            <option value="13:30:00" selected="selected">1.30 PM</option>
                            <option value="14:00:00" selected="selected">2.00 PM</option>
                            <option value="14:30:00" selected="selected">2.30 PM</option>
                            <option value="15:00:00" selected="selected">3.00 PM</option>
                            <option value="15:30:00" selected="selected">3.30 PM</option>
                            <option value="16:00:00" selected="selected">4.00 PM</option>
                            <option value="16:30:00" selected="selected">4.30 PM</option>
                            <option value="17:00:00" selected="selected">5.00 PM</option>
                            <option value="17:30:00" selected="selected">5.30 PM</option>
                            <option value="18:00:00" selected="selected">6.00 PM</option>
                            <option value="18:30:00" selected="selected">6.30 PM</option>
                            <option value="19:00:00" selected="selected">7.00 PM</option>
                            <option value="19:30:00" selected="selected">7.30 PM</option>
                            <option value="20:00:00" selected="selected">8.00 PM</option>
                            <option value="20:30:00" selected="selected">8.30 PM</option>
                            <option value="21:00:00" selected="selected">9.00 PM</option>
                            <option value="21:30:00" selected="selected">9.30 PM</option>
                            <option value="22:00:00" selected="selected">10.00 PM</option>
                            <option value="22:30:00" selected="selected">10.30 PM</option>
                            <option value="23:00:00" selected="selected">11.00 PM</option>
                            <option value="23:30:00" selected="selected">11.30 PM</option>
                            <option value="00:00:00" selected="selected">12.00 AM</option>
                           </select>
                          
                          </div>  
                          </div>  


                          <!----MONDAY END-->

                     
   <!--tueday is open	11:30 AM	to	05:00 PM--->
              
   <div class="col-md-4">
    <div class="form-group">
     <!-- <b>DAY</b>-->
     
       <input type="text"    class="form-control" value="Tuesday" style="background-color: #f2f2f2">
      </div>  
      </div>  
     
      <div class="col-md-4">
        <div class="form-group">
         <!--<b>START</b>--> 
          <select class="select-input form-control" formControlName="tue_startTime" name="tue_startTime" style="background-color: #f2f2f2">
            <option *ngFor="let a of lis" value="a.availability.tue_startTime" selected>{{a.availability.tue_startTime}}</option>
            <option value="00:30:00" selected="selected">12.30 AM</option>
                            <option value="01:00:00" selected="selected">1.00 AM</option>
                            <option value="01:30:00" selected="selected">1.30 AM</option>
                            <option value="02:00:00" selected="selected">2.00 AM</option>
                            <option value="02:30:00" selected="selected">2.30 AM</option>
                            <option value="03:00:00" selected="selected">3.00 AM</option>
                            <option value="03:30:00" selected="selected">3.30 AM</option>
                            <option value="04:00:00" selected="selected">4.00 AM</option>
                            <option value="04:30:00" selected="selected">4.30 AM</option>
                            <option value="05:00:00" selected="selected">5.00 AM</option>
                            <option value="05:30:00" selected="selected">5.30 AM</option>
                            <option value="06:00:00" selected="selected">6.00 AM</option>
                            <option value="06:30:00" selected="selected">6.30 AM</option>
                            <option value="07:00:00" selected="selected">7.00 AM</option>
                            <option value="07:30:00" selected="selected">7.30 AM</option>
                            <option value="08:00:00" selected="selected">8.00 AM</option>
                            <option value="08:30:00" selected="selected">8.30 AM</option>
                            <option value="09:00:00" selected="selected">9.00 AM</option>
                            <option value="09.30:00" selected="selected">9.30 AM</option>
                            <option value="10:00:00" selected="selected">10.00 AM</option>
                            <option value="10:30:00" selected="selected">10.30 AM</option>
                            <option value="11:00:00" selected="selected">11.00 AM</option>
                            <option value="11:30:00" selected="selected">11.30 AM</option>
                            <option value="12:00:00" selected="selected">12.00 PM</option>
                            <option value="12:30:00" selected="selected">12.30 PM</option>
                            <option value="13:00:00" selected="selected">1.00 PM</option>
                            <option value="13:30:00" selected="selected">1.30 PM</option>
                            <option value="14:00:00" selected="selected">2.00 PM</option>
                            <option value="14:30:00" selected="selected">2.30 PM</option>
                            <option value="15:00:00" selected="selected">3.00 PM</option>
                            <option value="15:30:00" selected="selected">3.30 PM</option>
                            <option value="16:00:00" selected="selected">4.00 PM</option>
                            <option value="16:30:00" selected="selected">4.30 PM</option>
                            <option value="17:00:00" selected="selected">5.00 PM</option>
                            <option value="17:30:00" selected="selected">5.30 PM</option>
                            <option value="18:00:00" selected="selected">6.00 PM</option>
                            <option value="18:30:00" selected="selected">6.30 PM</option>
                            <option value="19:00:00" selected="selected">7.00 PM</option>
                            <option value="19:30:00" selected="selected">7.30 PM</option>
                            <option value="20:00:00" selected="selected">8.00 PM</option>
                            <option value="20:30:00" selected="selected">8.30 PM</option>
                            <option value="21:00:00" selected="selected">9.00 PM</option>
                            <option value="21:30:00" selected="selected">9.30 PM</option>
                            <option value="22:00:00" selected="selected">10.00 PM</option>
                            <option value="22:30:00" selected="selected">10.30 PM</option>
                            <option value="23:00:00" selected="selected">11.00 PM</option>
                            <option value="23:30:00" selected="selected">11.30 PM</option>
                            <option value="00:00:00" selected="selected">12.00 AM</option>
           </select>
          
          </div>  
          </div>  

          <div class="col-md-4">
            <div class="form-group">
              <!--<b>END</b>-->
              <select class="select-input form-control" formControlName="tue_endTime" name="tue_endTime" style="background-color: #f2f2f2">
                <option *ngFor="let a of lis" value="a.availability.tue_endTime" selected>{{a.availability.tue_endTime}}</option>
                <option value="00:30:00" selected="selected">12.30 AM</option>
                            <option value="01:00:00" selected="selected">1.00 AM</option>
                            <option value="01:30:00" selected="selected">1.30 AM</option>
                            <option value="02:00:00" selected="selected">2.00 AM</option>
                            <option value="02:30:00" selected="selected">2.30 AM</option>
                            <option value="03:00:00" selected="selected">3.00 AM</option>
                            <option value="03:30:00" selected="selected">3.30 AM</option>
                            <option value="04:00:00" selected="selected">4.00 AM</option>
                            <option value="04:30:00" selected="selected">4.30 AM</option>
                            <option value="05:00:00" selected="selected">5.00 AM</option>
                            <option value="05:30:00" selected="selected">5.30 AM</option>
                            <option value="06:00:00" selected="selected">6.00 AM</option>
                            <option value="06:30:00" selected="selected">6.30 AM</option>
                            <option value="07:00:00" selected="selected">7.00 AM</option>
                            <option value="07:30:00" selected="selected">7.30 AM</option>
                            <option value="08:00:00" selected="selected">8.00 AM</option>
                            <option value="08:30:00" selected="selected">8.30 AM</option>
                            <option value="09:00:00" selected="selected">9.00 AM</option>
                            <option value="09.30:00" selected="selected">9.30 AM</option>
                            <option value="10:00:00" selected="selected">10.00 AM</option>
                            <option value="10:30:00" selected="selected">10.30 AM</option>
                            <option value="11:00:00" selected="selected">11.00 AM</option>
                            <option value="11:30:00" selected="selected">11.30 AM</option>
                            <option value="12:00:00" selected="selected">12.00 PM</option>
                            <option value="12:30:00" selected="selected">12.30 PM</option>
                            <option value="13:00:00" selected="selected">1.00 PM</option>
                            <option value="13:30:00" selected="selected">1.30 PM</option>
                            <option value="14:00:00" selected="selected">2.00 PM</option>
                            <option value="14:30:00" selected="selected">2.30 PM</option>
                            <option value="15:00:00" selected="selected">3.00 PM</option>
                            <option value="15:30:00" selected="selected">3.30 PM</option>
                            <option value="16:00:00" selected="selected">4.00 PM</option>
                            <option value="16:30:00" selected="selected">4.30 PM</option>
                            <option value="17:00:00" selected="selected">5.00 PM</option>
                            <option value="17:30:00" selected="selected">5.30 PM</option>
                            <option value="18:00:00" selected="selected">6.00 PM</option>
                            <option value="18:30:00" selected="selected">6.30 PM</option>
                            <option value="19:00:00" selected="selected">7.00 PM</option>
                            <option value="19:30:00" selected="selected">7.30 PM</option>
                            <option value="20:00:00" selected="selected">8.00 PM</option>
                            <option value="20:30:00" selected="selected">8.30 PM</option>
                            <option value="21:00:00" selected="selected">9.00 PM</option>
                            <option value="21:30:00" selected="selected">9.30 PM</option>
                            <option value="22:00:00" selected="selected">10.00 PM</option>
                            <option value="22:30:00" selected="selected">10.30 PM</option>
                            <option value="23:00:00" selected="selected">11.00 PM</option>
                            <option value="23:30:00" selected="selected">11.30 PM</option>
                            <option value="00:00:00" selected="selected">12.00 AM</option>
               </select>
             
              </div>  
              </div>  
              <!----tueday END-->
            
                  <!--Wednesday is open	11:30 AM	to	05:00 PM--->
              
   <div class="col-md-4">
    <div class="form-group">
     <!--<b>DAY</b>---> 
     
       <input type="text"    class="form-control" value="Wednesday" style="background-color: #f2f2f2">
      </div>  
      </div>  
    
      <div class="col-md-4">
        <div class="form-group">
         <!--<b>START</b>---> 
          <select class="select-input form-control" formControlName="wed_startTime" name="wed_startTime" style="background-color: #f2f2f2">
            <option *ngFor="let a of lis" value="a.availability.wed_startTime" selected>{{a.availability.wed_startTime}}</option>
            <option value="00:30:00" selected="selected">12.30 AM</option>
            <option value="01:00:00" selected="selected">1.00 AM</option>
            <option value="01:30:00" selected="selected">1.30 AM</option>
            <option value="02:00:00" selected="selected">2.00 AM</option>
            <option value="02:30:00" selected="selected">2.30 AM</option>
            <option value="03:00:00" selected="selected">3.00 AM</option>
            <option value="03:30:00" selected="selected">3.30 AM</option>
            <option value="04:00:00" selected="selected">4.00 AM</option>
            <option value="04:30:00" selected="selected">4.30 AM</option>
            <option value="05:00:00" selected="selected">5.00 AM</option>
            <option value="05:30:00" selected="selected">5.30 AM</option>
            <option value="06:00:00" selected="selected">6.00 AM</option>
            <option value="06:30:00" selected="selected">6.30 AM</option>
            <option value="07:00:00" selected="selected">7.00 AM</option>
            <option value="07:30:00" selected="selected">7.30 AM</option>
            <option value="08:00:00" selected="selected">8.00 AM</option>
            <option value="08:30:00" selected="selected">8.30 AM</option>
            <option value="09:00:00" selected="selected">9.00 AM</option>
            <option value="09.30:00" selected="selected">9.30 AM</option>
            <option value="10:00:00" selected="selected">10.00 AM</option>
            <option value="10:30:00" selected="selected">10.30 AM</option>
            <option value="11:00:00" selected="selected">11.00 AM</option>
            <option value="11:30:00" selected="selected">11.30 AM</option>
            <option value="12:00:00" selected="selected">12.00 PM</option>
            <option value="12:30:00" selected="selected">12.30 PM</option>
            <option value="13:00:00" selected="selected">1.00 PM</option>
            <option value="13:30:00" selected="selected">1.30 PM</option>
            <option value="14:00:00" selected="selected">2.00 PM</option>
            <option value="14:30:00" selected="selected">2.30 PM</option>
            <option value="15:00:00" selected="selected">3.00 PM</option>
            <option value="15:30:00" selected="selected">3.30 PM</option>
            <option value="16:00:00" selected="selected">4.00 PM</option>
            <option value="16:30:00" selected="selected">4.30 PM</option>
            <option value="17:00:00" selected="selected">5.00 PM</option>
            <option value="17:30:00" selected="selected">5.30 PM</option>
            <option value="18:00:00" selected="selected">6.00 PM</option>
            <option value="18:30:00" selected="selected">6.30 PM</option>
            <option value="19:00:00" selected="selected">7.00 PM</option>
            <option value="19:30:00" selected="selected">7.30 PM</option>
            <option value="20:00:00" selected="selected">8.00 PM</option>
            <option value="20:30:00" selected="selected">8.30 PM</option>
            <option value="21:00:00" selected="selected">9.00 PM</option>
            <option value="21:30:00" selected="selected">9.30 PM</option>
            <option value="22:00:00" selected="selected">10.00 PM</option>
            <option value="22:30:00" selected="selected">10.30 PM</option>
            <option value="23:00:00" selected="selected">11.00 PM</option>
            <option value="23:30:00" selected="selected">11.30 PM</option>
            <option value="00:00:00" selected="selected">12.00 AM</option>
           </select>
         
          </div>  
          </div>  

          <div class="col-md-4">
            <div class="form-group">
             <!---<b>END</b>---> 
              <select class="select-input form-control" formControlName="wed_endTime" name="wed_endTime" style="background-color: #f2f2f2">
                <option *ngFor="let a of lis" value="a.availability.wed_endTime" selected>{{a.availability.wed_endTime}}</option>
                <option value="00:30:00" selected="selected">12.30 AM</option>
                            <option value="01:00:00" selected="selected">1.00 AM</option>
                            <option value="01:30:00" selected="selected">1.30 AM</option>
                            <option value="02:00:00" selected="selected">2.00 AM</option>
                            <option value="02:30:00" selected="selected">2.30 AM</option>
                            <option value="03:00:00" selected="selected">3.00 AM</option>
                            <option value="03:30:00" selected="selected">3.30 AM</option>
                            <option value="04:00:00" selected="selected">4.00 AM</option>
                            <option value="04:30:00" selected="selected">4.30 AM</option>
                            <option value="05:00:00" selected="selected">5.00 AM</option>
                            <option value="05:30:00" selected="selected">5.30 AM</option>
                            <option value="06:00:00" selected="selected">6.00 AM</option>
                            <option value="06:30:00" selected="selected">6.30 AM</option>
                            <option value="07:00:00" selected="selected">7.00 AM</option>
                            <option value="07:30:00" selected="selected">7.30 AM</option>
                            <option value="08:00:00" selected="selected">8.00 AM</option>
                            <option value="08:30:00" selected="selected">8.30 AM</option>
                            <option value="09:00:00" selected="selected">9.00 AM</option>
                            <option value="09.30:00" selected="selected">9.30 AM</option>
                            <option value="10:00:00" selected="selected">10.00 AM</option>
                            <option value="10:30:00" selected="selected">10.30 AM</option>
                            <option value="11:00:00" selected="selected">11.00 AM</option>
                            <option value="11:30:00" selected="selected">11.30 AM</option>
                            <option value="12:00:00" selected="selected">12.00 PM</option>
                            <option value="12:30:00" selected="selected">12.30 PM</option>
                            <option value="13:00:00" selected="selected">1.00 PM</option>
                            <option value="13:30:00" selected="selected">1.30 PM</option>
                            <option value="14:00:00" selected="selected">2.00 PM</option>
                            <option value="14:30:00" selected="selected">2.30 PM</option>
                            <option value="15:00:00" selected="selected">3.00 PM</option>
                            <option value="15:30:00" selected="selected">3.30 PM</option>
                            <option value="16:00:00" selected="selected">4.00 PM</option>
                            <option value="16:30:00" selected="selected">4.30 PM</option>
                            <option value="17:00:00" selected="selected">5.00 PM</option>
                            <option value="17:30:00" selected="selected">5.30 PM</option>
                            <option value="18:00:00" selected="selected">6.00 PM</option>
                            <option value="18:30:00" selected="selected">6.30 PM</option>
                            <option value="19:00:00" selected="selected">7.00 PM</option>
                            <option value="19:30:00" selected="selected">7.30 PM</option>
                            <option value="20:00:00" selected="selected">8.00 PM</option>
                            <option value="20:30:00" selected="selected">8.30 PM</option>
                            <option value="21:00:00" selected="selected">9.00 PM</option>
                            <option value="21:30:00" selected="selected">9.30 PM</option>
                            <option value="22:00:00" selected="selected">10.00 PM</option>
                            <option value="22:30:00" selected="selected">10.30 PM</option>
                            <option value="23:00:00" selected="selected">11.00 PM</option>
                            <option value="23:30:00" selected="selected">11.30 PM</option>
                            <option value="00:00:00" selected="selected">12.00 AM</option>
               </select>
              </div>  
              </div>  
              
              <!----tueday END-->
            <!--Thursday is open	11:30 AM	to	05:00 PM--->
              
   <div class="col-md-4">
    <div class="form-group">
      <!--<b>DAY</b>-->
     
       <input type="text"    class="form-control" value="Thursday" style="background-color: #f2f2f2">
      </div>  
      </div>  
  
      <div class="col-md-4">
        <div class="form-group">
          <!--<b>START</b>--->
          <select class="select-input form-control" formControlName="thu_startTime" name="thu_startTime" style="background-color: #f2f2f2">
            <option *ngFor="let a of lis" value="a.availability.thu_startTime" selected>{{a.availability.thu_startTime}}</option>
            <option value="00:30:00" selected="selected">12.30 AM</option>
                            <option value="01:00:00" selected="selected">1.00 AM</option>
                            <option value="01:30:00" selected="selected">1.30 AM</option>
                            <option value="02:00:00" selected="selected">2.00 AM</option>
                            <option value="02:30:00" selected="selected">2.30 AM</option>
                            <option value="03:00:00" selected="selected">3.00 AM</option>
                            <option value="03:30:00" selected="selected">3.30 AM</option>
                            <option value="04:00:00" selected="selected">4.00 AM</option>
                            <option value="04:30:00" selected="selected">4.30 AM</option>
                            <option value="05:00:00" selected="selected">5.00 AM</option>
                            <option value="05:30:00" selected="selected">5.30 AM</option>
                            <option value="06:00:00" selected="selected">6.00 AM</option>
                            <option value="06:30:00" selected="selected">6.30 AM</option>
                            <option value="07:00:00" selected="selected">7.00 AM</option>
                            <option value="07:30:00" selected="selected">7.30 AM</option>
                            <option value="08:00:00" selected="selected">8.00 AM</option>
                            <option value="08:30:00" selected="selected">8.30 AM</option>
                            <option value="09:00:00" selected="selected">9.00 AM</option>
                            <option value="09.30:00" selected="selected">9.30 AM</option>
                            <option value="10:00:00" selected="selected">10.00 AM</option>
                            <option value="10:30:00" selected="selected">10.30 AM</option>
                            <option value="11:00:00" selected="selected">11.00 AM</option>
                            <option value="11:30:00" selected="selected">11.30 AM</option>
                            <option value="12:00:00" selected="selected">12.00 PM</option>
                            <option value="12:30:00" selected="selected">12.30 PM</option>
                            <option value="13:00:00" selected="selected">1.00 PM</option>
                            <option value="13:30:00" selected="selected">1.30 PM</option>
                            <option value="14:00:00" selected="selected">2.00 PM</option>
                            <option value="14:30:00" selected="selected">2.30 PM</option>
                            <option value="15:00:00" selected="selected">3.00 PM</option>
                            <option value="15:30:00" selected="selected">3.30 PM</option>
                            <option value="16:00:00" selected="selected">4.00 PM</option>
                            <option value="16:30:00" selected="selected">4.30 PM</option>
                            <option value="17:00:00" selected="selected">5.00 PM</option>
                            <option value="17:30:00" selected="selected">5.30 PM</option>
                            <option value="18:00:00" selected="selected">6.00 PM</option>
                            <option value="18:30:00" selected="selected">6.30 PM</option>
                            <option value="19:00:00" selected="selected">7.00 PM</option>
                            <option value="19:30:00" selected="selected">7.30 PM</option>
                            <option value="20:00:00" selected="selected">8.00 PM</option>
                            <option value="20:30:00" selected="selected">8.30 PM</option>
                            <option value="21:00:00" selected="selected">9.00 PM</option>
                            <option value="21:30:00" selected="selected">9.30 PM</option>
                            <option value="22:00:00" selected="selected">10.00 PM</option>
                            <option value="22:30:00" selected="selected">10.30 PM</option>
                            <option value="23:00:00" selected="selected">11.00 PM</option>
                            <option value="23:30:00" selected="selected">11.30 PM</option>
                            <option value="00:00:00" selected="selected">12.00 AM</option>
           </select>
          </div>  
          </div>  

          <div class="col-md-4">
            <div class="form-group">
             <!-- <b>END</b>--> 
              <select class="select-input form-control" formControlName="thu_endTime" name="thu_endTime" style="background-color: #f2f2f2">
                <option *ngFor="let a of lis" value="a.availability.thu_endTime" selected>{{a.availability.thu_endTime}}</option>
                <option value="00:30:00" selected="selected">12.30 AM</option>
                            <option value="01:00:00" selected="selected">1.00 AM</option>
                            <option value="01:30:00" selected="selected">1.30 AM</option>
                            <option value="02:00:00" selected="selected">2.00 AM</option>
                            <option value="02:30:00" selected="selected">2.30 AM</option>
                            <option value="03:00:00" selected="selected">3.00 AM</option>
                            <option value="03:30:00" selected="selected">3.30 AM</option>
                            <option value="04:00:00" selected="selected">4.00 AM</option>
                            <option value="04:30:00" selected="selected">4.30 AM</option>
                            <option value="05:00:00" selected="selected">5.00 AM</option>
                            <option value="05:30:00" selected="selected">5.30 AM</option>
                            <option value="06:00:00" selected="selected">6.00 AM</option>
                            <option value="06:30:00" selected="selected">6.30 AM</option>
                            <option value="07:00:00" selected="selected">7.00 AM</option>
                            <option value="07:30:00" selected="selected">7.30 AM</option>
                            <option value="08:00:00" selected="selected">8.00 AM</option>
                            <option value="08:30:00" selected="selected">8.30 AM</option>
                            <option value="09:00:00" selected="selected">9.00 AM</option>
                            <option value="09.30:00" selected="selected">9.30 AM</option>
                            <option value="10:00:00" selected="selected">10.00 AM</option>
                            <option value="10:30:00" selected="selected">10.30 AM</option>
                            <option value="11:00:00" selected="selected">11.00 AM</option>
                            <option value="11:30:00" selected="selected">11.30 AM</option>
                            <option value="12:00:00" selected="selected">12.00 PM</option>
                            <option value="12:30:00" selected="selected">12.30 PM</option>
                            <option value="13:00:00" selected="selected">1.00 PM</option>
                            <option value="13:30:00" selected="selected">1.30 PM</option>
                            <option value="14:00:00" selected="selected">2.00 PM</option>
                            <option value="14:30:00" selected="selected">2.30 PM</option>
                            <option value="15:00:00" selected="selected">3.00 PM</option>
                            <option value="15:30:00" selected="selected">3.30 PM</option>
                            <option value="16:00:00" selected="selected">4.00 PM</option>
                            <option value="16:30:00" selected="selected">4.30 PM</option>
                            <option value="17:00:00" selected="selected">5.00 PM</option>
                            <option value="17:30:00" selected="selected">5.30 PM</option>
                            <option value="18:00:00" selected="selected">6.00 PM</option>
                            <option value="18:30:00" selected="selected">6.30 PM</option>
                            <option value="19:00:00" selected="selected">7.00 PM</option>
                            <option value="19:30:00" selected="selected">7.30 PM</option>
                            <option value="20:00:00" selected="selected">8.00 PM</option>
                            <option value="20:30:00" selected="selected">8.30 PM</option>
                            <option value="21:00:00" selected="selected">9.00 PM</option>
                            <option value="21:30:00" selected="selected">9.30 PM</option>
                            <option value="22:00:00" selected="selected">10.00 PM</option>
                            <option value="22:30:00" selected="selected">10.30 PM</option>
                            <option value="23:00:00" selected="selected">11.00 PM</option>
                            <option value="23:30:00" selected="selected">11.30 PM</option>
                            <option value="00:00:00" selected="selected">12.00 AM</option>
               </select>
              </div>  
              </div>  
              
               <!--Friday is open	11:30 AM	to	05:00 PM--->
              
   <div class="col-md-4">
    <div class="form-group">
      <!--<b>DAY</b>-->
     
       <input type="text"    class="form-control" value="Friday" style="background-color: #f2f2f2">
      </div>  
      </div>  
  
      <div class="col-md-4">
        <div class="form-group">
          <!--<b>START</b>-->
          <select class="select-input form-control" formControlName="fri_startTime" style="background-color: #f2f2f2">
            <option *ngFor="let a of lis" value="a.availability.fri_startTime" selected>{{a.availability.fri_startTime}}</option>
            <option value="00:30:00" selected="selected">12.30 AM</option>
                            <option value="01:00:00" selected="selected">1.00 AM</option>
                            <option value="01:30:00" selected="selected">1.30 AM</option>
                            <option value="02:00:00" selected="selected">2.00 AM</option>
                            <option value="02:30:00" selected="selected">2.30 AM</option>
                            <option value="03:00:00" selected="selected">3.00 AM</option>
                            <option value="03:30:00" selected="selected">3.30 AM</option>
                            <option value="04:00:00" selected="selected">4.00 AM</option>
                            <option value="04:30:00" selected="selected">4.30 AM</option>
                            <option value="05:00:00" selected="selected">5.00 AM</option>
                            <option value="05:30:00" selected="selected">5.30 AM</option>
                            <option value="06:00:00" selected="selected">6.00 AM</option>
                            <option value="06:30:00" selected="selected">6.30 AM</option>
                            <option value="07:00:00" selected="selected">7.00 AM</option>
                            <option value="07:30:00" selected="selected">7.30 AM</option>
                            <option value="08:00:00" selected="selected">8.00 AM</option>
                            <option value="08:30:00" selected="selected">8.30 AM</option>
                            <option value="09:00:00" selected="selected">9.00 AM</option>
                            <option value="09.30:00" selected="selected">9.30 AM</option>
                            <option value="10:00:00" selected="selected">10.00 AM</option>
                            <option value="10:30:00" selected="selected">10.30 AM</option>
                            <option value="11:00:00" selected="selected">11.00 AM</option>
                            <option value="11:30:00" selected="selected">11.30 AM</option>
                            <option value="12:00:00" selected="selected">12.00 PM</option>
                            <option value="12:30:00" selected="selected">12.30 PM</option>
                            <option value="13:00:00" selected="selected">1.00 PM</option>
                            <option value="13:30:00" selected="selected">1.30 PM</option>
                            <option value="14:00:00" selected="selected">2.00 PM</option>
                            <option value="14:30:00" selected="selected">2.30 PM</option>
                            <option value="15:00:00" selected="selected">3.00 PM</option>
                            <option value="15:30:00" selected="selected">3.30 PM</option>
                            <option value="16:00:00" selected="selected">4.00 PM</option>
                            <option value="16:30:00" selected="selected">4.30 PM</option>
                            <option value="17:00:00" selected="selected">5.00 PM</option>
                            <option value="17:30:00" selected="selected">5.30 PM</option>
                            <option value="18:00:00" selected="selected">6.00 PM</option>
                            <option value="18:30:00" selected="selected">6.30 PM</option>
                            <option value="19:00:00" selected="selected">7.00 PM</option>
                            <option value="19:30:00" selected="selected">7.30 PM</option>
                            <option value="20:00:00" selected="selected">8.00 PM</option>
                            <option value="20:30:00" selected="selected">8.30 PM</option>
                            <option value="21:00:00" selected="selected">9.00 PM</option>
                            <option value="21:30:00" selected="selected">9.30 PM</option>
                            <option value="22:00:00" selected="selected">10.00 PM</option>
                            <option value="22:30:00" selected="selected">10.30 PM</option>
                            <option value="23:00:00" selected="selected">11.00 PM</option>
                            <option value="23:30:00" selected="selected">11.30 PM</option>
                            <option value="00:00:00" selected="selected">12.00 AM</option>
           </select>
          </div>  
          </div>  

          <div class="col-md-4">
            <div class="form-group">
             <!--<b>END</b>---> 
              <select class="select-input form-control" formControlName="fri_endTime" name="fri_endTime" style="background-color: #f2f2f2">
            
                <option *ngFor="let a of lis" value="a.availability.fri_endTime" selected>{{a.availability.fri_endTime}}</option>
                <option value="00:30:00" selected="selected">12.30 AM</option>
                <option value="01:00:00" selected="selected">1.00 AM</option>
                <option value="01:30:00" selected="selected">1.30 AM</option>
                <option value="02:00:00" selected="selected">2.00 AM</option>
                <option value="02:30:00" selected="selected">2.30 AM</option>
                <option value="03:00:00" selected="selected">3.00 AM</option>
                <option value="03:30:00" selected="selected">3.30 AM</option>
                <option value="04:00:00" selected="selected">4.00 AM</option>
                <option value="04:30:00" selected="selected">4.30 AM</option>
                <option value="05:00:00" selected="selected">5.00 AM</option>
                <option value="05:30:00" selected="selected">5.30 AM</option>
                <option value="06:00:00" selected="selected">6.00 AM</option>
                <option value="06:30:00" selected="selected">6.30 AM</option>
                <option value="07:00:00" selected="selected">7.00 AM</option>
                <option value="07:30:00" selected="selected">7.30 AM</option>
                <option value="08:00:00" selected="selected">8.00 AM</option>
                <option value="08:30:00" selected="selected">8.30 AM</option>
                <option value="09:00:00" selected="selected">9.00 AM</option>
                <option value="09.30:00" selected="selected">9.30 AM</option>
                <option value="10:00:00" selected="selected">10.00 AM</option>
                <option value="10:30:00" selected="selected">10.30 AM</option>
                <option value="11:00:00" selected="selected">11.00 AM</option>
                <option value="11:30:00" selected="selected">11.30 AM</option>
                <option value="12:00:00" selected="selected">12.00 PM</option>
                <option value="12:30:00" selected="selected">12.30 PM</option>
                <option value="13:00:00" selected="selected">1.00 PM</option>
                <option value="13:30:00" selected="selected">1.30 PM</option>
                <option value="14:00:00" selected="selected">2.00 PM</option>
                <option value="14:30:00" selected="selected">2.30 PM</option>
                <option value="15:00:00" selected="selected">3.00 PM</option>
                <option value="15:30:00" selected="selected">3.30 PM</option>
                <option value="16:00:00" selected="selected">4.00 PM</option>
                <option value="16:30:00" selected="selected">4.30 PM</option>
                <option value="17:00:00" selected="selected">5.00 PM</option>
                <option value="17:30:00" selected="selected">5.30 PM</option>
                <option value="18:00:00" selected="selected">6.00 PM</option>
                <option value="18:30:00" selected="selected">6.30 PM</option>
                <option value="19:00:00" selected="selected">7.00 PM</option>
                <option value="19:30:00" selected="selected">7.30 PM</option>
                <option value="20:00:00" selected="selected">8.00 PM</option>
                <option value="20:30:00" selected="selected">8.30 PM</option>
                <option value="21:00:00" selected="selected">9.00 PM</option>
                <option value="21:30:00" selected="selected">9.30 PM</option>
                <option value="22:00:00" selected="selected">10.00 PM</option>
                <option value="22:30:00" selected="selected">10.30 PM</option>
                <option value="23:00:00" selected="selected">11.00 PM</option>
                <option value="23:30:00" selected="selected">11.30 PM</option>
                <option value="00:00:00" selected="selected">12.00 AM</option>
                 </select>
              </div>  
              </div>  
             
              <!----Thursday END-->
                     
              <div class="col-md-4">
                <div class="form-group">
                  <!--<b>DAY</b>-->
                 
                   <input type="text"    class="form-control" value="Saturday" style="background-color: #f2f2f2">
                  </div>  
                  </div>  
              
                  <div class="col-md-4">
                    <div class="form-group">
                      <!--<b>START</b>-->
                      <select class="select-input form-control" formControlName="sat_startTime" style="background-color: #f2f2f2">
                        <option *ngFor="let a of lis" value="a.availability.sat_startTime" selected>{{a.availability.sat_startTime}}</option>
                        <option value="00:30:00" selected="selected">12.30 AM</option>
                            <option value="01:00:00" selected="selected">1.00 AM</option>
                            <option value="01:30:00" selected="selected">1.30 AM</option>
                            <option value="02:00:00" selected="selected">2.00 AM</option>
                            <option value="02:30:00" selected="selected">2.30 AM</option>
                            <option value="03:00:00" selected="selected">3.00 AM</option>
                            <option value="03:30:00" selected="selected">3.30 AM</option>
                            <option value="04:00:00" selected="selected">4.00 AM</option>
                            <option value="04:30:00" selected="selected">4.30 AM</option>
                            <option value="05:00:00" selected="selected">5.00 AM</option>
                            <option value="05:30:00" selected="selected">5.30 AM</option>
                            <option value="06:00:00" selected="selected">6.00 AM</option>
                            <option value="06:30:00" selected="selected">6.30 AM</option>
                            <option value="07:00:00" selected="selected">7.00 AM</option>
                            <option value="07:30:00" selected="selected">7.30 AM</option>
                            <option value="08:00:00" selected="selected">8.00 AM</option>
                            <option value="08:30:00" selected="selected">8.30 AM</option>
                            <option value="09:00:00" selected="selected">9.00 AM</option>
                            <option value="09.30:00" selected="selected">9.30 AM</option>
                            <option value="10:00:00" selected="selected">10.00 AM</option>
                            <option value="10:30:00" selected="selected">10.30 AM</option>
                            <option value="11:00:00" selected="selected">11.00 AM</option>
                            <option value="11:30:00" selected="selected">11.30 AM</option>
                            <option value="12:00:00" selected="selected">12.00 PM</option>
                            <option value="12:30:00" selected="selected">12.30 PM</option>
                            <option value="13:00:00" selected="selected">1.00 PM</option>
                            <option value="13:30:00" selected="selected">1.30 PM</option>
                            <option value="14:00:00" selected="selected">2.00 PM</option>
                            <option value="14:30:00" selected="selected">2.30 PM</option>
                            <option value="15:00:00" selected="selected">3.00 PM</option>
                            <option value="15:30:00" selected="selected">3.30 PM</option>
                            <option value="16:00:00" selected="selected">4.00 PM</option>
                            <option value="16:30:00" selected="selected">4.30 PM</option>
                            <option value="17:00:00" selected="selected">5.00 PM</option>
                            <option value="17:30:00" selected="selected">5.30 PM</option>
                            <option value="18:00:00" selected="selected">6.00 PM</option>
                            <option value="18:30:00" selected="selected">6.30 PM</option>
                            <option value="19:00:00" selected="selected">7.00 PM</option>
                            <option value="19:30:00" selected="selected">7.30 PM</option>
                            <option value="20:00:00" selected="selected">8.00 PM</option>
                            <option value="20:30:00" selected="selected">8.30 PM</option>
                            <option value="21:00:00" selected="selected">9.00 PM</option>
                            <option value="21:30:00" selected="selected">9.30 PM</option>
                            <option value="22:00:00" selected="selected">10.00 PM</option>
                            <option value="22:30:00" selected="selected">10.30 PM</option>
                            <option value="23:00:00" selected="selected">11.00 PM</option>
                            <option value="23:30:00" selected="selected">11.30 PM</option>
                            <option value="00:00:00" selected="selected">12.00 AM</option>
                       </select>
                      </div>  
                      </div>  
            
                      <div class="col-md-4">
                        <div class="form-group">
                         <!--<b>END</b>---> 
                          <select class="select-input form-control" formControlName="sat_endTime" name="sun_startTime" style="background-color: #f2f2f2">
                        
                            <option *ngFor="let a of lis" value="a.availability.sat_endTime" selected>{{a.availability.sat_endTime}}</option>
                            <option value="00:30:00" selected="selected">12.30 AM</option>
                            <option value="01:00:00" selected="selected">1.00 AM</option>
                            <option value="01:30:00" selected="selected">1.30 AM</option>
                            <option value="02:00:00" selected="selected">2.00 AM</option>
                            <option value="02:30:00" selected="selected">2.30 AM</option>
                            <option value="03:00:00" selected="selected">3.00 AM</option>
                            <option value="03:30:00" selected="selected">3.30 AM</option>
                            <option value="04:00:00" selected="selected">4.00 AM</option>
                            <option value="04:30:00" selected="selected">4.30 AM</option>
                            <option value="05:00:00" selected="selected">5.00 AM</option>
                            <option value="05:30:00" selected="selected">5.30 AM</option>
                            <option value="06:00:00" selected="selected">6.00 AM</option>
                            <option value="06:30:00" selected="selected">6.30 AM</option>
                            <option value="07:00:00" selected="selected">7.00 AM</option>
                            <option value="07:30:00" selected="selected">7.30 AM</option>
                            <option value="08:00:00" selected="selected">8.00 AM</option>
                            <option value="08:30:00" selected="selected">8.30 AM</option>
                            <option value="09:00:00" selected="selected">9.00 AM</option>
                            <option value="09.30:00" selected="selected">9.30 AM</option>
                            <option value="10:00:00" selected="selected">10.00 AM</option>
                            <option value="10:30:00" selected="selected">10.30 AM</option>
                            <option value="11:00:00" selected="selected">11.00 AM</option>
                            <option value="11:30:00" selected="selected">11.30 AM</option>
                            <option value="12:00:00" selected="selected">12.00 PM</option>
                            <option value="12:30:00" selected="selected">12.30 PM</option>
                            <option value="13:00:00" selected="selected">1.00 PM</option>
                            <option value="13:30:00" selected="selected">1.30 PM</option>
                            <option value="14:00:00" selected="selected">2.00 PM</option>
                            <option value="14:30:00" selected="selected">2.30 PM</option>
                            <option value="15:00:00" selected="selected">3.00 PM</option>
                            <option value="15:30:00" selected="selected">3.30 PM</option>
                            <option value="16:00:00" selected="selected">4.00 PM</option>
                            <option value="16:30:00" selected="selected">4.30 PM</option>
                            <option value="17:00:00" selected="selected">5.00 PM</option>
                            <option value="17:30:00" selected="selected">5.30 PM</option>
                            <option value="18:00:00" selected="selected">6.00 PM</option>
                            <option value="18:30:00" selected="selected">6.30 PM</option>
                            <option value="19:00:00" selected="selected">7.00 PM</option>
                            <option value="19:30:00" selected="selected">7.30 PM</option>
                            <option value="20:00:00" selected="selected">8.00 PM</option>
                            <option value="20:30:00" selected="selected">8.30 PM</option>
                            <option value="21:00:00" selected="selected">9.00 PM</option>
                            <option value="21:30:00" selected="selected">9.30 PM</option>
                            <option value="22:00:00" selected="selected">10.00 PM</option>
                            <option value="22:30:00" selected="selected">10.30 PM</option>
                            <option value="23:00:00" selected="selected">11.00 PM</option>
                            <option value="23:30:00" selected="selected">11.30 PM</option>
                            <option value="00:00:00" selected="selected">12.00 AM</option>
                             </select>
                          </div>  
                          </div>  

                          <div class="col-md-4">
                            <div class="form-group">
                              <!--<b>DAY</b>-->
                             
                               <input type="text"   class="form-control" value="Sunday" style="background-color: #f2f2f2">
                              </div>  
                              </div>  
                          
                              <div class="col-md-4">
                                <div class="form-group">
                                  <!--<b>START</b>-->
                                  <select class="select-input form-control" formControlName="sun_startTime" style="background-color: #f2f2f2">
                                    <option *ngFor="let a of lis" value="a.availability.sun_startTime" selected>{{a.availability.sun_startTime}}</option>
                                    <option value="00:30:00" selected="selected">12.30 AM</option>
                                    <option value="01:00:00" selected="selected">1.00 AM</option>
                                    <option value="01:30:00" selected="selected">1.30 AM</option>
                                    <option value="02:00:00" selected="selected">2.00 AM</option>
                                    <option value="02:30:00" selected="selected">2.30 AM</option>
                                    <option value="03:00:00" selected="selected">3.00 AM</option>
                                    <option value="03:30:00" selected="selected">3.30 AM</option>
                                    <option value="04:00:00" selected="selected">4.00 AM</option>
                                    <option value="04:30:00" selected="selected">4.30 AM</option>
                                    <option value="05:00:00" selected="selected">5.00 AM</option>
                                    <option value="05:30:00" selected="selected">5.30 AM</option>
                                    <option value="06:00:00" selected="selected">6.00 AM</option>
                                    <option value="06:30:00" selected="selected">6.30 AM</option>
                                    <option value="07:00:00" selected="selected">7.00 AM</option>
                                    <option value="07:30:00" selected="selected">7.30 AM</option>
                                    <option value="08:00:00" selected="selected">8.00 AM</option>
                                    <option value="08:30:00" selected="selected">8.30 AM</option>
                                    <option value="09:00:00" selected="selected">9.00 AM</option>
                                    <option value="09.30:00" selected="selected">9.30 AM</option>
                                    <option value="10:00:00" selected="selected">10.00 AM</option>
                                    <option value="10:30:00" selected="selected">10.30 AM</option>
                                    <option value="11:00:00" selected="selected">11.00 AM</option>
                                    <option value="11:30:00" selected="selected">11.30 AM</option>
                                    <option value="12:00:00" selected="selected">12.00 PM</option>
                                    <option value="12:30:00" selected="selected">12.30 PM</option>
                                    <option value="13:00:00" selected="selected">1.00 PM</option>
                                    <option value="13:30:00" selected="selected">1.30 PM</option>
                                    <option value="14:00:00" selected="selected">2.00 PM</option>
                                    <option value="14:30:00" selected="selected">2.30 PM</option>
                                    <option value="15:00:00" selected="selected">3.00 PM</option>
                                    <option value="15:30:00" selected="selected">3.30 PM</option>
                                    <option value="16:00:00" selected="selected">4.00 PM</option>
                                    <option value="16:30:00" selected="selected">4.30 PM</option>
                                    <option value="17:00:00" selected="selected">5.00 PM</option>
                                    <option value="17:30:00" selected="selected">5.30 PM</option>
                                    <option value="18:00:00" selected="selected">6.00 PM</option>
                                    <option value="18:30:00" selected="selected">6.30 PM</option>
                                    <option value="19:00:00" selected="selected">7.00 PM</option>
                                    <option value="19:30:00" selected="selected">7.30 PM</option>
                                    <option value="20:00:00" selected="selected">8.00 PM</option>
                                    <option value="20:30:00" selected="selected">8.30 PM</option>
                                    <option value="21:00:00" selected="selected">9.00 PM</option>
                                    <option value="21:30:00" selected="selected">9.30 PM</option>
                                    <option value="22:00:00" selected="selected">10.00 PM</option>
                                    <option value="22:30:00" selected="selected">10.30 PM</option>
                                    <option value="23:00:00" selected="selected">11.00 PM</option>
                                    <option value="23:30:00" selected="selected">11.30 PM</option>
                                    <option value="00:00:00" selected="selected">12.00 AM</option>
                                   </select>
                                  </div>  
                                  </div>  
                        
                                  <div class="col-md-4">
                                    <div class="form-group">
                                     <!--<b>END</b>---> 
                                      <select class="select-input form-control" formControlName="sun_endTime" name="sun_endTime" style="background-color: #f2f2f2">
                                    
                                        <option *ngFor="let a of lis" value="a.availability.sun_endTime" selected>{{a.availability.sun_endTime}}</option>
                                        <option value="00:30:00" selected="selected">12.30 AM</option>
                                        <option value="01:00:00" selected="selected">1.00 AM</option>
                                        <option value="01:30:00" selected="selected">1.30 AM</option>
                                        <option value="02:00:00" selected="selected">2.00 AM</option>
                                        <option value="02:30:00" selected="selected">2.30 AM</option>
                                        <option value="03:00:00" selected="selected">3.00 AM</option>
                                        <option value="03:30:00" selected="selected">3.30 AM</option>
                                        <option value="04:00:00" selected="selected">4.00 AM</option>
                                        <option value="04:30:00" selected="selected">4.30 AM</option>
                                        <option value="05:00:00" selected="selected">5.00 AM</option>
                                        <option value="05:30:00" selected="selected">5.30 AM</option>
                                        <option value="06:00:00" selected="selected">6.00 AM</option>
                                        <option value="06:30:00" selected="selected">6.30 AM</option>
                                        <option value="07:00:00" selected="selected">7.00 AM</option>
                                        <option value="07:30:00" selected="selected">7.30 AM</option>
                                        <option value="08:00:00" selected="selected">8.00 AM</option>
                                        <option value="08:30:00" selected="selected">8.30 AM</option>
                                        <option value="09:00:00" selected="selected">9.00 AM</option>
                                        <option value="09.30:00" selected="selected">9.30 AM</option>
                                        <option value="10:00:00" selected="selected">10.00 AM</option>
                                        <option value="10:30:00" selected="selected">10.30 AM</option>
                                        <option value="11:00:00" selected="selected">11.00 AM</option>
                                        <option value="11:30:00" selected="selected">11.30 AM</option>
                                        <option value="12:00:00" selected="selected">12.00 PM</option>
                                        <option value="12:30:00" selected="selected">12.30 PM</option>
                                        <option value="13:00:00" selected="selected">1.00 PM</option>
                                        <option value="13:30:00" selected="selected">1.30 PM</option>
                                        <option value="14:00:00" selected="selected">2.00 PM</option>
                                        <option value="14:30:00" selected="selected">2.30 PM</option>
                                        <option value="15:00:00" selected="selected">3.00 PM</option>
                                        <option value="15:30:00" selected="selected">3.30 PM</option>
                                        <option value="16:00:00" selected="selected">4.00 PM</option>
                                        <option value="16:30:00" selected="selected">4.30 PM</option>
                                        <option value="17:00:00" selected="selected">5.00 PM</option>
                                        <option value="17:30:00" selected="selected">5.30 PM</option>
                                        <option value="18:00:00" selected="selected">6.00 PM</option>
                                        <option value="18:30:00" selected="selected">6.30 PM</option>
                                        <option value="19:00:00" selected="selected">7.00 PM</option>
                                        <option value="19:30:00" selected="selected">7.30 PM</option>
                                        <option value="20:00:00" selected="selected">8.00 PM</option>
                                        <option value="20:30:00" selected="selected">8.30 PM</option>
                                        <option value="21:00:00" selected="selected">9.00 PM</option>
                                        <option value="21:30:00" selected="selected">9.30 PM</option>
                                        <option value="22:00:00" selected="selected">10.00 PM</option>
                                        <option value="22:30:00" selected="selected">10.30 PM</option>
                                        <option value="23:00:00" selected="selected">11.00 PM</option>
                                        <option value="23:30:00" selected="selected">11.30 PM</option>
                                        <option value="00:00:00" selected="selected">12.00 AM</option>
                                         </select>
                                      </div>  
                                      </div>  
                 
              

        <div class="footer-section">
          <hr />
          
          <button
            type="submit"
            class="button submit-button"
          >
            <span class="button-text">Submit Form</span>
          </button>
          <button
            type="button"
            (click)="cancelForm()"
            class="button cancel-button"
          >
            <span class="button-text">Cancel</span>
          </button>
          <button
          type="button"
          (click)="deleteProviderForm()"
          class="button cancel-button"
        >
          <span class="button-text">Delete</span>
        </button> 
        </div>
      </div>
    </div> 
       
      </form>
    </div>
  </div>
</div>
