import { Component, OnInit,Input, Output, EventEmitter  } from '@angular/core';
import { Router, RouterModule, Routes,NavigationStart } from '@angular/router';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { ProvidersService } from 'src/app/services/providers/providers.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { MatchFacade } from 'src/app/services/facades/match.facade';
import { PatientsFacade } from 'src/app/services/facades/patients.facade';
import { ProvidersFacade } from 'src/app/services/facades/providers.facade';
import { Observable, Subscription } from 'rxjs';
import { MatchesFacade } from 'src/app/services/facades/matches.facade';
import { MatchesService } from 'src/app/services/matches/matches.service';
import { Match } from 'src/app/models';
import { BookingService } from 'src/app/services/booking/booking.service'
import { BookingFacade } from 'src/app/services/facades/booking.facade'
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
declare var OnSched:any;


//declare var createform: any;

@Component({
  selector: 'wtls-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnInit {
  closeResult = '';
  booking_details = {}
  @Input() match: Match;
  constructor(private modalService: NgbModal,
    private providersService: ProvidersService,
    private matchFacade: MatchFacade,
    private bookingFacade: BookingFacade,
    private dialog: MatDialog
    ) {

   // new createform(); 
  }

  ngOnInit(): void {

    console.log("sssssssss=>",this.match);
    
    //console.log(myid);
    //window.location.reload();
    let url = window.location.href;
    let params = (new URL(url)).searchParams;
    var providerid = params.get('Provider');
    var serviceId = params.get('Client');
    console.log('providerid',providerid)
    console.log('serviceId', serviceId)
     if(providerid == null || serviceId == null){
      let dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: 'MissingApptDetails',
        disableClose: true
      })
     }
     var paramsid = [];
     paramsid.push(params.get('Provider'));
     paramsid.push(params.get('Client'));
    var onsched = OnSched("sbox1618245647", "sbox");
    // Get instance of elements to use for creating elements
    let availability_element = document.getElementById("availability")
    var elements = onsched.elements();
  
    var now = new Date();
    var tzOffset = -now.getTimezoneOffset();
  
    // Parameters relate to GET availability endpoint and PUT appointments endpoint in OnSched API
    var availabilityParams = {
      locationId: "5d36d296-b430-4b63-a4a3-aee12a855bea",
      serviceId: serviceId,
     resourceId: providerid, 
     tzOffset: tzOffset,
     ///appointmentBM: { customFields: { field1: "AIT" } },
    };
    
    console.log(availabilityParams);
   
    // Use privacy fields to force a customer to accept terms and/or view the privacy policy
    var availabilityOptions = {
  
    };
  
    var availability = elements.create(
      "availability",
      availabilityParams,
      availabilityOptions,
      
    );
 console.log("availability=>", availability);
  availability_element.addEventListener("bookingConfirmation", (res: any) => {
    console.log("booking result", res)
    this.booking_details = {
      Appinment_id: res.detail.auditTrail[0].appointmentId,
      client_id: res.detail.serviceId,
      provider_id: res.detail.resourceId,
      Start_time: res.detail.startDateTime.split('T')[1].split('-')[0],
      End_Time: res.detail.endDateTime.split('T')[1].split('-')[0],
      booking_date: res.detail.date,
      //day: res.detail.date
    }

    //console.log('booking_details', this.booking_details)
    this.bookingFacade
      .saveBooking(this.booking_details)
      .subscribe((bookingResponse) => {
        console.log('from server', bookingResponse)
      },
      error => {
        console.log('Error', error)
      }
      )
    
  })
  availability.mount("availability");


  
  // new createform(); 
  window.addEventListener("load",function(){
    setTimeout(function(){
  //   window.addEventListener("mousemove", function() {
  //   setTimeout(function(){

  //   var slides = document.getElementsByClassName("time");
  //   console.log('slides',slides)
  //   for (var i = 0; i < slides.length; i++) {

  //     slides.item(i).removeAttribute("href");

  //  }
  //  second();
  //   }, 1000);
  //   });
    second();
    }, 3000);

  });
    
    


  function second()
  {
    var myobj = document.getElementsByClassName("overlay");
    console.log('myobj--->>>>',myobj)
     myobj[0].remove();
  } 

   //console.log(paramsid);
  
    
    

  }
 
}

