import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, AuthService } from 'src/app/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EndPoints } from 'src/app/constants/endpoints';

@Component({
  selector: 'wtls-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {
  li:any;
  lis=[];

  loactions:any;
  other = false
  selectlocations=[];
  submitted = false;
  recaptchaResponse: string;
  form: FormGroup;
  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private http: HttpClient,
    private apiService: ApiService,
    private changeDetector: ChangeDetectorRef,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private snackbar: MatSnackBar
  ) { 
    this.form = this.formBuilder.group({
    first_name: ['',Validators.required ],
    last_name: ['',Validators.required],
    dob: ['',Validators.required],
    gender:['',Validators.required],
    message:['',Validators.required],
    phone:['',Validators.required],
    email:['',Validators.required],
    found_us:['',Validators.required],
    contact_time_of_day:['',Validators.required],
    contact_preferred_method:['',Validators.required],
    patient_type:['',Validators.required],
    insurance_type:['',Validators.required],
    intake_stage:['',Validators.required],
    addressline1:['',Validators.required],
    addressline2:['',Validators.required],
    city:['',Validators.required],
    state_abbr:['',Validators.required],
    country:['',Validators.required],
    zipcode:['',Validators.required], 
    clinic_name:['',Validators.required], 
    mon_startTime:[''], 
    mon_endTime:[''], 
    tue_startTime:[''], 
    tue_endTime:[''], 
    wed_startTime:[''], 
    wed_endTime:[''], 
    thu_startTime:[''], 
    thu_endTime:[''], 
    fri_startTime:[''], 
    fri_endTime:[''], 
    sat_startTime:[''], 
    sat_endTime:[''], 
    sun_startTime:[''], 
    sun_endTime:[''], 
  })
}
get f() { return this.form.controls; }
  ngOnInit(): void {

    /* this.apiService.get('/createcompany')
    .subscribe(Response => {
      if(Response){ 
      }
      console.log("datatest=>",Response)
      this.li=Response;
      this.lis=this.li;
    }); */

      // clinic locations//
      /* 
       */
    

      // code end ////
  }

  submitForm() {
   
    this.submitted = true;

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        else{
          let Formpostdata = this.form.value;
          console.log("testadmin",this.form.value)
          this.apiService.post('/patients/save-patients', Formpostdata).subscribe(
            (response) =>{
              if(response){
              this.snackbar.open('Client Creation Successful!', 'Dismiss', {
              verticalPosition: 'bottom',
              horizontalPosition: 'left',
              duration:2750,
            }); 
            this.router.navigate(['/form-complete'])
            //window.location.href = EndPoints.BASE_URL+'/form-complete';
            }
            else{
              this.snackbar.open('Error while saving client', 'Dismiss', {
                verticalPosition: 'bottom',
                horizontalPosition: 'left',
                duration:2750,
              }); 
            }
            },
            //(response) => console.log(this.router.navigate(['dashboard/createcompanys']),this.showMsg= true),
            
            (error) => console.log(error)
          ); 
        }
        
        
  }

  changeOther(){
    this.other = false
    //alert("ok");
  }
  onChange(deviceValue) {
    if(deviceValue =='other')
    {
      this.other = true
    }
    else{
      console.log("not match")
    }
   
}

  resolveCaptcha(res: string): void {
    this.recaptchaResponse = res;
  }
  client(): void {
    this.router.navigate(['/dashboard/clientsshow'])
  }

}
