import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { from, Observable, of, Subject } from 'rxjs';
import { Provider } from 'src/app/models';
import { EndPoints } from 'src/app/constants/endpoints';
import { HttpParams } from '@angular/common/http';
import { bufferCount, concatMap, delay, map, take } from 'rxjs/operators';
import { AgmGeocoder } from '@agm/core';
import lodash from 'lodash';
import { exportCsv } from 'src/app/store/actions/matches.actions';

@Injectable({
  providedIn: 'root',
})
export class ProvidersService {
  constructor(
    private apiService: ApiService,
    private geo: AgmGeocoder  
  ) {}

  getProviders(options?: {
    assigned?: boolean;
    name?: string;
    first_name?: string;
    last_name?: string;
    providerName?: string;
    employeeStatus?: string[];
    onboardingStage?: string[];
    credentialingStage?: string[];
    street?: string;
    city?: string;
    state?: string;
    zip?: string;
    clinicLocation?: string;
    hasSchedule?: boolean;
    day?:string;
    startTime?: string;
    endTime?: string;
  }): Observable<Provider[]> {
    let params = new HttpParams();

    if (options.providerName)
      params = params.append('name', options.providerName);
    else if (options.name) params = params.append('name', options.name);

    if (options.street) params = params.append('street', options.street);
    if (options.city) params = params.append('city', options.city);
    if (options.state) params = params.append('state', options.state);
    if (options.zip) params = params.append('zip', options.zip);
    if (options.day.length>0)
    params = params.append('day', options.day);
    if (options.startTime.length>0)
      params = params.append('startTime', options.startTime);
      if (options.endTime.length>0)
      params = params.append('endTime', options.endTime);
      if (options.clinicLocation)
      params = params.append('clinicLocation', options.clinicLocation);

    if (options.employeeStatus.length > 0)
      params = params.append(
        'employeeStatus',
        options.employeeStatus.join(',')
      );
    if (options.onboardingStage.length > 0)
      params = params.append(
        'onboardingStage',
        options.onboardingStage.join(',')
      );
    if (options.credentialingStage.length > 0)
      params = params.append(
        'credentialingStage',
        options.credentialingStage.join(',')
      );

    if (options.hasSchedule == false) params = params.append('hasAvailability', 'false');

    return this.apiService.get<Provider[]>(EndPoints.PROVIDERS.GET, { params });
  }
  saveProviders(data): Observable<any> {
    console.log("data => ",data);
      data.isData = true;
      return this.apiService.post(EndPoints.PROVIDERS.POST, data);
  }
  saveappointment(data): Observable<any> {
    console.log("data123 =>",data);
    
    return this.apiService.saveappionmentdetails<any>(EndPoints.PROVIDERS.APPOINTMENT,data);
  }

  getappointment():Observable<any>{
   console.log("testzzzzzzzzzzz");
    return this.apiService.getappionmentdetails(EndPoints.PROVIDERS.GETAPPOINTMENT);
   
  }

  getProviderStatus(id){
    return this.apiService.get(EndPoints.PROVIDERS.GET_STATUS+`/${id}`)
  }
  
  updateschedulroptions(scheduledoptions): Observable<any> {
    console.log("xxxxxxxxxx=>",scheduledoptions);
   
   return this.apiService.updateproviderdetail<any>(EndPoints.PROVIDERS.GETOPTIONS,scheduledoptions);
  }
  getProvider(id: number): Observable<Provider> {
    return this.apiService
      .get<Provider>(EndPoints.PROVIDERS.GET + `/${id}`)
      .pipe(
        map((provider) => {
          provider.schedule.map((timeSlot) => {
            timeSlot.startTime = new Date(timeSlot.startTime);
            timeSlot.endTime = new Date(timeSlot.endTime);
          });

          provider.availability.map((timeSlot) => {
            timeSlot.startTime = new Date(timeSlot.startTime);
            timeSlot.endTime = new Date(timeSlot.endTime);
          });

          return provider;
        })
      );
  }

  updateProvider(provider: Provider ): Observable<Provider> {
    console.log(provider)
    let availability = [];
    provider.availability.forEach(timeSlot => {
      availability.push({
        dayOfWeek: timeSlot.dayOfWeek,
        startTime: timeSlot.startTime.getTime(),
        endTime: timeSlot.endTime.getTime(),
      })
    })

    const form = JSON.stringify({
      first_name: provider.first_name,
      last_name: provider.last_name,
      clinicLocation: provider.clinicLocation,
      employeeStatus: provider.employeeStatus,
      onboardingStage: provider.onboardingStage,
      credentialingStage: provider.credentialingStage,
      street: provider.address.street,
      city: provider.address.city,
      state: provider.address.state,
      zip: provider.address.zip,
      day: provider.day,
      startTime:provider.startTime,
      endTime:provider.endTime,
      lat: '',
      lng: '',
      availability
    })

    return this.apiService.put<Provider>(EndPoints.PROVIDERS.PUT + provider.id, form, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  updateProviderLocation(
    id: number,
    lat: number,
    lng: number
  ): Observable<Provider> {
    const form = JSON.stringify({
      lat,
      lng,
    });

    return this.apiService.put<Provider>(EndPoints.PROVIDERS.PUT + id, form, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  deleteProvider(id: number): Observable<any> {
    return this.apiService.delete(EndPoints.PROVIDERS.DELETE + id);
  }

  exportCsv(){
    return this.apiService.exportProviders(EndPoints.PROVIDERSSHOW.EXPORTCSV)
  }

  uploadCSV(files: File[], clear: boolean = false): Observable<any> {
    const form = new FormData();
    files.forEach((file) => {
      form.append('csvs', file, file.name);
    });

    if (!clear){
      return this.apiService.post(`${EndPoints.PROVIDERS.UPLOADCSV}?clear=false`, form);
    }
    else
      return this.apiService.post(EndPoints.PROVIDERS.POST, form);
  }

  geocode(providers: Provider[]): Observable<Provider[]> {
    let result = new Subject<Provider[]>();

    const currentProviders: Provider[] = [];

    from(providers)
      .pipe(
        bufferCount(20),
        concatMap((item) => of(item).pipe(delay(200)))
      )
      .subscribe((chunk) => {
        this.geocodeBatch(chunk, 0).then((p) => {
          p.forEach((pat) => {
            currentProviders.push(pat);
            console.log(currentProviders);
          });
          console.log(providers);
          if (providers.length == currentProviders.length) {
            result.next(currentProviders);
            result.complete();
          }
        });
      });

    return result;
  }

  geocodeBatch(providers: Provider[], index: number): Promise<Provider[]> {
    return new Promise((resolve) => {
      this.geo
        .geocode({
          address: `${providers[index].address.street}, ${providers[index].address.city} ${providers[index].address.state}, ${providers[index].address.zip}`,
        })
        .pipe(take(1))
        .subscribe(
          (res) => {
            const lat = res[0].geometry.location.lat();
            const lng = res[0].geometry.location.lng();

            let patArr: Provider[] = lodash.cloneDeep(providers);
            patArr[index].address.lat = lat;
            patArr[index].address.lng = lng;

            this.updateProviderLocation(
              providers[index].id,
              lat,
              lng
            ).subscribe();

            if (providers.length - 1 == index) {
              resolve(patArr);
            } else {
              this.geocodeBatch(patArr, index + 1).then((providers) => {
                resolve(providers);
              });
            }
          },
          (error) => {
            if (providers.length - 1 == index) {
              resolve(providers);
            } else {
              this.geocodeBatch(providers, index + 1).then((providers) => {
                resolve(providers);
              });
            }
          }
        );
    });
  }
}
