<div
  class="filter-bar-container"
>
  <div class="section">
    <wtls-text-field clearable="true" placeholder="Name" [(value)]="filterData.name" (valueChange)="updateFilters()"></wtls-text-field>
  </div>
  <div class="section border-left">
    <wtls-text-field clearable="true" placeholder="Address" [(value)]="filterData.address" (valueChange)="updateFilters()"></wtls-text-field>
  </div>
  <div class="section border-left">
    <wtls-text-field clearable="true" placeholder="City" [(value)]="filterData.city" (valueChange)="updateFilters()"></wtls-text-field>
  </div>
  <div class="section border-left">
    <wtls-dropdown placeholder="State" [options]="stateOptions" [(value)]="filterData.state" (valueChange)="updateFilters()"></wtls-dropdown>
  </div>
  <div class="section border-left">
    <wtls-text-field clearable="true" placeholder="Zip" [(value)]="filterData.zip" (valueChange)="updateFilters()"></wtls-text-field>
  </div>
  <div class="section border-left">
    <wtls-dropdown placeholder="Clinic Location" [options]="clinicLocationOptions" [(value)]="filterData.clinicLocation" (valueChange)="updateFilters()"></wtls-dropdown>
  </div>
  <div class="filter-label-wrapper">
    <span class="filter-label">Filters</span>
  </div>
  <div class="filter-button">
    <button type="button" mat-icon-button aria-label="Edit Filters" (click)="openFilterDialog()">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</div>
