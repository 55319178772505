import { Component, OnInit } from '@angular/core';
import { InteractionMode } from "igniteui-angular";

@Component({
  selector: 'wtls-timepicker-dropdown',
  templateUrl: './timepicker-dropdown.component.html',
  styleUrls: ['./timepicker-dropdown.component.scss']
})
export class TimepickerDropdownComponent implements OnInit {

	public mode: InteractionMode = InteractionMode.DropDown;
    public format: string = "hh:mm tt";
    public date: Date = new Date();

  constructor() { }

  ngOnInit(): void {
  }

}
