import { Component, OnInit, OnDestroy } from '@angular/core';
import { FiltersState } from 'src/app/store/reducers';
import { Subscription } from 'rxjs';
import { FiltersFacade } from 'src/app/services/facades/filters.facade';
import lodash from 'lodash';
import { PatientsFacade } from 'src/app/services/facades/patients.facade';
import { ProvidersFacade } from 'src/app/services/facades/providers.facade';
import { map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'wtls-unassigned-filter-bar',
  templateUrl: './unassigned-filter-bar.component.html',
  styleUrls: ['./unassigned-filter-bar.component.scss'],
})
export class UnassignedFilterBarComponent implements OnInit, OnDestroy {
  filtersState: FiltersState;

  clinicLocationOptions: string[];

  subscriptions = new Subscription();

  constructor(
    private filtersFacade: FiltersFacade,
    private patientsFacade: PatientsFacade,
    private providersFacade: ProvidersFacade
  ) {}

  ngOnInit(): void {
    const sub1 = this.filtersFacade.getFilters().subscribe((filters) => {
      this.filtersState = lodash.cloneDeep(filters);
    });

    const sub2 = this.patientsFacade
      .getAllPatients().pipe(
        mergeMap(patients => this.providersFacade.getAllProviders().pipe(
          map(providers => {
            let c1: string[] = [];
            let c2: string[] = [];

            patients.forEach((patient) => {
              c1.push(patient.clinicLocation);
            });
            providers.forEach((provider) => {
              c2.push(provider.clinicLocation);
            });

            this.clinicLocationOptions = lodash.uniq([ ...c1, ...c2 ].filter(filter => !(filter == '')))
          })
        ))
      )
      .subscribe()

 //   this.subscriptions.add(sub1);
   // this.subscriptions.add(sub2);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updateFilters(filters: FiltersState): void {
    console.log("unassigned");
    this.filtersFacade.updateUnassignedFilters(filters);

    this.patientsFacade.loadPatients();
    this.providersFacade.loadProviders();
  }
}
