import { Component, OnInit, ViewChild, ComponentFactoryResolver, ElementRef, AfterViewInit, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import { createComponent } from '@angular/compiler/src/core';
import { MatchComponent } from '../match/match.component';
import { AgmSnazzyInfoWindow } from '@agm/snazzy-info-window';
import { AgmGeocoder,GoogleMapsAPIWrapper, AgmMap } from '@agm/core';
import { AppState } from 'src/app/store/app.state';
import { Store } from '@ngrx/store';
import { PatientsSelectors, ProvidersSelectors, MatchesSelectors } from 'src/app/store/selectors';
import { Patient, Provider, Match } from 'src/app/models';
import { Subscription } from 'rxjs';
import { MapFullScreenActions } from 'src/app/store/actions';
import { MatchFacade } from 'src/app/services/facades/match.facade';

export interface MapControl {
  elementRef: ElementRef,
  position: google.maps.ControlPosition
}

@Component({
  selector: 'wtls-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit, OnDestroy {
  @ViewChild('map') mapRef: ElementRef
  @ViewChild('legend') legend: ElementRef;
  @ViewChild('fullScreenControl') fullScreenControl: ElementRef;
  @ViewChild('fixedControl') fixedControl: ElementRef;
  @ViewChild('zoomControl') zoomControl: ElementRef;
  @ViewChild('matchControl') matchControl: ElementRef;
  @ViewChild('matchSideWindow') matchSideWindow: ElementRef;
  @ViewChild('AgmMap') agmMap: AgmMap;
  @Input() matches: Match[];
  @Input() controls: MapControl[];

  mapFullScreen: boolean;

  legendKeys = [];

  defaultCenter = { lat: 37.630085, lng: -77.543847 };

  zoom=10;

  map: google.maps.Map;
  patient: Patient;
  provider: Provider;
  patients: Patient[];
  patientsToDisplay: Patient[];
  providers: Provider[];
  providersToDisplay: Provider[];

  subscriptions = new Subscription();

  constructor(
    private store: Store<AppState>,
    private geocoder: AgmGeocoder,
    private matchFacade: MatchFacade,
    ) { }

  ngOnInit(): void {
    const sub1 = this.store.select('mapFullScreen').subscribe(
      value => {
        this.mapFullScreen = value;
        console.log(this.mapFullScreen);
      }
    )

    const sub2 = this.store.select(PatientsSelectors.selectPatientsList).subscribe(
      patients => {
        this.patients = patients;
        this.patientsToDisplay = patients;
        console.log('1234567890-');
        console.log(this.patients);
        
      }
    )

    const sub3 = this.store.select(ProvidersSelectors.selectProvidersList).subscribe(
      providers => {
        this.providers = providers;
        this.providersToDisplay = providers;
        this.setFitBounds();
      }
    )

    const sub4 = this.store.select(MatchesSelectors.selectMatchesList).subscribe(
      matches => {
        this.matches = matches;
      }
    )
    this.subscriptions.add(sub1);
    this.subscriptions.add(sub2);
    this.subscriptions.add(sub3);

    const sub5 = this.matchFacade
      .getProvider()
      .subscribe((provider) => {
        this.provider = provider;
        this.providersToDisplay = this.providers.filter((providersEle)=>{
          if(provider){
              return provider.id == providersEle.id
          } else {
              return true;
          }
        });
        this.setFitBounds();
        // console.log("this.providersToDisplay => ",this.providersToDisplay.map())
      });
    const sub6 = this.matchFacade
      .getPatient()
      .subscribe((patient) => {
        this.patient = patient;
        this.patientsToDisplay = this.patients.filter((patientEle)=>{
          if(patient){
              return patient.id == patientEle.id
          } else {
              return true;
          }
        });
        this.setFitBounds();
      });

  }
  async setFitBounds(){
    const bounds = new google.maps.LatLngBounds();
    let boundsto = [];
    if(this.provider && this.patient){
      boundsto = [this.provider,this.patient];
    } else if(this.provider) {

      boundsto = [this.provider];
    } else if(this.patient) {

      boundsto = [this.patient];
    } else {

      boundsto = [...this.providersToDisplay,...this.patientsToDisplay];
    }
    boundsto.forEach(e=>{
      if(e.address){
          bounds.extend(new google.maps.LatLng(e.address.lat, e.address.lng));
      }
    });
    
    if(this.map){
      await this.map.fitBounds(bounds);
      if(this.provider && this.patient){
        let [lat,lng] = this.middlePoint(this.provider.address.lat, this.provider.address.lng, this.patient.address.lat, this.patient.address.lng);
        let map = this.map;
        setTimeout(()=>{ 
          map.setCenter({lat,lng});
          console.log("zoom level",map.getZoom())
          map.setZoom(map.getZoom()*.97)
        },1000)
      }
    }
      
 
  }
  toRad(val){
    return val * Math.PI / 180;
  }
  toDeg(val) {
    return val * (180 / Math.PI);
  }
  middlePoint(lat1, lng1, lat2, lng2) {
	
    //-- Longitude difference
    var dLng = this.toRad(lng2 - lng1);

    //-- Convert to radians
    lat1 = this.toRad(lat1);
    lat2 = this.toRad(lat2);
    lng1 = this.toRad(lng1);

    var bX = Math.cos(lat2) * Math.cos(dLng);
    var bY = Math.cos(lat2) * Math.sin(dLng);
    var lat3 = Math.atan2(Math.sin(lat1) + Math.sin(lat2), Math.sqrt((Math.cos(lat1) + bX) * (Math.cos(lat1) + bX) + bY * bY));
    var lng3 = lng1 + Math.atan2(bY, Math.cos(lat1) + bX);
    return [this.toDeg(lat3),this.toDeg(lng3)];
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  mapReady(map: google.maps.Map): void {
    this.map = map;

    this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(this.legend.nativeElement);
    this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(this.fullScreenControl.nativeElement);
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(this.zoomControl.nativeElement);
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(this.fixedControl.nativeElement);
    
    if (this.controls) {
      this.controls.forEach(control => {
        this.map.controls[control.position].push(control.elementRef.nativeElement);
      })
      console.log("this.map.controls => ",this.map.controls)
    }

     
  }

  toggleFullScreen(): void {
    this.store.dispatch(MapFullScreenActions.toggleFullScreen());
  }

  centerMap(): void {
    navigator.geolocation.getCurrentPosition(position => {
      this.map.panTo({ lat: position.coords.latitude, lng: position.coords.longitude });
    })
  }
  
  zoomIn(): void {
    this.map.setZoom(this.map.getZoom() + 1);
  }

  zoomOut(): void {
    this.map.setZoom(this.map.getZoom() - 1);
  }
}
