import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType, act } from '@ngrx/effects';
import { ProvidersService } from 'src/app/services/providers/providers.service';
import { PatientsService } from 'src/app/services';
import { FiltersActions, PatientsActions, ProvidersActions } from '../actions';
import { mergeMap, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FiltersEffects {

    // updateUnassignedFilters$ = createEffect(() => 
    //     this.actions$.pipe(
    //         ofType(FiltersActions.updateUnassignedFilters),
    //         switchMap(action => [
    //             PatientsActions.loadPatients(),
    //             ProvidersActions.loadProviders({ options: { name: action.providerName, employeeStatus: action.employeeStatuses, onboardingStage: action.onboardingStages, street: action.address, city: action.city, state: action.state, zip: action.zip }})
    //         ])
    //     )
    // )

    
    // resetUnassignedFilters$ = createEffect(() => 
    //     this.actions$.pipe(
    //         ofType(FiltersActions.resetUnassignedFilter),
    //         switchMap(action => [
    //             PatientsActions.loadPatients(),
    //             ProvidersActions.loadProviders({})
    //         ])
    //     )
    // )


    constructor(
        private actions$: Actions
    ) { }  
}