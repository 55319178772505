import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, OnChanges } from '@angular/core';
import { Provider, TimeSlot } from 'src/app/models';
import { Patient } from 'src/app/models';
import { Subscription, BehaviorSubject } from 'rxjs';
import { ProvidersFacade } from 'src/app/services/facades/providers.facade';
import { valueInRange } from 'igniteui-angular';
import { ProvidersService } from 'src/app/services/providers/providers.service';
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { MatchFacade } from 'src/app/services/facades/match.facade';
//import { PatientsFacade } from 'src/app/services/facades/patients.facade';

//declare var createform: any;PatientRecordComponent


//declare var OnSched:any;

@Component({
  selector: 'wtls-provider-record',
  templateUrl: './provider-record.component.html',
  styleUrls: ['./provider-record.component.scss']
})
export class ProviderRecordComponent implements OnInit, OnChanges, OnDestroy {
   closeResult = '';
  
  @Input() provider;
  @Input() patient;
 // private providersService: ProvidersService;
  @Input() selectedProvider: Provider;
  @Output() change = new EventEmitter<Provider>();
  @Output() edit = new EventEmitter<Provider>();
  @Output() delete = new EventEmitter<Provider>();
  @Output() schange = new EventEmitter<Provider>();
  
  

  minDate: Date;
  maxDate: Date;

  //Patient: Patient[] = [];

  fullProvider: Provider;

  isExpanded = new BehaviorSubject<boolean>(false);
  checked: boolean;

  availabilityLoading: boolean;

  schedule: TimeSlot[] = [];
  availability: TimeSlot[] = [];
  availability_status = [1,1,0,0,2];
  days = ['Monday: ','Tuesday: ','Wednesday: ','Thursday: ','Friday: ','Saturday: ','Sunday: ']
  display_time = []
  subscriptions = new Subscription();

  constructor(
    public router: Router,
    private providersFacade: ProvidersFacade,
    //private patientsFacade: PatientsFacade,
    private providersService: ProvidersService,
    
    // private matchFacade: MatchFacade,
 
  ) { 
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 20, 0, 1);
    this.maxDate = new Date(currentYear + 1, 11, 31);
 
}

  ngOnInit(): void {
    // console.log('cureenthgfjhg')
    // this.matchFacade
    //   .getPatient()
    //   .subscribe((patient) => {
    //     console.log("current client => ",patient)
    //   })
    const sub = this.isExpanded
    .subscribe(
      isExpanded => {
        if (isExpanded && this.availability.length == 0) {
          // Get full patient
          this.availabilityLoading = true;

          this.providersFacade
            .getFullProvider(this.provider.id)
            .subscribe(
              provider => {
                 //this.patientsFacade.updatePatientInList();
                
                this.fullProvider = provider;

                this.schedule = provider.schedule;
                this.availability = provider.availability;
                let split_time
                let time_string
                // for(let i=0;i<provider.availability.length;++i){
                //   time_string = this.days[provider.availability[i].dayOfWeek-1]
                //   split_time = provider.availability[i].startTime
                //   time_string += ' '+ split_time + ' - '
                //   split_time = provider.availability[i].endTime
                //   time_string += split_time
                //   this.display_time.push(time_string)
                //   console.log('this.display_time',this.display_time)
                // }
                for(let i=0;i<this.availability.length;++i){
                  //time_string = this.days[this.availability[i].dayOfWeek-1]
                  this.display_time.push(this.availability[i].schedule)
                  
                }
                this.availabilityLoading = false;
              },
              error => {
                this.availabilityLoading = false;

                alert(error);
              }
            )
            // this.providersFacade
            // .getProviderStatus(this.provider.id)
            // .subscribe(
            //   (status) => {
            //     console.log('status_result',status)
            //     if(status.result == 'empty')
            //       this.availability_status = [2,2,2,2,2]
            //     else{
            //       this.availability_status = status.result
            //     }
            //   }
            // )
        }
      }
    )
  
    this.subscriptions.add(sub);
  }
 title = 'nand-js';
  ngOnChanges(): void {
    if (this.selectedProvider && this.provider.id == this.selectedProvider.id) {
      this.checked = true;
      this.isExpanded.next(true);
    } else {
      this.checked = false;
      this.isExpanded.next(false);
    }
  }



  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  changeProvider(event) {
    event.preventDefault();

    this.change.emit(this.provider);
  }

  toggleExpanded(): void {
    this.isExpanded.next(!this.isExpanded.getValue())
  }

  
  
  updateproviderdetails(event)
  {
    console.log("window open => ",this.patient.onschedId)
    console.log(event);
    
     var myid = event.id;
     var dmyid =this.patient.id;
    
     if(dmyid == this.patient.id)
     {
      window.location.href = 'dashboard/appointment-booking?Provider='+myid+'&Client='+dmyid;
     }
     else
     {
       console.log("test");
       //alert('ibnot passing');
     }
   
  }
  getTimeFormat(hours, minutes){
    console.log('hours,minutes',hours,minutes)
    let timeString = ''
    let hourString = ''
    let minuteString = '' 
    let am = 'AM'
    let pm = 'PM'
    //hour conversion to 12 hour format
    if(hours == 0){
      hourString = '12'
      timeString += hourString+':'
    }
    else{
      if(hours > 12){
        hourString = (hours-12).toString()
        timeString = hourString+':'
      }
      else if(hours < 10){
        hourString = '0'+(hours).toString()
        timeString = hourString+':'
      }
      else{
        hourString = (hours).toString()
        timeString = hourString+':'
      }
    }
    //minute conversion
    if(minutes == 0){
      minuteString = '00'
      timeString += minuteString
    }
    else{
      timeString+= minutes
    }
  
    if(hours == 0 || hours < 11){
      timeString += ''+am
    }
    else{
      timeString += ''+pm
    }
  
    return timeString
   }

 
}
