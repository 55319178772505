import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'wtls-spinner-overlay',
  templateUrl: './spinner-overlay.component.html',
  styleUrls: ['./spinner-overlay.component.scss']
})
export class SpinnerOverlayComponent implements OnInit, OnChanges {
  @Input() active: boolean;

  constructor() { }

  ngOnInit(): void {
    console.log(this.active);
  }

  ngOnChanges(): void {
  }
}
