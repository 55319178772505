<div class="text-field-container">
  <button type="button" class="icon-btn">
    <mat-icon color="primary">search</mat-icon>
  </button>
  <input
    #textField
    type="text"
    [placeholder]="placeholder"
    class="input-field"
    [(ngModel)]="value"
    (input)="input$.next(textField.value)"
    ngDefaultControl
  />
  <button
    *ngIf="clearable"
    [style.visibility]="textField.value ? 'visible' : 'hidden'"
    type="button"
    class="icon-btn"
    (click)="clearInput()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
