import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services';

@Component({
  selector: 'wtls-blocks',
  templateUrl: './blocks.component.html',
  styleUrls: ['./blocks.component.scss']
})
export class BlocksComponent implements OnInit {
  role = ''
  isSuperAdmin = false
  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.role = this.authService.getRoleToken()
    console.log('role',this.role)
    console.log('admin',this.isSuperAdmin)
    if(this.role == '1'){
      this.isSuperAdmin = true
      console.log('admin',this.isSuperAdmin)
    }
  }

}
