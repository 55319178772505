import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { NewClientsState } from '../reducers';

export const selectNewClients = (state: AppState) => state.newClients;

export const selectNewClientsPage = createSelector(
    selectNewClients,
    (state: NewClientsState) => state.page
);

export const selectNewClientsLoading = createSelector(
    selectNewClients,
    (state: NewClientsState) => state.loading
);