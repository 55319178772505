<div class="record-container border-light-bottom">
  <div class="record-column">
    <div class="record-preview-container">
      <div class="radio-button-column">
        <div class="radio-button-wrapper">
          
          <mat-radio-button
            [checked]="checked"
            [value]="provider"
            color="primary"
            (click)="changeProvider($event)"
          ></mat-radio-button>
        </div>
      </div>
      <div class="arrow-icon-wrapper">
        <button
          type="button"
          mat-icon-button
          aria-label="Edit Filters"
          (click)="toggleExpanded()"
        >
          <mat-icon *ngIf="!(isExpanded | async)">chevron_right</mat-icon>
          <mat-icon *ngIf="isExpanded | async">expand_more</mat-icon>
        </button>
      </div>
      <div class="record-preview">
        <h4 *ngIf="!provider.name" class="record-name"><em>No Name</em></h4>
        <h4 *ngIf="provider.name" class="record-name">{{ provider.name }}</h4>
        <span *ngIf="!provider.address.street"
          ><em>No Address Available</em></span
        >
        <span
          *ngIf="provider.address.street && !(isExpanded | async)"
          class="record-address"
          >{{
            provider.address.street +
              ", " +
              provider.address.city +
              " " +
              provider.address.state +
              " " +
              provider.address.zip | truncateEllipsis: 24
          }}</span
        >
        <span
          *ngIf="provider.address.street && (isExpanded | async)"
          class="record-address"
          >{{
            provider.address.street +
              ", " +
              provider.address.city +
              " " +
              provider.address.state +
              " " +
              provider.address.zip
          }}</span
        >
      </div>
    </div>
    <div *ngIf="isExpanded | async" class="record-details">
      <div class="border-light-top"></div>
      <div class="record-details-row">
        <div class="record-details-column">
          <h5>Clinic Location:</h5>
          <span>{{ provider.clinicLocation }}</span>
        </div>
      </div>
      <div class="record-details-row">
        <div class="record-details-column">
          <h5>Availability:</h5>
          <div class="spinner-wrapper" *ngIf="availabilityLoading">
            <mat-progress-spinner
              color="primary"
              mode="indeterminate"
              diameter="30"
            >
            </mat-progress-spinner>
          </div>
          <span *ngIf="!availabilityLoading && !display_time[0]"
            ><em>No Availability</em></span
          >
          <span *ngIf="!availabilityLoading && display_time[0]">
            <table>
              <tr *ngFor="let time of display_time; index as i"> 
                <span>
                  {{days[i]}}
                  <!-- {{ time.dayOfWeek }} {{time.startTime}} {{ time.endTime }} -->
                </span>
                <span>
                  {{ time }}
                </span>
                <!-- <tr class ="dot" [ngClass]="{
                  'dot-red' : availability_status[i] == 0,
                  'dot-green' : availability_status[i] == 2,
                  'dot-yellow' : availability_status[i] == 1}">
                </tr> -->
              </tr>
              </table>
          </span>
        <script>



        </script>
        </div>
      </div>
      <div class="record-details-row">
        <div class="record-details-column">
          <h5>Employee Status:</h5>
          <span>{{ provider.employeeStatus }}</span>
        </div>
      </div>
      <div class="record-details-row">
        <div class="record-details-column">
          <h5>Onboarding Stage:</h5>
          <span>{{ provider.onboardingStage }}</span>
        </div>
      </div>
      <div class="record-details-row">
        <div class="record-details-column">
          <h5>Credentialing Stage:</h5>
          <span>{{ provider.credentialingStage }}</span>
        </div>
      </div>
      <!-- <div class="record-details-row">
        <div class="record-details-column">
          <h5>onsched-id</h5>
          <span>{{ provider.onschedId }}</span>
        </div>
      </div> -->
      <div class="record-details-row">
        <div class="record-details-column">
          <div class="button-group">
            <!-- button
              type="button"
              mat-stroked-button
              (click)="edit.emit(fullProvider)"
            >
              Edit
            </button -->
            <!-- button
              type="button"
              mat-stroked-button
              (click)="delete.emit(provider)"
            >
              Delete
            </button -->
     
   

            <button (click) = updateproviderdetails(provider)
            type="button"
            mat-stroked-button>
            Add Client to Schedule
          </button>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
