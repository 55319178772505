import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormField, FormSelectOption } from 'src/app/models';

@Component({
  selector: 'div[wtls-form-field]',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss']
})
export class FormFieldComponent implements OnInit {
  @ViewChild('phoneFirst') phoneFirst: ElementRef;
  @ViewChild('phoneSecond') phoneSecond: ElementRef;
  @ViewChild('phoneThird') phoneThird: ElementRef;

  @Input() formField: FormField;
  @Input() parentGroup: FormGroup;

  open: boolean;
  selectedOption: FormSelectOption;
  endDate
  constructor() { 
    this.endDate = new Date()
  }

  ngOnInit(): void {
  }

  phoneChange(value: string, maxLength: string, previous: any, next: any): void {
    const first = this.phoneFirst.nativeElement.value;
    const second = this.phoneSecond.nativeElement.value;
    const third = this.phoneThird.nativeElement.value;

    const formControl = this.parentGroup.get(`${this.formField.id}`);

    if (formControl.pristine) {
      formControl.markAsDirty();
    }

    if (first || second || third) {
      const value = `${first}-${second}-${third}`;

      formControl.setValue(value);
    } else {
      formControl.setValue('');
    }
    
    // Move focus
    if (previous && value.length === 0) {
      previous.focus();
    } else if (next && value.length >= parseInt(maxLength)) {
      next.focus();
    }
  }

  phoneBlur(text: string): void {
    const formControl = this.parentGroup.get(`${this.formField.id}`);

    if (formControl.untouched) {
      formControl.markAsTouched();
    }   
  }

  upload(files: FileList): void {
    if (files[0]) {
      const formControl = this.parentGroup.get(`${this.formField.id}`);

      formControl.setValue(files[0])
    }
  }
  /* updateclienthours(data)
  {
    var x = document.getElementsByClassName("120");
    alert(x);
  } */
  
   
}
