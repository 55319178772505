import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { take } from 'rxjs/operators';
import { error } from 'protractor';
import { AuthFacade } from 'src/app/services/facades/auth.facade';

@Component({
  selector: 'wtls-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loading: boolean;
  success: string;
  error: string;
  returnUrl: string;

  formData = {
    email: '',
    password: '',
    rememberMe: false
  }

  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private authFacade: AuthFacade
  ) {
    if (this.authFacade.getAuthToken()) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    let email = localStorage.getItem('email');

    if (email) {
      this.formData = { ...this.formData, email, rememberMe: true }
    }

    if (this.route.snapshot.queryParams['resetPassword']) {
      this.success = 'Password has successfuly been reset';
    }
  }

  onSubmit(): void {
    this.loading = true;
    this.success = ''
    this.error = '';

    this.authFacade.login(this.formData.email, this.formData.password)
      .pipe(
        take(1)
      )
      .subscribe(
        jwt => {
          console.log("success!")

          if (this.formData.rememberMe) {
            localStorage.setItem('email', this.formData.email);
          } else {
            localStorage.removeItem('email');
          }
      
          this.authFacade.saveAuthToken(jwt.token, jwt.role,jwt.email);
          
          if (this.returnUrl)
            this.router.navigate([this.returnUrl]); 
          else
            this.router.navigate(['/dashboard']);     
        },
        error => {
          console.log("failure!")

          this.error = error;
          this.loading = false;
        }
      )
  }
}
