import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup,Validators  } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { ApiService, AuthService } from 'src/app/services';
import { ValidatorsService } from 'src/app/services/validators/validators.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EndPoints } from 'src/app/constants/endpoints';


@Component({
  selector: 'wtls-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  li:any;
  lis=[];
  role = ''
  select_riles:any;
  select_list=[];
  isSuperAdmin = false
  constructor(
    private router: Router, 
    private route: ActivatedRoute, 
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private apiService: ApiService,
    private authService: AuthService,
    private snackbar: MatSnackBar,
    private validationService: ValidatorsService
  ) {  
      this.form = this.formBuilder.group({
        fullname: ['',Validators.compose([Validators.required, this.validationService.nameValidate()])],
        email: ['',[Validators.required,Validators.email]],
        Address: ['',Validators.compose([Validators.required, this.validationService.addressValidate()])],
        contactno:['',Validators.compose([Validators.required,this.validationService.contactValidate()])],
        password:['', Validators.required],
        conformpassword:['', Validators.required],
        role:['',Validators.required]
      },
      {
        validator: this.validationService.MatchPassword('password', 'conformpassword'),
      })
   }
  
   get f() { return this.form.controls; }
   showMsg: boolean = false;
  submitForm() {
   
    this.submitted = true;
    console.log('this role submit', this.role)
        // stop here if form is invalid
        
        if (this.form.invalid) {
            return;
        }
        else{
          let Formpostdata = this.form.value;
          /* if(this.role == '2'){
            Formpostdata.role=3
            console.log('form', Formpostdata)
          } */
          console.log(this.form.value)
          //this.http.post('http://localhost:4000/createusers/', Formpostdata)
          this.apiService.post('/createusers/',Formpostdata)
          .subscribe(
        
            /* (response) => {
              console.log('success response', response)
              this.showMsg= true
              this.router.navigate(['/dashboard/usersdetails'])
            }, */
            (response) =>{
              console.log("upadelocation",response)
              this.snackbar.open('User Save Successful', 'Dismiss', {
              verticalPosition: 'bottom',
              horizontalPosition: 'left',
            }); 
            window.location.href = EndPoints.BASE_URL+'dashboard/usersdetails';
           //setTimeout(() => this.router.navigate(['/dashboard/company']), 1750)
            },
            
            (error) => console.log(error)
          ); 
        }
        
        
  }

  cancelForm(): void {
    this.router.navigate(['/dashboard/usersdetails'])
  }
/* 
  ngOnInit(): void {
  } */

 
  ngOnInit(): void {
    //this.http.get('http://localhost:4000/createusers/')
    this.role = this.authService.getRoleToken()
    if(this.role == '3') this.router.navigate(['/dashboard/unassigned'])
    else if(this.role =='1'){
      this.isSuperAdmin = true
    }
    this.apiService.get('/createusers/')
    .subscribe(Response => {
 
      // If response comes hideloader() function is called
      // to hide that loader
      if(Response){ 
        //hideloader();
      }
      console.log("datatest=>",Response)
      this.li=Response;
      this.lis=this.li;
      console.log("datatest1=>",this.lis)
     
      
    });
   
  }

}

