import { Injectable } from '@angular/core';
import {
  catchError,
  mergeMap,
  map,
  take,
  switchMap,
  exhaustMap,
  toArray,
  publish,
  bufferCount,
} from 'rxjs/operators';
import { of, forkJoin, from, Observable } from 'rxjs';
import { createEffect, Actions, ofType, act } from '@ngrx/effects';
import { PatientsService } from 'src/app/services';
import { PatientsActions } from '../actions';
import { AgmGeocoder } from '@agm/core';
import lodash from 'lodash';
import { Patient } from 'src/app/models';
import { FiltersFacade } from 'src/app/services/facades/filters.facade';
import { Action } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class PatientsEffects {
  loadPatients$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PatientsActions.loadPatients), // create new action for this
      mergeMap((action) =>
        forkJoin([
          this.patientService.getPatients({ hasSchedule: true }),
          this.patientService.getPatients({ hasSchedule: false })
        ]).pipe(
          // mergeMap(([scheduled, unsheduled]) => from([ ...scheduled, ...unsheduled ]).pipe(
          //   bufferCount(20),
          //   map((patients) => {
          //     return
          //   })
          // )),
          
          map(([scheduled, unsheduled]) => {
            const patients = [ ...scheduled, ...unsheduled ];

            new Promise(() => {}).then(() => {
              return PatientsActions.loadPatientsSuccess({ patients: scheduled })
            })

            return PatientsActions.loadPatientsSuccess({ patients: scheduled })
          })
        ))
    )
  );

//   geocodePatients$ = createEffect(() =>
//       this.actions$.pipe(
//           ofType(PatientsActions.patientsGeocoding),
//           map(action => {
//               let p: Patient[] = lodash.cloneDeep(action.patients);

//               console.log(p)
//               p.forEach(patient => {
//                   this.geocoder.geocode({ address: '10409 Buffapple Dr, Richmond, VA 23233' }).pipe(
//                       take(1),
//                       map(result => {
//                           let location = {
//                               lat: result[0].geometry.location.lat(),
//                               lng: result[0].geometry.location.lng(),
//                           }
//                           console.log(location);
//                           let pa = lodash.cloneDeep(patient);

//                           pa.location = location;
//                           // pat.push(pa);
//                       })
//                   ).subscribe()
//               });

//               return PatientsActions.patientsGeocodingSuccess({ patients: p })
//           }),

//       map(patient => {
//           let p = lodash.cloneDeep(patient);
//           this.geocoder.geocode({ address: '10409 Buffapple Dr, Richmond, VA 23233' }).pipe(
//               take(1),
//               map(result => {
//                   let location = {
//                       lat: result[0].geometry.location.lat(),
//                       lng: result[0].geometry.location.lng()
//                   }
//                   p.location = location;
//                   return result;
//               })
//           ).subscribe();
//           return patient;
//       }),
//       toArray(),
//   ).pipe(
//       map(action => {
//           console.log(action)
//           return PatientsActions.patientsGeocodingSuccess({ patients: action })
//       })
//   ))

//   // Runs when '[Patients] Geocode Locations' is dispatched
//   // Should take in observable action containing patients array and return new action containing patients with location data
//   geocodePatients$  = createEffect(() =>
//   this.actions$.pipe(
//       ofType(PatientsActions.patientsGeocoding),
//       mergeMap(action => {
//           let locations = action.patients.map(patient => this.geocoder.geocode({ address: '9020 Quioccasin Rd Ste B, Richmond, VA 23229'}))
//           console.log(locations) // array of location observables
//           return forkJoin(...locations).pipe(
//               map(locationArray => {
//                   console.log('test...') // never gets here
//                   action.patients.forEach((patient, i) => {
//                       patient.location = locationArray[i];
//                   })

//                   return PatientsActions.patientsGeocodingSuccess({ patients: action.patients }); // should return this after all patients have been updated
//               }),
//               catchError(error => of(PatientsActions.patientsGeocodingError({ error }))) // should return this if an error occured while goecoding
//           )
//       }
//   )))

//   geocodePatients$  =createEffect(() =>
//       this.actions$.pipe(
//           ofType(PatientsActions.patientsGeocoding),
//           mergeMap(action =>
//               from(action.patients).pipe(
//                   mergeMap(
//                       patient =>
//                           this.geocoder.geocode('').pipe(
//                               map(result => ({ ...patient, location: { lat: 0, lng: 0 } }))
//                           ),
//                           toArray()
                      
//                   )
//               ))
//       ))

//   geocodePatients$ = createEffect(() =>
//       this.actions$.pipe(
//           ofType(PatientsActions.patientsGeocoding),
//           map(action => action.patients.map(patient => {
//               this.geocoder.geocode({ address: '8990 Midway Road, Richmond VA, 23229'})
//                   .pipe(
//                       take(1),
//                       map(result => {
//                           console.log(result);
//                           return result;
//                       }),
                      
//                   )
//           })),
//           map(patients => PatientsActions.patientsGeocodingSuccess({ patients }))
//       )
//   )

//   geocodePatients$ = createEffect(() =>
//       this.actions$.pipe(
//           ofType(PatientsActions.patientsGeocoding),
//           exhaustMap(action => {
//               let patients = [];

//               action.patients.forEach(patient => {
//                   this.geocoder.geocode({ address: '8990 Midway Road, Richmond VA, 23229'})
//                       .pipe(
//                           // take(1),
//                           map(result => {
//                               console.log(lodash);
//                               let p = lodash.cloneDeep(patient)
//                               patients.push({ ...p})
//                           })
//                       ).subscribe()
//               })

//               console.log(patients);

//               return of(PatientsActions.patientsGeocodingSuccess({ patients }))
//           })
//       ))

  constructor(
    private actions$: Actions,
    private filtersFacade: FiltersFacade,
    private patientService: PatientsService,
    private geocoder: AgmGeocoder
  ) {}
}
