import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'wtls-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {
  @Input() options: string[];
  @Input() placeholder: string;
  @Input() value;
  @Input() selected: string;
  @Output() valueChange = new EventEmitter();

  open: boolean

  constructor() { }

  ngOnInit(): void { }

  valueChanged(value): void {
    console.log(value);
    this.valueChange.emit(value);
  }
}