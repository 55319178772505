import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

import {AfterViewInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { FormBuilder, FormGroup,Validators  } from "@angular/forms";
import { EndPoints } from 'src/app/constants/endpoints';
import { ApiService } from 'src/app/services';

@Component({
  selector: 'wtls-usersdetails',
  templateUrl: './usersdetails.component.html',
  styleUrls: ['./usersdetails.component.scss']
})
export class UsersdetailsComponent implements OnInit {
  form: FormGroup;
dataset = [];
  loading = true
  displayedColumns: string[] = ["S No.","Fullname","Email","Address","Contact No","Action"];
  pageSize = 10
  pageSizeOptions = [10, 25, 50, 100];
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  
  li
  rows = [];

  /* ngOnInit() {
    this.fetch((data) => {
      this.rows = data;
    });
  } */
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private router: Router) { }

  ngOnInit(): void {
    /* this.fetch((data) => {
      this.rows = data;
    }); */
    this.apiService.get('/createusers/')
    .subscribe(Response => {
 
      // If response comes hideloader() function is called
      // to hide that loader,
      if(Response){ 
        //hideloader();
        this.loading = false
      }
      console.log("datatest=>",Response)
      this.li=Response;
      this.dataSource.data=this.li;
    
      console.log("datatest1=>",this.dataSource)
      return this.dataset;
      
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  /* fetch(cb) {
    const req = new XMLHttpRequest();
    req.open('GET', EndPoints.BASE_URL+`createusers/`);

    req.onload = () => {
      const data = JSON.parse(req.response);
      cb(data);
    };

    req.send();
  } */
 
  goToDrivePage(id : any){
    console.log("testingid=>",id);
    let editusersid = id;

    this.apiService.get('/createusers/'+editusersid)
    .subscribe(Response => {
 
      // If response comes hideloader() function is called
      // to hide that loader,
      if(Response){ 
        hideloader();
      }
      console.log("edit=>",Response)
      this.li=Response;
      this.dataSource=this.li;
     //this.dataset = this.da;
    
      console.log("datatest1=>",this.dataSource)
      return this.dataset;
      
    });
    function hideloader(){
      document.getElementById('loading').style.display = 'none';}

}

 submitForm() {
   
      console.log("tested=>");
       /*  this.http.put('http://localhost:4000/createusers/', Formpostdata).subscribe(
      
          (response) => console.log("Users Edited"),
          
          (error) => console.log(error)
        );  */
      
} 


Toogle(){
  console.log("toogle ok");
  alert("okkkkk");
}

delete(id : any)
{
  console.log("delete=>",id);
    let deleteid = id;
    
         this.apiService.delete('/createusers/'+ deleteid).subscribe(
      
          (response) => console.log(window.location.reload()),
          
          (error) => console.log(error)
        );  
}

 block(id : any)
{
  console.log("delete=>",id);
    let blocked = id;
   let actions ='1';

   console.log("testggfgfg=>",actions,blocked);
   
         this.apiService.put('/createusers/'+ blocked,actions).subscribe(
      
          (response) => console.log(window.location.reload()),
          
          (error) => console.log(error)
        );  
}

edit(id: any){
  this.router.navigate(['/dashboard/usersedit/'+id])
}


}




export interface PeriodicElement {
  fullname: string;
  email: string;
  Address: string;
  contactno: string;
 
}

/* const ELEMENT_DATA: PeriodicElement[] = [ 
   {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
  {position: 11, name: 'Sodium', weight: 22.9897, symbol: 'Na'},
  {position: 12, name: 'Magnesium', weight: 24.305, symbol: 'Mg'},
  {position: 13, name: 'Aluminum', weight: 26.9815, symbol: 'Al'},
  {position: 14, name: 'Silicon', weight: 28.0855, symbol: 'Si'},
  {position: 15, name: 'Phosphorus', weight: 30.9738, symbol: 'P'},
  {position: 16, name: 'Sulfur', weight: 32.065, symbol: 'S'},
  {position: 17, name: 'Chlorine', weight: 35.453, symbol: 'Cl'},
  {position: 18, name: 'Argon', weight: 39.948, symbol: 'Ar'},
  {position: 19, name: 'Potassium', weight: 39.0983, symbol: 'K'},
  {position: 20, name: 'Calcium', weight: 40.078, symbol: 'Ca'},  
]; */
