import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import { selectPatientsList } from "./patients.selectors";
import { selectProvidersList } from "./providers.selectors";
import { selectMatchesList } from "./matches.selectors";
import lodash from "lodash";

export const selectFilters = (state: AppState) => state.filters;

export const selectClinicLocations = createSelector(
  selectPatientsList,
  selectProvidersList,
  selectMatchesList,
  (patients, providers, matches) => {
    if (patients.length > 0 || providers.length > 0) {
      let c1: string[] = [];
      let c2: string[] = [];

      patients.forEach((patient) => {
        c1.push(patient.clinicLocation);
      });
      providers.forEach((provider) => {
        c2.push(provider.clinicLocation);
      });

      return lodash.uniq([...c1, ...c2]);
    } else if (matches.length > 0) {
      console.log(matches);
      let c1: string[] = [];
      let c2: string[] = [];

      matches.forEach((match) => {
        c1.push(match.patient.clinicLocation);
        c2.push(match.provider.clinicLocation);
      });

      return lodash.uniq([...c1, ...c2]);
    }
  }
);

export const selectAgeFilter = (state: AppState) => state.filters.age


export const selectClientTypesFilter = (state: AppState) => state.filters.clientTypes

export const selectstartTimeFilter = (state: AppState) => state.filters.startTime




export const selectstartTime = createSelector(
  selectPatientsList,
  selectProvidersList,
  selectMatchesList,
  (patients, providers, matches) => {
    if (patients.length > 0 || providers.length > 0) {
      let c1: string[] = [];
      let c2: string[] = [];

      patients.forEach((patient) => {
        c1.push(patient.startTime);
      });
      providers.forEach((provider) => {
        c2.push(provider.startTime);
      });

      return lodash.uniq([...c1, ...c2]);
    } else if (matches.length > 0) {
      console.log(matches);
      let c1: string[] = [];
      let c2: string[] = [];

      matches.forEach((match) => {
        c1.push(match.patient.startTime);
        c2.push(match.provider.startTime);
      });

      return lodash.uniq([...c1, ...c2]);
    }
  }

)
export const selectendTime = createSelector(
  selectPatientsList,
  selectProvidersList,
  selectMatchesList,
  (patients, providers, matches) => {
    if (patients.length > 0 || providers.length > 0) {
      let c1: string[] = [];
      let c2: string[] = [];

      patients.forEach((patient) => {
        c1.push(patient.endTime);
      });
      providers.forEach((provider) => {
        c2.push(provider.endTime);
      });

      return lodash.uniq([...c1, ...c2]);
    } else if (matches.length > 0) {
      console.log(matches);
      let c1: string[] = [];
      let c2: string[] = [];

      matches.forEach((match) => {
        c1.push(match.patient.endTime);
        c2.push(match.provider.endTime);
      });

      return lodash.uniq([...c1, ...c2]);
    }
  }

)
export const selectAllClientTypes = createSelector(
    selectPatientsList,
    (patients) => {
        let clientTypes: string[] = [];

        patients.forEach(patient => {
            clientTypes.push(patient.clientType);
        })

        return lodash.uniq([ ...clientTypes ]);
    }
)

export const selectClientTypes = createSelector(
  selectAllClientTypes,
  selectClientTypesFilter,
  (allClientTypes: string[], selectedClientTypes) => {
    let types = []

    allClientTypes.forEach(clientType => {
      types.push({
        name: clientType,
        value: selectedClientTypes.includes(clientType)
      })
    })

    return types;
  }
)

export const selectInsuranceTypesFilter = (state: AppState) => state.filters.insuranceTypes;

export const selectAllInsuranceTypes = createSelector(
    selectPatientsList,
    (patients) => {
        let instyps: string[] = [];

        patients.forEach(patient => {
            instyps.push(patient.insuranceType);
        })

        return lodash.uniq([ ...instyps ]);
    }
)

export const selectInsuranceTypes = createSelector(
  selectAllInsuranceTypes,
  selectInsuranceTypesFilter,
  (allInsuranceTypes: string[], selectInsuranceTypes) => {
    let types = []

    allInsuranceTypes.forEach(insuranceType => {
      types.push({
        name: insuranceType,
        value: selectInsuranceTypes.includes(insuranceType)
      })
    })

    return types;
  }
)

export const selectIntakeStagesFilter = (state: AppState) => state.filters.intakeStages;

export const selectAllIntakeStages = createSelector(
  selectPatientsList,
  (patients) => {
    let stages: string[] = [];

    patients.forEach(patient => {
      stages.push(patient.intakeStage);
    })
  
    return lodash.uniq([ ...stages ]);  
  }
)

export const selectIntakeStages = createSelector(
  selectAllIntakeStages,
  selectIntakeStagesFilter,
  (allIntakeStages: string[], selectedIntakeStages) => {
    let stages = []

    allIntakeStages.forEach(intakeStage => {
      stages.push({
        name: intakeStage,
        value: selectedIntakeStages.includes(intakeStage)
      })
    })

    return stages;
  }
)

export const selectEmployeeStatusesFilter = (state: AppState) => state.filters.employeeStatuses

export const selectAllEmployeeStatuses = createSelector(
  selectProvidersList,
  (providers) => {
    let statuses: string[] = [];

    providers.forEach(provider => {
      statuses.push(provider.employeeStatus);
    })

    return lodash.uniq([ ...statuses ]);
  }
)

export const selectEmployeeStatuses = createSelector(
  selectAllEmployeeStatuses,
  selectEmployeeStatusesFilter,
  (allEmployeeStatuses: string[], selectedEmployeeStatuses) => {
    let statuses = []

    allEmployeeStatuses.forEach(employeeStatus => {
      statuses.push({
        name: employeeStatus,
        value: selectedEmployeeStatuses.includes(employeeStatus)
      })
    })

    return statuses;
  }
)

export const selectOnboardingStagesFilter = (state: AppState) => state.filters.onboardingStages;

export const selectAllOnboardingStages = createSelector(
  selectProvidersList,
  (providers) => {
    let stages: string[] = [];

    providers.forEach(provider => {
      stages.push(provider.onboardingStage);
    })
  
    return lodash.uniq([ ...stages ]);  
  }
);

export const selectOnboardingStages = createSelector(
  selectAllOnboardingStages,
  selectOnboardingStagesFilter,
  (allOnboardingStages: string[], selectedOnboardingStages) => {
    let stages = []

    allOnboardingStages.forEach(onboardingStages => {
      stages.push({
        name: onboardingStages,
        value: selectedOnboardingStages.includes(onboardingStages)
      })
    })

    return stages;
  }
);

export const selectCredentialingStagesFilter = (state: AppState) => state.filters.credentialingStages;

export const selectAllCredentialingStages = createSelector(
  selectProvidersList,
  (providers) => {
    let stages: string[] = [];

    providers.forEach(provider => {
      stages.push(provider.credentialingStage);
    })
  
    return lodash.uniq([ ...stages ]);  
  }
);

export const selectCredentialingStages = createSelector(
  selectAllCredentialingStages,
  selectCredentialingStagesFilter,
  (allCredentialingStages: string[], selectedCredentialingStages) => {
    let stages = []

    allCredentialingStages.forEach(credentialingStages => {
      stages.push({
        name: credentialingStages,
        value: selectedCredentialingStages.includes(credentialingStages)
      })
    })

    return stages;
  }
)

export const selectPatientsHasSchedule = createSelector(
  selectFilters,
  (filters) => filters.patientsHasSchedule
)

export const selectProvidersHasSchedule = createSelector(
  selectFilters,
  (filters) => filters.providersHasSchedule
)