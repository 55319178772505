import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
      const token: string = this.authService.getAuthToken();
      
      if (token) {
        const cloneReq = req.clone({ headers: req.headers.set('X-AUTH-TOKEN', token) });

        return next.handle(cloneReq);
      } else {
        return next.handle(req);
      }
  }
}