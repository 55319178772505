import { Injectable } from '@angular/core';
import { EndPoints } from 'src/app/constants/endpoints';
import { ApiService } from '..';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(
    private apiService: ApiService
  ) { }

  saveBooking(bookingDetails) {
    return this.apiService.post<any>(EndPoints.BOOKING.SAVE, bookingDetails)
  }
}
