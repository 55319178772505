import { createAction, props } from '@ngrx/store';

export enum FiltersActionTypes {
    UPDATE_UNASSIGNED_FILTERS = '[Filters] Update Unassigned Filters',
    UPDATE_ASSIGNED_FILTERS = '[Filters] Update Assigned Filters',
    RESET_UNASSIGNED_FILTERS = '[Filters] Reset Unassigned Filters',
    RESET_ASSIGNED_FILTERS = '[Filters] Reset Assigned Filters'
}

export const updateUnassignedFilters = createAction(
    FiltersActionTypes.UPDATE_UNASSIGNED_FILTERS,
    props<{
            name: string,
            patientName: string,
            providerName: string,
            address: string,
            city: string,
            state: string,
            zip: string,
            clinicLocation: string,
            day:string,
            startTime: string,
            endTime: string,
    
            // Patients
            age: {
                min: string,
                max: string
            },
            clientTypes: string[],
            insuranceTypes: string[],
            intakeStages: string[],
            patientsHasSchedule: boolean,
    
            // Providers
            employeeStatuses: string[],
            onboardingStages: string[],
            credentialingStages: string[],
            providersHasSchedule: boolean
    }>()
)

export const updateAssignedFilters = createAction(
    FiltersActionTypes.UPDATE_ASSIGNED_FILTERS,
    props<{
            name: string,
            patientName: string,
            providerName: string,
            address: string,
            city: string,
            state: string,
            zip: string,
            clinicLocation: string,
            day:string,
            startTime:string,
            endTime:string
    
            // Patients
            age: {
                min: string,
                max: string
            },
            clientTypes: string[],
            insuranceTypes: string[],
            intakeStages: string[],
    
            // Providers
            employeeStatuses: string[],
            onboardingStages: string[],
            credentialingStages: string[]
    }>()
)

export const resetUnassignedFilter = createAction(
    FiltersActionTypes.RESET_UNASSIGNED_FILTERS
)

export const resetAssignedFilter = createAction(
    FiltersActionTypes.RESET_ASSIGNED_FILTERS
)