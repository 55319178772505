export const EndPoints = {
    AUTH: {
        LOGIN: '/auth/login',
        RESET_PASSWORD: '/auth/reset-password',
    },
    PATIENTS: {
        GET: '/patients',
        POST: '/patients/csv',
        //PUT: '/patients/',
        PUT: '/clientshow/',
        DELETE: '/patients/',
        GET_STATUS: '/patients/status'
    },
    PROVIDERS: {
        GET: '/providers',
        POST: '/providers/saveprovider',
        //PUT: '/providers/',
        PUT: '/providersshow/',
        DELETE: '/providers/',
        GETOPTIONS:'/providers/getupdateproviderdetail',
        APPOINTMENT:'/providers/saveapointmentdetails',
        GETAPPOINTMENT:'/providers/getapointmentdetails/details',
        UPLOADCSV: '/providers/csv',
        GET_STATUS: '/providers/status',
        SAVE_PROVIDERS:'/providers/save-providers'
    },
    PROVIDERSSHOW: {
        EXPORTCSV: '/providersshow/exportcsv'
    },
    MATCHES: {
        GET: '/matches',
        GET_CSV: '/matches/csv',
        POST: '/matches',
        DELETE: '/matches/',
        GET_PROVIDER_DETAIL:'/matches/getproviderdetails'
    },
    NEW_CLIENTS: {
        FORM: '/forms/',
        GET: '/responses',
        GET_CSV: '/responses/csv'
    },
    BOOKING: {
        SAVE: '/booking/savebooking'
    },
    FORM: '/forms/',
    POST_FORM: '/responses',
    USER : {
        GET_USER: '/createusers'
    },
    // BASE_URL: 'http://localhost:4200/'
    BASE_URL: 'https://abaschedulezipper.com/'
}