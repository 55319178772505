import { Component, OnInit } from '@angular/core';
import { FiltersState } from 'src/app/store/reducers';
import { Subscription } from 'rxjs';
import { FiltersFacade } from 'src/app/services/facades/filters.facade';
import lodash from 'lodash';
import { MatchesFacade } from 'src/app/services/facades/matches.facade';
import { map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'wtls-assigned-filter-bar',
  templateUrl: './assigned-filter-bar.component.html',
  styleUrls: ['./assigned-filter-bar.component.scss']
})
export class AssignedFilterBarComponent implements OnInit {
  filtersState: FiltersState;

  clinicLocationOptions: string[];

  subscriptions = new Subscription();

  constructor(
    private filtersFacade: FiltersFacade,
    private matchesFacade: MatchesFacade
  ) { }

  ngOnInit(): void {
    const sub1 = this.filtersFacade.getFilters().subscribe((filters) => {
      this.filtersState = lodash.cloneDeep(filters);
    });

    const sub2 = this.matchesFacade
      .getAllMatches().pipe(
        map(matches => {
          let c1: string[] = [];

          matches.forEach((match) => {
            c1.push(match.patient.clinicLocation);
            c1.push(match.provider.clinicLocation);
          });

          this.clinicLocationOptions = lodash.uniq(c1.filter(filter => !(filter == '')))
        })
      )
      .subscribe()

    // const sub2 = this.filtersFacade
    //   .getAllClinicLocations()
    //   .subscribe((clinicLocations) => {
    //     this.clinicLocationOptions = clinicLocations;
    //   });

    this.subscriptions.add(sub1);
    this.subscriptions.add(sub2);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updateFilters(filters: FiltersState): void {
    this.filtersFacade.updateAssignedFilters(filters);

    this.matchesFacade.loadMatches();
  }
}
