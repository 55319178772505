import { createAction, props } from '@ngrx/store';
import { Patient, Provider, Match, TimeSlot } from '../../models';

export enum MatchActionTypes {
  SELECT_PATIENT = '[Match] Select Patient',
  SELECT_PROVIDER = '[Match] Select Provider',
  SELECT_MATCH = '[Match] Select Match',
  CHANGE_SCHEDULE = '[Match] Change Schedule',
  CLEAR_SCHEDULE = '[Match] Clear Schedule',
  RESET_MATCH = '[Match] Reset Match'
}


export const selectPatient = createAction(
  MatchActionTypes.SELECT_PATIENT,
  props<{ patient: Patient }>()
);

export const selectProvider = createAction(
  MatchActionTypes.SELECT_PROVIDER,
  props<{ provider: Provider }>()
);

export const selectMatch = createAction(
  MatchActionTypes.SELECT_MATCH,
  props<{ match: Match }>()
)

export const changeSchedule = createAction(
  MatchActionTypes.CHANGE_SCHEDULE,
  props<{ schedule: TimeSlot[] }>()
)

export const clearSchedule = createAction(
  MatchActionTypes.CLEAR_SCHEDULE
)

export const resetMatch = createAction(
  MatchActionTypes.RESET_MATCH
)