import { Injectable} from '@angular/core'
import { Observable } from 'rxjs';
import { BookingService } from '../booking/booking.service';


@Injectable({
    providedIn: 'root',
})
export class BookingFacade{

    constructor(
        private bookingService: BookingService
    ){}

    saveBooking(bookingDetails): Observable<any>{
        return this.bookingService.saveBooking(bookingDetails)
    }
}