import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiService, ProvidersService } from 'src/app/services';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator'; 
import { ProvidersFacade } from 'src/app/services/facades/providers.facade';
import { EndPoints } from 'src/app/constants/endpoints';
import { map } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'wtls-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  
  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private http: HttpClient,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private providersFacade: ProvidersFacade,
    private providersService: ProvidersService,
    private snackbar: MatSnackBar,
  ) { 
    this.form = this.formBuilder.group({
      First_Name: ['' ],
      Last_name: [''],
      //adminEmail: ['',[Validators.required,Validators.email]],
      phone:[''],
      email:[''],
      timezoneName:[''],
      addressLine1:[''],
      addressLine2:[''],
      city:[''],
      state:[''],
      country:[''],
      mon:[''],
      postalCode:[''],
      monstarttime:[''],
      monendtime:[''],
      tueday:[''],
      tuestarttime:[''],
      tueendtime:[''],
      Wedday:[''],
      Wedstarttime:[''],
      Wedendtime:[''],
      Thuday:[''],
      Thustarttime:[''],
      Thuendtime:[''],
      friday:[''],
      fristarttime:[''],
      friendtime:[''],


    })
  }
  get f() { return this.form.controls; }
  ngOnInit(): void {
  }

  submitForm() {
   
    this.submitted = true;
    console.log("kjh=>",this.form.invalid);
        // stop here if form is invalid
        if (this.form.invalid) {

            return;
        }
        else{
          let Formpostdata = this.form.value;
          console.log("testadmin",this.form.value)
          this.apiService.post('/clientlocations', Formpostdata).subscribe(
            (response) =>{
              this.snackbar.open('Client locations Created Successfull!', 'Dismiss', {
              verticalPosition: 'bottom',
              horizontalPosition: 'left',
            }); 
            window.location.href = EndPoints.BASE_URL+'dashboard/company';
            },
            //(response) => console.log(this.router.navigate(['dashboard/createcompanys']),this.showMsg= true),
            
            (error) => console.log(error)
          ); 
        }
        
        
  }

}
