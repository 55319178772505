import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup,Validators  } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EndPoints } from 'src/app/constants/endpoints';

@Component({
  selector: 'wtls-editcompany',
  templateUrl: './editcompany.component.html',
  styleUrls: ['./editcompany.component.scss']
})
export class EditcompanyComponent implements OnInit {
  form: FormGroup;
  id: number;
  li:any;
  lis=[];
  selectlist:any;
  selectlistorders=[];
  dataset = [];
  data:any;
  locationlist:any;
  locationsselectlistorders=[];
  submitted = false;
  constructor(private router: Router,
     private route: ActivatedRoute,
      private formBuilder: FormBuilder,
      private http: HttpClient,
      private snackbar: MatSnackBar,
       private apiService: ApiService) { 
    this.form = this.formBuilder.group({
      comapny_name: new FormControl(),
      company_email: new FormControl(),
      company_address: new FormControl(),
      companay_opening_time: new FormControl(),
      clinic_location_id: new FormControl(),
      form_sub_title_id: new FormControl(),
      company_contactno: new FormControl(),
      company_url: new FormControl(),
      user_id: new FormControl(),
      company_closing_time: new FormControl(),
      form_title_id: new FormControl()


    })
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id']; 
    });
   

    this.apiService.get('/createcompany/'+this.id)
    .subscribe(Response => {
 
      if(Response){ 
        console.log("edit=>",Response)
      }
      console.log("edit=>",Response)
      this.li=Response;
      this.lis=this.li;
     this.dataset = this.lis;
     this.data = this.lis;
     this.form = this.formBuilder.group({
      comapny_name : [this.lis[0]?.comapny_name],
      company_email : [this.lis[0]?.company_email],
      company_address : [this.lis[0]?.company_address],
      companay_opening_time : [this.lis[0]?.companay_opening_time],
      clinic_location_id : [this.lis[0]?.clinic_location_id],
      form_sub_title : [this.lis[0]?.form_sub_title],
      company_contactno : [this.lis[0]?.company_contactno],
      company_url : [this.lis[0]?.company_url],
       user_id : [this.lis[0]?.user_id],
      company_closing_time: [this.lis[0]?.company_closing_time],
      form_title: [this.lis[0]?.form_title]
     })
      console.log("datatest1=>",this.data)
      return this.dataset;
      
    });
////get users
this.apiService.get('/createusers/')
.subscribe(Response => {
  if(Response){ 
  }
  console.log("asd=>",Response)
  this.selectlist=Response;
  this.selectlistorders=this.selectlist;
  console.log("asd=>",this.selectlistorders)
});

////get locations
this.apiService.get('/cliniclocations/')
.subscribe(Response => {
  if(Response){ 
  }
  console.log("datatest=>",Response)
  this.locationlist=Response;
  this.locationsselectlistorders=this.locationlist;
  console.log("datatest1=>",this.locationsselectlistorders)
});
 
  }
  get f() { return this.form.controls; }
  showMsg: boolean = false;
  submitForm() {

      let Formpostdata = this.form.value;
      console.log("test=>",this.id)
         // this.http.put('http://localhost:4000/createcompany/'+this.id,Formpostdata).subscribe(
            this.apiService.put('/createcompany/'+this.id,Formpostdata).subscribe(
          
        
            /* (response) => {
              this.showMsg= true
              console.log('res==>>>', response)
              setTimeout(() => this.router.navigate(['/dashboard/company']), 1750)
            }, */
            (response) =>{
              console.log("upadelocation",response)
              this.snackbar.open('Company Update Successful', 'Dismiss', {
              verticalPosition: 'bottom',
              horizontalPosition: 'left',
            }); 
            window.location.href = EndPoints.BASE_URL+'dashboard/company';
           //setTimeout(() => this.router.navigate(['/dashboard/company']), 1750)
            },
            
            (error) => console.log(error)
          );  
   
  }

selectusers()
{
  //this.http.get('http://localhost:4000/createusers/')
  this.apiService.get('/createusers/')

    .subscribe(Response => {
 
      // If response comes hideloader() function is called
      // to hide that loader
      if(Response){ 
        //hideloader();
      }
      console.log("dsd=>",Response)
      this.li=Response;
      this.lis=this.li;
      console.log("dsd=>",this.lis)
     
      
    });
   /*  function hideloader(){
      document.getElementById('loading').style.display = 'none';} */

}

cancelForm(): void {
  this.router.navigate(['/dashboard/company'])
}
  
}
