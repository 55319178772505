import { Injectable} from '@angular/core'
import { Observable } from 'rxjs';
import { UsersService } from '../users/users.service';


@Injectable({
    providedIn: 'root',
})
export class UsersFacade{

    constructor(
        private userServices: UsersService
    ){}

    getUser(id): Observable<any>{
        return this.userServices.getUser(id)
    }
}