import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(private snackbar: MatSnackBar) { }

  public downloadResource(url, filename?) {
    if (!filename) filename = url.split('\\').pop().split('/').pop();
    fetch(url, {
        headers: new Headers({
          'Origin': location.origin
        }),
        mode: 'cors'
      })
      .then(response => response.blob())
      .then(blob => {
        let blobUrl = window.URL.createObjectURL(blob);
        this.forceDownload(blobUrl, filename);
      })
      .catch(e => {
        console.error(e)
        this.snackbar.open(`ERROR: ${e}`, 'Dismiss', {
          verticalPosition: 'bottom',
          horizontalPosition: 'left',
        });
      });
  }

  private forceDownload(blob, filename) {
    var a = document.createElement('a');
    a.download = filename;
    a.href = blob;
    
    document.body.appendChild(a);
    a.click();
    a.remove();
  }
}
