import { ActionReducerMap } from '@ngrx/store';
import { AppState } from '../app.state';
import { patientsReducer } from './patients.reducer';
import { providersReducer } from './providers.reducer';
import { matchesReducer } from './matches.reducer';
import { matchReducer } from './match.reducer';
import { filterReducer } from './filters.reducer';
import { mapFullScreenReducer } from './fullScreen.reducer';
import { addEditPatientReducer } from './addEditPatient.reducer';
import { addEditProviderReducer } from './addEditProvider.reducer';
import { optionsReducer } from './options.reducer';
import { newClientsReducer } from './newClients.reducer';
import { formReducer } from './form.reducer';


// export const reducers: ActionReducerMap<AppState> = {
export const reducers = {
    patients: patientsReducer,
    providers: providersReducer,
    matches: matchesReducer,
    newClients: newClientsReducer,
    match: matchReducer,
    filters: filterReducer,
    mapFullScreen: mapFullScreenReducer,
    addEditPatient: addEditPatientReducer,
    addEditProvider: addEditProviderReducer,
    options: optionsReducer,
    form: formReducer
}