import { createAction, props } from '@ngrx/store';
import { Patient } from '../../models';

export enum AddEditPatientActionTypes {
    OPEN_PANEL = '[Add Edit Patient] Open Panel',
    CLOSE_PANEL = '[Add Edit Patient] Close Panel',
    SET_PATIENT = '[Add Edit Patient] Set Patient',
    CLEAR_PATIENT = '[Add Edit Patient] Clear Patient',
    UPDATE_PATIENT = '[Add Edit Patient] Update Patient',
    UPDATE_PATIENT_SUCCESS = '[Add Edit Patient] Update Patient Success',
    UPDATE_PATIENT_ERROR = '[Add Edit Patient] Update Patient Error',
}

export const openPanel = createAction(
    AddEditPatientActionTypes.OPEN_PANEL
)

export const closePanel = createAction(
    AddEditPatientActionTypes.CLOSE_PANEL
)

export const setPatient = createAction(
    AddEditPatientActionTypes.SET_PATIENT,
    props<{ patient: Patient }>()
)

export const clearPatient = createAction(
    AddEditPatientActionTypes.CLEAR_PATIENT
)

export const updatePatient = createAction(
    AddEditPatientActionTypes.UPDATE_PATIENT,
    props<{ patient: Patient }>()
)

export const updatePatientSuccess = createAction(
    AddEditPatientActionTypes.UPDATE_PATIENT_SUCCESS,
    // props<{ success: string }>()
)

export const updatePatientError = createAction(
    AddEditPatientActionTypes.UPDATE_PATIENT_ERROR,
    props<{ error: string }>()
)