import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import { BehaviorSubject, Observable } from 'rxjs';
import { Match, Patient, Provider, TimeSlot } from 'src/app/models';
import { MatchSelectors } from 'src/app/store/selectors';
import { MatchActions, MatchesActions } from 'src/app/store/actions';
import { MatchesService } from '../matches/matches.service';
import { PatientsService } from '../patients/patients.service';
import { ProvidersService } from '../providers/providers.service';

@Injectable({
  providedIn: 'root',
})
export class MatchFacade {
  private match$ = new BehaviorSubject<Match>(null);

  private patient$ = new BehaviorSubject<Patient>(null);

  private provider$ = new BehaviorSubject<Provider>(null);

  private schedule$ = new BehaviorSubject<TimeSlot[]>([]);

  private availability$ = new BehaviorSubject<TimeSlot[]>([]);

  constructor(
    private store: Store<AppState>,
    private patientsService: PatientsService,
    private providersService: ProvidersService,
    private matchesService: MatchesService
  ) {
    store.select(MatchSelectors.selectMatch).subscribe((match) => {
      this.match$.next(match);
    });

    store.select(MatchSelectors.selectMatchPatient).subscribe((patient) => {
      this.patient$.next(patient);
    });

    store.select(MatchSelectors.selectMatchProvider).subscribe((provider) => {
      this.provider$.next(provider);
    });

    store.select(MatchSelectors.selectMatchSchedule).subscribe((schedule) => {
      this.schedule$.next(schedule);
    });
  }

  getMatch(): Observable<Match> {
    return this.match$.asObservable();
  }

  selectMatch(match: Match): void {
    if (this.match$.value && this.match$.getValue().id == match.id)
      this.store.dispatch(MatchActions.resetMatch());
    else this.store.dispatch(MatchActions.selectMatch({ match }));
  }

  resetMatch(): void {
    this.setAvailability([]);

    this.store.dispatch(MatchActions.resetMatch());
  }

  getPatient(): Observable<Patient> {
    return this.patient$.asObservable();
  }

  selectPatient(patient: Patient): void {
    if (this.patient$.getValue() && this.patient$.getValue().id == patient.id) {
      if (this.provider$.getValue())
        this.store.dispatch(MatchActions.clearSchedule());

      if (this.availability$.getValue().length > 0)
        this.availability$.next([]);

      this.store.dispatch(MatchActions.selectPatient({ patient: null }));
    } else {
      this.patientsService.getPatient(patient.id).subscribe(
        (p) => {
          if (this.provider$.getValue())
            this.setFullSchedule(p, this.provider$.getValue());

          this.store.dispatch(MatchActions.selectPatient({ patient: p }));
        },
        (error) => {
          alert(error);
        }
      );
    }
  }

  clearPatient(): void {
    this.store.dispatch(MatchActions.selectPatient({ patient: null }));
  }

  getProvider(): Observable<Provider> {
    return this.provider$.asObservable();
  }

  selectProvider(provider: Provider): void {
    if (
      this.provider$.getValue() &&
      this.provider$.getValue().id == provider.id
    ) {
      if (this.patient$.getValue())
        this.store.dispatch(MatchActions.clearSchedule());

      if (this.availability$.getValue().length > 0)
        this.availability$.next([]);

      this.store.dispatch(MatchActions.selectProvider({ provider: null }));
    } else {
      this.providersService.getProvider(provider.id).subscribe(
        (p) => {
          if (this.patient$.getValue())
            this.setFullSchedule(this.patient$.getValue(), p);

          this.store.dispatch(MatchActions.selectProvider({ provider: p }));
        },
        (error) => {
          alert(error);
        }
      );
    }
  }

  clearProvider(): void {
    this.store.dispatch(MatchActions.selectProvider({ provider: null }));
  }

  getSchedule(): Observable<TimeSlot[]> {
    return this.schedule$.asObservable();
  }

  getAvailability(): Observable<TimeSlot[]> {
    return this.availability$.asObservable();
  }

  setAvailability(timeSlots: TimeSlot[]): void {
    this.availability$.next(timeSlots);
  }

  setFullSchedule(patient: Patient, provider: Provider): void {
    const availablePatientTimeSlots = patient.availability.filter((timeSlot) =>
      !patient.schedule.some(
        (ts) =>
          ts.dayOfWeek == timeSlot.dayOfWeek &&
          ts.startTime.getUTCHours() == timeSlot.startTime.getUTCHours() &&
          ts.endTime.getUTCHours() == timeSlot.endTime.getUTCHours()
      )
    )

    const availableProviderTimeSlots = provider.availability.filter((timeSlot) =>
      !provider.schedule.some(
        (ts) =>
          ts.dayOfWeek == timeSlot.dayOfWeek &&
          ts.startTime.getUTCHours() == timeSlot.startTime.getUTCHours() &&
          ts.endTime.getUTCHours() == timeSlot.endTime.getUTCHours()
      )
    )

    const schedule = availablePatientTimeSlots.filter((timeSlot) =>
      availableProviderTimeSlots.some(
        (ts) =>
          ts.dayOfWeek == timeSlot.dayOfWeek &&
          ts.startTime.getUTCHours() == timeSlot.startTime.getUTCHours() &&
          ts.endTime.getUTCHours() == timeSlot.endTime.getUTCHours()
      )
    )

    this.setAvailability(schedule);

    this.store.dispatch(MatchActions.changeSchedule({ schedule }));
  }

  changeTimeSlot(timeSlot: TimeSlot): void {
    let schedule = [...this.schedule$.getValue()];

    if (
      schedule.some(
        (ts) =>
          ts.dayOfWeek == timeSlot.dayOfWeek &&
          ts.startTime.getHours() == timeSlot.startTime.getHours()
      )
    ) {
      console.log(timeSlot);
      console.log(schedule);
      schedule = schedule.filter(
        (ts) =>
          ts.dayOfWeek != timeSlot.dayOfWeek ||
          ts.startTime.getHours() != timeSlot.startTime.getHours()
      );
    } else {
      schedule.push(timeSlot);
    }

    this.store.dispatch(MatchActions.changeSchedule({ schedule }));
  }
}
