<div class="assignment-container">
  <div class="section name-section">

    
      <div class="name-wrapper">
          <h5 class="name-heading">Client:</h5>
          <span *ngIf="!match.patient" class="name">Not Selected</span>
          <span *ngIf="match.patient && !match.patient.name" class="name selected"><em>No Name</em></span>
          <span *ngIf="match.patient && match.patient.name" class="name selected">{{ match.patient.name }}</span>
          
          
          
      </div>
      <div class="name-wrapper">
          <h5 class="name-heading">Provider:</h5>
          <span *ngIf="!match.provider" class="name">Not Selected</span>
          <span *ngIf="match.provider && !match.provider.name" class="name selected"><em>No Name</em></span>
          <span *ngIf="match.provider && match.provider.name" class="name selected">{{ match.provider.name }}</span>
      </div>
      
  </div>
 
   <!-- <div class="section time-section border-left">
    <wtls-time-picker [dayOfWeek]="1" [schedule]="match.schedule" [availability]="availability$ | async" (change)="changeSchedule($event)"></wtls-time-picker>
    <wtls-time-picker [dayOfWeek]="2" [schedule]="match.schedule" [availability]="availability$ | async" (change)="changeSchedule($event)"></wtls-time-picker>
    <wtls-time-picker [dayOfWeek]="3" [schedule]="match.schedule" [availability]="availability$ | async" (change)="changeSchedule($event)"></wtls-time-picker>
    <wtls-time-picker [dayOfWeek]="4" [schedule]="match.schedule" [availability]="availability$ | async" (change)="changeSchedule($event)"></wtls-time-picker>
    <wtls-time-picker [dayOfWeek]="5" [schedule]="match.schedule" [availability]="availability$ | async" (change)="changeSchedule($event)"></wtls-time-picker>
    <wtls-time-picker [dayOfWeek]="6" [schedule]="match.schedule" [availability]="availability$ | async" (change)="changeSchedule($event)"></wtls-time-picker>
    <wtls-time-picker [dayOfWeek]="7" [schedule]="match.schedule" [availability]="availability$ | async" (change)="changeSchedule($event)"></wtls-time-picker>  
  </div>  -->
  

  <div class="section time-section border-left">
  <div class="weekDays-selector">
    <!--<input type="radio" id="weekday_mon" name= "weekday1" value="Mon" checked="Ischecked"  class="weekday" (change)="updateFilters($event,'day')" />-->
    <input type="checkbox" id="weekday_mon" name= "weekday" value="Mon"   class="weekday"   (click)="updateFilters($event,'day')" />
    
    <label for="weekday_mon">Mon</label>
    <input type="checkbox" id="weekday_tue" name= "weekday" value="Tue" class="weekday" (click)="updateFilters($event,'day')" />
    <label for="weekday_tue">Tue</label>
    <input type="checkbox" id="weekday_wed" name= "weekday"  value="Wed" class="weekday"  (click)="updateFilters($event,'day')" />
    <label for="weekday_wed">Wed</label>
    <input type="checkbox" id="weekday_thu" name= "weekday" value="Thu"  class="weekday"  (click)="updateFilters($event,'day')" />
    <label for="weekday_thu">Thu</label>
    <input type="checkbox" id="weekday_fri" name= "weekday"  value="Fri"  class="weekday"  (click)="updateFilters($event,'day')" />
    <label for="weekday_fri">Fri</label>
    <input type="checkbox" id="weekday_sat" name= "weekday"  value="Sat"  class="weekday"  (click)="updateFilters($event,'day')" />
    <label for="weekday_sat">Sat</label>
    <input type="checkbox" id="weekday_sun" name= "weekday"  value="Sun"  class="weekday"  (click)="updateFilters($event,'day')" />
    <label for="weekday_sun">Sun</label>
    <!-- input type="radio" id="weekday_sat" name= "weekday" value="0" class="weekday" />
    <label for="weekday_sat">Sat</label -->
    <!--input type="radio" id="weekday_sun" name= "weekday" value="0" class="weekday"  />
    <label for="weekday_sun">Sun</label -->


  </div> 
  <div class="col-lg-12">
  <div class="section">
    <!--label for="appt">In</label>
  <input type="time" id="startappt" name="appt" >
  <label for="appt">Out</label>
  <input type="time" id="endappt" name="appt" -->
  
     <b>Time:</b>
     <select class="select-input" id="startappt" (change)="updateDateFilters($event,'startTime')">
      <option id="start" value="6:00 AM" selected>6:00 AM</option>
      <option *ngFor ="let slot of timeSlots" [value]="slot">{{slot}}</option> 
     </select>

     <select class="select-input" id="endappt" (change)="updateDateFilters($event,'endTime')">
      <option id="start" value="11:30 PM" selected>11:30 PM</option>
      <option *ngFor ="let slot of timeSlots" [value]="slot">{{slot}}</option> 
     </select>

      <!--<select class="select-input" id="startappt" (change)="updateDateFilters($event,'startTime')" >
        <option value="12.00 AM">12.00 AM</option>
        <option value="12.30 AM">12.30 AM</option>
        <option value="1.00 AM">1.00 AM</option>
        <option value="1.30 AM">1.30 AM</option>
        <option value="2.00 AM">2.00 AM</option>
        <option value="2.30 AM">2.30 AM</option>
        <option value="3.00 AM">3.00 AM</option>
        <option value="3.30 AM">3.30 AM</option>
        <option value="4.00 AM">4.00 AM</option>
        <option value="4.30 AM">4.30 AM</option>
        <option value="5.00 AM">5.00 AM</option>
        <option value="5.30 AM">5.30 AM</option>
        <option value="6.00 AM" selected="selected" id="start">6.00 AM</option>
        <option value="6.30 AM">6.30 AM</option>
        <option value="7.00 AM">7.00 AM</option>
        <option value="7.30 AM">7.30 AM</option>
        <option value="8.00 AM">8.00 AM</option>
        <option value="8.30 AM">8.30 AM</option>
        <option value="9.00 AM">9.00 AM</option>
        <option value="9.30 AM">9.30 AM</option>
        <option value="10.00 AM">10.00 AM</option>
        <option value="10.30 AM">10.30 AM</option>
        <option value="11.00 AM">11.00 AM</option>
        <option value="11.30 AM">11.30 AM</option>
        <option value="12.00 PM">12.00 PM</option>
        <option value="12.30 PM">12.30 PM</option>
        <option value="1.00 PM">1.00 PM</option>
        <option value="1.30 PM">1.30 PM</option>
        <option value="2.00 PM">2.00 PM</option>
        <option value="2.30 PM">2.30 PM</option>
        <option value="3.00 PM">3.00 PM</option>
        <option value="3.30 PM">3.30 PM</option>
        <option value="4.00 PM">4.00 PM</option>
        <option value="4.30 PM">4.30 PM</option>
        <option value="5.00 PM">5.00 PM</option>
        <option value="5.30 PM">5.30 PM</option>
        <option value="6.00 PM">6.00 PM</option>
        <option value="6.30 PM">6.30 PM</option>
        <option value="7.00 PM">7.00 PM</option>
        <option value="7.30 PM">7.30 PM</option>
        <option value="8.00 PM">8.00 PM</option>
        <option value="8.30 PM">8.30 PM</option>
        <option value="9.00 PM">9.00 PM</option>
        <option value="9.30 PM">9.30 PM</option>
        <option value="10.00 PM">10.00 PM</option>
        <option value="10.30 PM">10.30 PM</option>
        <option value="11.00 PM">11.00 PM</option>
        <option value="11.30 PM">11.30 PM</option>
        <option value="12.00 AM">12.00 AM</option>
       </select> -->
     
    
          <!-- <select class="select-input" id="endappt" (change)="updateDateFilters($event,'endTime')" >
            <option value="12.00 AM">12.00 AM</option>
            <option value="12.30 AM">12.30 AM</option>
            <option value="1.00 AM" >1.00 AM</option>
            <option value="1.30 AM" >1.30 AM</option>
            <option value="2.00 AM" >2.00 AM</option>
            <option value="2.30 AM" >2.30 AM</option>
            <option value="3.00 AM" >3.00 AM</option>
            <option value="3.30 AM" >3.30 AM</option>
            <option value="4.00 AM" >4.00 AM</option>
            <option value="4.30 AM" >4.30 AM</option>
            <option value="5.00 AM" >5.00 AM</option>
            <option value="5.30 AM" >5.30 AM</option>
            <option value="6.00 AM" >6.00 AM</option>
            <option value="6.30 AM" >6.30 AM</option>
            <option value="7.00 AM" >7.00 AM</option>
            <option value="7.30 AM" >7.30 AM</option>
            <option value="8.00 AM" >8.00 AM</option>
            <option value="8.30 AM" >8.30 AM</option>
            <option value="9.00 AM" >9.00 AM</option>
            <option value="9.30 AM" >9.30 AM</option>
            <option value="10.00 AM">10.00 AM</option>
            <option value="10.30 AM">10.30 AM</option>
            <option value="11.00 AM">11.00 AM</option>
            <option value="11.30 AM">11.30 AM</option>
            <option value="12.00 PM">12.00 PM</option>
            <option value="12.30 PM">12.30 PM</option>
            <option value="1.30 PM" >1.30 PM</option>
            <option value="2.00 PM" >2.00 PM</option>
            <option value="2.30 PM" >2.30 PM</option>
            <option value="3.00 PM" >3.00 PM</option>
            <option value="3.30 PM" >3.30 PM</option>
            <option value="4.00 PM" >4.00 PM</option>
            <option value="4.30 PM" >4.30 PM</option>
            <option value="5.00 PM" >5.00 PM</option>
            <option value="5.30 PM" >5.30 PM</option>
            <option value="6.00 PM" >6.00 PM</option>
            <option value="6.30 PM" >6.30 PM</option>
            <option value="7.00 PM" >7.00 PM</option>
            <option value="7.30 PM" >7.30 PM</option>
            <option value="8.00 PM" >8.00 PM</option>
            <option value="8.30 PM" >8.30 PM</option>
            <option value="9.00 PM" >9.00 PM</option>
            <option value="9.30 PM" >9.30 PM</option>
            <option value="10.00 PM">10.00 PM</option>
            <option value="10.30 PM">10.30 PM</option>
            <option value="11.00 PM">11.00 PM</option>
            <option value="11.30 PM" selected="selected" id="end">11.30 PM</option>
            <option value="12.00 AM">12.00 AM</option>
           </select> -->
         
        </div>  
  </div>  
  
</div>

  <div class="section selection-section border-left">
      <button
      id="btn"
      mat-flat-button
      type="button"
      color="primary"
      (click)="assignSelected()"
     
    >
     Search availability
    </button>
    <!-- <button
      mat-stroked-button
      type="button"
      (click)="clearSelection()"
      [disabled]="!match.patient && !match.provider"
    >
      Clear Selection
    </button> -->
    <button
      mat-stroked-button
      type="button"
      (click)="clearSelection()"
    
    >
      Clear Selection
    </button>
  </div>

</div>