import { createAction, props } from '@ngrx/store';
import { Answer, Form } from 'src/app/models';

enum FormActionTypes {
    LOAD_FORM = '[Form] Load Form',
    LOAD_FORM_SUCCESS = '[Forms API] Load Form Success',
    LOAD_FORM_ERROR = '[Forms API] Load Form Error',
    LOAD_FORM_RETRY = '[Form] Load Form Retry',

    SUBMIT_FORM = '[Form] Submit Form',
    SUBMIT_FORM_SUCCESS = '[Forms API] Submit Form Success',
    SUBMIT_FORM_ERROR = '[Forms API] Submit Form Error',
};

export const loadForm = createAction(
    FormActionTypes.LOAD_FORM,
    props<{ formID: number }>()
);

export const loadFormSuccess = createAction(
    FormActionTypes.LOAD_FORM_SUCCESS,
    props<{ form: Form }>()
);

export const loadFormError = createAction(
    FormActionTypes.LOAD_FORM_ERROR,
    props<{ error: string }>()
);

export const loadFormRetry = createAction(
    FormActionTypes.LOAD_FORM_RETRY,
    props<{ formID: number }>()
);

export const submitForm = createAction(
    FormActionTypes.SUBMIT_FORM,
    props<{ formID: number, answers: Answer[], recaptchaResponse: string }>()
);

export const submitFormSuccess = createAction(
    FormActionTypes.SUBMIT_FORM_SUCCESS,
    props<{ formID: number }>()
);

export const submitFormError = createAction(
    FormActionTypes.SUBMIT_FORM_ERROR,
    props<{ error: string }>()
);