import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { TimeSlot, Match } from 'src/app/models';

@Component({
  selector: 'wtls-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements OnInit {
  @Input() dayOfWeek: number;
  @Input() schedule: TimeSlot[]; // what slots are selected (blue)
  @Input() availability: TimeSlot[]; // what slots are available (not greyed out)
  @Input() disabled: boolean
  
  @Output() change = new EventEmitter<TimeSlot>();

  amStartTime: Date = new Date('2020-09-21T08:00:00.000Z');
  amEndTime: Date = new Date('2020-09-21T15:00:00.000Z');
  pmStartTime: Date = new Date('2020-09-21T15:00:00.000Z');
  pmEndTime: Date = new Date('2020-09-21T18:00:00.000Z');

  constructor() {}

  ngOnInit(): void {}

  amChanged(): void {
    const timeSlot = {
      id: -1,
      type: -1,
      dayOfWeek: this.dayOfWeek,
      startTime: this.amStartTime,
      endTime: this.amEndTime,
    };

    this.change.emit(timeSlot);
  }

  pmChanged(): void {
    const timeSlot = {
      id: -1,
      type: -1,
      dayOfWeek: this.dayOfWeek,
      startTime: this.pmStartTime,
      endTime: this.pmEndTime,
    };

    this.change.emit(timeSlot);
  }

  isAm(): boolean {
    return this.schedule.some(
      (timeSlot) =>
        timeSlot.dayOfWeek == this.dayOfWeek &&
        timeSlot.startTime.getHours() == this.amStartTime.getHours()
    );
  }

  isPm(): boolean {
    return this.schedule.some(
      (timeSlot) =>
        timeSlot.dayOfWeek == this.dayOfWeek &&
        timeSlot.startTime.getHours() == this.pmStartTime.getHours()
    );
  }

  isAmDisabled(): boolean {
    return (
      !this.availability ||
      this.availability.length == 0 ||
      !this.availability.some(
        (timeSlot) =>
          timeSlot.dayOfWeek == this.dayOfWeek &&
          timeSlot.startTime.getUTCHours() == this.amStartTime.getUTCHours()
      )
    );
  }

  isPmDisabled(): boolean {
    return (
      !this.availability ||
      this.availability.length  == 0 ||
      !this.availability.some(
        (timeSlot) =>
          timeSlot.dayOfWeek == this.dayOfWeek &&
          timeSlot.startTime.getUTCHours() == this.pmStartTime.getUTCHours()
      )
    );
  }
}