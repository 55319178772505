import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Observable, Subject } from 'rxjs';
import { FormResponse, Page } from 'src/app/models';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditClientsComponent } from './edit-clients/edit-clients.component';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services';
interface ColumnDef {
  name: string,
  label
}

@Component({
  selector: 'wtls-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {
  @Input() page: Page<FormResponse>;
  @Input() loading: boolean;

  @Output() pageChange = new EventEmitter<PageEvent>();
  @Output() export = new EventEmitter();
  @Output() sort = new EventEmitter<Sort>();
  @Output() download = new EventEmitter<string>();
  @Output() loadClients = new EventEmitter();
  

  rows: any[] = [];
  answer: any;
  displayedColumns: string[] = ["First Name", "Last Name", "Birth Date", "Gender", "Diagnosis", "Date of Diagnosis", "Clinic Location", "Your message/concerns", "How did you hear about us?", "What is the best time of day to reach you?", "Preferred method of contact", "Pediatrician Name", "Diagnosing Provider Name", "Parent/Guardian First Name", "Parent/Guardian Last Name", "Email", "Cell Phone", "Home Phone", "Address - line 1", "Address - line 2", "City", "State", "Zip / Postal Code", "Work Phone", "Work Phone Ext", "Name of person completing this form", "School Name", "Grade", "What insurance do you have?", "If Insurance Not Listed, please list here", "Insurance Contact Number", "Subscriber Name", "Subscriber's Gender", "Subscriber Date of Birth", "Member ID", "Group ID", "Copy of insurance-Front", "Copy of insurance-Back","edit"];
  pageSizeOptions = [5, 10, 25, 50];

  constructor(public dialog: MatDialog, private router: Router,private authService: AuthService) { }

  ngOnInit(): void {
  }

  sortChange(sort: Sort): void {
    this.sort.emit(sort);
  }

  downloadClicked(url: string): void {
    this.download.emit(url);
  }

  exportCSV(): void {
    this.export.emit();
  }

  openDialog(data) {
    console.log("1237777",this.page.content)
    console.log("this.displayedColumns => ",this.displayedColumns)
    console.log("ok",data)
    let datas = JSON.parse(JSON.stringify(data));
    let dialogRef = this.dialog.open(EditClientsComponent, {
      width: '800px',
      data: {data:datas,displayedColumns:this.displayedColumns},
    });
    dialogRef.afterClosed().subscribe(
      data => this.loadClients.emit()
    );   
  }
}
