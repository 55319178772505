import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { EndPoints } from 'src/app/constants/endpoints';
import { Answer, Form } from 'src/app/models';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  constructor(private api: ApiService) { }

  getForm(formID: number): Observable<Form> {
    return this.api.get(EndPoints.FORM + formID);
  }

  submitForm(formID: number, answers: Answer[], recaptchaResponse: string): Observable<any> {
    const data = JSON.stringify({ answers, 'g-recaptcha-response': recaptchaResponse });

    console.log(data);

    return this.api.post(EndPoints.FORM + formID + EndPoints.POST_FORM, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
}
