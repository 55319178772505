import { SortDirection } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { FormResponse, Page } from 'src/app/models';

export enum NewClientsActionTypes {
  LOAD_NEW_CLIENTS = '[New Clients Page] Load New Clients',
  LOAD_NEW_CLIENTS_SUCCESS = '[New Clients Page] Load New Clients Success',
  LOAD_NEW_CLIENTS_ERROR = '[New Clients Page] Load New Clients Error',
  EXPORT_CSV = '[New Clients Export Button] Export New Clients CSV',
  EXPORT_CSV_SUCCESS = '[New Clients Export Button] Export New Clients CSV Success',
  EXPORT_CSV_ERROR = '[New Clients Export Button] Export New Clients CSV Error',
}

export const loadNewClients = createAction(
  NewClientsActionTypes.LOAD_NEW_CLIENTS,
  props<{
    sortBy: string;
    page: number;
    size: number;
    direction: SortDirection;
  }>()
);

export const loadNewClientsSuccess = createAction(
  NewClientsActionTypes.LOAD_NEW_CLIENTS_SUCCESS,
  props<{ page: Page<FormResponse> }>()
);

export const loadNewClientsError = createAction(
  NewClientsActionTypes.LOAD_NEW_CLIENTS_ERROR,
  props<{ error: string }>()
);

export const exportCSV = createAction(NewClientsActionTypes.EXPORT_CSV);

export const exportCSVSuccess = createAction(
  NewClientsActionTypes.EXPORT_CSV_SUCCESS
);

export const exportCSVError = createAction(
  NewClientsActionTypes.EXPORT_CSV_ERROR,
  props<{ error: string }>()
);
