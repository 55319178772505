import { Component, Input, OnInit } from '@angular/core';
import { TimeSlot } from 'src/app/models';

@Component({
  selector: 'wtls-patient-scheduler',
  templateUrl: './patient-scheduler.component.html',
  styleUrls: ['./patient-scheduler.component.scss']
})
export class PatientSchedulerComponent implements OnInit {
  @Input() schedule: TimeSlot[]; // All selected timeslots. Some will be from csv. User can add/remove as they see fit
  @Input() availability: TimeSlot; // All timeslots not in use in a match
  @Input() disabled: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  public getSchedule(): TimeSlot[] {
    return this.schedule;
  }

  changeSchedule(timeSlot: TimeSlot): void {
    
  }
}
