<div class="wrapper">
  <div class="overlay" *ngIf="active">
    <div class="spinner-wrapper">
      <mat-progress-spinner color="primary" mode="indeterminate" diameter="50">
      </mat-progress-spinner>
    </div>
  </div>

  <div class="loaded-content" [class.blurred]="active">
    <ng-content></ng-content>
  </div>
</div>
