<div
  *ngIf="mapFullScreen && show"
  class="match-panel-container"
>
  <div class="top-section border-light-bottom">
    <h2>Client/Provider #1</h2>
    <button type="button" class="icon-btn" (click)="closePanel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="main-section">
    <div class="section border-light-bottom">
      <div class="panel-row">
        <div class="panel-col">
          <h5>Client</h5>
          <p *ngIf="!match.patient.name" class="name"><em>No Name</em></p>
          <p *ngIf="match.patient.name" class="name">{{ match.patient.name }}</p>
          <p *ngIf="!match.patient.address.street"><em>No address available</em></p>
          <p *ngIf="match.patient.address.street">
              {{ match.patient.address.street }},
              {{ match.patient.address.city }} {{ match.patient.address.state }}
              {{ match.patient.address.zip }}  
          </p>
          </div>
      </div>
      <div class="panel-row">
        <div class="panel-col">
          <h5>Age:</h5>
          <p>
            {{ match.patient.age }}
          </p>
        </div>
      </div>
      <div class="panel-row">
        <div class="panel-col">
          <h5>Client Type:</h5>
          <p>
            {{ match.patient.clientType }}
          </p>
        </div>
      </div>
      <div class="panel-row">
        <div class="panel-col">
          <h5>Insurance Type:</h5>
          <p>
            {{ match.patient.insuranceType }}
          </p>
        </div>
      </div>
      <div class="panel-row">
        <div class="panel-col">
          <h5>Intake Stage:</h5>
          <p>
            {{ match.patient.intakeStage }}
          </p>
        </div>
      </div>
    </div>
    <div class="section border-light-bottom">
      <div class="panel-row">
        <div class="panel-col">
          <h5>Provider</h5>
          <p *ngIf="!match.provider.name" class="name"><em>No Name</em></p>
          <p *ngIf="match.provider.name" class="name">{{ match.provider.name }}</p>
          <p *ngIf="!match.provider.address.street"><em>No address available</em></p>
          <p *ngIf="match.provider.address.street">
            {{ match.provider.address.street }},
            {{ match.provider.address.city }} {{ match.provider.address.state }}
            {{ match.provider.address.zip }}
          </p>
          </div>
      </div>
      <div class="panel-row">
        <div class="panel-col">
          <h5>Employee Status:</h5>
          <p>
            {{ match.provider.employeeStatus }}
          </p>
        </div>
      </div>
      <div class="panel-row">
        <div class="panel-col">
          <h5>Provider Onboarding Stage:</h5>
          <p>
            {{ match.provider.onboardingStage }}
          </p>
        </div>
      </div>
      <div class="panel-row">
        <div class="panel-col">
          <h5>Provider Credentialing Stage:</h5>
          <p>
            {{ match.provider.credentialingStage }}
          </p>
        </div>
      </div>
    </div>
    <div class="section">
        <div class="panel-row">
            <div class="panel-col">
              <h5>Clinic Locations:</h5>
              <p>
                <strong>Client:</strong> {{ match.patient.clinicLocation }}
              </p>
              <p>
                <strong>Provider:</strong> {{ match.provider.clinicLocation }}
              </p>
            </div>
          </div>
          <div class="panel-row">
            <div class="panel-col">
              <h5>Schedule:</h5>
              <p>
                {{ match.schedule | timeSlots }}
              </p>
            </div>
          </div>
    </div>
  </div>
  <div class="bottom-section">
    <button
      mat-stroked-button
      type="button"
      class="delete-btn"
      (click)="unassignMatch()"
    >
      Unassign
    </button>
  </div>
</div>
