<!-- <!DOCTYPE html> -->
<!-- <html lang="en"> -->

<!-- <body>  -->
  <div class="page-container">
    <div class="content-container">
      
      <div class="container"> 
        <div class="company-header">
          <h2>
        

          <div class="spinner-wrapper" *ngIf="loading">
            <mat-progress-spinner
              color="primary"
              mode="indeterminate"
              diameter="30"
            >
            </mat-progress-spinner>
          </div>
         
        </h2>
          
        </div>
        
    
<div class="client-table">
  <div class="table-wrapper">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!-- Position Column -->
      
     <!--  <ng-container matColumnDef="S No.">
        <th mat-header-cell *matHeaderCellDef> S No.</th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container> -->
    
      <!-- Name Column -->
      <ng-container matColumnDef="S NO">
        <th mat-header-cell *matHeaderCellDef> S NO </th>
        <td mat-cell *matCellDef="let element let i=index"> {{i + 1}} </td>
      </ng-container>
    
      <ng-container matColumnDef="Company Name">
        <th mat-header-cell *matHeaderCellDef> Company Name </th>
        <td mat-cell *matCellDef="let element"> {{element.comapny_name}} </td>
      </ng-container>

      <ng-container matColumnDef="Email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let element"> {{element.company_email}} </td>
      </ng-container>

      <ng-container matColumnDef="Contact No">
        <th mat-header-cell *matHeaderCellDef> Contact No </th>
        <td mat-cell *matCellDef="let element"> {{element.company_contactno}} </td>
      </ng-container>

      <ng-container matColumnDef="Company Opening Time">
        <th mat-header-cell *matHeaderCellDef> Company Opening Time </th>
        <td mat-cell *matCellDef="let element"> {{element.companay_opening_time}} </td>
      </ng-container>

      <ng-container matColumnDef="Company Closing Time">
        <th mat-header-cell *matHeaderCellDef> Company Closing Time </th>
        <td mat-cell *matCellDef="let element">{{element.company_closing_time}}</td>
      </ng-container>

     <!--  <ng-container matColumnDef="Permissison">
        <th mat-header-cell *matHeaderCellDef> Permissison</th>
        <td mat-cell *matCellDef="let element"> <button mat-button color="warn" type="button">Granted</button>  </td>
      </ng-container> -->

      <ng-container matColumnDef="Permission">
        <th mat-header-cell *matHeaderCellDef> Permission </th>
        <td mat-cell *matCellDef="let element"> 
          <span *ngIf="element.admin_permission == 1" style="color: green"> <a href="/dashboard/companylogs/{{element.id}}"     > View Logs</a></span>
          <span *ngIf="element.admin_permission == 0" style="color: red">Access Denied!</span>
        </td>
      </ng-container>

     <!--  <ng-container matColumnDef="Permission Request">
        <th mat-header-cell *matHeaderCellDef> Permission Request </th>
        <td mat-cell *matCellDef="let element"><button mat-button color="warn" type="button">Send !{{element.company_url}}</button>  </td>
      </ng-container> -->

      <ng-container matColumnDef="Permission Request">
        <th mat-header-cell *matHeaderCellDef> Permission Request </th>
        <td mat-cell *matCellDef="let element"> 
          <span *ngIf="element.admin_permission == 1" style="color: green"> Granted</span>
          <span *ngIf="element.admin_permission == 0" style="color: red"><a href="#"     [routerLink]="['/dashboard/companylogs']"  routerLinkActive="active"> Request Access!</a></span>
        </td>
      </ng-container>
 <!--
      <ng-container matColumnDef="Admin Permissions">
        <th mat-header-cell *matHeaderCellDef> Admin Permissions </th>
        <td mat-cell *matCellDef="let element">      <a href="#"  mat-flat-button class="tab"  [routerLink]="['/dashboard/providerslogs']"  routerLinkActive="active"   type="button"  color="primary">Yes</a> </td>
      </ng-container>
 -->
      <!-- Weight Column -->
      
     
      
     
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions">
  </mat-paginator>
  
</div>

      </div>
    </div>
  </div>




