<div id="availability"></div>
  <div id="confirmation"></div>
<div class="overlay">
    <div class="overlay__inner">
        <div class="overlay__content">
            <span class="spinner"></span>
</div>
    </div>
    </div>
  <style>
      .overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    position: fixed;
    background: rgba(0,0,0,0.5);
    z-index: 999;
}



.overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #fff;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
    z-index: 1001;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
  </style>