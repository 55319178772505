
<div class="page-container">
    <div class="content-container">
      
      <div class="container">
  
        
       
  
        
  
        <form  [formGroup]="form" (ngSubmit)="submitForm()"
        >
          <div class="header-section">
            <h2 class="title">Client Location</h2>
          </div>
  
  
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                      <label for="name"> First Name </label>
                      <input type="text" formControlName="First_Name"  name="First_Name" [ngClass]="{ 'is-invalid': submitted && f.First_Name.errors }" class="form-control"   placeholder="First Name ">
                      <div *ngIf="submitted && f.First_Name.errors" class="invalid-feedback">
                        <div *ngIf="f.First_Name.errors.required"> First Name is required</div>
                    </div>
                     
                </div>
                
                <div class="form-group">
                      <label for="name">   Email</label>
                      <input type="text" formControlName="email"  name="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"  placeholder="Email">
                      <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required"> Email is required</div>
                    </div>
                    </div>
       
                
              </div>
              <div class="col-md-6">
                    
                    <div class="form-group">
                    <label for="name"> Last Name </label>
                    <input type="text" formControlName="Last_name" name="Last_name" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.Last_name.errors }"  placeholder="Last Name"> 
                    <div *ngIf="submitted && f.Last_name.errors" class="invalid-feedback">
                        <div *ngIf="f.Last_name.errors.required"> Last Name is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="name"> Phone </label>
                    <input type="text" formControlName="phone" name="phone" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"    placeholder="phone number">
                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                        <div *ngIf="f.phone.errors.required"> Phone is required</div>
                    </div>
                    </div>
              </div>

              <div class="col-md-12">
                
                <div class="form-group">
                <label for="name"> Street Address Line 1</label>
                <input type="text" formControlName="addressLine1" name="addressLine1" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.addressLine1.errors }"   placeholder="Street Address Line"> 
                <div *ngIf="submitted && f.addressLine1.errors" class="invalid-feedback">
                    <div *ngIf="f.addressLine1.errors.required"> Street Address Line is required</div>
                </div>
            </div>
        
              </div>

              <div class="col-md-12">
                
                <div class="form-group">
                <label for="name"> Street Address Line 2</label>
                <input type="text" formControlName="addressLine2" name="addressLine2" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.addressLine2.errors }"    placeholder="Street Address Line"> 
                <div *ngIf="submitted && f.addressLine2.errors" class="invalid-feedback">
                    <div *ngIf="f.addressLine2.errors.required"> Street Address Line  is required</div>
                </div>
            </div>
        
              </div>
              <div class="col-md-6">
                <div class="form-group">
                <label for="name"> Country </label>
                <input type="text" formControlName="country" name="country" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.country.errors }"    placeholder="US">
                <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                    <div *ngIf="f.country.errors.required"> Country is required</div>
                </div>
                </div>
                <div class="form-group">
                <label for="name"> City </label>
                <input type="text" formControlName="city" name="city" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.city.errors }"    placeholder="City"> 
                <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                    <div *ngIf="f.country.errors.required"> City is required</div>
                </div>
            </div>
        
          </div>
          <div class="col-md-6">
            <div class="form-group">
            <label for="name"> State </label>
            <select name="state"  formControlName="state"  class="form-control" [ngClass]="{ 'is-invalid': submitted && f.state.errors }" >
                <option value="" selected>Select State</option>
                <option value="AL">AL</option>
                <option value="AK">AK</option>
                <option value="AZ">AZ</option>
                <option value="AR">AR</option>
                <option value="CA">CA</option>
                <option value="CO">CO</option>
                <option value="CT">CT</option>
                <option value="DE">DE</option>
                <option value="DC">DC</option>
                <option value="FL">FL</option>
                <option value="GA">GA</option>
                <option value="HI">HI</option>
                <option value="ID">ID</option>
                <option value="IL">IL</option>
                <option value="IN">IN</option>
                <option value="IA">IA</option>
                <option value="KS">KS</option>
                <option value="KY">KY</option>
                <option value="LA">LA</option>
                <option value="ME">ME</option>
                <option value="MD">MD</option>
                <option value="MA">MA</option>
                <option value="MI">MI</option>
                <option value="MN">MN</option>
                <option value="MS">MS</option>
                <option value="MO">MO</option>
                <option value="MT">MT</option>
                <option value="NE">NE</option>
                <option value="NV">NV</option>
                <option value="NH">NH</option>
                <option value="NJ">NJ</option>
                <option value="NM">NM</option>
                <option value="NY">NY</option>
                <option value="NC">NC</option>
                <option value="ND">ND</option>
                <option value="OH">OH</option>
                <option value="OK">OK</option>
                <option value="OR">OR</option>
                <option value="PA">PA</option>
                <option value="RI">RI</option>
                <option value="SC">SC</option>
                <option value="SD">SD</option>
                <option value="TN">TN</option>
                <option value="TX">TX</option>
                <option value="UT">UT</option>
                <option value="VT">VT</option>
                <option value="VA">VA</option>
                <option value="WA">WA</option>
                <option value="WV">WV</option>
                <option value="WI">WI</option>
                <option value="WY">WY</option>

              </select>
              <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                <div *ngIf="f.state.errors.required"> State is required</div>
            </div>
            </div>
            <div class="form-group">
            <label for="name"> Zip/Postel Code </label>
            <input type="text" formControlName="postalCode" name="postalCode" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.postalCode.errors }"   placeholder="Zip/Postel Code"> 
            <div *ngIf="submitted && f.postalCode.errors" class="invalid-feedback">
                <div *ngIf="f.postalCode.errors.required"> Zip/Postel Code is required</div>
            </div>
        </div>
    
            </div>
            <div class="col-md-12">
                
                <div class="form-group">
                <label for="name"> Timezone</label>
                <select name="timezoneName"  formControlName="timezoneName"    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.timezoneName.errors }">
                        <option value="">Select Timezone</option>
                        <option value="America/Denver">Mountain Time</option>
                        <option value="America/Los_Angeles">Pacific Time</option>
                        <option value="America/Chicago">Central Time</option>
                        <option value="America/New_York">Eastern Time</option>
                        <option value="Pacific/Honolulu">Hawaiian Time</option>
                        <option value="America/Halifax">Atlantic Time</option>
                        <option value="America/St_Johns">Newfoundland Time</option>
                    </select>
                    <div *ngIf="submitted && f.timezoneName.errors" class="invalid-feedback">
                        <div *ngIf="f.timezoneName.errors.required"> Timezone is required</div>
                    </div>
                </div>
        
              </div>  

              <!----availablity time -->
              <div class="col-md-4">
                <div class="form-group">
                  Day
                   <select class="select-input form-control"  formControlName="mon" name="mon" >
                    <option value="Mon"  [selected]="1"> Monday </option>
                    
                   </select> 
                  
                  </div>  
                  </div>  
              
                  <div class="col-md-4">
                    <div class="form-group">
                      Start
                      <select class="select-input form-control" formControlName="monstarttime">
                        <option value="12.00 AM" [selected]="1">12.00 AM</option>
                        <option value="12.30 AM" selected="selected">12.30 AM</option>
                        <option value="1.00 AM" selected="selected">1.00 AM</option>
                        <option value="1.30 AM" selected="selected">1.30 AM</option>
                        <option value="2.00 AM" selected="selected">2.00 AM</option>
                        <option value="2.30 AM" selected="selected">2.30 AM</option>
                        <option value="3.00 AM" selected="selected">3.00 AM</option>
                        <option value="3.30 AM" selected="selected">3.30 AM</option>
                        <option value="4.00 AM" selected="selected">4.00 AM</option>
                        <option value="4.30 AM" selected="selected">4.30 AM</option>
                        <option value="5.00 AM" selected="selected">5.00 AM</option>
                        <option value="5.30 AM" selected="selected">5.30 AM</option>
                        <option value="6.00 AM" selected="selected">6.00 AM</option>
                        <option value="6.30 AM" selected="selected">6.30 AM</option>
                        <option value="7.00 AM" selected="selected">7.00 AM</option>
                        <option value="7.30 AM" selected="selected">7.30 AM</option>
                        <option value="8.00 AM" selected="selected">8.00 AM</option>
                        <option value="8.30 AM" selected="selected">8.30 AM</option>
                        <option value="9.00 AM" selected="selected">9.00 AM</option>
                        <option value="9.30 AM" selected="selected">9.30 AM</option>
                        <option value="10.00 AM" selected="selected">10.00 AM</option>
                        <option value="10.30 AM" selected="selected">10.30 AM</option>
                        <option value="11.00 AM" selected="selected">11.00 AM</option>
                        <option value="11.30 AM" selected="selected">11.30 AM</option>
                        <option value="12.00 PM" selected="selected">12.00 PM</option>
                        <option value="12.30 PM" selected="selected">12.30 PM</option>
                        <option value="1.00 PM" selected="selected">1.00 PM</option>
                        <option value="1.30 PM" selected="selected">1.30 PM</option>
                        <option value="2.00 PM" selected="selected">2.00 PM</option>
                        <option value="2.30 PM" selected="selected">2.30 PM</option>
                        <option value="3.00 PM" selected="selected">3.00 PM</option>
                        <option value="3.30 PM" selected="selected">3.30 PM</option>
                        <option value="4.00 PM" selected="selected">4.00 PM</option>
                        <option value="4.30 PM" selected="selected">4.30 PM</option>
                        <option value="5.00 PM" selected="selected">5.00 PM</option>
                        <option value="5.30 PM" selected="selected">5.30 PM</option>
                        <option value="6.00 PM" selected="selected">6.00 PM</option>
                        <option value="6.30 PM" selected="selected">6.30 PM</option>
                        <option value="7.00 PM" selected="selected">7.00 PM</option>
                        <option value="7.30 PM" selected="selected">7.30 PM</option>
                        <option value="8.00 PM" selected="selected">8.00 PM</option>
                        <option value="8.30 PM" selected="selected">8.30 PM</option>
                        <option value="9.00 PM" selected="selected">9.00 PM</option>
                        <option value="9.30 PM" selected="selected">9.30 PM</option>
                        <option value="10.00 PM" selected="selected">10.00 PM</option>
                        <option value="10.30 PM" selected="selected">10.30 PM</option>
                        <option value="11.00 PM" selected="selected">11.00 PM</option>
                        <option value="11.30 PM" selected="selected">11.30 PM</option>
                        <option value="12.00 AM" selected="selected">12.00 AM</option>
                       </select>
                      </div>  
                      </div>  

                      <div class="col-md-4">
                        <div class="form-group">
                          End
                          <select class="select-input form-control" formControlName="monendtime">
                            <option value="12.00 AM">12.00 AM</option>
                            <option value="12.30 AM" selected="selected">12.30 AM</option>
                            <option value="1.00 AM" selected="selected">1.00 AM</option>
                            <option value="1.30 AM" selected="selected">1.30 AM</option>
                            <option value="2.00 AM" selected="selected">2.00 AM</option>
                            <option value="2.30 AM" selected="selected">2.30 AM</option>
                            <option value="3.00 AM" selected="selected">3.00 AM</option>
                            <option value="3.30 AM" selected="selected">3.30 AM</option>
                            <option value="4.00 AM" selected="selected">4.00 AM</option>
                            <option value="4.30 AM" selected="selected">4.30 AM</option>
                            <option value="5.00 AM" selected="selected">5.00 AM</option>
                            <option value="5.30 AM" selected="selected">5.30 AM</option>
                            <option value="6.00 AM" selected="selected">6.00 AM</option>
                            <option value="6.30 AM" selected="selected">6.30 AM</option>
                            <option value="7.00 AM" selected="selected">7.00 AM</option>
                            <option value="7.30 AM" selected="selected">7.30 AM</option>
                            <option value="8.00 AM" selected="selected">8.00 AM</option>
                            <option value="8.30 AM" selected="selected">8.30 AM</option>
                            <option value="9.00 AM" selected="selected">9.00 AM</option>
                            <option value="9.30 AM" selected="selected">9.30 AM</option>
                            <option value="10.00 AM" selected="selected">10.00 AM</option>
                            <option value="10.30 AM" selected="selected">10.30 AM</option>
                            <option value="11.00 AM" selected="selected">11.00 AM</option>
                            <option value="11.30 AM" selected="selected">11.30 AM</option>
                            <option value="12.00 PM" selected="selected">12.00 PM</option>
                            <option value="12.30 PM" selected="selected">12.30 PM</option>
                            <option value="1.30 PM" selected="selected">1.30 PM</option>
                            <option value="2.00 PM" selected="selected">2.00 PM</option>
                            <option value="2.30 PM" selected="selected">2.30 PM</option>
                            <option value="3.00 PM" selected="selected">3.00 PM</option>
                            <option value="3.30 PM" selected="selected">3.30 PM</option>
                            <option value="4.00 PM" selected="selected">4.00 PM</option>
                            <option value="4.30 PM" selected="selected">4.30 PM</option>
                            <option value="5.00 PM" [selected]="1">5.00 PM</option>
                            <option value="5.30 PM" selected="selected">5.30 PM</option>
                            <option value="6.00 PM" selected="selected">6.00 PM</option>
                            <option value="6.30 PM" selected="selected">6.30 PM</option>
                            <option value="7.00 PM" selected="selected">7.00 PM</option>
                            <option value="7.30 PM" selected="selected">7.30 PM</option>
                            <option value="8.00 PM" selected="selected">8.00 PM</option>
                            <option value="8.30 PM" selected="selected">8.30 PM</option>
                            <option value="9.00 PM" selected="selected">9.00 PM</option>
                            <option value="9.30 PM" selected="selected">9.30 PM</option>
                            <option value="10.00 PM" selected="selected">10.00 PM</option>
                            <option value="10.30 PM" selected="selected">10.30 PM</option>
                            <option value="11.00 PM" selected="selected">11.00 PM</option>
                            <option value="11.30 PM" selected="selected">11.30 PM</option>
                            <option value="12.00 AM" selected="selected">12.00 AM</option>
                           </select>
                          </div>  
                          </div>  
                          <!----MONDAY END-->

                     
   <!--tueday is open	11:30 AM	to	05:00 PM--->
              
   <div class="col-md-4">
    <div class="form-group">
     <!-- <b>DAY</b>-->
      <select class="select-input form-control"   formControlName="tueday" name="tueday">
      
        <option value="Tue"  [selected]="1">Tuesday </option>
        
       </select>
      </div>  
      </div>  
  
      <div class="col-md-4">
        <div class="form-group">
         <!--<b>START</b>--> 
          <select class="select-input form-control" formControlName="tuestarttime" name="tuestarttime">
            <option value="12.00 AM" [selected]="1">12.00 AM</option>
            <option value="12.30 AM" selected="selected">12.30 AM</option>
            <option value="1.00 AM" selected="selected">1.00 AM</option>
            <option value="1.30 AM" selected="selected">1.30 AM</option>
            <option value="2.00 AM" selected="selected">2.00 AM</option>
            <option value="2.30 AM" selected="selected">2.30 AM</option>
            <option value="3.00 AM" selected="selected">3.00 AM</option>
            <option value="3.30 AM" selected="selected">3.30 AM</option>
            <option value="4.00 AM" selected="selected">4.00 AM</option>
            <option value="4.30 AM" selected="selected">4.30 AM</option>
            <option value="5.00 AM" selected="selected">5.00 AM</option>
            <option value="5.30 AM" selected="selected">5.30 AM</option>
            <option value="6.00 AM" selected="selected">6.00 AM</option>
            <option value="6.30 AM" selected="selected">6.30 AM</option>
            <option value="7.00 AM" selected="selected">7.00 AM</option>
            <option value="7.30 AM" selected="selected">7.30 AM</option>
            <option value="8.00 AM" selected="selected">8.00 AM</option>
            <option value="8.30 AM" selected="selected">8.30 AM</option>
            <option value="9.00 AM" selected="selected">9.00 AM</option>
            <option value="9.30 AM" selected="selected">9.30 AM</option>
            <option value="10.00 AM" selected="selected">10.00 AM</option>
            <option value="10.30 AM" selected="selected">10.30 AM</option>
            <option value="11.00 AM" selected="selected">11.00 AM</option>
            <option value="11.30 AM" selected="selected">11.30 AM</option>
            <option value="12.00 PM" selected="selected">12.00 PM</option>
            <option value="12.30 PM" selected="selected">12.30 PM</option>
            <option value="1.00 PM" selected="selected">1.00 PM</option>
            <option value="1.30 PM" selected="selected">1.30 PM</option>
            <option value="2.00 PM" selected="selected">2.00 PM</option>
            <option value="2.30 PM" selected="selected">2.30 PM</option>
            <option value="3.00 PM" selected="selected">3.00 PM</option>
            <option value="3.30 PM" selected="selected">3.30 PM</option>
            <option value="4.00 PM" selected="selected">4.00 PM</option>
            <option value="4.30 PM" selected="selected">4.30 PM</option>
            <option value="5.00 PM" selected="selected">5.00 PM</option>
            <option value="5.30 PM" selected="selected">5.30 PM</option>
            <option value="6.00 PM" selected="selected">6.00 PM</option>
            <option value="6.30 PM" selected="selected">6.30 PM</option>
            <option value="7.00 PM" selected="selected">7.00 PM</option>
            <option value="7.30 PM" selected="selected">7.30 PM</option>
            <option value="8.00 PM" selected="selected">8.00 PM</option>
            <option value="8.30 PM" selected="selected">8.30 PM</option>
            <option value="9.00 PM" selected="selected">9.00 PM</option>
            <option value="9.30 PM" selected="selected">9.30 PM</option>
            <option value="10.00 PM" selected="selected">10.00 PM</option>
            <option value="10.30 PM" selected="selected">10.30 PM</option>
            <option value="11.00 PM" selected="selected">11.00 PM</option>
            <option value="11.30 PM" selected="selected">11.30 PM</option>
            <option value="12.00 AM" selected="selected">12.00 AM</option>
           </select>
          </div>  
          </div>  

          <div class="col-md-4">
            <div class="form-group">
              <!--<b>END</b>-->
              <select class="select-input form-control" formControlName="tueendtime" name="tueendtime">
                <option value="12.00 AM">12.00 AM</option>
                <option value="12.30 AM" selected="selected">12.30 AM</option>
                <option value="1.00 AM" selected="selected">1.00 AM</option>
                <option value="1.30 AM" selected="selected">1.30 AM</option>
                <option value="2.00 AM" selected="selected">2.00 AM</option>
                <option value="2.30 AM" selected="selected">2.30 AM</option>
                <option value="3.00 AM" selected="selected">3.00 AM</option>
                <option value="3.30 AM" selected="selected">3.30 AM</option>
                <option value="4.00 AM" selected="selected">4.00 AM</option>
                <option value="4.30 AM" selected="selected">4.30 AM</option>
                <option value="5.00 AM" selected="selected">5.00 AM</option>
                <option value="5.30 AM" selected="selected">5.30 AM</option>
                <option value="6.00 AM" selected="selected">6.00 AM</option>
                <option value="6.30 AM" selected="selected">6.30 AM</option>
                <option value="7.00 AM" selected="selected">7.00 AM</option>
                <option value="7.30 AM" selected="selected">7.30 AM</option>
                <option value="8.00 AM" selected="selected">8.00 AM</option>
                <option value="8.30 AM" selected="selected">8.30 AM</option>
                <option value="9.00 AM" selected="selected">9.00 AM</option>
                <option value="9.30 AM" selected="selected">9.30 AM</option>
                <option value="10.00 AM" selected="selected">10.00 AM</option>
                <option value="10.30 AM" selected="selected">10.30 AM</option>
                <option value="11.00 AM" selected="selected">11.00 AM</option>
                <option value="11.30 AM" selected="selected">11.30 AM</option>
                <option value="12.00 PM" selected="selected">12.00 PM</option>
                <option value="12.30 PM" selected="selected">12.30 PM</option>
                <option value="1.30 PM" selected="selected">1.30 PM</option>
                <option value="2.00 PM" selected="selected">2.00 PM</option>
                <option value="2.30 PM" selected="selected">2.30 PM</option>
                <option value="3.00 PM" selected="selected">3.00 PM</option>
                <option value="3.30 PM" selected="selected">3.30 PM</option>
                <option value="4.00 PM" selected="selected">4.00 PM</option>
                <option value="4.30 PM" selected="selected">4.30 PM</option>
                <option value="5.00 PM" [selected]="1">5.00 PM</option>
                <option value="5.30 PM" selected="selected">5.30 PM</option>
                <option value="6.00 PM" selected="selected">6.00 PM</option>
                <option value="6.30 PM" selected="selected">6.30 PM</option>
                <option value="7.00 PM" selected="selected">7.00 PM</option>
                <option value="7.30 PM" selected="selected">7.30 PM</option>
                <option value="8.00 PM" selected="selected">8.00 PM</option>
                <option value="8.30 PM" selected="selected">8.30 PM</option>
                <option value="9.00 PM" selected="selected">9.00 PM</option>
                <option value="9.30 PM" selected="selected">9.30 PM</option>
                <option value="10.00 PM" selected="selected">10.00 PM</option>
                <option value="10.30 PM" selected="selected">10.30 PM</option>
                <option value="11.00 PM" selected="selected">11.00 PM</option>
                <option value="11.30 PM" selected="selected">11.30 PM</option>
                <option value="12.00 AM" selected="selected">12.00 AM</option>
               </select>
              </div>  
              </div>  
              <!----tueday END-->
            
                  <!--Wednesday is open	11:30 AM	to	05:00 PM--->
              
   <div class="col-md-4">
    <div class="form-group">
     <!--<b>DAY</b>---> 
      <select class="select-input form-control"  formControlName="Wedday" name="Wedday">
      
        <option value="Wed"  [selected]="1">Wednesday </option>
       
       </select>
      </div>  
      </div>  
  
      <div class="col-md-4">
        <div class="form-group">
         <!--<b>START</b>---> 
          <select class="select-input form-control" formControlName="Wedstarttime" name="Wedstarttime">
            <option value="12.00 AM" [selected]="1">12.00 AM</option>
            <option value="12.30 AM" selected="selected">12.30 AM</option>
            <option value="1.00 AM" selected="selected">1.00 AM</option>
            <option value="1.30 AM" selected="selected">1.30 AM</option>
            <option value="2.00 AM" selected="selected">2.00 AM</option>
            <option value="2.30 AM" selected="selected">2.30 AM</option>
            <option value="3.00 AM" selected="selected">3.00 AM</option>
            <option value="3.30 AM" selected="selected">3.30 AM</option>
            <option value="4.00 AM" selected="selected">4.00 AM</option>
            <option value="4.30 AM" selected="selected">4.30 AM</option>
            <option value="5.00 AM" selected="selected">5.00 AM</option>
            <option value="5.30 AM" selected="selected">5.30 AM</option>
            <option value="6.00 AM" selected="selected">6.00 AM</option>
            <option value="6.30 AM" selected="selected">6.30 AM</option>
            <option value="7.00 AM" selected="selected">7.00 AM</option>
            <option value="7.30 AM" selected="selected">7.30 AM</option>
            <option value="8.00 AM" selected="selected">8.00 AM</option>
            <option value="8.30 AM" selected="selected">8.30 AM</option>
            <option value="9.00 AM" selected="selected">9.00 AM</option>
            <option value="9.30 AM" selected="selected">9.30 AM</option>
            <option value="10.00 AM" selected="selected">10.00 AM</option>
            <option value="10.30 AM" selected="selected">10.30 AM</option>
            <option value="11.00 AM" selected="selected">11.00 AM</option>
            <option value="11.30 AM" selected="selected">11.30 AM</option>
            <option value="12.00 PM" selected="selected">12.00 PM</option>
            <option value="12.30 PM" selected="selected">12.30 PM</option>
            <option value="1.00 PM" selected="selected">1.00 PM</option>
            <option value="1.30 PM" selected="selected">1.30 PM</option>
            <option value="2.00 PM" selected="selected">2.00 PM</option>
            <option value="2.30 PM" selected="selected">2.30 PM</option>
            <option value="3.00 PM" selected="selected">3.00 PM</option>
            <option value="3.30 PM" selected="selected">3.30 PM</option>
            <option value="4.00 PM" selected="selected">4.00 PM</option>
            <option value="4.30 PM" selected="selected">4.30 PM</option>
            <option value="5.00 PM" selected="selected">5.00 PM</option>
            <option value="5.30 PM" selected="selected">5.30 PM</option>
            <option value="6.00 PM" selected="selected">6.00 PM</option>
            <option value="6.30 PM" selected="selected">6.30 PM</option>
            <option value="7.00 PM" selected="selected">7.00 PM</option>
            <option value="7.30 PM" selected="selected">7.30 PM</option>
            <option value="8.00 PM" selected="selected">8.00 PM</option>
            <option value="8.30 PM" selected="selected">8.30 PM</option>
            <option value="9.00 PM" selected="selected">9.00 PM</option>
            <option value="9.30 PM" selected="selected">9.30 PM</option>
            <option value="10.00 PM" selected="selected">10.00 PM</option>
            <option value="10.30 PM" selected="selected">10.30 PM</option>
            <option value="11.00 PM" selected="selected">11.00 PM</option>
            <option value="11.30 PM" selected="selected">11.30 PM</option>
            <option value="12.00 AM" selected="selected">12.00 AM</option>
           </select>
          </div>  
          </div>  

          <div class="col-md-4">
            <div class="form-group">
             <!---<b>END</b>---> 
              <select class="select-input form-control" formControlName="Wedendtime" name="Wedendtime">
                <option value="12.00 AM">12.00 AM</option>
                <option value="12.30 AM" selected="selected">12.30 AM</option>
                <option value="1.00 AM" selected="selected">1.00 AM</option>
                <option value="1.30 AM" selected="selected">1.30 AM</option>
                <option value="2.00 AM" selected="selected">2.00 AM</option>
                <option value="2.30 AM" selected="selected">2.30 AM</option>
                <option value="3.00 AM" selected="selected">3.00 AM</option>
                <option value="3.30 AM" selected="selected">3.30 AM</option>
                <option value="4.00 AM" selected="selected">4.00 AM</option>
                <option value="4.30 AM" selected="selected">4.30 AM</option>
                <option value="5.00 AM" selected="selected">5.00 AM</option>
                <option value="5.30 AM" selected="selected">5.30 AM</option>
                <option value="6.00 AM" selected="selected">6.00 AM</option>
                <option value="6.30 AM" selected="selected">6.30 AM</option>
                <option value="7.00 AM" selected="selected">7.00 AM</option>
                <option value="7.30 AM" selected="selected">7.30 AM</option>
                <option value="8.00 AM" selected="selected">8.00 AM</option>
                <option value="8.30 AM" selected="selected">8.30 AM</option>
                <option value="9.00 AM" selected="selected">9.00 AM</option>
                <option value="9.30 AM" selected="selected">9.30 AM</option>
                <option value="10.00 AM" selected="selected">10.00 AM</option>
                <option value="10.30 AM" selected="selected">10.30 AM</option>
                <option value="11.00 AM" selected="selected">11.00 AM</option>
                <option value="11.30 AM" selected="selected">11.30 AM</option>
                <option value="12.00 PM" selected="selected">12.00 PM</option>
                <option value="12.30 PM" selected="selected">12.30 PM</option>
                <option value="1.30 PM" selected="selected">1.30 PM</option>
                <option value="2.00 PM" selected="selected">2.00 PM</option>
                <option value="2.30 PM" selected="selected">2.30 PM</option>
                <option value="3.00 PM" selected="selected">3.00 PM</option>
                <option value="3.30 PM" selected="selected">3.30 PM</option>
                <option value="4.00 PM" selected="selected">4.00 PM</option>
                <option value="4.30 PM" selected="selected">4.30 PM</option>
                <option value="5.00 PM" [selected]="1">5.00 PM</option>
                <option value="5.30 PM" selected="selected">5.30 PM</option>
                <option value="6.00 PM" selected="selected">6.00 PM</option>
                <option value="6.30 PM" selected="selected">6.30 PM</option>
                <option value="7.00 PM" selected="selected">7.00 PM</option>
                <option value="7.30 PM" selected="selected">7.30 PM</option>
                <option value="8.00 PM" selected="selected">8.00 PM</option>
                <option value="8.30 PM" selected="selected">8.30 PM</option>
                <option value="9.00 PM" selected="selected">9.00 PM</option>
                <option value="9.30 PM" selected="selected">9.30 PM</option>
                <option value="10.00 PM" selected="selected">10.00 PM</option>
                <option value="10.30 PM" selected="selected">10.30 PM</option>
                <option value="11.00 PM" selected="selected">11.00 PM</option>
                <option value="11.30 PM" selected="selected">11.30 PM</option>
                <option value="12.00 AM" selected="selected">12.00 AM</option>
               </select>
              </div>  
              </div>  
              <!----tueday END-->
            <!--Thursday is open	11:30 AM	to	05:00 PM--->
              
   <div class="col-md-4">
    <div class="form-group">
      <!--<b>DAY</b>-->
      <select class="select-input form-control"   formControlName="Thuday" name="Thuday">
       
        <option value="Thu"  [selected]="1">Thursday </option>
       
       </select>
      </div>  
      </div>  
  
      <div class="col-md-4">
        <div class="form-group">
          <!--<b>START</b>--->
          <select class="select-input form-control" formControlName="Thustarttime" name="Thustarttime">
            <option value="12.00 AM" [selected]="1">12.00 AM</option>
            <option value="12.30 AM" selected="selected">12.30 AM</option>
            <option value="1.00 AM" selected="selected">1.00 AM</option>
            <option value="1.30 AM" selected="selected">1.30 AM</option>
            <option value="2.00 AM" selected="selected">2.00 AM</option>
            <option value="2.30 AM" selected="selected">2.30 AM</option>
            <option value="3.00 AM" selected="selected">3.00 AM</option>
            <option value="3.30 AM" selected="selected">3.30 AM</option>
            <option value="4.00 AM" selected="selected">4.00 AM</option>
            <option value="4.30 AM" selected="selected">4.30 AM</option>
            <option value="5.00 AM" selected="selected">5.00 AM</option>
            <option value="5.30 AM" selected="selected">5.30 AM</option>
            <option value="6.00 AM" selected="selected">6.00 AM</option>
            <option value="6.30 AM" selected="selected">6.30 AM</option>
            <option value="7.00 AM" selected="selected">7.00 AM</option>
            <option value="7.30 AM" selected="selected">7.30 AM</option>
            <option value="8.00 AM" selected="selected">8.00 AM</option>
            <option value="8.30 AM" selected="selected">8.30 AM</option>
            <option value="9.00 AM" selected="selected">9.00 AM</option>
            <option value="9.30 AM" selected="selected">9.30 AM</option>
            <option value="10.00 AM" selected="selected">10.00 AM</option>
            <option value="10.30 AM" selected="selected">10.30 AM</option>
            <option value="11.00 AM" selected="selected">11.00 AM</option>
            <option value="11.30 AM" selected="selected">11.30 AM</option>
            <option value="12.00 PM" selected="selected">12.00 PM</option>
            <option value="12.30 PM" selected="selected">12.30 PM</option>
            <option value="1.00 PM" selected="selected">1.00 PM</option>
            <option value="1.30 PM" selected="selected">1.30 PM</option>
            <option value="2.00 PM" selected="selected">2.00 PM</option>
            <option value="2.30 PM" selected="selected">2.30 PM</option>
            <option value="3.00 PM" selected="selected">3.00 PM</option>
            <option value="3.30 PM" selected="selected">3.30 PM</option>
            <option value="4.00 PM" selected="selected">4.00 PM</option>
            <option value="4.30 PM" selected="selected">4.30 PM</option>
            <option value="5.00 PM" selected="selected">5.00 PM</option>
            <option value="5.30 PM" selected="selected">5.30 PM</option>
            <option value="6.00 PM" selected="selected">6.00 PM</option>
            <option value="6.30 PM" selected="selected">6.30 PM</option>
            <option value="7.00 PM" selected="selected">7.00 PM</option>
            <option value="7.30 PM" selected="selected">7.30 PM</option>
            <option value="8.00 PM" selected="selected">8.00 PM</option>
            <option value="8.30 PM" selected="selected">8.30 PM</option>
            <option value="9.00 PM" selected="selected">9.00 PM</option>
            <option value="9.30 PM" selected="selected">9.30 PM</option>
            <option value="10.00 PM" selected="selected">10.00 PM</option>
            <option value="10.30 PM" selected="selected">10.30 PM</option>
            <option value="11.00 PM" selected="selected">11.00 PM</option>
            <option value="11.30 PM" selected="selected">11.30 PM</option>
            <option value="12.00 AM" selected="selected">12.00 AM</option>
           </select>
          </div>  
          </div>  

          <div class="col-md-4">
            <div class="form-group">
             <!-- <b>END</b>--> 
              <select class="select-input form-control" formControlName="Thuendtime" name="Thuendtime">
                <option value="12.00 AM">12.00 AM</option>
                <option value="12.30 AM" selected="selected">12.30 AM</option>
                <option value="1.00 AM" selected="selected">1.00 AM</option>
                <option value="1.30 AM" selected="selected">1.30 AM</option>
                <option value="2.00 AM" selected="selected">2.00 AM</option>
                <option value="2.30 AM" selected="selected">2.30 AM</option>
                <option value="3.00 AM" selected="selected">3.00 AM</option>
                <option value="3.30 AM" selected="selected">3.30 AM</option>
                <option value="4.00 AM" selected="selected">4.00 AM</option>
                <option value="4.30 AM" selected="selected">4.30 AM</option>
                <option value="5.00 AM" selected="selected">5.00 AM</option>
                <option value="5.30 AM" selected="selected">5.30 AM</option>
                <option value="6.00 AM" selected="selected">6.00 AM</option>
                <option value="6.30 AM" selected="selected">6.30 AM</option>
                <option value="7.00 AM" selected="selected">7.00 AM</option>
                <option value="7.30 AM" selected="selected">7.30 AM</option>
                <option value="8.00 AM" selected="selected">8.00 AM</option>
                <option value="8.30 AM" selected="selected">8.30 AM</option>
                <option value="9.00 AM" selected="selected">9.00 AM</option>
                <option value="9.30 AM" selected="selected">9.30 AM</option>
                <option value="10.00 AM" selected="selected">10.00 AM</option>
                <option value="10.30 AM" selected="selected">10.30 AM</option>
                <option value="11.00 AM" selected="selected">11.00 AM</option>
                <option value="11.30 AM" selected="selected">11.30 AM</option>
                <option value="12.00 PM" selected="selected">12.00 PM</option>
                <option value="12.30 PM" selected="selected">12.30 PM</option>
                <option value="1.30 PM" selected="selected">1.30 PM</option>
                <option value="2.00 PM" selected="selected">2.00 PM</option>
                <option value="2.30 PM" selected="selected">2.30 PM</option>
                <option value="3.00 PM" selected="selected">3.00 PM</option>
                <option value="3.30 PM" selected="selected">3.30 PM</option>
                <option value="4.00 PM" selected="selected">4.00 PM</option>
                <option value="4.30 PM" selected="selected">4.30 PM</option>
                <option value="5.00 PM" [selected]="1">5.00 PM</option>
                <option value="5.30 PM" selected="selected">5.30 PM</option>
                <option value="6.00 PM" selected="selected">6.00 PM</option>
                <option value="6.30 PM" selected="selected">6.30 PM</option>
                <option value="7.00 PM" selected="selected">7.00 PM</option>
                <option value="7.30 PM" selected="selected">7.30 PM</option>
                <option value="8.00 PM" selected="selected">8.00 PM</option>
                <option value="8.30 PM" selected="selected">8.30 PM</option>
                <option value="9.00 PM" selected="selected">9.00 PM</option>
                <option value="9.30 PM" selected="selected">9.30 PM</option>
                <option value="10.00 PM" selected="selected">10.00 PM</option>
                <option value="10.30 PM" selected="selected">10.30 PM</option>
                <option value="11.00 PM" selected="selected">11.00 PM</option>
                <option value="11.30 PM" selected="selected">11.30 PM</option>
                <option value="12.00 AM" selected="selected">12.00 AM</option>
               </select>
              </div>  
              </div>  
              <!----Thursday END-->
            
               <!--Friday is open	11:30 AM	to	05:00 PM--->
              
   <div class="col-md-4">
    <div class="form-group">
      <!--<b>DAY</b>-->
      <select class="select-input form-control"   formControlName="friday" name="friday">
      
        <option value="Fri"  [selected]="1">Friday </option>
      
       </select>
      </div>  
      </div>  
  
      <div class="col-md-4">
        <div class="form-group">
          <!--<b>START</b>-->
          <select class="select-input form-control" formControlName="fristarttime">
            <option value="12.00 AM" [selected]="1">12.00 AM</option>
            <option value="12.30 AM" selected="selected">12.30 AM</option>
            <option value="1.00 AM" selected="selected">1.00 AM</option>
            <option value="1.30 AM" selected="selected">1.30 AM</option>
            <option value="2.00 AM" selected="selected">2.00 AM</option>
            <option value="2.30 AM" selected="selected">2.30 AM</option>
            <option value="3.00 AM" selected="selected">3.00 AM</option>
            <option value="3.30 AM" selected="selected">3.30 AM</option>
            <option value="4.00 AM" selected="selected">4.00 AM</option>
            <option value="4.30 AM" selected="selected">4.30 AM</option>
            <option value="5.00 AM" selected="selected">5.00 AM</option>
            <option value="5.30 AM" selected="selected">5.30 AM</option>
            <option value="6.00 AM" selected="selected">6.00 AM</option>
            <option value="6.30 AM" selected="selected">6.30 AM</option>
            <option value="7.00 AM" selected="selected">7.00 AM</option>
            <option value="7.30 AM" selected="selected">7.30 AM</option>
            <option value="8.00 AM" selected="selected">8.00 AM</option>
            <option value="8.30 AM" selected="selected">8.30 AM</option>
            <option value="9.00 AM" selected="selected">9.00 AM</option>
            <option value="9.30 AM" selected="selected">9.30 AM</option>
            <option value="10.00 AM" selected="selected">10.00 AM</option>
            <option value="10.30 AM" selected="selected">10.30 AM</option>
            <option value="11.00 AM" selected="selected">11.00 AM</option>
            <option value="11.30 AM" selected="selected">11.30 AM</option>
            <option value="12.00 PM" selected="selected">12.00 PM</option>
            <option value="12.30 PM" selected="selected">12.30 PM</option>
            <option value="1.00 PM" selected="selected">1.00 PM</option>
            <option value="1.30 PM" selected="selected">1.30 PM</option>
            <option value="2.00 PM" selected="selected">2.00 PM</option>
            <option value="2.30 PM" selected="selected">2.30 PM</option>
            <option value="3.00 PM" selected="selected">3.00 PM</option>
            <option value="3.30 PM" selected="selected">3.30 PM</option>
            <option value="4.00 PM" selected="selected">4.00 PM</option>
            <option value="4.30 PM" selected="selected">4.30 PM</option>
            <option value="5.00 PM" selected="selected">5.00 PM</option>
            <option value="5.30 PM" selected="selected">5.30 PM</option>
            <option value="6.00 PM" selected="selected">6.00 PM</option>
            <option value="6.30 PM" selected="selected">6.30 PM</option>
            <option value="7.00 PM" selected="selected">7.00 PM</option>
            <option value="7.30 PM" selected="selected">7.30 PM</option>
            <option value="8.00 PM" selected="selected">8.00 PM</option>
            <option value="8.30 PM" selected="selected">8.30 PM</option>
            <option value="9.00 PM" selected="selected">9.00 PM</option>
            <option value="9.30 PM" selected="selected">9.30 PM</option>
            <option value="10.00 PM" selected="selected">10.00 PM</option>
            <option value="10.30 PM" selected="selected">10.30 PM</option>
            <option value="11.00 PM" selected="selected">11.00 PM</option>
            <option value="11.30 PM" selected="selected">11.30 PM</option>
            <option value="12.00 AM" selected="selected">12.00 AM</option>
           </select>
          </div>  
          </div>  

          <div class="col-md-4">
            <div class="form-group">
             <!--<b>END</b>---> 
              <select class="select-input form-control" formControlName="friendtime" name="friendtime">
                <option value="12.00 AM">12.00 AM</option>
                <option value="12.30 AM" selected="selected">12.30 AM</option>
                <option value="1.00 AM" selected="selected">1.00 AM</option>
                <option value="1.30 AM" selected="selected">1.30 AM</option>
                <option value="2.00 AM" selected="selected">2.00 AM</option>
                <option value="2.30 AM" selected="selected">2.30 AM</option>
                <option value="3.00 AM" selected="selected">3.00 AM</option>
                <option value="3.30 AM" selected="selected">3.30 AM</option>
                <option value="4.00 AM" selected="selected">4.00 AM</option>
                <option value="4.30 AM" selected="selected">4.30 AM</option>
                <option value="5.00 AM" selected="selected">5.00 AM</option>
                <option value="5.30 AM" selected="selected">5.30 AM</option>
                <option value="6.00 AM" selected="selected">6.00 AM</option>
                <option value="6.30 AM" selected="selected">6.30 AM</option>
                <option value="7.00 AM" selected="selected">7.00 AM</option>
                <option value="7.30 AM" selected="selected">7.30 AM</option>
                <option value="8.00 AM" selected="selected">8.00 AM</option>
                <option value="8.30 AM" selected="selected">8.30 AM</option>
                <option value="9.00 AM" selected="selected">9.00 AM</option>
                <option value="9.30 AM" selected="selected">9.30 AM</option>
                <option value="10.00 AM" selected="selected">10.00 AM</option>
                <option value="10.30 AM" selected="selected">10.30 AM</option>
                <option value="11.00 AM" selected="selected">11.00 AM</option>
                <option value="11.30 AM" selected="selected">11.30 AM</option>
                <option value="12.00 PM" selected="selected">12.00 PM</option>
                <option value="12.30 PM" selected="selected">12.30 PM</option>
                <option value="1.30 PM" selected="selected">1.30 PM</option>
                <option value="2.00 PM" selected="selected">2.00 PM</option>
                <option value="2.30 PM" selected="selected">2.30 PM</option>
                <option value="3.00 PM" selected="selected">3.00 PM</option>
                <option value="3.30 PM" selected="selected">3.30 PM</option>
                <option value="4.00 PM" selected="selected">4.00 PM</option>
                <option value="4.30 PM" selected="selected">4.30 PM</option>
                <option value="5.00 PM" [selected]="1">5.00 PM</option>
                <option value="5.30 PM" selected="selected">5.30 PM</option>
                <option value="6.00 PM" selected="selected">6.00 PM</option>
                <option value="6.30 PM" selected="selected">6.30 PM</option>
                <option value="7.00 PM" selected="selected">7.00 PM</option>
                <option value="7.30 PM" selected="selected">7.30 PM</option>
                <option value="8.00 PM" selected="selected">8.00 PM</option>
                <option value="8.30 PM" selected="selected">8.30 PM</option>
                <option value="9.00 PM" selected="selected">9.00 PM</option>
                <option value="9.30 PM" selected="selected">9.30 PM</option>
                <option value="10.00 PM" selected="selected">10.00 PM</option>
                <option value="10.30 PM" selected="selected">10.30 PM</option>
                <option value="11.00 PM" selected="selected">11.00 PM</option>
                <option value="11.30 PM" selected="selected">11.30 PM</option>
                <option value="12.00 AM" selected="selected">12.00 AM</option>
               </select>
              </div>  
              </div>  
              <!----Thursday END-->
                     

                 
            

             <!---------avilablity time-->
          <div class="footer-section">
            <hr />
            <button
              type="submit"
              class="button submit-button"
             
            >
              <span class="button-text">Submit Form</span>
            </button>
            <button
              type="button"
              
              class="button cancel-button"
            >
              <span class="button-text">Cancel</span>
            </button>
        </div>
          </div>
        </form>
        <br><br>
      <!--  <div class="row"  *ngIf="showMsg">
          <div class="col-xs-12">
            <p class="alert alert-primary alert-dismissible">
              
                <strong>Clinic Location saved successfully!</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
  
            </p>
          </div>
        </div>  -->
       
      
      </div>
    </div>
  </div>
  