import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/services';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator'; 


@Component({
  selector: 'wtls-providersupdatelogs',
  templateUrl: './providersupdatelogs.component.html',
  styleUrls: ['./providersupdatelogs.component.scss']
})
export class ProvidersupdatelogsComponent implements OnInit {

  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private http: HttpClient,
    private apiService: ApiService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

}
