import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup,Validators  } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { UsersFacade } from 'src/app/services/facades/users.facade';
import { ApiService } from 'src/app/services';
import { ValidatorsService } from 'src/app/services/validators/validators.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EndPoints } from 'src/app/constants/endpoints';

@Component({
  selector: 'wtls-editusers',
  templateUrl: './editusers.component.html',
  styleUrls: ['./editusers.component.scss']
})
export class EditusersComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  data:any;
  id: number;
  li:any;
  lis=[];
  dataset = [];
  constructor(private router: Router, 
    private route: ActivatedRoute, 
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private userFacade: UsersFacade,
    private apiService: ApiService,
    private snackbar: MatSnackBar,
    private validationService: ValidatorsService
    ) {  
      this.form = this.formBuilder.group({
        fullname: new FormControl(),
        email: new FormControl(),
        Address: new FormControl(),
        contactno: new FormControl(),
        password: new FormControl(),
        conformpassword: new FormControl()
      })
    }
  get validate() { return this.form.controls; }
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id']; 
    });
   

    this.apiService.get('/createusers/'+this.id)
    .subscribe(Response => {
 
      if(Response){ 
        console.log("edit=>",Response)
      }
      console.log("edit=>",Response)
      this.li=Response;
      this.lis=this.li;
     this.dataset = this.lis;
     this.data = this.lis;
    //  this.form.setValue({
    //    fullname: this.lis[0].fullname,
    //    email: this.lis[0].email,
    //    Address: this.lis[0].Address,
    //    contactno: this.lis[0].contactno,
    //    password: '',
    //    conformpassword: '',
    //  })
     this.form = this.formBuilder.group({
      fullname: [this.lis[0]?.fullname, Validators.compose([this.validationService.nameValidate()])],
      email: [this.lis[0]?.email, Validators.compose([Validators.email])],
      Address: [this.lis[0]?.Address, Validators.compose([this.validationService.addressValidate()])],
      contactno:[this.lis[0]?.contactno, Validators.compose([this.validationService.contactValidate()])],
      password:[''],
      conformpassword:['']
    },{
      validator: this.validationService.MatchPassword('password','conformpassword')
    })
      console.log("datatest1=>",this.data)
      return this.dataset;
      
    });
 
  }

  cancelForm(): void {
    this.router.navigate(['/dashboard/usersdetails'])
  }

  showMsg: boolean = false;
  submitForm() {

      let Formpostdata = this.form.value;
      console.log("test=>",this.id)
          this.apiService.put('/createusers/'+this.id,Formpostdata).subscribe(
          
        
            /* (response) => {
              this.showMsg= true
              setTimeout( () => this.router.navigate(['/dashboard/usersdetails']), 1750)
            }, */
            (response) =>{
              console.log("upadelocation",response)
              this.snackbar.open('User Update Successfull!', 'Dismiss', {
              verticalPosition: 'bottom',
              horizontalPosition: 'left',
            }); 
            window.location.href = EndPoints.BASE_URL+'dashboard/usersdetails';
            },
            
            (error) => console.log(error)
          );  
   
  }
  selecteddata(){
  
    //this.userFacade.getUser(this.id)
    this.apiService.get("/createusers/"+this.id)
    .subscribe(Response => {
 
      // If response comes hideloader() function is called
      // to hide that loader,
      if(Response){ 
        //hideloader();
        console.log("edit=>",Response)
      }
      console.log("edit=>",this.id)
      this.li=Response;
      this.lis=this.li;
     this.dataset = this.lis;
    
     console.log("fullname=>",this.data);
      this.data = this.lis;
     
      return this.data;
      
    });
    
  }

}
