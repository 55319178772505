import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { Answer, Form } from 'src/app/models';
import { FormActions } from 'src/app/store/actions';
import { FormSelectors } from 'src/app/store/selectors';


@Injectable({
    providedIn: 'root'
})
export class FormFacade {
    constructor(private store: Store) {}

    loadForm(formID: number): void {
        this.store.dispatch(FormActions.loadForm({ formID }));
    }

    getForm(): Observable<Form> {
        return this.store.select(FormSelectors.selectForm);
    }

    submitAnswers(formID: number, answers: Answer[], recaptchaResponse: string): void {
        console.log(answers);

        this.store.dispatch(FormActions.submitForm({ formID, answers, recaptchaResponse }));
    }

    uploadDocument(file: File): Observable<string> {
        return of('');
    }

    getLoading(): Observable<boolean> {
        return this.store.select(FormSelectors.selectFormLoading);
    }

    getSubmitting(): Observable<boolean> {
        return this.store.select(FormSelectors.selectFormSubmitting);
    }

    getError(): Observable<string> {
        return this.store.select(FormSelectors.selectFormError);
    }

}