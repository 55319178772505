import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatchesFacade } from 'src/app/services/facades/matches.facade';
import { Match } from 'src/app/models';

@Component({
  selector: 'wtls-match-panel',
  templateUrl: './match-panel.component.html',
  styleUrls: ['./match-panel.component.scss']
})
export class MatchPanelComponent implements OnInit {
  @Input() match: Match;
  @Input() mapFullScreen: boolean;
  @Input() show: boolean;
  @Input() index: number;
  @Output() showChange = new EventEmitter<boolean>();
  @Output() unassign = new EventEmitter<Match>();

  constructor() { }

  ngOnInit(): void {
  }

  closePanel(): void {
    this.showChange.emit(false)
  }

  unassignMatch(): void {
    this.unassign.emit(this.match);
  }
}
