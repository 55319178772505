<agm-marker
  *ngIf="marker.patient.address.lat && marker.patient.address.lng"
  [latitude]="marker.patient.address.lat"
  [longitude]="marker.patient.address.lng"
  [iconUrl]="marker.patientIcon"
  [label]="marker.label"
  (markerClick)="changeMatch()"
></agm-marker>
<agm-marker
  *ngIf="marker.provider.address.lat && marker.provider.address.lng"
  [latitude]="marker.provider.address.lat"
  [longitude]="marker.provider.address.lng"
  [iconUrl]="marker.providerIcon"
  [label]="marker.label"
  (markerClick)="changeMatch()"
></agm-marker>
<agm-snazzy-info-window
  *ngIf="
    !fullScreen &&
    ((marker.patient.address.lat &&
    marker.patient.address.lng) ||
    (marker.provider.address.lat &&
    marker.provider.address.lng))
  "
  [isOpen]="stayOpen"
  backgroundColor="white"
  pointer="false"
  [latitude]="marker.infoWindow.lat"
  [longitude]="marker.infoWindow.lng"
>
  <ng-template>
    <div class="info-window-content-container">
      <h2>Client/Provider #{{ index }}</h2>
      <h5>Client</h5>
      <p *ngIf="!marker.patient.name"><em>No Name</em></p>
      <p *ngIf="marker.patient.name">{{ marker.patient.name }}</p>
      <p *ngIf="!marker.patient.address.street">
        <em>No client address available</em>
      </p>
      <p *ngIf="marker.patient.address.street">
          {{ marker.patient.address.street }},
          {{ marker.patient.address.city }} {{ marker.patient.address.state }}
          {{ marker.patient.address.zip }}  
      </p>
    <h5>Provider</h5>
      <p *ngIf="!marker.provider.name"><em>No Name</em></p>
      <p *ngIf="marker.provider.name">{{ marker.provider.name }}</p>
      <p *ngIf="!marker.provider.address.street">
        <em>No provider address available</em>
      </p>
      <p *ngIf="marker.provider.address.street">
        {{ marker.provider.address.street }},
        {{ marker.provider.address.city }} {{ marker.provider.address.state }}
        {{ marker.provider.address.zip }}
      </p>
      <button
        mat-stroked-button
        type="button"
        class="delete-btn"
        (click)="unassignMatch()"
      >
        Unassign
      </button>
    </div>
  </ng-template>
</agm-snazzy-info-window>
