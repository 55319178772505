import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/services';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator'; 
import { ConfirmDialogComponent } from '../dashboard/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'wtls-companylogs',
  templateUrl: './companylogs.component.html',
  styleUrls: ['./companylogs.component.scss']
})
export class CompanylogsComponent implements OnInit,AfterViewInit {
  li:any;
  id: number;
  dataSource = new MatTableDataSource<any>();
  loading = true
 // displayedColumns: string[] = ["Users","Company Name",	"Login Date",	"Login Time",	"Logout Time",	"View company",	"Another Logs","Admin Permissions"];
 displayedColumns: string[] = ["S NO","Company Name",	"Login Time",	"Logout Time",	"Login date",	"Client Updated Logs",	"Providers Updated Logs"];
  pageSize = 10
  pageSizeOptions = [10, 25, 50, 500];
  @ViewChild(MatPaginator) paginator: MatPaginator
  dialogRef: any;
  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private http: HttpClient,
    private apiService: ApiService,
    private changeDetector: ChangeDetectorRef
  ) { }
  lis=[];
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id']; 
    });

    this.apiService.get('/loginlogs/'+this.id)
    .subscribe(Response => {
 
      // If response comes hideloader() function is called
      // to hide that loader
      if(Response){ 
        //hideloader();
      this.loading = false
      console.log("datatest=>",Response)
      this.li=Response;
      this.lis=this.li;
      this.dataSource.data =this.li;
      console.log("datatest1=>",this.lis)
      }
      
    },
    error => {
      this.loading = false
      this.dialogRef.open(ConfirmDialogComponent, {
        data: 'AccessDenied'
      })
    });
    function hideloader(){
      document.getElementById('loading').style.display = 'none';}
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator
  }
  
  

}
