import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { from, Observable, of, Subject } from 'rxjs';
import { Match, TimeSlot } from 'src/app/models';
import { EndPoints } from 'src/app/constants/endpoints';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { bufferCount, concatMap, delay, take } from 'rxjs/operators';
import { AgmGeocoder } from '@agm/core';
import lodash from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class MatchesService {
  constructor(private api: ApiService) {}

providerdetails(selected_values):Observable<any> {

  console.log(selected_values);
  console.log(EndPoints.MATCHES.GET_PROVIDER_DETAIL);
  return this.api.getproviderdetails<any>(EndPoints.MATCHES.GET_PROVIDER_DETAIL,selected_values);

  
 /*  return this.api.get<any>(EndPoints.MATCHES.GET_PROVIDER_DETAIL +selected_values);
  return this.api.get('/getproviderdetails' +selected_values);  */
}

  getMatches(options?: {
    name?: string;
    street?: string;
    city?: string;
    state?: string;
    zip?: string;
    clinicLocation?: string;
  }): Observable<Match[]> {
    console.log(options);
    let params = new HttpParams();

    if (options.name) params = params.append('name', options.name);
    if (options.street) params = params.append('street', options.street);
    if (options.city) params = params.append('city', options.city);
    if (options.state) params = params.append('state', options.state);
    if (options.zip) params = params.append('zip', options.zip);
    if (options.clinicLocation) params = params.append('clinicLocation', options.clinicLocation);

    return this.api.get<Match[]>(EndPoints.MATCHES.GET, { params })
      .pipe(
        map(matches => {
          console.log('matches',matches)
          matches.map(match => {
            console.log('match',match)
            // match.schedule.map(timeSlot => {
            //   console.log('timeSlot',timeSlot)
            //   //timeSlot.apptDate = new Date(timeSlot.apptDate).toUTCString().split('T')[0]
            //   //timeSlot = timeSlot
            //   timeSlot = JSON.stringify(timeSlot)
            //   timeSlot = JSON.parse(timeSlot)
            //   console.log('timeSlot',timeSlot)
            // })
            
          })

          return matches;
        })
      );
  }

  assignMatch(
    patientId: number,
    providerId: number,
    schedule: TimeSlot[]
  ): Observable<Match> {
     /* const form = new FormData();
     form.append('patientId', `${patientId}`);
     form.append('providerId', `${providerId}`);
    form.append('schedule', JSON.stringify(schedule));
 */
    let timeSlots = schedule.map(timeSlot => {
      return {
        dayOfWeek: timeSlot.dayOfWeek,
        startTime: timeSlot.startTime,
        endTime: timeSlot.endTime
      }
    })

    const form = JSON.stringify({
      patientId,
      providerId,
      schedule: timeSlots
    })

    return this.api.post<Match>(EndPoints.MATCHES.POST, form, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  unassignMatch(matchId: number): Observable<any> {
    return this.api.delete<any>(EndPoints.MATCHES.DELETE + matchId);
  }

  exportCSV(): Observable<any> {
    return this.api.getCSV(EndPoints.MATCHES.GET_CSV);
  }
}
