<div *ngIf="open$ | async" class="panel-container">
  <div (click)="closePanel()" class="overlay"></div>
  <form class="form" (ngSubmit)="submitForm()">
    <div class="content mat-elevation-z9">
      <div class="header">
        <button
          (click)="closePanel()"
          type="button"
          class="close-button"
          mat-icon-button
          aria-label="Open menu"
        >
          <mat-icon>close</mat-icon>
        </button>
        <h2>Edit Provider</h2>
      </div>
      <div class="form-section">
        <div class="row">
          <div class="column small">
            <mat-form-field appearance="outline">
              <mat-label>First Name</mat-label>
              <input matInput [(ngModel)]="provider.first_name" name="first_name" required/>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="column small">
            <mat-form-field appearance="outline">
              <mat-label>Last Name</mat-label>
              <input matInput [(ngModel)]="provider.last_name" name="last_name" required/>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="column small">
            <mat-form-field appearance="outline">
              <mat-label>Street Address</mat-label>
              <input
                matInput
                [(ngModel)]="provider.address.street"
                name="street" required
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="column small">
            <mat-form-field appearance="outline">
              <mat-label>City</mat-label>
              <input matInput [(ngModel)]="provider.address.city" name="city" required/>
            </mat-form-field>
          </div>
          <div class="column small sm">
            <mat-form-field appearance="outline">
              <mat-label>State</mat-label>
              <mat-select name="state" [(ngModel)]="provider.address.state"  required>
                <mat-option *ngFor="let state of usStates" [value]="state">{{
                  state
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="column small md">
            <mat-form-field class="medium" appearance="outline">
              <mat-label>Zip</mat-label>
              <input matInput [(ngModel)]="provider.address.zip" name="zip" required/>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <div class="gender-wrapper">
              <h5>Gender</h5>
              <mat-radio-group color="primary" aria-label="Select an gender">
                <mat-radio-button value="male">Male</mat-radio-button>
                <mat-radio-button value="female">Female</mat-radio-button>
                <mat-radio-button value="other">Other</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="column small">
            <mat-form-field appearance="outline">
              <mat-label>Clinic Location</mat-label>
              <mat-select
                [(ngModel)]="provider.clinicLocation"
                name="clinicLocation"
              >
                <mat-option
                  *ngFor="let location of clinicLocationOptions"
                  [value]="location"
                  >{{ location }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <div class="schedule-wrapper">
              <h5>Schedule (Greyed out times are in use)</h5>
              <div class="time-picker-wrapper">
                <wtls-time-picker
                  [dayOfWeek]="1"
                  [schedule]="provider.availability"
                  [availability]="availability"
                  (change)="changeSchedule($event)"
                ></wtls-time-picker>
                
                <select class="select-input" id="startappt" [(ngModel)]='selected_start_slot_mon' name="slot" value="8:00 AM">
                  <option [ngValue]="undefined" selected>12:00 AM</option>
                  <option *ngFor="let slot of timeslot" [value]="slot">{{slot}}</option>
                </select>

                <select class="select-input" id="endappt" [(ngModel)]='selected_end_slot_mon' name="slot" value="8:00 PM">
                  <option [ngValue]="undefined" selected>11:30 PM</option>
                  <option *ngFor="let slot of timeslot" [value]="slot">{{slot}}</option>
                </select>
                
                <!-- <select class="select-input " id="startappt" >
                  <option value="12.00 AM" selected="selected">12.00 AM</option>
                  <option value="12.30 AM">12.30 AM</option>
                  <option value="1.00 AM">1.00 AM</option>
                  <option value="1.30 AM">1.30 AM</option>
                  <option value="2.00 AM">2.00 AM</option>
                  <option value="2.30 AM">2.30 AM</option>
                  <option value="3.00 AM">3.00 AM</option>
                  <option value="3.30 AM">3.30 AM</option>
                  <option value="4.00 AM">4.00 AM</option>
                  <option value="4.30 AM">4.30 AM</option>
                  <option value="5.00 AM">5.00 AM</option>
                  <option value="5.30 AM">5.30 AM</option>
                  <option value="6.00 AM">6.00 AM</option>
                  <option value="6.30 AM">6.30 AM</option>
                  <option value="7.00 AM">7.00 AM</option>
                  <option value="7.30 AM">7.30 AM</option>
                  <option value="8.00 AM">8.00 AM</option>
                  <option value="8.30 AM">8.30 AM</option>
                  <option value="9.00 AM">9.00 AM</option>
                  <option value="9.30 AM">9.30 AM</option>
                  <option value="10.00 AM">10.00 AM</option>
                  <option value="10.30 AM">10.30 AM</option>
                  <option value="11.00 AM">11.00 AM</option>
                  <option value="11.30 AM">11.30 AM</option>
                  <option value="12.00 PM">12.00 PM</option>
                  <option value="12.30 PM">12.30 PM</option>
                  <option value="1.00 PM">1.00 PM</option>
                  <option value="1.30 PM">1.30 PM</option>
                  <option value="2.00 PM">2.00 PM</option>
                  <option value="2.30 PM">2.30 PM</option>
                  <option value="3.00 PM">3.00 PM</option>
                  <option value="3.30 PM">3.30 PM</option>
                  <option value="4.00 PM">4.00 PM</option>
                  <option value="4.30 PM">4.30 PM</option>
                  <option value="5.00 PM">5.00 PM</option>
                  <option value="5.30 PM">5.30 PM</option>
                  <option value="6.00 PM">6.00 PM</option>
                  <option value="6.30 PM">6.30 PM</option>
                  <option value="7.00 PM">7.00 PM</option>
                  <option value="7.30 PM">7.30 PM</option>
                  <option value="8.00 PM">8.00 PM</option>
                  <option value="8.30 PM">8.30 PM</option>
                  <option value="9.00 PM">9.00 PM</option>
                  <option value="9.30 PM">9.30 PM</option>
                  <option value="10.00 PM">10.00 PM</option>
                  <option value="10.30 PM">10.30 PM</option>
                  <option value="11.00 PM">11.00 PM</option>
                  <option value="11.30 PM">11.30 PM</option>
                  <option value="12.00 AM">12.00 AM</option>
                 </select>

                 <select class="select-input" id="endappt" >
                  <option value="12.00 AM">12.00 AM</option>
                  <option value="12.30 AM">12.30 AM</option>
                  <option value="1.00 AM" >1.00 AM</option>
                  <option value="1.30 AM" >1.30 AM</option>
                  <option value="2.00 AM" >2.00 AM</option>
                  <option value="2.30 AM" >2.30 AM</option>
                  <option value="3.00 AM" >3.00 AM</option>
                  <option value="3.30 AM" >3.30 AM</option>
                  <option value="4.00 AM" >4.00 AM</option>
                  <option value="4.30 AM" >4.30 AM</option>
                  <option value="5.00 AM" >5.00 AM</option>
                  <option value="5.30 AM" >5.30 AM</option>
                  <option value="6.00 AM" >6.00 AM</option>
                  <option value="6.30 AM" >6.30 AM</option>
                  <option value="7.00 AM" >7.00 AM</option>
                  <option value="7.30 AM" >7.30 AM</option>
                  <option value="8.00 AM" >8.00 AM</option>
                  <option value="8.30 AM" >8.30 AM</option>
                  <option value="9.00 AM" >9.00 AM</option>
                  <option value="9.30 AM" >9.30 AM</option>
                  <option value="10.00 AM">10.00 AM</option>
                  <option value="10.30 AM">10.30 AM</option>
                  <option value="11.00 AM">11.00 AM</option>
                  <option value="11.30 AM">11.30 AM</option>
                  <option value="12.00 PM">12.00 PM</option>
                  <option value="12.30 PM">12.30 PM</option>
                  <option value="1.30 PM" >1.30 PM</option>
                  <option value="2.00 PM" >2.00 PM</option>
                  <option value="2.30 PM" >2.30 PM</option>
                  <option value="3.00 PM" >3.00 PM</option>
                  <option value="3.30 PM" >3.30 PM</option>
                  <option value="4.00 PM" >4.00 PM</option>
                  <option value="4.30 PM" >4.30 PM</option>
                  <option value="5.00 PM" >5.00 PM</option>
                  <option value="5.30 PM" >5.30 PM</option>
                  <option value="6.00 PM" >6.00 PM</option>
                  <option value="6.30 PM" >6.30 PM</option>
                  <option value="7.00 PM" >7.00 PM</option>
                  <option value="7.30 PM" >7.30 PM</option>
                  <option value="8.00 PM" >8.00 PM</option>
                  <option value="8.30 PM" >8.30 PM</option>
                  <option value="9.00 PM" >9.00 PM</option>
                  <option value="9.30 PM" >9.30 PM</option>
                  <option value="10.00 PM">10.00 PM</option>
                  <option value="10.30 PM">10.30 PM</option>
                  <option value="11.00 PM">11.00 PM</option>
                  <option value="11.30 PM" selected="selected">11.30 PM</option>
                  <option value="12.00 AM">12.00 AM</option>
                 </select>-->
                <wtls-time-picker
                  [dayOfWeek]="2"
                  [schedule]="provider.availability"
                  [availability]="availability"
                  (change)="changeSchedule($event)"
                ></wtls-time-picker>
                <select class="select-input" id="startappt" [(ngModel)]='selected_start_slot_tue' name="slot" value="8:00 AM">
                  <option [ngValue]="undefined" selected>12:00 AM</option>
                  <option *ngFor="let slot of timeslot" [value]="slot">{{slot}}</option>
                </select>

                <select class="select-input" id="endappt" [(ngModel)]='selected_end_slot_tue' name="slot" value="8:00 PM">
                  <option [ngValue]="undefined" selected>11:30 PM</option>
                  <option *ngFor="let slot of timeslot" [value]="slot">{{slot}}</option>
                </select>
                <!-- <select class="select-input " id="startappt" (change)="changeSchedule($event)" >
                  <option value="12.00 AM" selected="selected">12.00 AM</option>
                  <option value="12.30 AM">12.30 AM</option>
                  <option value="1.00 AM">1.00 AM</option>
                  <option value="1.30 AM">1.30 AM</option>
                  <option value="2.00 AM">2.00 AM</option>
                  <option value="2.30 AM">2.30 AM</option>
                  <option value="3.00 AM">3.00 AM</option>
                  <option value="3.30 AM">3.30 AM</option>
                  <option value="4.00 AM">4.00 AM</option>
                  <option value="4.30 AM">4.30 AM</option>
                  <option value="5.00 AM">5.00 AM</option>
                  <option value="5.30 AM">5.30 AM</option>
                  <option value="6.00 AM">6.00 AM</option>
                  <option value="6.30 AM">6.30 AM</option>
                  <option value="7.00 AM">7.00 AM</option>
                  <option value="7.30 AM">7.30 AM</option>
                  <option value="8.00 AM">8.00 AM</option>
                  <option value="8.30 AM">8.30 AM</option>
                  <option value="9.00 AM">9.00 AM</option>
                  <option value="9.30 AM">9.30 AM</option>
                  <option value="10.00 AM">10.00 AM</option>
                  <option value="10.30 AM">10.30 AM</option>
                  <option value="11.00 AM">11.00 AM</option>
                  <option value="11.30 AM">11.30 AM</option>
                  <option value="12.00 PM">12.00 PM</option>
                  <option value="12.30 PM">12.30 PM</option>
                  <option value="1.00 PM">1.00 PM</option>
                  <option value="1.30 PM">1.30 PM</option>
                  <option value="2.00 PM">2.00 PM</option>
                  <option value="2.30 PM">2.30 PM</option>
                  <option value="3.00 PM">3.00 PM</option>
                  <option value="3.30 PM">3.30 PM</option>
                  <option value="4.00 PM">4.00 PM</option>
                  <option value="4.30 PM">4.30 PM</option>
                  <option value="5.00 PM">5.00 PM</option>
                  <option value="5.30 PM">5.30 PM</option>
                  <option value="6.00 PM">6.00 PM</option>
                  <option value="6.30 PM">6.30 PM</option>
                  <option value="7.00 PM">7.00 PM</option>
                  <option value="7.30 PM">7.30 PM</option>
                  <option value="8.00 PM">8.00 PM</option>
                  <option value="8.30 PM">8.30 PM</option>
                  <option value="9.00 PM">9.00 PM</option>
                  <option value="9.30 PM">9.30 PM</option>
                  <option value="10.00 PM">10.00 PM</option>
                  <option value="10.30 PM">10.30 PM</option>
                  <option value="11.00 PM">11.00 PM</option>
                  <option value="11.30 PM">11.30 PM</option>
                  <option value="12.00 AM">12.00 AM</option>
                 </select>

                 <select class="select-input" id="endappt" (change)="changeSchedule($event)">
                  <option value="12.00 AM">12.00 AM</option>
                  <option value="12.30 AM">12.30 AM</option>
                  <option value="1.00 AM" >1.00 AM</option>
                  <option value="1.30 AM" >1.30 AM</option>
                  <option value="2.00 AM" >2.00 AM</option>
                  <option value="2.30 AM" >2.30 AM</option>
                  <option value="3.00 AM" >3.00 AM</option>
                  <option value="3.30 AM" >3.30 AM</option>
                  <option value="4.00 AM" >4.00 AM</option>
                  <option value="4.30 AM" >4.30 AM</option>
                  <option value="5.00 AM" >5.00 AM</option>
                  <option value="5.30 AM" >5.30 AM</option>
                  <option value="6.00 AM" >6.00 AM</option>
                  <option value="6.30 AM" >6.30 AM</option>
                  <option value="7.00 AM" >7.00 AM</option>
                  <option value="7.30 AM" >7.30 AM</option>
                  <option value="8.00 AM" >8.00 AM</option>
                  <option value="8.30 AM" >8.30 AM</option>
                  <option value="9.00 AM" >9.00 AM</option>
                  <option value="9.30 AM" >9.30 AM</option>
                  <option value="10.00 AM">10.00 AM</option>
                  <option value="10.30 AM">10.30 AM</option>
                  <option value="11.00 AM">11.00 AM</option>
                  <option value="11.30 AM">11.30 AM</option>
                  <option value="12.00 PM">12.00 PM</option>
                  <option value="12.30 PM">12.30 PM</option>
                  <option value="1.30 PM" >1.30 PM</option>
                  <option value="2.00 PM" >2.00 PM</option>
                  <option value="2.30 PM" >2.30 PM</option>
                  <option value="3.00 PM" >3.00 PM</option>
                  <option value="3.30 PM" >3.30 PM</option>
                  <option value="4.00 PM" >4.00 PM</option>
                  <option value="4.30 PM" >4.30 PM</option>
                  <option value="5.00 PM" >5.00 PM</option>
                  <option value="5.30 PM" >5.30 PM</option>
                  <option value="6.00 PM" >6.00 PM</option>
                  <option value="6.30 PM" >6.30 PM</option>
                  <option value="7.00 PM" >7.00 PM</option>
                  <option value="7.30 PM" >7.30 PM</option>
                  <option value="8.00 PM" >8.00 PM</option>
                  <option value="8.30 PM" >8.30 PM</option>
                  <option value="9.00 PM" >9.00 PM</option>
                  <option value="9.30 PM" >9.30 PM</option>
                  <option value="10.00 PM">10.00 PM</option>
                  <option value="10.30 PM">10.30 PM</option>
                  <option value="11.00 PM">11.00 PM</option>
                  <option value="11.30 PM" selected="selected">11.30 PM</option>
                  <option value="12.00 AM">12.00 AM</option>
                 </select> -->
                <wtls-time-picker
                  [dayOfWeek]="3"
                  [schedule]="provider.availability"
                  [availability]="availability"
                  (change)="changeSchedule($event)"
                ></wtls-time-picker>
                <select class="select-input" id="startappt" [(ngModel)]='selected_start_slot_wed' name="slot" >
                  <option [ngValue]="undefined" selected>12:00 AM</option>
                  <option *ngFor="let slot of timeslot" [value]="slot">{{slot}}</option>
                </select>

                <select class="select-input" id="endappt" [(ngModel)]='selected_end_slot_wed' name="slot" >
                  <option [ngValue]="undefined" selected>11:30 PM</option>
                  <option *ngFor="let slot of timeslot" [value]="slot">{{slot}}</option>
                </select>
                <!-- <select class="select-input " id="startappt" (change)="changeSchedule($event)">
                  <option value="12.00 AM" selected="selected">12.00 AM</option>
                  <option value="12.30 AM">12.30 AM</option>
                  <option value="1.00 AM">1.00 AM</option>
                  <option value="1.30 AM">1.30 AM</option>
                  <option value="2.00 AM">2.00 AM</option>
                  <option value="2.30 AM">2.30 AM</option>
                  <option value="3.00 AM">3.00 AM</option>
                  <option value="3.30 AM">3.30 AM</option>
                  <option value="4.00 AM">4.00 AM</option>
                  <option value="4.30 AM">4.30 AM</option>
                  <option value="5.00 AM">5.00 AM</option>
                  <option value="5.30 AM">5.30 AM</option>
                  <option value="6.00 AM">6.00 AM</option>
                  <option value="6.30 AM">6.30 AM</option>
                  <option value="7.00 AM">7.00 AM</option>
                  <option value="7.30 AM">7.30 AM</option>
                  <option value="8.00 AM">8.00 AM</option>
                  <option value="8.30 AM">8.30 AM</option>
                  <option value="9.00 AM">9.00 AM</option>
                  <option value="9.30 AM">9.30 AM</option>
                  <option value="10.00 AM">10.00 AM</option>
                  <option value="10.30 AM">10.30 AM</option>
                  <option value="11.00 AM">11.00 AM</option>
                  <option value="11.30 AM">11.30 AM</option>
                  <option value="12.00 PM">12.00 PM</option>
                  <option value="12.30 PM">12.30 PM</option>
                  <option value="1.00 PM">1.00 PM</option>
                  <option value="1.30 PM">1.30 PM</option>
                  <option value="2.00 PM">2.00 PM</option>
                  <option value="2.30 PM">2.30 PM</option>
                  <option value="3.00 PM">3.00 PM</option>
                  <option value="3.30 PM">3.30 PM</option>
                  <option value="4.00 PM">4.00 PM</option>
                  <option value="4.30 PM">4.30 PM</option>
                  <option value="5.00 PM">5.00 PM</option>
                  <option value="5.30 PM">5.30 PM</option>
                  <option value="6.00 PM">6.00 PM</option>
                  <option value="6.30 PM">6.30 PM</option>
                  <option value="7.00 PM">7.00 PM</option>
                  <option value="7.30 PM">7.30 PM</option>
                  <option value="8.00 PM">8.00 PM</option>
                  <option value="8.30 PM">8.30 PM</option>
                  <option value="9.00 PM">9.00 PM</option>
                  <option value="9.30 PM">9.30 PM</option>
                  <option value="10.00 PM">10.00 PM</option>
                  <option value="10.30 PM">10.30 PM</option>
                  <option value="11.00 PM">11.00 PM</option>
                  <option value="11.30 PM">11.30 PM</option>
                  <option value="12.00 AM">12.00 AM</option>
                 </select>

                 <select class="select-input" id="endappt" (change)="changeSchedule($event)">
                  <option value="12.00 AM">12.00 AM</option>
                  <option value="12.30 AM">12.30 AM</option>
                  <option value="1.00 AM" >1.00 AM</option>
                  <option value="1.30 AM" >1.30 AM</option>
                  <option value="2.00 AM" >2.00 AM</option>
                  <option value="2.30 AM" >2.30 AM</option>
                  <option value="3.00 AM" >3.00 AM</option>
                  <option value="3.30 AM" >3.30 AM</option>
                  <option value="4.00 AM" >4.00 AM</option>
                  <option value="4.30 AM" >4.30 AM</option>
                  <option value="5.00 AM" >5.00 AM</option>
                  <option value="5.30 AM" >5.30 AM</option>
                  <option value="6.00 AM" >6.00 AM</option>
                  <option value="6.30 AM" >6.30 AM</option>
                  <option value="7.00 AM" >7.00 AM</option>
                  <option value="7.30 AM" >7.30 AM</option>
                  <option value="8.00 AM" >8.00 AM</option>
                  <option value="8.30 AM" >8.30 AM</option>
                  <option value="9.00 AM" >9.00 AM</option>
                  <option value="9.30 AM" >9.30 AM</option>
                  <option value="10.00 AM">10.00 AM</option>
                  <option value="10.30 AM">10.30 AM</option>
                  <option value="11.00 AM">11.00 AM</option>
                  <option value="11.30 AM">11.30 AM</option>
                  <option value="12.00 PM">12.00 PM</option>
                  <option value="12.30 PM">12.30 PM</option>
                  <option value="1.30 PM" >1.30 PM</option>
                  <option value="2.00 PM" >2.00 PM</option>
                  <option value="2.30 PM" >2.30 PM</option>
                  <option value="3.00 PM" >3.00 PM</option>
                  <option value="3.30 PM" >3.30 PM</option>
                  <option value="4.00 PM" >4.00 PM</option>
                  <option value="4.30 PM" >4.30 PM</option>
                  <option value="5.00 PM" >5.00 PM</option>
                  <option value="5.30 PM" >5.30 PM</option>
                  <option value="6.00 PM" >6.00 PM</option>
                  <option value="6.30 PM" >6.30 PM</option>
                  <option value="7.00 PM" >7.00 PM</option>
                  <option value="7.30 PM" >7.30 PM</option>
                  <option value="8.00 PM" >8.00 PM</option>
                  <option value="8.30 PM" >8.30 PM</option>
                  <option value="9.00 PM" >9.00 PM</option>
                  <option value="9.30 PM" >9.30 PM</option>
                  <option value="10.00 PM">10.00 PM</option>
                  <option value="10.30 PM">10.30 PM</option>
                  <option value="11.00 PM">11.00 PM</option>
                  <option value="11.30 PM" selected="selected">11.30 PM</option>
                  <option value="12.00 AM">12.00 AM</option>
                 </select> -->
                <wtls-time-picker
                  [dayOfWeek]="4"
                  [schedule]="provider.availability"
                  [availability]="availability"
                  (change)="changeSchedule($event)"
                ></wtls-time-picker>
                <select class="select-input" id="startappt" [(ngModel)]='selected_start_slot_thu' name="slot" value="8:00 AM" >
                  <option [ngValue]="undefined" selected>12:00 AM</option>
                  <option *ngFor="let slot of timeslot" [value]="slot">{{slot}}</option>
                </select>

                <select class="select-input" id="endappt" [(ngModel)]='selected_end_slot_thu' name="slot" value="8:00 PM">
                  <option [ngValue]="undefined" selected>11:30 PM</option>
                  <option *ngFor="let slot of timeslot" [value]="slot">{{slot}}</option>
                </select>
                <!-- <select class="select-input " id="startappt" (change)="changeSchedule($event)">
                  <option value="12.00 AM" selected="selected">12.00 AM</option>
                  <option value="12.30 AM">12.30 AM</option>
                  <option value="1.00 AM">1.00 AM</option>
                  <option value="1.30 AM">1.30 AM</option>
                  <option value="2.00 AM">2.00 AM</option>
                  <option value="2.30 AM">2.30 AM</option>
                  <option value="3.00 AM">3.00 AM</option>
                  <option value="3.30 AM">3.30 AM</option>
                  <option value="4.00 AM">4.00 AM</option>
                  <option value="4.30 AM">4.30 AM</option>
                  <option value="5.00 AM">5.00 AM</option>
                  <option value="5.30 AM">5.30 AM</option>
                  <option value="6.00 AM">6.00 AM</option>
                  <option value="6.30 AM">6.30 AM</option>
                  <option value="7.00 AM">7.00 AM</option>
                  <option value="7.30 AM">7.30 AM</option>
                  <option value="8.00 AM">8.00 AM</option>
                  <option value="8.30 AM">8.30 AM</option>
                  <option value="9.00 AM">9.00 AM</option>
                  <option value="9.30 AM">9.30 AM</option>
                  <option value="10.00 AM">10.00 AM</option>
                  <option value="10.30 AM">10.30 AM</option>
                  <option value="11.00 AM">11.00 AM</option>
                  <option value="11.30 AM">11.30 AM</option>
                  <option value="12.00 PM">12.00 PM</option>
                  <option value="12.30 PM">12.30 PM</option>
                  <option value="1.00 PM">1.00 PM</option>
                  <option value="1.30 PM">1.30 PM</option>
                  <option value="2.00 PM">2.00 PM</option>
                  <option value="2.30 PM">2.30 PM</option>
                  <option value="3.00 PM">3.00 PM</option>
                  <option value="3.30 PM">3.30 PM</option>
                  <option value="4.00 PM">4.00 PM</option>
                  <option value="4.30 PM">4.30 PM</option>
                  <option value="5.00 PM">5.00 PM</option>
                  <option value="5.30 PM">5.30 PM</option>
                  <option value="6.00 PM">6.00 PM</option>
                  <option value="6.30 PM">6.30 PM</option>
                  <option value="7.00 PM">7.00 PM</option>
                  <option value="7.30 PM">7.30 PM</option>
                  <option value="8.00 PM">8.00 PM</option>
                  <option value="8.30 PM">8.30 PM</option>
                  <option value="9.00 PM">9.00 PM</option>
                  <option value="9.30 PM">9.30 PM</option>
                  <option value="10.00 PM">10.00 PM</option>
                  <option value="10.30 PM">10.30 PM</option>
                  <option value="11.00 PM">11.00 PM</option>
                  <option value="11.30 PM">11.30 PM</option>
                  <option value="12.00 AM">12.00 AM</option>
                 </select>

                 <select class="select-input" id="endappt" (change)="changeSchedule($event)">
                  <option value="12.00 AM">12.00 AM</option>
                  <option value="12.30 AM">12.30 AM</option>
                  <option value="1.00 AM" >1.00 AM</option>
                  <option value="1.30 AM" >1.30 AM</option>
                  <option value="2.00 AM" >2.00 AM</option>
                  <option value="2.30 AM" >2.30 AM</option>
                  <option value="3.00 AM" >3.00 AM</option>
                  <option value="3.30 AM" >3.30 AM</option>
                  <option value="4.00 AM" >4.00 AM</option>
                  <option value="4.30 AM" >4.30 AM</option>
                  <option value="5.00 AM" >5.00 AM</option>
                  <option value="5.30 AM" >5.30 AM</option>
                  <option value="6.00 AM" >6.00 AM</option>
                  <option value="6.30 AM" >6.30 AM</option>
                  <option value="7.00 AM" >7.00 AM</option>
                  <option value="7.30 AM" >7.30 AM</option>
                  <option value="8.00 AM" >8.00 AM</option>
                  <option value="8.30 AM" >8.30 AM</option>
                  <option value="9.00 AM" >9.00 AM</option>
                  <option value="9.30 AM" >9.30 AM</option>
                  <option value="10.00 AM">10.00 AM</option>
                  <option value="10.30 AM">10.30 AM</option>
                  <option value="11.00 AM">11.00 AM</option>
                  <option value="11.30 AM">11.30 AM</option>
                  <option value="12.00 PM">12.00 PM</option>
                  <option value="12.30 PM">12.30 PM</option>
                  <option value="1.30 PM" >1.30 PM</option>
                  <option value="2.00 PM" >2.00 PM</option>
                  <option value="2.30 PM" >2.30 PM</option>
                  <option value="3.00 PM" >3.00 PM</option>
                  <option value="3.30 PM" >3.30 PM</option>
                  <option value="4.00 PM" >4.00 PM</option>
                  <option value="4.30 PM" >4.30 PM</option>
                  <option value="5.00 PM" >5.00 PM</option>
                  <option value="5.30 PM" >5.30 PM</option>
                  <option value="6.00 PM" >6.00 PM</option>
                  <option value="6.30 PM" >6.30 PM</option>
                  <option value="7.00 PM" >7.00 PM</option>
                  <option value="7.30 PM" >7.30 PM</option>
                  <option value="8.00 PM" >8.00 PM</option>
                  <option value="8.30 PM" >8.30 PM</option>
                  <option value="9.00 PM" >9.00 PM</option>
                  <option value="9.30 PM" >9.30 PM</option>
                  <option value="10.00 PM">10.00 PM</option>
                  <option value="10.30 PM">10.30 PM</option>
                  <option value="11.00 PM">11.00 PM</option>
                  <option value="11.30 PM" selected="selected">11.30 PM</option>
                  <option value="12.00 AM">12.00 AM</option>
                 </select> -->
                <wtls-time-picker
                  [dayOfWeek]="5"
                  [schedule]="provider.availability"
                  [availability]="availability"
                  (change)="changeSchedule($event)"
                ></wtls-time-picker>
                <select class="select-input" id="startappt" [(ngModel)]='selected_start_slot_fri' name="slot" >
                  <option [ngValue]="undefined" selected>12:00 AM</option>
                  <option *ngFor="let slot of timeslot" [value]="slot">{{slot}}</option>
                </select>

                <select class="select-input" id="endappt" [(ngModel)]='selected_end_slot_fri' name="slot" >
                  <option [ngValue]="undefined" selected>11:30 PM</option>
                  <option *ngFor="let slot of timeslot" [value]="slot">{{slot}}</option>
                </select>
                <!-- <select class="select-input " id="startappt" (change)="changeSchedule($event)">
                  <option value="12.00 AM" selected="selected">12.00 AM</option>
                  <option value="12.30 AM">12.30 AM</option>
                  <option value="1.00 AM">1.00 AM</option>
                  <option value="1.30 AM">1.30 AM</option>
                  <option value="2.00 AM">2.00 AM</option>
                  <option value="2.30 AM">2.30 AM</option>
                  <option value="3.00 AM">3.00 AM</option>
                  <option value="3.30 AM">3.30 AM</option>
                  <option value="4.00 AM">4.00 AM</option>
                  <option value="4.30 AM">4.30 AM</option>
                  <option value="5.00 AM">5.00 AM</option>
                  <option value="5.30 AM">5.30 AM</option>
                  <option value="6.00 AM">6.00 AM</option>
                  <option value="6.30 AM">6.30 AM</option>
                  <option value="7.00 AM">7.00 AM</option>
                  <option value="7.30 AM">7.30 AM</option>
                  <option value="8.00 AM">8.00 AM</option>
                  <option value="8.30 AM">8.30 AM</option>
                  <option value="9.00 AM">9.00 AM</option>
                  <option value="9.30 AM">9.30 AM</option>
                  <option value="10.00 AM">10.00 AM</option>
                  <option value="10.30 AM">10.30 AM</option>
                  <option value="11.00 AM">11.00 AM</option>
                  <option value="11.30 AM">11.30 AM</option>
                  <option value="12.00 PM">12.00 PM</option>
                  <option value="12.30 PM">12.30 PM</option>
                  <option value="1.00 PM">1.00 PM</option>
                  <option value="1.30 PM">1.30 PM</option>
                  <option value="2.00 PM">2.00 PM</option>
                  <option value="2.30 PM">2.30 PM</option>
                  <option value="3.00 PM">3.00 PM</option>
                  <option value="3.30 PM">3.30 PM</option>
                  <option value="4.00 PM">4.00 PM</option>
                  <option value="4.30 PM">4.30 PM</option>
                  <option value="5.00 PM">5.00 PM</option>
                  <option value="5.30 PM">5.30 PM</option>
                  <option value="6.00 PM">6.00 PM</option>
                  <option value="6.30 PM">6.30 PM</option>
                  <option value="7.00 PM">7.00 PM</option>
                  <option value="7.30 PM">7.30 PM</option>
                  <option value="8.00 PM">8.00 PM</option>
                  <option value="8.30 PM">8.30 PM</option>
                  <option value="9.00 PM">9.00 PM</option>
                  <option value="9.30 PM">9.30 PM</option>
                  <option value="10.00 PM">10.00 PM</option>
                  <option value="10.30 PM">10.30 PM</option>
                  <option value="11.00 PM">11.00 PM</option>
                  <option value="11.30 PM">11.30 PM</option>
                  <option value="12.00 AM">12.00 AM</option>
                 </select>

                 <select class="select-input" id="endappt" (change)="changeSchedule($event)">
                  <option value="12.00 AM">12.00 AM</option>
                  <option value="12.30 AM">12.30 AM</option>
                  <option value="1.00 AM" >1.00 AM</option>
                  <option value="1.30 AM" >1.30 AM</option>
                  <option value="2.00 AM" >2.00 AM</option>
                  <option value="2.30 AM" >2.30 AM</option>
                  <option value="3.00 AM" >3.00 AM</option>
                  <option value="3.30 AM" >3.30 AM</option>
                  <option value="4.00 AM" >4.00 AM</option>
                  <option value="4.30 AM" >4.30 AM</option>
                  <option value="5.00 AM" >5.00 AM</option>
                  <option value="5.30 AM" >5.30 AM</option>
                  <option value="6.00 AM" >6.00 AM</option>
                  <option value="6.30 AM" >6.30 AM</option>
                  <option value="7.00 AM" >7.00 AM</option>
                  <option value="7.30 AM" >7.30 AM</option>
                  <option value="8.00 AM" >8.00 AM</option>
                  <option value="8.30 AM" >8.30 AM</option>
                  <option value="9.00 AM" >9.00 AM</option>
                  <option value="9.30 AM" >9.30 AM</option>
                  <option value="10.00 AM">10.00 AM</option>
                  <option value="10.30 AM">10.30 AM</option>
                  <option value="11.00 AM">11.00 AM</option>
                  <option value="11.30 AM">11.30 AM</option>
                  <option value="12.00 PM">12.00 PM</option>
                  <option value="12.30 PM">12.30 PM</option>
                  <option value="1.30 PM" >1.30 PM</option>
                  <option value="2.00 PM" >2.00 PM</option>
                  <option value="2.30 PM" >2.30 PM</option>
                  <option value="3.00 PM" >3.00 PM</option>
                  <option value="3.30 PM" >3.30 PM</option>
                  <option value="4.00 PM" >4.00 PM</option>
                  <option value="4.30 PM" >4.30 PM</option>
                  <option value="5.00 PM" >5.00 PM</option>
                  <option value="5.30 PM" >5.30 PM</option>
                  <option value="6.00 PM" >6.00 PM</option>
                  <option value="6.30 PM" >6.30 PM</option>
                  <option value="7.00 PM" >7.00 PM</option>
                  <option value="7.30 PM" >7.30 PM</option>
                  <option value="8.00 PM" >8.00 PM</option>
                  <option value="8.30 PM" >8.30 PM</option>
                  <option value="9.00 PM" >9.00 PM</option>
                  <option value="9.30 PM" >9.30 PM</option>
                  <option value="10.00 PM">10.00 PM</option>
                  <option value="10.30 PM">10.30 PM</option>
                  <option value="11.00 PM">11.00 PM</option>
                  <option value="11.30 PM" selected="selected">11.30 PM</option>
                  <option value="12.00 AM">12.00 AM</option>
                 </select> -->
                <!-- <wtls-time-picker
                  [dayOfWeek]="6"
                  [schedule]="provider.availability"
                  [availability]="availability"
                  (change)="changeSchedule($event)"
                ></wtls-time-picker>
                <wtls-time-picker
                  [dayOfWeek]="7"
                  [schedule]="provider.availability"
                  [availability]="availability"
                  (change)="changeSchedule($event)"
                ></wtls-time-picker> -->
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="column small">
            <mat-form-field appearance="outline">
              <mat-label>Employee Status</mat-label>
              <mat-select
                [(ngModel)]="provider.employeeStatus"
                name="employeeStatus"
              >
                <mat-option
                  *ngFor="let employeeStatus of employeeStatusOptions"
                  [value]="employeeStatus"
                  >{{ employeeStatus }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="column small">
            <mat-form-field appearance="outline">
              <mat-label>Onboarding Stage</mat-label>
              <mat-select
                [(ngModel)]="provider.onboardingStage"
                name="onboardingStage"
              >
                <mat-option
                  *ngFor="let onboardingStage of onboardingStageOptions"
                  [value]="onboardingStage"
                  >{{ onboardingStage }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="column small">
            <mat-form-field appearance="outline">
              <mat-label>Credentialing Stage</mat-label>
              <mat-select
                [(ngModel)]="provider.credentialingStage"
                name="credentialingStage"
              >
                <mat-option
                  *ngFor="let credentialingStage of credentialingStageOptions"
                  [value]="credentialingStage"
                  >{{ credentialingStage }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="button-group">
          <button mat-flat-button type="submit" color="primary">Save</button>
          <button mat-stroked-button type="button" (click)="closePanel()">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
