import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable, of, throwError } from 'rxjs';
import { Params } from 'src/app/models';
import { EndPoints } from 'src/app/constants/endpoints';
import { Jwt } from 'src/app/models/jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private api: ApiService) { }
  
  login(email: string, password: string): Observable<Jwt> {
    const form = JSON.stringify({
      email,
      password
    });

    return this.api.post<Jwt>(EndPoints.AUTH.LOGIN, form, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  logout(): void {
    // let Formpostdata ='1';
    // this.api.post('/loginlogs/', Formpostdata).subscribe(
        
    //   (response) =>{ 
    //     //console.log()
    //     localStorage.removeItem('token');

    //   },
      
    //   (error) => console.log(error)
    //   );
    localStorage.removeItem('token');
    window.location.href = 'login'
  }

  resetPassword(email: string): Observable<any> {
    const form = new FormData();
    form.append('email', email);

    return this.api.post(EndPoints.AUTH.RESET_PASSWORD, form, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  saveAuthToken(token: string, role: string,email: string): void {
    localStorage.setItem('token', token);
    localStorage.setItem('role', role);
    localStorage.setItem('email',email);
  }

  getAuthToken(): string {
    return localStorage.getItem('token');
  }

  getEmailToken() {
    return localStorage.getItem('email');
  }
  getRoleToken(): string {
    return localStorage.getItem('role')
  }

}