import { createReducer, on, Action } from '@ngrx/store';
import { MapFullScreenActions } from '../actions';

const initialState: boolean = false;

const reducer  = createReducer(
    initialState,
    on(MapFullScreenActions.toggleFullScreen, (state) => (!state)),
    on(MapFullScreenActions.resetFullScreen, (state) => (false))
)

export function mapFullScreenReducer(state: boolean | undefined, action: Action) {
    return reducer(state, action)
}