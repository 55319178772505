<!-- <!DOCTYPE html> -->
<!-- <html lang="en"> -->

<!-- <body>  -->
  <div class="page-container">
    <div class="content-container">
      
      <div class="container"> 
        <div class="company-header">
          <h2>
          <a href="#"  mat-flat-button class="tab"  [routerLink]="['/dashboard/locations']"  routerLinkActive="active"   type="button"  color="primary">Clinic location</a> 
        <!--   &nbsp;&nbsp;
          <a href="#"  mat-flat-button class="tab"  [routerLink]="['/dashboard/createcompanys']"  routerLinkActive="active"   type="button"  color="primary">New Company</a>  -->
          &nbsp;&nbsp;
          <a href="#"  mat-flat-button class="tab"  [routerLink]="['/dashboard/create-client-locations']"  routerLinkActive="active"   type="button"  color="primary">Client locations</a> 
          <div class="spinner-wrapper" *ngIf="loading">
            <mat-progress-spinner
              color="primary"
              mode="indeterminate"
              diameter="30"
            >
            </mat-progress-spinner>
          </div>
         
        </h2>
          
        </div>
        
      <!-- <br> -->
        <!-- <a href="#"  mat-flat-button class="tab"  [routerLink]="['/dashboard/createcompanys']"  routerLinkActive="active"   type="button"  color="primary">New company</a> 
        &nbsp;&nbsp;&nbsp;<a href="#"  mat-flat-button class="tab"  [routerLink]="['/dashboard/locations']"  routerLinkActive="active"   type="button"  color="primary">Clinic location & Sub headings</a> 

        <br> <br>
        <div class="d-flex justify-content-center"> -->
<!-- <div class="spinner-border" role="status" >
    <span class="sr-only" id="loading">Loading...</span>
  
</div> -->
<!-- </div> --> 
<div class="client-table">
  <div class="table-wrapper">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!-- Position Column -->
      
      <ng-container matColumnDef="S No.">
        <th mat-header-cell *matHeaderCellDef> S No.</th>
        <td mat-cell *matCellDef="let element let i=index"> {{i + 1}} </td>
      </ng-container>
    
      <!-- Name Column -->
      <ng-container matColumnDef="Fullname">
        <th mat-header-cell *matHeaderCellDef> Fullname </th>
        <td mat-cell *matCellDef="let element"> {{element.fullname}} </td>
      </ng-container>
    
      <ng-container matColumnDef="Company Name">
        <th mat-header-cell *matHeaderCellDef> Company name </th>
        <td mat-cell *matCellDef="let element"> {{element.comapny_name}} </td>
      </ng-container>

      <ng-container matColumnDef="Email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let element"> {{element.company_email}} </td>
      </ng-container>

      <ng-container matColumnDef="Address">
        <th mat-header-cell *matHeaderCellDef> Address </th>
        <td mat-cell *matCellDef="let element"> {{element.company_address}} </td>
      </ng-container>

      <ng-container matColumnDef="Contact No">
        <th mat-header-cell *matHeaderCellDef> Contact No </th>
        <td mat-cell *matCellDef="let element"> {{element.company_contactno}} </td>
      </ng-container>

      <ng-container matColumnDef="Opening time">
        <th mat-header-cell *matHeaderCellDef> Opening Time </th>
        <td mat-cell *matCellDef="let element"> {{element.companay_opening_time}} </td>
      </ng-container>

      <ng-container matColumnDef="Closing time">
        <th mat-header-cell *matHeaderCellDef> Closing Time</th>
        <td mat-cell *matCellDef="let element"> {{element.company_closing_time}} </td>
      </ng-container>

      <ng-container matColumnDef="Url">
        <th mat-header-cell *matHeaderCellDef> URL </th>
        <td mat-cell *matCellDef="let element"> {{element.company_url}} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element"> 
          <span *ngIf="element.status == 0" style="color: green">Active</span>
          <span *ngIf="element.status == 1" style="color: red">Inactive</span>
        </td>
      </ng-container>
    
      <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element"> 
          <button 
            mat-icon-button 
            matTooltip="Click to Edit"  
            class="iconbutton"
            (click)="edit(element.id)" 
            color="primary"
          >
          <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions">
  </mat-paginator>
  <!-- <mat-paginator
  class="paginator"
  [length]="totalElements"
  [pageSize]="size"
  [pageSizeOptions]="pageSizeOptions"
  >
  </mat-paginator> -->
</div>
<!-- <table class="table" id='tab' style="border:1px">
    <thead>
    <tr>
      <th scope="col">S.No</th>
        <th scope="col">Fullname</th>
        <th scope="col">Company Name</th>
        <th scope="col"> Email</th>
        <th scope="col"> Address</th>
        <th scope="col"> Contact No</th>
        <th scope="col"> Opening time</th>
        <th scope="col"> Closing time</th>
        <th scope="col"> Url</th>
        <th scope="col">Status</th>
        <th scope="col">Action</th>
       
       
    </tr>
    </thead>
    <tbody>
    <tr  *ngFor="let e of lis;">
      <td>{{ e.id }}</td>
      <td>{{ e.fullname }}</td>
        <td>{{ e.comapny_name }}</td>
        <td>{{ e.company_email }}</td>
        <td>{{ e.company_address }}</td>
        <td>{{ e.company_contactno }}</td>
        <td>{{ e.companay_opening_time }}</td>
        <td>{{ e.company_closing_time }}</td>
        <td>{{ e.company_url }}</td>
        <td>
          <span *ngIf="e.status == 0" style="color: green">Active</span>
          <span *ngIf="e.status == 1" style="color: red">Inactive</span>
        </td>
        <td>
           
         
          &nbsp; <a href="/dashboard/editcompany/{{ e.id }}"><span class="glyphicon glyphicon-pencil btn btn-primary" ></span></a>
            &nbsp;<span class="	glyphicon glyphicon-trash btn btn-danger" (click)="delete(e.id)"></span>
         </td>
       
    </tr>
    </tbody>
    
</table>     -->
      </div>
    </div>
  </div>

<!-- </body> -->
<!-- </html> -->


