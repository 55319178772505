import { createReducer, on, Action } from '@ngrx/store';
import { Patient } from "src/app/models";
import { AddEditPatientActions } from '../actions';

export interface AddEditPatientState {
    open: boolean,
    patient: Patient,
    loading: boolean,
    error: string
}

const initialState: AddEditPatientState = {
    open: false,
    patient: null,
    loading: false,
    error: ''
}

const reducer = createReducer(
    initialState,
    on(AddEditPatientActions.openPanel, (state) => ({ ...state, open: true })),
    on(AddEditPatientActions.closePanel, (state) => ({ ...state, open: false })),
    on(AddEditPatientActions.setPatient, (state, { patient }) => ({ ...state, patient })),
    on(AddEditPatientActions.clearPatient, (state) => ({ ...state, patient: null })),
    on(AddEditPatientActions.updatePatient, (state, { patient }) => ({ ...state, loading: true })),
    on(AddEditPatientActions.updatePatientSuccess, (state) => ({ ...state, loading: false })),
    on(AddEditPatientActions.updatePatientError, (state, { error }) => ({ ...state, loading: false, error })),
);

export function addEditPatientReducer(state: AddEditPatientState | undefined, action: Action) {
    return reducer(state, action);
}