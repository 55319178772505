
<div
  class="top-bar-container d-flex align-items-center justify-content-space-between"
>

  <div class="items-left d-flex justify-content-space-between">
    <span class="title">Schedule Zipper </span>
    <div class="tab-group" >
      <!--<a class="tab" *ngFor="let a of lis;"  routerLinkActive="active" routerLink="/{{a.url}}">{{ a.label_name}}</a>-->
      <a  
        class="tab"
        [routerLink]="['/dashboard/unassigned']"
        routerLinkActive="active"
        >Unassigned</a
      >
      <a
        class="tab"
        [routerLink]="['/dashboard/assigned']"
        routerLinkActive="active"
        >Assigned</a
      >
      <a
        class="tab"
        [routerLink]="['/dashboard/clientsshow']"
        routerLinkActive="active"
        >Clients</a
      >
      <a
        class="tab"
        [routerLink]="['/dashboard/providersshow']"
        routerLinkActive="active"
        >Providers</a
      >
        <!-- <a
        class="tab"
        [routerLink]="['/dashboard/appointment']"
        routerLinkActive="active"
        >Schedule appointments</a
      > -->
      <a
        class="tab"
        [routerLink]="['/dashboard/appointment-booking']"
        routerLinkActive="active"
        >Schedule</a
      >
      <!--
      <a
        class="tab"
        [routerLink]="['/dashboard/auditlogs']"
        routerLinkActive="active"
        >Audit logs</a
      > 

        <a
        class="tab"
        [routerLink]="['/dashboard/users']"
        routerLinkActive="active"
        > Users</a
      >  -->
      <!-- <a
        class="tab"
        [routerLink]="['/dashboard/blocks']"
        routerLinkActive="active"
        > Blocks</a
      > 
      -->
    </div>
  
   <div *ngIf="email"><a class="tab"><p style="color:rgb(252, 246, 246);">{{email}}</p></a> </div>

  
  <div class="items-right" >
    
    <button type="button" class="logout-button" (click)="logout()">
      Log Out
    </button>
  </div>
</div>
