<div class="new-clients-container">
  <wtls-clients
    [page]="page$ | async"
    [loading]="loading$ | async"
    (pageChange)="pageChange($event)"
    (loadClients)="loadClients()"
    (sort)="sortChanged($event)"
    (download)="downloadFile($event)"
    (export)="exportCSV()"
  ></wtls-clients>
</div>
