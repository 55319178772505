import { Injectable } from '@angular/core';
import {
  catchError,
  mergeMap,
  switchMap,
  map,
  take
} from 'rxjs/operators';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { MatchesService } from 'src/app/services/matches/matches.service';
import { AgmGeocoder } from '@agm/core';
import { MatchActions, MatchesActions, PatientsActions, ProvidersActions } from '../actions';
import { of } from 'rxjs';
import { FiltersFacade } from 'src/app/services/facades/filters.facade';
import { MatchFacade } from 'src/app/services/facades/match.facade';
import { PatientsFacade } from 'src/app/services/facades/patients.facade';
import { ProvidersFacade } from 'src/app/services/facades/providers.facade';

@Injectable({
  providedIn: 'root',
})
export class MatchesEffects {
  loadMatches$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MatchesActions.loadMatches),
      mergeMap(action => this.filtersFacade.getFilters().pipe(
        take(1),
        mergeMap(filters => this.matchesService.getMatches({
          name: filters.name,
          street: filters.address,
          city: filters.city,
          state: filters.state,
          zip: filters.zip,
          clinicLocation: filters.clinicLocation
        }).pipe(
          map(matches => {
            // patients and providers in matches should always have coords, so no need to goecode
            
            return MatchesActions.loadMatchesSuccess({ matches });
          }),
          catchError(error => {
            console.log(error);
            return of(MatchesActions.loadMatchesError({ error }));
          })
        ))
      ) 
      )
    )
  );

  unassignMatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MatchesActions.unassignMatch),
      mergeMap(action => this.matchesService.unassignMatch(action.matchId).pipe(
        switchMap(result => [
          MatchesActions.unassignMatchSuccess(),
          MatchActions.resetMatch(),
          MatchesActions.loadMatches()
        ]),
        catchError(error => {
          return of(MatchesActions.unassignMatchError({ error }))
        })
      ))
    ))

  assignMatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MatchesActions.assignMatch),
      mergeMap((action) => this.matchFacade.getMatch().pipe(
        take(1),
        mergeMap((match) => this.matchesService.assignMatch(match.patient.id, match.provider.id, match.schedule).pipe(
          map(match => {
            this.matchFacade.resetMatch();
            this.patientsFacade.loadPatients();
            this.providersFacade.loadProviders();

            return MatchesActions.assignMatchSuccess()
          }),
          catchError((error) => {
            return of(MatchesActions.assignMatchError({ error }));
          })
        ))
      ))
    )
  )

  exportCsv = createEffect(() =>
    this.actions$.pipe(
      ofType(MatchesActions.exportCsv),
      mergeMap((action) => this.matchesService.exportCSV().pipe(
        map(csvString => {
          console.log(csvString);

          // Download file
          const type = 'data:text/csv;charset=utf-8,';
    
          const encodedUri = encodeURI(type + csvString);
    
          const link = document.createElement('a');
          link.setAttribute('href', encodedUri);
          link.setAttribute('download', `matches.csv`);
          document.body.appendChild(link); // Required for FF
    
          link.click(); // This will download the data file
          link.remove();
          
          return MatchesActions.exportCsvSuccess();
        }),
        catchError((error) => {
          return of(MatchesActions.exportCsvError({ error }));
        })
      ))
    ))

  constructor(
    private actions$: Actions,
    private matchesService: MatchesService,
    private patientsFacade: PatientsFacade,
    private providersFacade: ProvidersFacade,
    private matchFacade: MatchFacade,
    private filtersFacade: FiltersFacade
  ) {}
}
