<div class="assigned-google-map-container">
  <div class="panel-container">
    <wtls-match-panel
      [match]="match"
      [mapFullScreen]="mapFullScreen"
      [show]="showPanel"
      (showChange)="closePanel()"
      (unassign)="unassignMatch($event)"
    ></wtls-match-panel>
  </div>
  <wtls-google-map></wtls-google-map>
</div>
