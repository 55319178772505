import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { AddEditProviderState } from '../reducers';

const selectAddEditProviderState = (state: AppState) => state.addEditProvider;

export const selectAddEditProviderOpen = createSelector(
    selectAddEditProviderState,
    (state: AddEditProviderState) => state.open
);

export const selectAddEditProvider = createSelector(
    selectAddEditProviderState,
    (state: AddEditProviderState) => state.provider
);

export const selectAddEditProviderLoading = createSelector(
    selectAddEditProviderState,
    (state: AddEditProviderState) => state.loading
)

export const selectAddEditProviderError = createSelector(
    selectAddEditProviderState,
    (state: AddEditProviderState) => state.error
)