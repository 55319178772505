import { Component, Injectable } from '@angular/core';
import { SelectComponent } from './select.component';

@Injectable()
export class DropDownService {
  private select: SelectComponent;

  public register(select: SelectComponent) {
    this.select = select;
    console.log("strf", this.select);
  }

  public getSelect(): SelectComponent {
    console.log("rest", this.select);
    return this.select;
  }
}
