import { Component, OnInit,AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiService, ProvidersService } from 'src/app/services';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator'; 
import { ProvidersFacade } from 'src/app/services/facades/providers.facade';
import { EndPoints } from 'src/app/constants/endpoints';
import { map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'wtls-providersshow',
  templateUrl: './providersshow.component.html',
  styleUrls: ['./providersshow.component.scss']
})
export class ProvidersshowComponent implements OnInit,AfterViewInit {
  li:any;
  dataSource = new MatTableDataSource<any>();
  loading = true
  displayedColumns: string[] = ["Id","First Name", "Last Name","Cell_Phone",
    "Email","ClinicLocation",	"EmployeeStatus",	"OnboardingStage",	"CredentialingStage",	
    "Address",	"City",	"State"	, "Zip","mon_startTime","mon_endTime","tue_startTime","tue_endTime","wed_startTime","wed_endTime","thu_startTime","thu_endTime","fri_startTime","fri_endTime","sat_startTime","sat_endTime","sun_startTime","sun_endTime","Edit","Delete"];
  pageSize = 10
  pageSizeOptions = [5, 10, 50, 500];
  @ViewChild(MatPaginator) paginator: MatPaginator
  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private http: HttpClient,
    private apiService: ApiService,
    private changeDetector: ChangeDetectorRef,
    private providersFacade: ProvidersFacade,
    private providersService: ProvidersService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.apiService.get('/providersshow')
    .subscribe(Response => {
 
      // If response comes hideloader() function is called
      // to hide that loader
      if(Response){ 
        this.loading = false
      }
      console.log("datatest=>",Response)
      this.li=Response;
      this.dataSource.data =this.li;
      console.log("datatest1=>",this.dataSource)
      
    });
    //this.changeDetector.detectChanges()
    //this.dataSource.paginator = this.paginator
    function hideloader(){
      document.getElementById('loading').style.display = 'none';}
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator
  }
  /* delete(id : any)
  {
    console.log("delete=>",id);
      let deleteid = id;
      
           this.apiService.delete('/createcompany/'+ deleteid).subscribe(
        
            (response) => console.log(window.location.reload()),
            
            (error) => console.log(error)
          );  
  } */
  edit(id: any){
    this.router.navigate(['/dashboard/editproviders/'+id])
  }

  delete(id: any){
    console.log("delete=>",id);
    let deleteid = id;
    
         this.apiService.delete('/providersshow/'+ deleteid).subscribe(
      
          (response) => {
            this.snackBar.open('Provider Delete Successful','Dismiss', {
              verticalPosition: 'bottom',
              horizontalPosition: 'left',
              duration: 2750
            })
            window.location.reload()
          },
          
          (error) => console.log(error)
        );  
  }

  exportCsv(){
    console.log('export CSV')

    // 
    // this.providersService.exportCsv().subscribe(
    //   (response) => {
    //     console.log('rrrrr',response)
    //   },
    //   (error) => {
    //     console.log('error', error)
    //   }
    // )
    this.providersService.exportCsv()
        .subscribe(
          (response) => {
            console.log('row',response)
            var contentType = 'text/csv';
            var csvFile = new Blob([response], {type: contentType});


            var a = document.createElement('a');
            a.download = 'providers.csv';
            a.href = window.URL.createObjectURL(csvFile);
            a.textContent = 'Download CSV';
            
            a.dataset.downloadurl = [contentType, a.download, a.href].join(':');
            
            document.body.appendChild(a);
            a.click()
            a.remove()
           // return response
          },
          (error) => {

          }
        )
       

    console.log('after csv')
  }

}

