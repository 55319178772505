<wtls-unassigned-filter-bar></wtls-unassigned-filter-bar>
<div class="unassigned-container" [ngClass]="{'minimized-padding': !(mapFullScreen$ | async), 'full-padding': (mapFullScreen$ | async)}">
    <div class="view-toggle">
        <button 
            *ngIf="!(mapFullScreen$ | async)"
            mat-stroked-button 
            class="view-toggle-btn"
            (click)="toggleMap()"
            >
            Toggle View
        </button>
    </div>
    <div class="main-container">
        <div class="column column-left" *ngIf="!(mapFullScreen$ | async)" [ngClass]="{'hide': showMap}">
            <div class="match-wrapper">
                <wtls-match></wtls-match>
            </div >
            <div class="column-left-bottom">
                <wtls-patients [patients]="patients$ | async" [matchPatient]="matchPatient$ | async" [loading]="patientsLoading$ | async"></wtls-patients>
                <wtls-providers [providers]="providers$ | async" [matchProvider]="matchProvider$ | async" [loading]="providersLoading$ | async"></wtls-providers>
            </div>
        </div>
        <div class="column column-right" [ngClass]="{'minimized': !(mapFullScreen$ | async), 'hide': !showMap}">
            <div class="map-container" [ngClass]="{'map-minimized unassigned-map-minimized': !(mapFullScreen$ | async)}">
                <wtls-unassigned-google-map [patients]="patients$ | async" [providers]="providers$ | async"></wtls-unassigned-google-map>
            </div>
        </div>
    </div>
</div>
<wtls-add-edit-patient></wtls-add-edit-patient>
<wtls-add-edit-provider></wtls-add-edit-provider>