import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Patient, Provider, Match } from '../../../models'

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  patient: Patient
  provider: Provider
  match: Match
  type: string
  errorData: string[]
  row: string
  field: string
  constructor(
    private router: Router,
    private dialogref: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    ) { }

  ngOnInit(): void {
    if(this.returnFieldAndRow(this.data)){
      console.log(this.data)
      this.errorData = this.data.split(',')
      this.type = this.errorData[0]
      this.row = this.errorData[2]
      this.field = this.errorData[1] 
    }
    else if (this.data == 'uploadCSVpatients' || this.data == 'uploadCSVproviders' || this.data == 'MissingApptDetails') {
      this.type = this.data;
    } else if (this.instanceOfPatient(this.data)) {
      this.patient = this.data;
    } else if (this.instanceOfProvider(this.data)) {
      this.provider = this.data;
    } else if (this.instanceOfMatch(this.data)) {
      this.match = this.data;
    }
  }

  instanceOfPatient(object: any): object is Patient {
    return 'insuranceType' in object;
  }

  instanceOfProvider(object: any): object is Provider {
    return 'employeeStatus' in object;
  }

  instanceOfMatch(object: any): object is Match {
    return 'patient' in object;
  }

  returnFieldAndRow(data: string){
    let msg = data.split(',')
    if(msg[0] == 'uploadFailure')
      return true
    else return false
  }

  confirm(): void {
    this.dialogref.close(true);
  }

  uploadFailure(): void {
    this.dialogref.close(true)
  }

  cancel(): void {
    this.dialogref.close(false);
  }

  missingDetails(): void {
    this.dialogref.close(true)
    this.router.navigate(['/dashboard/unassigned'])
  }
}
