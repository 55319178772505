import { createReducer, on, Action } from '@ngrx/store';
import { Provider } from "src/app/models";
import { ProvidersActions } from '../actions';


export interface ProvidersState {
    list: Provider[],
    loading: boolean,
    error: string
}

const initialState: ProvidersState = {
    list: [],
    loading: false,
    error: ''
}

const reducer = createReducer(
    initialState,
    on(ProvidersActions.loadProviders, (state) => ({ ...state, loading: true })),
    on(ProvidersActions.loadProvidersSuccess, (state, { providers }) => ({ ...state, list: providers, loading: false })),
    on(ProvidersActions.loadProvidersError, (state, { error }) => ({ ...state, loading: false, error: error })),
    on(ProvidersActions.clearProviders, (state) => ({ ...state, list: [] }))
)

export function providersReducer(state: ProvidersState | undefined, action: Action) {
    return reducer(state, action);
}