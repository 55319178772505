import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AuthGuard } from './components/dashboard/auth-guard/auth.guard';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { UnassignedComponent } from './components/dashboard/unassigned/unassigned.component';
import { AssignedComponent } from './components/dashboard/assigned/assigned.component';
import { NewClientsComponent } from './components/dashboard/new-clients/new-clients.component';
import { ClientFormComponent } from './components/client-form/client-form.component';
import { CompleteComponent } from './components/complete/complete.component';
import { CompleteGuard } from './components/complete/guard/complete.guard';

import { ProvidersFormComponent } from './components/providers-form/providers-form.component';

import { AppointmentComponent } from './components/dashboard/appointment/appointment.component';
import { PcompleteComponent } from './components/pcomplete/pcomplete.component';
import { PcompleteGuard } from './components/pcomplete/guard/pcomplete.guard';
import { UsersComponent } from './components/users/users.component';
import { AuditlogsComponent } from './components/auditlogs/auditlogs.component';
import { UsersdetailsComponent } from './components/usersdetails/usersdetails.component';
import { EditusersComponent } from './components/editusers/editusers.component';
import { BlocksComponent } from './components/blocks/blocks.component';
import { CompanyComponent } from './components/company/company.component';
import { CreatecompanysComponent } from './components/createcompanys/createcompanys.component';
import { EditcompanyComponent } from './components/editcompany/editcompany.component';
import { CliniclocationsComponent } from './components/cliniclocations/cliniclocations.component';
import { CliniclocationshowComponent } from './components/cliniclocationshow/cliniclocationshow.component';
import { EditlocationsComponent } from './components/editlocations/editlocations.component';
import { ProvidersshowComponent } from './components/providersshow/providersshow.component';
import { EditprovidersComponent } from './components/editproviders/editproviders.component';
import { ClientsshowComponent } from './components/clientsshow/clientsshow.component';
import { EditclientComponent } from './components/editclient/editclient.component';
import { ProvidersupdatelogsComponent } from './components/providersupdatelogs/providersupdatelogs.component';
import { CompanylogsComponent } from './components/companylogs/companylogs.component';
import { ClientComponent } from './components/client/client.component';
import { LocationsComponent } from './components/locations/locations.component';
import { AppoinmentbookingComponent } from './components/appoinmentbooking/appoinmentbooking.component';

import { RoleGuard } from './components/dashboard/role-guard/role.guard'; 
const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], children: [
    { path: '', pathMatch: 'full', redirectTo: 'unassigned' },
    { path: 'unassigned', component: UnassignedComponent },
    { path: 'assigned', component: AssignedComponent },
    { path: 'new-clients', component: NewClientsComponent },
    { path: 'providers-form', component: ProvidersFormComponent },
    { path: 'complete-providers', component: PcompleteComponent },
    { path: 'referral', component: PcompleteComponent },
    { path: 'users', component: UsersComponent },
    { path: 'auditlogs', component: AuditlogsComponent,canActivate: [RoleGuard] },
    { path: 'usersdetails', component: UsersdetailsComponent },
    { path: 'usersedit/:id', component: EditusersComponent },
    { path: 'blocks', component: BlocksComponent,canActivate: [RoleGuard] },
    { path: 'company', component: CompanyComponent },
    { path: 'createcompanys', component: CreatecompanysComponent },
    { path: 'editcompany/:id', component: EditcompanyComponent },
    { path: 'locations', component: CliniclocationsComponent },
    { path: 'appointment', component: AppointmentComponent },
    { path: 'cliniclocation', component: CliniclocationshowComponent },
    { path: 'editlocations/:id',component:EditlocationsComponent},
    { path: 'providersshow',component:ProvidersshowComponent},
    { path: 'editproviders/:id',component:EditprovidersComponent},
    { path: 'clientsshow',component:ClientsshowComponent},
    { path: 'editclient/:id',component:EditclientComponent},
    { path: 'providerslogs',component:ProvidersupdatelogsComponent},
    { path: 'companylogs/:id',component:CompanylogsComponent},
    { path: 'create-client',component:ClientComponent},
    { path: 'create-client-locations',component:LocationsComponent},
    { path: 'appointment-booking',component:AppoinmentbookingComponent}

  ]},
  { path: 'login', component: LoginComponent },

  
 

  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'intake', component: ClientFormComponent, data: { formID: 1 } },
  { path: 'referral', component: ClientFormComponent, data: { formID: 2 } },
  //{ path: 'form-complete', component: CompleteComponent, canActivate: [CompleteGuard] },
  { path: 'form-complete', component: CompleteComponent, },
  //{ path: 'complete-providers', component: PcompleteComponent, canActivate: [PcompleteGuard] },
  { path: 'complete-providers', component: PcompleteComponent,  },
  { path: '**', redirectTo: '' }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
