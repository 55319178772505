import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import { PatientsService } from '../patients/patients.service';
import { PatientsActions } from 'src/app/store/actions';
import { Patient } from 'src/app/models';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { PatientsSelectors } from 'src/app/store/selectors';
import { take, map, switchMap, mergeMap, debounceTime, catchError } from 'rxjs/operators';
import { AgmGeocoder } from '@agm/core';
import { FiltersFacade } from './filters.facade';
import lodash from 'lodash';
import { MatchFacade } from './match.facade';
import { ProvidersFacade } from './providers.facade';

@Injectable({
  providedIn: 'root',
})
export class PatientsFacade {
  private patients$ = new BehaviorSubject<Patient[]>([]);

  private allPatients$ = new BehaviorSubject<Patient[]>([]);

  private loading$ = new BehaviorSubject<boolean>(false);

  private error$ = new BehaviorSubject<string>('');

  constructor(
    private store: Store<AppState>,
    private patientsService: PatientsService,
    private filtersFacade: FiltersFacade,
    private matchFacade: MatchFacade,
    private geo: AgmGeocoder
  ) {
    this.store
      .select(PatientsSelectors.selectPatientsList)
      .subscribe((patients) => {
        this.patients$.next(patients);

        if (this.allPatients$.getValue().length < patients.length)
          this.allPatients$.next(patients);
      });

    this.store
      .select(PatientsSelectors.selectPatientsLoading)
      .subscribe((loading) => {
        this.loading$.next(loading);
      });

    this.store
      .select(PatientsSelectors.selectPatientsError)
      .subscribe((error) => {
        this.error$.next(error);
      });
  }

  loadPatients(): void {
console.log("testtts");

    this.store.dispatch(PatientsActions.loadPatients());

    this.filtersFacade
      .getFilters()
      .pipe(
        take(1),
        mergeMap((filters) =>
          this.patientsService
            .getPatients({
              name: filters.name,
              patientName: filters.patientName,
              clientType: filters.clientTypes,
              insuranceType: filters.insuranceTypes,
              minAge: filters.age.min,
              maxAge: filters.age.max,
              intakeStage: filters.intakeStages,
              street: filters.address,
              city: filters.city,
              state: filters.state,
              zip: filters.zip,
              clinicLocation: filters.clinicLocation,
              hasSchedule: filters.patientsHasSchedule,
              startTime:filters.startTime,
              endTime:filters.endTime,
              day:filters.day
             
            })
            .pipe(
              map((patients) => {
                this.matchFacade
                  .getPatient()
                  .pipe(take(1))
                  .subscribe((patient) => {
                    if (patient && !patients.some((p) => p.id == patient.id))
                      this.matchFacade.clearPatient();
                  });
                
                return patients;
              })
            )
        )
      )
      .subscribe(
        (patients) => {
          this.store.dispatch(PatientsActions.loadPatientsSuccess({ patients }));
        },
        (error) => {
          console.log(error);
          this.store.dispatch(PatientsActions.loadPatientsError({ error }));
        }
      );
  }

  loadNewPatients(): Observable<any> {
    this.store.dispatch(PatientsActions.loadPatients());

    // geocode patients with schedule
    // this.patientsService.getPatients( {hasSchedule: true })
    //   .pipe(take(1))
    //   .subscribe(patients => {
    //     this.patientsService.geocode(patients)
    //       .pipe(take(1))
    //       .subscribe(patients => {
    //         console.log(patients);
    //         // geocode patients without schedule
    //         this.patientsService.getPatients({ hasSchedule: false })
    //           .pipe(take(1))
    //           .subscribe(pats => {
    //             this.patientsService.geocode(pats)
    //               .pipe(take(1))
    //               .subscribe(pats => console.log)
    //           })
    //       })
    //   })

    return this.filtersFacade
      .getFilters()
      .pipe(
        take(1),
        mergeMap((filters) =>
          this.patientsService
            .getPatients({
              name: filters.name,
              patientName: filters.patientName,
              clientType: filters.clientTypes,
              insuranceType: filters.insuranceTypes,
              minAge: filters.age.min,
              maxAge: filters.age.max,
              intakeStage: filters.intakeStages,
              street: filters.address,
              city: filters.city,
              state: filters.state,
              zip: filters.zip,
              clinicLocation: filters.clinicLocation,
              day: filters.day,
              startTime:filters.startTime,
              endTime:filters.endTime
              
            })
            .pipe(
              map((patients) => {
                if (patients.length > 0) { // TODO remove check and make seperate method for geocode new patients
                  this.patientsService.geocode(patients)
                    .subscribe(
                      patients => {
                        console.log(patients);
                        this.store.dispatch(

                          PatientsActions.loadPatientsSuccess({
                            patients
                          })
                        );
                      },
                      error => {
                        this.store.dispatch(PatientsActions.loadPatientsError({
                          error
                        }));
                      }
                    )
                }

                  return patients
              }),
              catchError((error) => {
                this.store.dispatch(PatientsActions.loadPatientsError({
                  error
                }));

                return error
              })
            )
        )
      )
  }

  getPatients(): Observable<Patient[]> {
    return this.patients$.asObservable();
  }

  getFullPatient(id: number): Observable<Patient> {
    return this.patientsService
      .getPatient(id);
  }

  getPatientStatus(id: number): Observable<any>{
    return this.patientsService.getPatientStatus(id)
  }

  getAllPatients(): Observable<Patient[]> {
    return this.allPatients$.asObservable();
  }

  updatePatientInList(patient: Patient): void {
    let patients = [ ...this.patients$.getValue() ];

    const newPatients = [ ...this.patients$.getValue() ].map(p => {
      if (patient.id == p.id)
        return patient;
      else
        return p;
    })

    this.store.dispatch(PatientsActions.updatePatients({ patients: newPatients }));
  }

  deletePatient(patient: Patient): Observable<any> {
    // If patient is selected, unselect
    this.matchFacade
      .getPatient()
      .pipe(take(1))
      .subscribe((matchPatient) => {
        if (matchPatient && patient.id == matchPatient.id) {
          this.matchFacade.clearPatient();
        }
      });

    return this.patientsService.deletePatient(patient.id).pipe(
      switchMap(() =>
        this.filtersFacade.getFilters().pipe(
          map((filters) => {
            this.loadPatients();
          })
        )
      )
    );
  }

  clearPatients(): void {
    if (this.patients$.getValue().length > 0)
      this.store.dispatch(PatientsActions.clearPatients());
  }

  clearAllPatients(): void {
    if (this.allPatients$.getValue().length > 0)
      this.allPatients$.next([])
  }

  uploadCSV(files: File[]): Observable<any> {
    return this.patientsService.uploadCSV(files);
  }

  getLoading(): Observable<boolean> {
    return this.loading$.asObservable();
  }

  getError(): Observable<string> {
    return this.error$.asObservable();
  }
}
