import { createAction, props } from '@ngrx/store';

export enum OptionsActionTypes {
    SET_CLINIC_LOCATIONS = '[Options] Set Clinic Locations',
    SET_CLIENT_TYPES = '[Options] Set Client Types',
    SET_INSURANCE_TYPES = '[Options] Set Insurance Types',
    SET_INTAKE_STAGES = '[Options] Set Intake Stages',
    SET_EMPLOYEE_STATUSES = '[Options] Set Employee Statuses',
    SET_ONBOARDING_STAGES = '[Options] Set Onboarding Stages',
    SET_CREDENTIALING_STAGES = '[Options] Set Credentialing Stages',
}

export const setClinicLocations = createAction(
    OptionsActionTypes.SET_CLINIC_LOCATIONS,
    props<{ clinicLocations: string[] }>()
)

export const setClientTypes = createAction(
    OptionsActionTypes.SET_CLIENT_TYPES,
    props<{ clientTypes: string[] }>()
)

export const setInsuranceTypes = createAction(
    OptionsActionTypes.SET_INSURANCE_TYPES,
    props<{ insuranceTypes: string[] }>()
)

export const setIntakeStages = createAction(
    OptionsActionTypes.SET_INTAKE_STAGES,
    props<{ intakeStages: string[] }>()
)

export const setEmployeeStatuses = createAction(
    OptionsActionTypes.SET_EMPLOYEE_STATUSES,
    props<{ employeeStatuses: string[] }>()
)

export const setOnboardingStages = createAction(
    OptionsActionTypes.SET_ONBOARDING_STAGES,
    props<{ onboardingStages: string[] }>()
)

export const setCredentialingStages = createAction(
    OptionsActionTypes.SET_CREDENTIALING_STAGES,
    props<{ credentialingStages: string[] }>()
)