<div class="clients-container">
  <div class="clients-header">
    <h2>
      <a href="#"  mat-flat-button class="tab"  [routerLink]="['/referral']"  routerLinkActive="active"   type="button"  color="primary">New Client</a> 
     
     
      <div class="spinner-wrapper" *ngIf="loading">
        <mat-progress-spinner
          color="primary"
          mode="indeterminate"
          diameter="30"
        >
        </mat-progress-spinner>
      </div>
     
    </h2>
   
    <button
      mat-stroked-button
      class="export-button"
      type="button"
      (click)="exportCSV()"
    >
      Export CSV
    </button>
  </div>
  <div class="client-table">
    <div class="table-wrapper">
      <table mat-table [dataSource]="page?.content" matSort (matSortChange)="sortChange($event)" multiTemplateDataRows>
        <ng-container
          [matColumnDef]="column"
          *ngFor="let column of displayedColumns"
        >
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column }}</th>
          <!-- <td mat-cell *matCellDef="let row">{{ row[column] }}</td> -->

          <ng-container *matCellDef="let row">
            <td mat-cell *ngIf="column == 'Birth Date' || column == 'Date of Diagnosis' || column == 'Subscriber Date of Birth'">{{ row[column] && row[column]['value'] | date: 'MM/dd/yyyy' }}</td>
            <td mat-cell *ngIf="column == 'Copy of insurance-Back' || column == 'Copy of insurance-Front'"><div class="download" *ngIf="row[column] && row[column]['value']" (click)="downloadClicked(row[column])"><mat-icon>get_app</mat-icon><span class="text">{{ row[column] | file }}</span></div></td>
            <td mat-cell *ngIf="column != 'Birth Date' && column != 'Date of Diagnosis' && column != 'Subscriber Date of Birth' && column != 'Copy of insurance-Back' && column != 'Copy of insurance-Front'">{{ row[column] && row[column]['value'] }}</td>
            <td class="sticky-col ng-star-inserted" mat-cell *ngIf="column == 'edit'" style="width:20%">
            <button mat-icon-button color="primary" title="Edit Contact" (click)="openDialog(row)"><mat-icon>edit</mat-icon></button>
            </td>
          </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <mat-paginator
      class="paginator"
      [length]="page?.totalElements"
      [pageSize]="page?.size"
      [pageSizeOptions]="pageSizeOptions"
      (page)="pageChange.emit($event)"
    >
    </mat-paginator>
  </div>
</div>
