import { Injectable } from "@angular/core";
import { catchError, mergeMap, switchMap, map, take } from "rxjs/operators";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { ProvidersService } from 'src/app/services';
import { AddEditProviderActions } from '../actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of } from 'rxjs';
import { ProvidersFacade } from 'src/app/services/facades/providers.facade';

@Injectable({
  providedIn: "root",
})
export class AddEditProviderEffects {

    updateProvider$ = createEffect(() => 
        this.actions$.pipe(
            ofType(AddEditProviderActions.updateProvider),
            mergeMap(action => this.providersService.updateProvider(action.provider).pipe(
                switchMap(providers => {
                    this.providersFacade.loadProviders();
                    
                    return [
                    // AddEditProviderActions.updateProviderSuccess({ success: 'Update patient successful' }),
                    AddEditProviderActions.updateProviderSuccess(),
                    AddEditProviderActions.closePanel(),
                    AddEditProviderActions.clearProvider()
                ]
            })
            ))
        ))

    // updateProviderSuccess$ = createEffect(() => 
    //     this.actions$.pipe(
    //         ofType(AddEditProviderActions.updateProviderSuccess),
    //             mergeMap(action => {
    //                 console.log('hi!!!');
    //                 this.snackbar.open(action.success, '' , {
    //                     duration: 4000,
    //                     horizontalPosition: 'left',
    //                     verticalPosition: 'bottom'
    //                 })    

    //                 return of(action)
    //             })
    //     ))

    constructor(private actions$: Actions, private providersService: ProvidersService, private providersFacade: ProvidersFacade, private snackbar: MatSnackBar) { }
}
