import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormSection } from 'src/app/models';

@Component({
  selector: 'wtls-form-section',
  templateUrl: './form-section.component.html',
  styleUrls: ['./form-section.component.scss']
})
export class FormSectionComponent implements OnInit {
  @Input() formSection: FormSection;
  @Input() parentGroup: FormGroup;

  constructor() { }

  ngOnInit(): void {
  }

  hasRequired(): boolean {
    return this.formSection.fields.some(field => field.isRequired == true);
  }
}
