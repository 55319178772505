import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, AuthService } from 'src/app/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EndPoints } from 'src/app/constants/endpoints';

@Component({
  selector: 'wtls-appoinmentbooking',
  templateUrl: './appoinmentbooking.component.html',
  styleUrls: ['./appoinmentbooking.component.scss']
})
export class AppoinmentbookingComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  patientName = ''
  providerName = ''
  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private http: HttpClient,
    private apiService: ApiService,
    private changeDetector: ChangeDetectorRef,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private snackbar: MatSnackBar
  ) { 
    this.form = this.formBuilder.group({
      patientName: [''],
      providerName: [''],
      mon_startTime:[''],
      mon_endTime:[''],
      tue_startTime:[''],
      tue_endTime:[''],
      wed_startTime:[''],
      wed_endTime:[''],
      thu_startTime:[''],
      thu_endTime:[''],
      fri_startTime:[''],
      fri_endTime:[''],
      sat_startTime:[''],
      sat_endTime:[''],
      sun_startTime:[''],
      sun_endTime:[''],
  
    })
  }
  get f() { return this.form.controls; }
  ngOnInit(): void {
    let url = window.location.href;
    let params = (new URL(url)).searchParams;
    var clientId = params.get('Client');
    var providerId = params.get('Provider');

    this.apiService.get('/clientshow/'+clientId).subscribe(
      (response: any) => {
        console.log('response', response)
        this.patientName = response[0].first_name+' '+response[0].last_name
      },
      (error) => {
        console.log('err',error)
      }
    )

    this.apiService.get('/providersshow/'+providerId).subscribe(
      (response: any)=>{
        console.log('response', response)
        this.providerName = response[0].first_name+' '+response[0].last_name
      },
      (error) => {
        console.log('err', error)
      }
    )
  }
  submitForm() {
   
    this.submitted = true;

      
        if (this.form.invalid) {
            return;
        }
        else{
          let Formpostdata = this.form.value;
           let url = window.location.href;
          let params = (new URL(url)).searchParams;
          var patient_id = params.get('Client');
          var providers_id = params.get('Provider');
          Formpostdata.patientId = patient_id;
          Formpostdata.providerId =  providers_id; 
          
          console.log("gg=>",Formpostdata)
          this.apiService.post('/booking/savebooking/', Formpostdata).subscribe(
        
            (response) =>{
              this.snackbar.open('Booking Successfull!', 'Dismiss', {
              verticalPosition: 'bottom',
              horizontalPosition: 'left',
            }); 
            window.location.href = EndPoints.BASE_URL+'dashboard/assigned';
            },
            
            (error) => console.log(error)
          ); 
        }
        
        
  }
  cancelbookingForm(): void {
    this.router.navigate(['/dashboard/unassigned'])
  }

}
