import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription, Subject, EMPTY } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'wtls-min-max',
  templateUrl: './min-max.component.html',
  styleUrls: ['./min-max.component.scss']
})
export class MinMaxComponent implements OnInit {
  @Input() min: number;
  @Output() minChange = new EventEmitter<number>();
  @Input() max: number;
  @Output() maxChange = new EventEmitter<number>();

  minSubscription: Subscription;
  maxSubscription: Subscription;
  minInput$ = new Subject<number>();
  maxInput$ = new Subject<number>();

  constructor() {
    this.minSubscription = this.minInput$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(value => {
        this.onMinChange(value);
        return EMPTY
      })
    ).subscribe();

    this.maxSubscription = this.maxInput$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(value => {
        this.onMaxChange(value);
        return EMPTY
      })
    ).subscribe();
   }

  ngOnInit(): void {
  }

  onMinChange(min: number): void {
    this.minChange.emit(min);
  }

  onMaxChange(max: number): void {
    this.maxChange.emit(max);
  }
}
