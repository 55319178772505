import { createAction, props } from '@ngrx/store';
import { Provider } from '../../models';

export enum AddEditProviderActionTypes {
    OPEN_PANEL = '[Add Edit Provider] Open Panel',
    CLOSE_PANEL = '[Add Edit Provider] Close Panel',
    SET_PROVIDER = '[Add Edit Provider] Set Provider',
    CLEAR_PROVIDER = '[Add Edit Provider] Clear Provider',
    UPDATE_PROVIDER = '[Add Edit Provider] Update Provider',
    UPDATE_PROVIDER_SUCCESS = '[Add Edit Provider] Update Provider Success',
    UPDATE_PROVIDER_ERROR = '[Add Edit Provider] Update Provider Error',
}

export const openPanel = createAction(
    AddEditProviderActionTypes.OPEN_PANEL
)

export const closePanel = createAction(
    AddEditProviderActionTypes.CLOSE_PANEL
)

export const setProvider = createAction(
    AddEditProviderActionTypes.SET_PROVIDER,
    props<{ provider: Provider }>()
)

export const clearProvider = createAction(
    AddEditProviderActionTypes.CLEAR_PROVIDER
)

export const updateProvider = createAction(
    AddEditProviderActionTypes.UPDATE_PROVIDER,
    props<{ provider: Provider }>()
)

export const updateProviderSuccess = createAction(
    AddEditProviderActionTypes.UPDATE_PROVIDER_SUCCESS,
    // props<{ success: string }>()
)

export const updateProviderError = createAction(
    AddEditProviderActionTypes.UPDATE_PROVIDER_ERROR,
    props<{ error: string }>()
)