
<div class="page-container">
    <div class="content-container">
      
      <div class="container">

        
        <div class="row"  *ngIf="showMsg">
          <div class="col-xs-12">
            <p class="alert alert-primary alert-dismissible">
              
                <strong>Registration Success!</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
  
            </p>
          </div>
        </div>
  
        

        <form [formGroup]="form" (ngSubmit)="submitForm()" autocomplete="off"
        >
          <div class="header-section">
            <h2 class="title">Create Account</h2>
          </div>


            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                      <label for="name"> Full Name </label>
                      <input type="text" formControlName="fullname"  class="form-control" [ngClass]="{ 'is-invalid': submitted && f.fullname.errors }"  placeholder="Full Name">
                      <div *ngIf="submitted && f.fullname.errors" class="invalid-feedback">
                        <span *ngIf="f.fullname.errors.required">Fullname is required</span>
                        <span *ngIf="f.fullname.errors.specialCharacters">Avoid using special characters in name</span>
                    </div>
                </div>
                
                <div class="form-group">
                      <label for="name"> Address </label>
                      <input type="text" formControlName="Address"  name="Address" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Address.errors }"  placeholder="Address">
                      <div *ngIf="submitted && f.Address.errors" class="invalid-feedback">
                        <span *ngIf="f.Address.errors.required">Address is required</span>
                        <span *ngIf="f.Address.errors.addressValidation">Avoid using special characters in name</span>
                    </div>
                    </div>
  
  
                <div class="form-group">
                      <label for="name">  Password </label>
                      <input type="password" formControlName="password"  name="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"  placeholder="Password">
                      <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <span *ngIf="f.password.errors.required"> Password is required</span>
                    </div>
                    </div>
               
                    <div class="form-group" >
                      <label for="name" > Select Role </label>
                      <select class="form-control"  list="browsers" formControlName="role"  name="role" [ngClass]="{ 'is-invalid': submitted && f.role.errors }">
                        <option value="2" *ngIf="isSuperAdmin==true">Admin</option>
                        <option value="3">User</option> 
                      </select>
                      <div *ngIf="submitted && f.role.errors" class="invalid-feedback">
                        <div *ngIf="f.role.errors.required">Role is required</div>
                    </div>
                    </div>
              </div>
              <!-- <div class="form-group" *ngIf="isSuperAdmin == true">
                <label for="name" > Select Role </label>
                      <select class="form-control"   formControlName="role"  name="role" [ngClass]="{ 'is-invalid': submitted && f.role.errors }">
                        <option value="2">Admin</option>
                        <option value="3">User</option> 
                      </select>
                      <div *ngIf="submitted && f.role.errors" class="invalid-feedback">
                        <div *ngIf="f.role.errors.required">Select role is required</div>
                  </div>
              </div> -->
              <div class="col-md-6">
                <div class="form-group">
                      <label for="name"> Email </label>
                      <input type="text" formControlName="email" name="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"   placeholder="Email">
                      <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">Email is required</div>
                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                    </div>
                    </div>
                <div class="form-group">
                      <label for="name"> Contact No </label>
                      <input type="text" formControlName="contactno" name="contactno" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.contactno.errors }"   placeholder="Contact no">
                      <div *ngIf="submitted && f.contactno.errors" class="invalid-feedback">
                        <span *ngIf="f.contactno.errors.required">Contact No is required</span>
                        <span *ngIf="f.contactno.errors.contactValidation">Please enter a valid Contact</span>
                    </div>
                    </div>
                <div class="form-group">
                      <label for="name"> Confirm Password </label>
                      <input type="password" formControlName="conformpassword" name="conformpassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.conformpassword.errors }"  placeholder="Confirm password">
                      <div *ngIf="submitted && f.conformpassword.errors" class="invalid-feedback">
                        <span *ngIf="f.conformpassword.errors.required">Password is required</span>
                        <span *ngIf="f.conformpassword.errors.passwordMismatch">Passwords do not match</span>
                    </div>
                    </div>
            
              </div>
             
              
          
          <div class="footer-section">
            <hr />
            <button
              type="submit"
              class="button submit-button"
             
            >
              <span class="button-text">Submit Form</span>
            </button>
            <button
            type="button"
            (click)="cancelForm()"
            class="button cancel-button"
          >
            <span class="button-text">Cancel</span>
          </button>

        </div>
          </div>
        </form>


       
      
      </div>
    </div>
  </div>
  