
<div class="page-container">
    <div class="content-container">
      
      <div class="container">

     
       <!--  <div class="row"  *ngIf="showMsg">
          <div class="col-xs-12">
            <p class="alert alert-primary alert-dismissible">
              
                <strong>Company Updated Success!</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
  
            </p>
          </div>
        </div> -->
  
        

        <form [formGroup]="form" (ngSubmit)="submitForm()" autocomplete="on">
          <div class="header-section">
            <h2 class="title">Edit Company</h2>
          </div>
          
          <div *ngFor="let e of lis;" >
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                      <label for="name"> Company Name </label>
                      <input type="text" formControlName="comapny_name"  name="comapny_name"  class="form-control"  [(ngModel)]="e.comapny_name"  placeholder="Company Name">
                      <div *ngIf="submitted && f.comapny_name.errors" class="invalid-feedback">
                        <span *ngIf="f.comapny_name.errors.specialCharacters">Avoid using special characters in name</span>
                    </div>
                </div>
                
                <div class="form-group">
                      <label for="name"> Email </label>
                      <input type="text" formControlName="company_email"  name="company_email" class="form-control"  [(ngModel)]="e.company_email"   placeholder="Email">
                      <!-- <div *ngIf="submitted && f.company_email.errors" class="invalid-feedback">
                        <span *ngIf="f.company_email.errors.email">Avoid using special characters in name</span>
                    </div> -->
                    </div>
  
  
                <div class="form-group">
                      <label for="name"> Address </label>
                      <input type="text" formControlName="company_address"  name="company_address" class="form-control" [(ngModel)]="e.company_address"   placeholder="Address">
                      <div *ngIf="submitted && f.company_address.errors" class="invalid-feedback">
                        <span *ngIf="f.company_address.errors.addressValidation">Avoid using special characters in name</span>
                    </div>
                    </div>
               
                    <div class="form-group">
                      <label for="name"> Opening Time </label>

                      <select class="select-input form-control" formControlName="companay_opening_time" name="companay_opening_time"  >
                        <option *ngFor="let a of lis;" value="a.companay_opening_time" selected="selected" >{{a.companay_opening_time}}</option>
                        <option value="12.00 AM" selected="selected">12.00 AM</option>
                        <option value="12.30 AM" selected="selected">12.30 AM</option>
                        <option value="1.00 AM" selected="selected">1.00 AM</option>
                        <option value="1.30 AM" selected="selected">1.30 AM</option>
                        <option value="2.00 AM" selected="selected">2.00 AM</option>
                        <option value="2.30 AM" selected="selected">2.30 AM</option>
                        <option value="3.00 AM" selected="selected">3.00 AM</option>
                        <option value="3.30 AM" selected="selected">3.30 AM</option>
                        <option value="4.00 AM" selected="selected">4.00 AM</option>
                        <option value="4.30 AM" selected="selected">4.30 AM</option>
                        <option value="5.00 AM" selected="selected">5.00 AM</option>
                        <option value="5.30 AM" selected="selected">5.30 AM</option>
                        <option value="6.00 AM" selected="selected">6.00 AM</option>
                        <option value="6.30 AM" selected="selected">6.30 AM</option>
                        <option value="7.00 AM" selected="selected">7.00 AM</option>
                        <option value="7.30 AM" selected="selected">7.30 AM</option>
                        <option value="8.00 AM" selected="selected">8.00 AM</option>
                        <option value="8.30 AM" selected="selected">8.30 AM</option>
                        <option value="9.00 AM" selected="selected">9.00 AM</option>
                        <option value="9.30 AM" selected="selected">9.30 AM</option>
                        <option value="10.00 AM" selected="selected">10.00 AM</option>
                        <option value="10.30 AM" selected="selected">10.30 AM</option>
                        <option value="11.00 AM" selected="selected">11.00 AM</option>
                        <option value="11.30 AM" selected="selected">11.30 AM</option>
                        <option value="12.00 PM" selected="selected">12.00 PM</option>
                        <option value="12.30 PM" selected="selected">12.30 PM</option>
                        <option value="1.00 PM" selected="selected">1.00 PM</option>
                        <option value="1.30 PM" selected="selected">1.30 PM</option>
                        <option value="2.00 PM" selected="selected">2.00 PM</option>
                        <option value="2.30 PM" selected="selected">2.30 PM</option>
                        <option value="3.00 PM" selected="selected">3.00 PM</option>
                        <option value="3.30 PM" selected="selected">3.30 PM</option>
                        <option value="4.00 PM" selected="selected">4.00 PM</option>
                        <option value="4.30 PM" selected="selected">4.30 PM</option>
                        <option value="5.00 PM" selected="selected">5.00 PM</option>
                        <option value="5.30 PM" selected="selected">5.30 PM</option>
                        <option value="6.00 PM" selected="selected">6.00 PM</option>
                        <option value="6.30 PM" selected="selected">6.30 PM</option>
                        <option value="7.00 PM" selected="selected">7.00 PM</option>
                        <option value="7.30 PM" selected="selected">7.30 PM</option>
                        <option value="8.00 PM" selected="selected">8.00 PM</option>
                        <option value="8.30 PM" selected="selected">8.30 PM</option>
                        <option value="9.00 PM" selected="selected">9.00 PM</option>
                        <option value="9.30 PM" selected="selected">9.30 PM</option>
                        <option value="10.00 PM" selected="selected">10.00 PM</option>
                        <option value="10.30 PM" selected="selected">10.30 PM</option>
                        <option value="11.00 PM" selected="selected">11.00 PM</option>
                        <option value="11.30 PM" selected="selected">11.30 PM</option>
                        <option value="12.00 AM" selected="selected">12.00 AM</option>
                       </select>
                     
                    </div>
                    <div class="form-group">
                      <label for="name"> Clinic Location </label>
                    
                      <select class="form-control" formControlName="clinic_location_id"  name="clinic_location_id" >
                      
                        <option *ngFor="let a of locationsselectlistorders;" value="{{ a.id }}" >{{a.location_name}}</option>
                      </select>
                    
                    </div>
                    <div class="form-group">
                      <label for="name"> Client Form Subtitle </label>
                      <input type="text" formControlName="form_sub_title" [(ngModel)]="e.form_sub_title" name="form_sub_title" class="form-control"   placeholder="Client Form Subtitle">
                      <!-- <select class="form-control" formControlName="form_sub_title_id" name="user_id" >
                        <option *ngFor="let a of locationsselectform_titlelistorders;" value="{{a.id}}" >{{a.subtitle}}</option>
                      
                      </select> -->
                    
                    </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                      <label for="name"> Contact No </label>
                      <input type="text" formControlName="company_contactno" name="company_contactno" class="form-control"   placeholder="Contcat No">
                      <div *ngIf="submitted && f.company_contactno.errors" class="invalid-feedback">
                        <span *ngIf="f.company_contactno.errors.addressValidation">Avoid using special characters in name</span>
                    </div>
                    </div>
                <div class="form-group">
                      <label for="name"> Company Name Url </label>
                      <input type="text" formControlName="company_url" [(ngModel)]="e.company_url" name="company_url" class="form-control" >
                    
                    </div>
                <div class="form-group">
                      <label for="name" > Select User </label>
                    
                      <select  class="form-control" formControlName="user_id" name="user_id"  >
                      
                        <option *ngFor="let a of selectlistorders;" value="{{ a.id }}" >{{a.fullname}}</option>
                        
                      </select>
                 
                      
                    </div>
            

                    <div class="form-group">
                      <label for="name"> Closing Time </label>
                      <select class="select-input form-control" formControlName="company_closing_time" name="company_closing_time"  >
                        <option *ngFor="let a of lis;" value="a.company_closing_time" selected="selected" >{{a.company_closing_time}}</option>
                        <option value="12.00 AM" selected="1">12.00 AM</option>
                        <option value="12.30 AM" selected="selected">12.30 AM</option>
                        <option value="1.00 AM" selected="selected">1.00 AM</option>
                        <option value="1.30 AM" selected="selected">1.30 AM</option>
                        <option value="2.00 AM" selected="selected">2.00 AM</option>
                        <option value="2.30 AM" selected="selected">2.30 AM</option>
                        <option value="3.00 AM" selected="selected">3.00 AM</option>
                        <option value="3.30 AM" selected="selected">3.30 AM</option>
                        <option value="4.00 AM" selected="selected">4.00 AM</option>
                        <option value="4.30 AM" selected="selected">4.30 AM</option>
                        <option value="5.00 AM" selected="selected">5.00 AM</option>
                        <option value="5.30 AM" selected="selected">5.30 AM</option>
                        <option value="6.00 AM" selected="selected">6.00 AM</option>
                        <option value="6.30 AM" selected="selected">6.30 AM</option>
                        <option value="7.00 AM" selected="selected">7.00 AM</option>
                        <option value="7.30 AM" selected="selected">7.30 AM</option>
                        <option value="8.00 AM" selected="selected">8.00 AM</option>
                        <option value="8.30 AM" selected="selected">8.30 AM</option>
                        <option value="9.00 AM" selected="selected">9.00 AM</option>
                        <option value="9.30 AM" selected="selected">9.30 AM</option>
                        <option value="10.00 AM" selected="selected">10.00 AM</option>
                        <option value="10.30 AM" selected="selected">10.30 AM</option>
                        <option value="11.00 AM" selected="selected">11.00 AM</option>
                        <option value="11.30 AM" selected="selected">11.30 AM</option>
                        <option value="12.00 PM" selected="1">12.00 PM</option>
                        <option value="12.30 PM" selected="selected">12.30 PM</option>
                        <option value="1.00 PM" selected="selected">1.00 PM</option>
                        <option value="1.30 PM" selected="selected">1.30 PM</option>
                        <option value="2.00 PM" selected="selected">2.00 PM</option>
                        <option value="2.30 PM" selected="selected">2.30 PM</option>
                        <option value="3.00 PM" selected="selected">3.00 PM</option>
                        <option value="3.30 PM" selected="selected">3.30 PM</option>
                        <option value="4.00 PM" selected="selected">4.00 PM</option>
                        <option value="4.30 PM" selected="selected">4.30 PM</option>
                        <option value="5.00 PM" selected="selected">5.00 PM</option>
                        <option value="5.30 PM" selected="selected">5.30 PM</option>
                        <option value="6.00 PM" selected="selected">6.00 PM</option>
                        <option value="6.30 PM" selected="selected">6.30 PM</option>
                        <option value="7.00 PM" selected="selected">7.00 PM</option>
                        <option value="7.30 PM" selected="selected">7.30 PM</option>
                        <option value="8.00 PM" selected="selected">8.00 PM</option>
                        <option value="8.30 PM" selected="selected">8.30 PM</option>
                        <option value="9.00 PM" selected="selected">9.00 PM</option>
                        <option value="9.30 PM" selected="selected">9.30 PM</option>
                        <option value="10.00 PM" selected="selected">10.00 PM</option>
                        <option value="10.30 PM" selected="selected">10.30 PM</option>
                        <option value="11.00 PM" selected="selected">11.00 PM</option>
                        <option value="11.30 PM" selected="selected">11.30 PM</option>
                        <option value="12.00 AM" selected="selected">12.00 AM</option>
                       </select>
                       
                    </div>     
                    <div class="form-group">
                      <label for="name"> Client Form Title </label>
                     
                     <!--  <select class="form-control"  formControlName="form_title_id"  name="form_title_id">
                        <option *ngFor="let a of locationsselectlistorders;" value="{{a.id}}" >{{a.title}}</option>
                       
                      </select> -->
                      <input type="text" formControlName="form_title" [(ngModel)]="e.form_title" name="form_title" class="form-control" >
                    </div>
                   
              </div>
          <div class="footer-section">
            <hr />
            <button
              type="submit"
              class="button submit-button"
             
            >
              <span class="button-text">Submit form</span>
            </button>
            <button
            type="button"
            (click)="cancelForm()"
            class="button cancel-button"
          >
            <span class="button-text">Cancel</span>
          </button>
        </div>
          </div>
          </div>
        </form>


       
      
      </div>
    </div>
  </div>
  