import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Match } from 'src/app/models';

@Component({
  selector: 'wtls-match-record',
  templateUrl: './match-record.component.html',
  styleUrls: ['./match-record.component.scss']
})
export class MatchRecordComponent implements OnInit {
  @Input() index: number;
  @Input() match: Match;
  @Input() selected: Match;
  // @Input() expanded: boolean = false;

  @Output() select = new EventEmitter<Match>();
  @Output() unassign = new EventEmitter<Match>();

  constructor() { }

  ngOnInit(): void {

    console.log('cureenthgfjhg')
    
  }

  selectMatch(): void {
    // this.expanded = !this.expanded
    this.select.emit(this.match);
  }

  unassignMatch(): void {
    this.unassign.emit(this.match);
  }
}
