import { AgmGeocoder } from '@agm/core';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Patient } from 'src/app/models';
import { AddEditPatientActions } from 'src/app/store/actions';
import { AppState } from 'src/app/store/app.state';
import { AddEditPatientSelectors } from 'src/app/store/selectors';
import { PatientsService } from '../patients/patients.service';

@Injectable({
    providedIn: 'root',
})
export class AddEditPatientFacade {

    constructor(private store: Store<AppState>, private patientsService: PatientsService, private geo: AgmGeocoder) { }

    getOpen(): Observable<boolean> {
        return this.store.select(AddEditPatientSelectors.selectAddEditPatientOpen);
    }

    openPanel(): void {
        this.store.dispatch(AddEditPatientActions.openPanel());
    }

    closePanel(): void {
        this.store.dispatch(AddEditPatientActions.closePanel());
    }

    getPatient(): Observable<Patient> {
        return this.store.select(AddEditPatientSelectors.selectAddEditPatient);
    }

    setPatient(patient: Patient): void {
        this.store.dispatch(AddEditPatientActions.setPatient({ patient }));
    }

    clearPatient(): void {
        this.store.dispatch(AddEditPatientActions.clearPatient());
    }

    geocodePatient(patient: Patient): Observable<Patient> {
        return this.geo.geocode({
            address: `${patient.address.street}, ${patient.address.city} ${patient.address.state}, ${patient.address.zip}`,
          }).pipe(
              map((res) => {
                patient.address.lat = res[0].geometry.location.lat();
                patient.address.lng = res[0].geometry.location.lng();

                return patient;
              }),
              catchError((err) => {
                  console.log(err);
                  
                  return of(patient);
              })
          )
    }

    addPatient(patient: Patient): void {
        
    }

    updatePatient(patient: Patient): void {
        console.log(patient);
        this.store.dispatch(AddEditPatientActions.updatePatient({ patient }));
    }
}