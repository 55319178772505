
<div class="page-container">
    <div class="content-container">
      
      <div class="container">
  
        
       <!--  <div class="row"  *ngIf="showMsg">
          <div class="col-xs-12">
            <p class="alert alert-primary alert-dismissible">
              
                <strong>Clinic Location Saved Success!</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
  
            </p>
          </div>
        </div> -->
  
        
  
        <form [formGroup]="form" (ngSubmit)="submitForm()" autocomplete="on">
          <div class="header-section">
            <h2 class="title">Clinic Location</h2>
          </div>
  
          <div *ngFor="let e of lis;" >
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                      <label for="name"> State </label>
                      <select name="state" id="state" formControlName="state"   name="state" class="form-control">
                        
                        <option *ngFor="let a of lis;" value="a.state" selected="selected" >{{a.state}}</option>
                        <option value="AL">AL</option>
                        <option value="AK">AK</option>
                        <option value="AZ">AZ</option>
                        <option value="AR">AR</option>
                        <option value="CA">CA</option>
                        <option value="CO">CO</option>
                        <option value="CT">CT</option>
                        <option value="DE">DE</option>
                        <option value="DC">DC</option>
                        <option value="FL">FL</option>
                        <option value="GA">GA</option>
                        <option value="HI">HI</option>
                        <option value="ID">ID</option>
                        <option value="IL">IL</option>
                        <option value="IN">IN</option>
                        <option value="IA">IA</option>
                        <option value="KS">KS</option>
                        <option value="KY">KY</option>
                        <option value="LA">LA</option>
                        <option value="ME">ME</option>
                        <option value="MD">MD</option>
                        <option value="MA">MA</option>
                        <option value="MI">MI</option>
                        <option value="MN">MN</option>
                        <option value="MS">MS</option>
                        <option value="MO">MO</option>
                        <option value="MT">MT</option>
                        <option value="NE">NE</option>
                        <option value="NV">NV</option>
                        <option value="NH">NH</option>
                        <option value="NJ">NJ</option>
                        <option value="NM">NM</option>
                        <option value="NY">NY</option>
                        <option value="NC">NC</option>
                        <option value="ND">ND</option>
                        <option value="OH">OH</option>
                        <option value="OK">OK</option>
                        <option value="OR">OR</option>
                        <option value="PA">PA</option>
                        <option value="RI">RI</option>
                        <option value="SC">SC</option>
                        <option value="SD">SD</option>
                        <option value="TN">TN</option>
                        <option value="TX">TX</option>
                        <option value="UT">UT</option>
                        <option value="VT">VT</option>
                        <option value="VA">VA</option>
                        <option value="WA">WA</option>
                        <option value="WV">WV</option>
                        <option value="WI">WI</option>
                        <option value="WY">WY</option>
  
                      </select>
                    
                </div>
                
                <div class="form-group">
                      <label for="name"> Location </label>
                      <input type="text" formControlName="location_name" [(ngModel)]="e.location_name"   name="location_name" class="form-control"   placeholder="Location ">
                     
                    </div>
  
  
              
                
              </div>
              <div class="col-md-6">
                <div class="form-group">
                      <label for="name"> City </label>
                      <input type="text" formControlName="city" name="city" [(ngModel)]="e.city" class="form-control"    placeholder="City">
                     
                    </div>
              
                
            
              </div>
          <div class="footer-section">
            <hr />
            <button
              type="submit"
              class="button submit-button"
             
            >
              <span class="button-text">Submit Form</span>
            </button><button
            type="button"
            (click)="cancelForm()"
            class="button cancel-button"
          >
            <span class="button-text">Cancel</span>
          </button> </div>
        </div>
         
    </div>
        </form>
  
  
       
      
      </div>
    </div>
  </div>
  