import { createReducer, on, Action } from '@ngrx/store';
import { OptionsActions } from '../actions';

export interface OptionsState {
    clinicLocations: string[],
    clientTypes: string[],
    insuranceTypes: string[],
    intakeStages: string[],
    employeeStatuses: string[],
    onboardingStages: string[],
    credentialingStages: string[]
};

const initialState: OptionsState = {
    clinicLocations: [],
    clientTypes: [],
    insuranceTypes: [],
    intakeStages: [],
    employeeStatuses: [],
    onboardingStages: [],
    credentialingStages: []
};

const reducer = createReducer(
    initialState,
    on(OptionsActions.setClinicLocations, (state, { clinicLocations }) => ({ ...state, clinicLocations })),
    on(OptionsActions.setClientTypes, (state, { clientTypes }) => ({ ...state, clientTypes })),
    on(OptionsActions.setInsuranceTypes, (state, { insuranceTypes }) => ({ ...state, insuranceTypes })),
    on(OptionsActions.setIntakeStages, (state, { intakeStages }) => ({ ...state, intakeStages })),
    on(OptionsActions.setEmployeeStatuses, (state, { employeeStatuses }) => ({ ...state, employeeStatuses })),
    on(OptionsActions.setOnboardingStages, (state, { onboardingStages }) => ({ ...state, onboardingStages })),
    on(OptionsActions.setCredentialingStages, (state, { credentialingStages }) => ({ ...state, credentialingStages })),
);

export function optionsReducer(state: OptionsState | undefined, action: Action) {
    return reducer(state, action);
}