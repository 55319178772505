import { PatientsEffects } from './patients.effects';
import { ProvidersEffects } from './providers.effects';
import { MatchesEffects } from './matches.effects';
import { FiltersEffects } from './filters.effects';
import { AddEditPatientEffects } from './addEditPatient.effects';
import { AddEditProviderEffects } from './addEditProvider.effects';
import { NewClientsEffects } from './newClients.effects';
import { FormEffects } from './form.effects';

export const effects = [
    PatientsEffects,
    ProvidersEffects,
    MatchesEffects,
    NewClientsEffects,
    FiltersEffects,
    AddEditPatientEffects,
    AddEditProviderEffects,
    FormEffects
]