<!-- <!DOCTYPE html> -->
<!-- <html lang="en"> -->

<!-- <body>  -->
    <div class="page-container">
        <div class="content-container">
          
          <div class="container"> 
            <div class="company-header">
              <h2>
            
    
              <div class="spinner-wrapper" *ngIf="loading">
                <mat-progress-spinner
                  color="primary"
                  mode="indeterminate"
                  diameter="30"
                >
                </mat-progress-spinner>
              </div>
             
            </h2>
              
            </div>
            
        
    <div class="client-table">
      <div class="table-wrapper">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <!-- Position Column -->
          
         <!--  <ng-container matColumnDef="S No.">
            <th mat-header-cell *matHeaderCellDef> S No.</th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container> -->
        
          <!-- Name Column -->
          <ng-container matColumnDef="S NO">
            <th mat-header-cell *matHeaderCellDef> S NO </th>
            <td mat-cell *matCellDef="let element let i=index"> {{i + 1}} </td>
          </ng-container>
        
          <ng-container matColumnDef="Company Name">
            <th mat-header-cell *matHeaderCellDef> Company Name </th>
            <td mat-cell *matCellDef="let element"> {{element.comapny_name}} </td>
          </ng-container>
    
          <ng-container matColumnDef="Login Time">
            <th mat-header-cell *matHeaderCellDef> Login Time </th>
            <td mat-cell *matCellDef="let element"> {{element.login_time}} </td>
          </ng-container>
    
          <ng-container matColumnDef="Logout Time">
            <th mat-header-cell *matHeaderCellDef> Logout Time </th>
            <td mat-cell *matCellDef="let element"> {{element.logout_time}} </td>
          </ng-container>
    
          <ng-container matColumnDef="Login date">
            <th mat-header-cell *matHeaderCellDef> Login date </th>
            <td mat-cell *matCellDef="let element"> {{element.login_date}} </td>
          </ng-container>
    
          <ng-container matColumnDef="Client Updated Logs">
            <th mat-header-cell *matHeaderCellDef> Client Updated Logs </th>
            <td mat-cell *matCellDef="let element"><a href="#"     [routerLink]="'/dashboard/companylogs'"  routerLinkActive="active">Client</a></td>
          </ng-container>
    
           <ng-container matColumnDef="Providers Updated Logs">
            <th mat-header-cell *matHeaderCellDef> Providers Updated Logs</th>
            <td mat-cell *matCellDef="let element"> <a href="#"     [routerLink]="'/dashboard/providerslogs'"  routerLinkActive="active"> Providers</a> </td>
          </ng-container> 
    
         
         
          
         
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>
      
    </div>
    
          </div>
        </div>
      </div>
    
    
    
    
    