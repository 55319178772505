import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { ProvidersState } from '../reducers';


export const selectProviders = (state: AppState) => state.providers;

export const selectProvidersList = createSelector(
    selectProviders,
    (state: ProvidersState) => state.list
)

export const selectProvidersLoading = createSelector(
    selectProviders,
    (state: ProvidersState) => state.loading
)

export const selectProvidersError = createSelector(
    selectProviders,
    (state: ProvidersState) => state.error
)