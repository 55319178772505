import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import { MatchActions, MapFullScreenActions, FiltersActions, MatchesActions } from 'src/app/store/actions';
import { Subscription, Observable } from 'rxjs';
import { PatientsFacade } from 'src/app/services/facades/patients.facade';
import { ProvidersFacade } from 'src/app/services/facades/providers.facade';
import { Patient, Provider } from 'src/app/models';
import { MatchFacade } from 'src/app/services/facades/match.facade';
import { FiltersFacade } from 'src/app/services/facades/filters.facade';
import { MapFacade } from 'src/app/services/facades/map.facade';
import { MatchesFacade } from 'src/app/services/facades/matches.facade';
import { TestBed } from '@angular/core/testing';


//import { WINDOW } from 'ngx-window-token';
//export default TestOnSchedJs
//declare var createform: any;
declare global {
  interface Window {
      OnSched:any;
  }
}



//declare var window: MyWindow;


@Component({
  selector: 'app-unassigned',
  templateUrl: './unassigned.component.html',
  styleUrls: ['./unassigned.component.scss']
})
//export default TestOnSchedJs;
export class UnassignedComponent implements OnInit, OnDestroy {

  
  Ischecked=true;

  minDate: Date;
  maxDate: Date;
  patients$: Observable<Patient[]>;
  providers$: Observable<Provider[]>;
  //matchgetprovider$:Observable<Matches[]>;

  patientsLoading$: Observable<boolean>;
  providersLoading$: Observable<boolean>;

  matchPatient$: Observable<Patient>;
  matchProvider$: Observable<Provider>;

  mapFullScreen$: Observable<boolean>;

  showMap: boolean;

  subscriptions = new Subscription();
  
  constructor(
    private store: Store<AppState>,
    private patientsFacade: PatientsFacade,
    private providersFacade: ProvidersFacade,
    private matchesFacade: MatchesFacade,
    private filtersFacade: FiltersFacade,
    private matchFacade: MatchFacade,
    private mapFacade: MapFacade,
    
  ) { 


    
  


   // window.onload = onPageLoad();
    
  
    //var  TestOnSchedJs = () => {
   // var onsched = window.OnSched("sbox1618465159", "sbox");

    /// Get instance of elements to use for creating elements
    //var elements = onsched.elements();
  
  
 
    
    //var now = new Date();
  //  var tzOffset = -now.getTimezoneOffset();
  
    // Parameters relate to GET availability endpoint and PUT appointments endpoint in OnSched API
   // var availabilityParams = {
    //  locationId: "a81e6d80-7b3e-48f5-8a50-afa7ceda4a9b",
    //  serviceId: 133273,
    //  resourceId: 128937,
    //  tzOffset: tzOffset,
     // appointmentBM: { customFields: { field1: "AIT" } },
    //};
  
    // Use privacy fields to force a customer to accept terms and/or view the privacy policy
   // var availabilityOptions = { groupSize: true };
  
   // var availability = elements.create(
    //  "availability",
   //   availabilityParams,
   //   availabilityOptions
   // );
  
    // mount the onsched element into an html element on the page, typically a div.
    // The id of the html element is what you you pass as the parameter to mount.
    // The UI for the element will be rendered into the html element.
  
    // You may wish to defer the mounting of availability after you have
    // gathered information from other elements. e.g. serviceId, resourceId
 
 
 
    //console.log("dddddd=>",availability);
  
    //availability.mount("availability");

   // }
    this.patients$ = patientsFacade.getPatients();
    this.patients$ = patientsFacade.getPatients();
    this.providers$ = providersFacade.getProviders();

    this.patientsLoading$ = patientsFacade.getLoading();
    this.providersLoading$ = providersFacade.getLoading();

    this.matchPatient$ = matchFacade.getPatient();
    this.matchProvider$ = matchFacade.getProvider();
    
    this.mapFullScreen$ = mapFacade.getMapFullScreen();
    this.patients$ = patientsFacade.getPatients();
    this.filtersFacade.getFilters()


    const sub1 = patientsFacade
      .getError()
      .subscribe(
        error => {
          if (error)
            alert(error);
        }
      )

    const sub2 = providersFacade
      .getError()
      .subscribe(
        error => {
          if (error)
            alert(error);
        }
      )

    const sub3 = matchesFacade
      .getError()
      .subscribe(
        error => {
          if (error)
            alert(error);
        }
      )

    this.subscriptions.add(sub1);
    this.subscriptions.add(sub2);
    this.subscriptions.add(sub3);
   // var onsched = new OnSched("sbox1618465159", "sbox");
   // console.log("onsched => ",onsched)
  }

  ngOnInit(): void {
    this.store.dispatch(MatchesActions.clearMatches());
    this.store.dispatch(MapFullScreenActions.resetFullScreen());
    this.store.dispatch(MatchActions.resetMatch());
    //console.log("KISSANmANH=>",this.filtersFacade['day'] ='Mon');
    this.filtersFacade['day'];
    this.filtersFacade['startTime'];
    this.filtersFacade['endTime'];
    this.filtersFacade.resetUnassignedFilters();
    this.patientsFacade.loadPatients();
    this.providersFacade.loadProviders();

    /* window.addEventListener('load', (event) => {
   
     let data =  document.getElementById('btn').click();
   
  }); */

    }

 
    
    update() {
      this.ngOnInit();
}  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();

    this.matchesFacade.clearError();
    
  }

  toggleMap(): void {
    this.showMap = !this.showMap;
  }
}
