<wtls-assigned-filter-bar></wtls-assigned-filter-bar>
<div class="assigned-container" [ngClass]="{'minimized-padding': !(mapFullScreen$ | async), 'full-padding': (mapFullScreen$ | async)}">
    <div class="view-toggle">
        <button 
            *ngIf="!(mapFullScreen$ | async)"
            mat-stroked-button 
            class="view-toggle-btn"
            (click)="toggleMap()"
            >
            Toggle View
        </button>
    </div>
    <div class="main-container">
       <div class="column column-left" *ngIf="!(mapFullScreen$ | async)" [ngClass]="{'hide': showMap}">
            <div class="matches-wrapper">
                <wtls-matches [matches]="matches$ | async" [selected]="match$ | async" [loading]="matchesLoading$ | async"></wtls-matches>
            </div>
        </div>
        <div class="column column-right" [ngClass]="{'minimized': !(mapFullScreen$ | async), 'hide': !showMap}">
            <div class="map-container" [ngClass]="{'map-minimized': !(mapFullScreen$ | async)}">
                <wtls-assigned-google-map [matches]="matches$ | async"></wtls-assigned-google-map>
            </div>
        </div>
    </div>
</div>