import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription, Subject, EMPTY } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'wtls-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss']
})
export class TextFieldComponent implements OnInit {
  @Input() placeholder;
  @Input() clearable: boolean;
  @Input() value;
  @Output() valueChange = new EventEmitter<string>();

  valueSubscription: Subscription;
  input$ = new Subject<string>();

  constructor() { 
    this.valueSubscription = this.input$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(value => {
        this.onValueChange(value);
        return EMPTY
      })
    ).subscribe();
  }

  ngOnInit(): void {
  }

  onValueChange(value: string): void {
    this.valueChange.emit(value);
  }

  clearInput(): void {
    this.value = '';
    this.input$.next('');
  }
}
