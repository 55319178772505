
 
<div class="container">
 
  <div class="card-columns" id="center">
    
   
    <div class="card bg-success">
      <div class="card-body text-center">
        <a href="#"  [routerLink]="['/dashboard/usersdetails']"  routerLinkActive="active"><p style="font-size:25px;color:rgb(230, 218, 218); text-align: center;">Users Details</p></a> 
        <p class="card-text"><i class="fas fa-users" style="font-size:50px;color:rgb(230, 218, 218);"></i></p>
      </div>
    </div>
    
    
    <div class="card bg-info" *ngIf="isSuperAdmin == true">
      <div class="card-body text-center">
        <a href="#"  [routerLink]="['/dashboard/company']"  routerLinkActive="active"><p style="font-size:25px;color:rgb(230, 218, 218); text-align: center;">Company Details</p></a> 
        <p class="card-text"><i class="fas fa-address-card" style="font-size:50px;color:rgb(230, 218, 218);"></i></p>
        
      </div>
    </div>
    <div class="card bg-secondary" *ngIf="isSuperAdmin == true">
      <div class="card-body text-center">
        <a href="#"  [routerLink]="['/dashboard/cliniclocation']"  routerLinkActive="active"><p style="font-size:25px;color:rgb(230, 218, 218); text-align: center;">Clinic Location </p></a> 
        <p class="card-text"><i class="fas fa-address-card" style="font-size:50px;color:rgb(230, 218, 218);"></i></p>
        
      </div>
    </div>
    
  </div>
</div>
