<div class="providers-container">
  <!--div id="services"></div>
    <div id="resources"></div>
    <div id="availability"></div>
    <div id="customer"></div>
   <div id="appointment"></div>
    <div id="confirmation"></div -->
    <div class="providers-header">
        <h2>Providers
          <div class="spinner-wrapper" *ngIf="loading">
            <mat-progress-spinner color="primary" mode="indeterminate" diameter="30">
            </mat-progress-spinner>  
          </div>    
        </h2>
        <div class="tab-wrapper">
          <span class="schedule-tab" (click)="setHasSchedule(false)" [ngClass]="{'active': !filters?.providersHasSchedule }">
            No Schedule
          </span>
        </div>
    </div>
    <input #fileUpload type="file" accept=".csv" style="display: none;" (change)="csvUpload($event.target.files)" multiple />
    <button
    mat-flat-button
    type="button"
    color="accent"
    (click)="fileUpload.click()"
  >
    Upload CSV
  </button>
    <div class="search-box border-dark-bottom">
      <wtls-text-field placeholder="Name" [(value)]="filters.providerName" (valueChange)="filterNameChange($event)"></wtls-text-field>
    </div>
    <div class="overflow-auto">
      <div class="filters-section border-light-bottom">
        <wtls-filter-tree [treeData]="treeData" (filterChange)="filterChanged($event)"></wtls-filter-tree>
      </div>
      <div class="list-section">
          <mat-radio-group [value]="matchProvider">
            <wtls-provider-record *ngFor="let provider of providers" [provider]="provider" [patient]="patient" [selectedProvider]="matchProvider" (change)="changeProvider($event)" (edit)="editProvider($event)" (delete)="deleteProvider($event)"></wtls-provider-record>
          </mat-radio-group>
      </div>
    </div>
</div>



