import { Component, OnInit, Input } from '@angular/core';
import { MatchesFacade } from 'src/app/services/facades/matches.facade';
import { Match } from 'src/app/models';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { MatchFacade } from 'src/app/services/facades/match.facade';

@Component({
  selector: 'wtls-matches',
  templateUrl: './matches.component.html',
  styleUrls: ['./matches.component.scss']
})
export class MatchesComponent implements OnInit {
  @Input() matches: Match[];
  @Input() selected: Match;
  @Input() loading: boolean;

  expanded: boolean;

  constructor(
    private dialog: MatDialog,
    private matchFacade: MatchFacade,
    private matchesFacade: MatchesFacade
  ) { }

  ngOnInit(): void {
    console.log(this.matches);
  }

  selectMatch(match: Match): void {
    this.matchFacade.selectMatch(match);
  }

  unassignMatch(match: Match): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: match,
      disableClose: true
    });

    dialogRef.beforeClosed().subscribe(result => {
      if (result) {
        //console.log('result',match)
        this.matchesFacade.unassignedMatch(match);
      }
    })
  }

  exportCSV(): void {
    this.matchesFacade.getCSVExport();
  }
}
