<div class="matches-container">
  <div class="section top-section">
    <h2>Assigned Matches
      <div class="spinner-wrapper" *ngIf="loading">
        <mat-progress-spinner color="primary" mode="indeterminate" diameter="30">
        </mat-progress-spinner>  
      </div>    
    </h2>
   
    <button mat-stroked-button type="button" (click)="exportCSV()" [disabled]="loading">
      Export CSV
    </button>
  </div>
  <div class="section main-section">
    <div class="matches-table-wrapper">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>#</th>
            <th>Client</th>
            <th>Client Address</th>
            <th>Provider</th>
            <th>Provider Address</th>
          </tr>
        </thead>
        <tbody class="match-records-body">
        <wtls-match-record *ngFor="let match of matches; index as i" [index]="i + 1" [match]="match" [selected]="selected" (select)="selectMatch($event)" (unassign)="unassignMatch($event)" class="d-contents"></wtls-match-record>
        </tbody>
      </table>
    </div>
  </div>
</div>
