<!-- <div class="time-picker-container">
    <h5 *ngIf="dayOfWeek == 1" class="day-of-week">MONDAY</h5>
    <h5 *ngIf="dayOfWeek == 2" class="day-of-week">TUESDAY</h5>
    <h5 *ngIf="dayOfWeek == 3" class="day-of-week">WEDNESDAY</h5>
    <h5 *ngIf="dayOfWeek == 4" class="day-of-week">THURSDAY</h5>
    <h5 *ngIf="dayOfWeek == 5" class="day-of-week">FRIDAY</h5>
    <h5 *ngIf="dayOfWeek == 6" class="day-of-week">SATURDAY</h5>
    <h5 *ngIf="dayOfWeek == 7" class="day-of-week">SUNDAY</h5>
    <div class="toggle-collection-wrapper">
        <button class="toggle-container" [ngClass]="{ 'active' : match.patient && match.provider && isAm(), 'disabled' : isAmDisabled() }" (click)="amChanged()" [disabled]="isAmDisabled()">
            <span class="toggle-text">AM</span>
        </button>
        <button class="toggle-container" [ngClass]="{ 'active' : match.patient && match.provider && isPm(), 'disabled' : isPmDisabled() }" (click)="pmChanged()" [disabled]="isPmDisabled()">
            <span class="toggle-text">PM</span>
        </button>    
    </div>
</div> -->
<div class="time-picker-container">
    <h5 *ngIf="dayOfWeek == 1" class="day-of-week">MONDAY</h5>
    <h5 *ngIf="dayOfWeek == 2" class="day-of-week">TUESDAY</h5>
    <h5 *ngIf="dayOfWeek == 3" class="day-of-week">WEDNESDAY</h5>
    <h5 *ngIf="dayOfWeek == 4" class="day-of-week">THURSDAY</h5>
    <h5 *ngIf="dayOfWeek == 5" class="day-of-week">FRIDAY</h5>
    <h5 *ngIf="dayOfWeek == 6" class="day-of-week">SATURDAY</h5>
    <h5 *ngIf="dayOfWeek == 7" class="day-of-week">SUNDAY</h5>
    <div class="toggle-collection-wrapper">
     <!--   <button type="button" class="toggle-container" [ngClass]="{ 'active' : isAm(), 'disabled' : isAmDisabled() }" (click)="amChanged()" [disabled]="isAmDisabled()">
            <span class="toggle-text">AM</span>
        </button>
        <button type="button" class="toggle-container" [ngClass]="{ 'active' : isPm(), 'disabled' : isPmDisabled() }" (click)="pmChanged()" [disabled]="isPmDisabled()">
            <span class="toggle-text">PM</span>
        </button> -->   
    </div>
</div>