<div class="input-wrapper" (click)="showDropdown()" #dropreference>
  <div class="input ms-Fabric" [class.required]="required">
    <input
      #input  
      placeholder="{{ placeholder }}"
      [disabled]="disabled"
      readonly
      [value]="displayText"
      autocomplete="off"      
      (keydown)="onKeyDown($event)"
      (blur)="onTouched()"      
   
    />
    <wtls-drop-down [reference]="dropreference" #dropdownComp>
      <div class="dropdown-options-container">
        <ng-content select="wtls-select-option"></ng-content>
      </div>
    </wtls-drop-down>
  </div>
  <i class="ms-Icon ms-Icon--ChevronDown"></i>
  <!-- <i class="ms-Icon ms-Icon--ChevronUp"></i> -->
</div>
