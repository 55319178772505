import * as PatientsSelectors from './patients.selectors'
import * as ProvidersSelectors from './providers.selectors'
import * as MatchesSelectors from './matches.selectors';
import * as NewClientsSelectors from './newClients.selectors';
import * as MatchSelectors  from './match.selectors';
import * as FiltersSelectors from './filters.selectors';
import * as AddEditPatientSelectors from './addEditPatient.selectors';
import * as AddEditProviderSelectors from './addEditProvider.selectors';
import * as FormSelectors from './form.selectors';

export {
    PatientsSelectors,
    ProvidersSelectors,
    MatchesSelectors,
    NewClientsSelectors,
    MatchSelectors,
    FiltersSelectors,
    AddEditPatientSelectors,
    AddEditProviderSelectors,
    FormSelectors
}