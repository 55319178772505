import { createReducer, on, Action } from '@ngrx/store';
import { Form } from 'src/app/models';
import { FormActions } from '../actions';

export interface FormState {
    form: Form,
    loading: boolean,
    submitting: boolean,
    error: string
};

const initialState: FormState = {
    form: null,
    loading: false,
    submitting: false,
    error: ''
};

const reducer = createReducer(
    initialState,
    on(FormActions.loadForm, (state) => ({ ...state, loading: true })),
    on(FormActions.loadFormSuccess, (state, { form }) => ({ ...state, form, loading: false })),
    on(FormActions.loadFormError, (state, { error }) => ({ ...state, loading: false, error })),
    on(FormActions.submitForm, (state) => ({ ...state, submitting: true })),
    on(FormActions.submitFormSuccess, (state) => ({ ...state, submitting: false })),
    on(FormActions.submitFormError, (state, { error }) => ({ ...state, submitting: false, error })),
);

export function formReducer(state: FormState, action: Action) {
    return reducer(state, action);
}