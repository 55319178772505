import { Injectable } from '@angular/core';
import { EndPoints } from 'src/app/constants/endpoints';
import { ApiService } from '../api/api.service';


@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private apiService: ApiService
  ) { }

  getUser(id){
    return this.apiService.get<any>(EndPoints.USER.GET_USER, id)
  }
}
