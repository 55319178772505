import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup,Validators  } from "@angular/forms";
import { ApiService } from 'src/app/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EndPoints } from 'src/app/constants/endpoints';
@Component({
  selector: 'wtls-cliniclocations',
  templateUrl: './cliniclocations.component.html',
  styleUrls: ['./cliniclocations.component.scss']
})
export class CliniclocationsComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  constructor(private router: Router,
     private route: ActivatedRoute,
     private http: HttpClient,
     private formBuilder: FormBuilder,
     private snackbar: MatSnackBar,
     private apiService: ApiService) {
    this.form = this.formBuilder.group({
      state: ['',Validators.required ],
      location_name: ['',Validators.required],
      city:['',Validators.required]
    
     
    })
   }
   get f() { return this.form.controls; }

  ngOnInit(): void {
  }
 
  submitForm() {
   
    this.submitted = true;

      
        if (this.form.invalid) {
            return;
        }
        else{
          let Formpostdata = this.form.value;
          console.log(this.form.value)
          this.apiService.post('/cliniclocations/', Formpostdata).subscribe(
        
            (response) =>{
              this.snackbar.open('Clinic Location saved  Successfull!', 'Dismiss', {
              verticalPosition: 'bottom',
              horizontalPosition: 'left',
            }); 
            window.location.href = EndPoints.BASE_URL+'dashboard/cliniclocation';
            },
            
            (error) => console.log(error)
          ); 
        }
        
        
  }
  cancelForm(): void {
    this.router.navigate(['/dashboard/cliniclocation'])
  }


}
