import { AgmGeocoder } from '@agm/core';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Provider } from 'src/app/models';
import { AddEditProviderActions } from 'src/app/store/actions';
import { AppState } from 'src/app/store/app.state';
import { AddEditProviderSelectors } from 'src/app/store/selectors';
import { ProvidersService } from '../providers/providers.service';

@Injectable({
    providedIn: 'root'
})
export class AddEditProviderFacade {

    constructor(private store: Store<AppState>, private providersService: ProvidersService, private geo: AgmGeocoder) { }

    getOpen(): Observable<boolean> {
        return this.store.select(AddEditProviderSelectors.selectAddEditProviderOpen);
    }

    openPanel(): void {
        this.store.dispatch(AddEditProviderActions.openPanel());
    }

    closePanel(): void {
        this.store.dispatch(AddEditProviderActions.closePanel());
    }

    getProvider(): Observable<Provider> {
        return this.store.select(AddEditProviderSelectors.selectAddEditProvider);
    }

    setProvider(provider: Provider): void {
        this.store.dispatch(AddEditProviderActions.setProvider({ provider }));
    }

    clearProvider(): void {
        this.store.dispatch(AddEditProviderActions.clearProvider());
    }

    geocodeProvider(provider: Provider): Observable<Provider> {
        return this.geo.geocode({
            address: `${provider.address.street}, ${provider.address.city} ${provider.address.state}, ${provider.address.zip}`,
          }).pipe(
              map((res) => {
                provider.address.lat = res[0].geometry.location.lat();
                provider.address.lng = res[0].geometry.location.lng();

                return provider;
              }),
              catchError((err) => {
                  console.log(err);
                  
                  return of(provider);
              })
          )
    }

    addProvider(provider: Provider): void {
        
    }

    updateProvider(provider: Provider): void {
        console.log(provider);
        this.store.dispatch(AddEditProviderActions.updateProvider({ provider }));
    }
}