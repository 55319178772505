import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wtls-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  // TODO subscribe to fullscreen property in store

  mapFullScreen: boolean;

  constructor() { }

  ngOnInit(): void {
    console.log("ghsdhfgasdggasfdgfga");
  }

}
