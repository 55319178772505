import { createReducer, on, Action } from '@ngrx/store';
import { Match } from "src/app/models";
import { MatchActions } from '../actions';

const initialState: Match = {
    id: 0,
    patient: null,
    provider: null,
    schedule: [],
}

const reducer = createReducer(
    initialState,
    on(MatchActions.selectPatient, (state, { patient }) => ({ ...state, patient })),
    on(MatchActions.selectProvider, (state, { provider }) => ({ ...state, provider })),
    on(MatchActions.selectMatch, (state, { match }) => ({ ...match })),
    on(MatchActions.changeSchedule, (state, { schedule }) => ({ ...state, schedule })),
    on(MatchActions.clearSchedule, (state) => ({ ...state, schedule: [] })),
    on(MatchActions.resetMatch, (state) => ({ ...initialState,  }))
);

export function matchReducer(state: Match | undefined, action: Action) {
    return reducer(state, action);
}