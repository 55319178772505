import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FilterDialogComponent } from '../filter-dialog/filter-dialog.component';
import { FiltersState } from 'src/app/store/reducers';
import { LIST_OF_US_STATES_ABBREVIATIONS } from 'src/app/constants/usStates';

@Component({
  selector: 'wtls-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss'],
})
export class FilterBarComponent implements OnInit {
  @Input() filterData: FiltersState;
  @Input() clinicLocationOptions: string[];
  @Output() filtersChange = new EventEmitter<FiltersState>();

  stateOptions = LIST_OF_US_STATES_ABBREVIATIONS;

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {}

  updateFilters(): void {
    this.filtersChange.emit(this.filterData);
  }

  openFilterDialog(): void {
    const dialogRef = this.dialog.open(FilterDialogComponent, {
      data: {
        filterData: this.filterData,
        clinicLocationOptions: this.clinicLocationOptions
      }
    });

    dialogRef.beforeClosed()
      .subscribe((result: FiltersState) => {
        console.log(result);
        if (result) {
          this.filtersChange.emit(result)
        }
      })
  }
}
