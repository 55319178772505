import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'file'
})
export class FilePipe implements PipeTransform {

  transform(value: string): string {
    if (value)
      return value.split('/').pop();
    else
      return value;
  }

}
