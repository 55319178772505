import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { AddEditPatientState } from '../reducers';

const selectAddEditPatientState = (state: AppState) => state.addEditPatient;

export const selectAddEditPatientOpen = createSelector(
    selectAddEditPatientState,
    (state: AddEditPatientState) => state.open
);

export const selectAddEditPatient = createSelector(
    selectAddEditPatientState,
    (state: AddEditPatientState) => state.patient
);

export const selectAddEditPatientLoading = createSelector(
    selectAddEditPatientState,
    (state: AddEditPatientState) => state.loading
)

export const selectAddEditPatientError = createSelector(
    selectAddEditPatientState,
    (state: AddEditPatientState) => state.error
)