import { createAction, props } from '@ngrx/store';
import { Provider } from '../../models';

export enum ProvidersActionTypes {
  LOAD_PROVIDERS = '[Providers] Load Providers',
  LOAD_PROVIDERS_SUCCESS = '[Providers] Load Providers Success',
  LOAD_PROVIDERS_ERROR = '[Providers] Load Providers Error',
  CLEAR_PROVIDERS = '[Providers] Clear Providers',
}

export const loadProviders = createAction(
    ProvidersActionTypes.LOAD_PROVIDERS,
  // props<{
  //   options?: {
  //     assigned?: boolean;
  //     name?: string;
  //     employeeStatus?: string[];
  //     onboardingStage?: string[];
  //     street?: string;
  //     city?: string;
  //     state?: string;
  //     zip?: string;
  //   };
  // }>()
);

export const loadProvidersSuccess = createAction(
    ProvidersActionTypes.LOAD_PROVIDERS_SUCCESS,
  props<{ providers: Provider[] }>()
);

export const loadProvidersError = createAction(
    ProvidersActionTypes.LOAD_PROVIDERS_ERROR,
  props<{ error }>()
);

export const clearProviders = createAction(ProvidersActionTypes.CLEAR_PROVIDERS);