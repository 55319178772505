import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Match, Patient, Provider } from 'src/app/models';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/store/app.state';
import { Store } from '@ngrx/store';
import { MatchSelectors } from 'src/app/store/selectors';
import { MatchesFacade } from 'src/app/services/facades/matches.facade';
import { MatchFacade } from 'src/app/services/facades/match.facade';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { MapFacade } from 'src/app/services/facades/map.facade';

interface Marker {
  patient: Patient
  provider: Provider,
  patientIcon: google.maps.Icon,
  providerIcon: google.maps.Icon,
  label: google.maps.MarkerLabel
  infoWindow: {
    lat: number,
    lng: number
  }
}

@Component({
  selector: 'wtls-match-map-marker',
  templateUrl: './match-map-marker.component.html',
  styleUrls: ['./match-map-marker.component.scss']
})
export class MatchMapMarkerComponent implements OnInit, OnDestroy {
  @Input() match: Match;
  @Input() index: number;

  selectedMatch: Match;

  marker: Marker;

  stayOpen: boolean;

  fullScreen: boolean;

  subscriptions: Subscription = new Subscription();

  constructor(
    private dialog: MatDialog,
    private matchFacade: MatchFacade,
    private matchesFacade: MatchesFacade,
    private mapFacade: MapFacade
  ) { }

  ngOnInit(): void {
    this.marker = {
      patient: this.match.patient,
      provider: this.match.provider,
      patientIcon: {
        url: '../../../../assets/map-markers/patient-assigned.svg',
        labelOrigin: new google.maps.Point(13, 13)
      },
      providerIcon: {
        url: '../../../../assets/map-markers/provider-assigned.svg',
        labelOrigin: new google.maps.Point(13, 13)
      },
      label: {
        text: `${this.index}`,
        color: 'white'
      },
      infoWindow: {
        lat: null,
        lng: null
      }
    }
    
    // Determine where to place the info window
    if ((this.marker.patient.address.lat &&
      this.marker.patient.address.lng) && 
      (this.marker.provider.address.lat &&
        this.marker.provider.address.lng)) {
          this.marker.infoWindow.lat = (this.match.patient.address.lat + this.match.provider.address.lat) / 2
          this.marker.infoWindow.lng = (this.match.patient.address.lng + this.match.provider.address.lng) / 2
    } else if ((this.marker.patient.address.lat &&
      this.marker.patient.address.lng)) {
        this.marker.infoWindow.lat = this.match.patient.address.lat
        this.marker.infoWindow.lng = this.match.patient.address.lng
    } else if ((this.marker.provider.address.lat &&
      this.marker.provider.address.lng)) {
        this.marker.infoWindow.lat = this.match.provider.address.lat
        this.marker.infoWindow.lng = this.match.provider.address.lng
      }

    const sub1 = this.mapFacade
      .getMapFullScreen()
      .subscribe((value) => {
        this.fullScreen = value;
    })

    const sub2 = this.matchFacade
    .getMatch()
    .subscribe((match) => {
      this.selectedMatch = match;
      console.log(match);
      if (this.match.id == match.id) {
        this.stayOpen = true;
      } else {
        this.stayOpen = false;
      }
    });

    this.subscriptions.add(sub1);
    this.subscriptions.add(sub2);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  changeMatch(): void {
    this.matchFacade.selectMatch(this.match);
  }

  unassignMatch(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: this.match
    });

    dialogRef.beforeClosed().subscribe(result => {
      if (result) {
        this.matchesFacade.unassignedMatch(this.match);
      }
    })
  }
}
