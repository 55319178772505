import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';

const selectFormState = (state: AppState) => state.form;

export const selectForm = createSelector(
    selectFormState,
    (state) => state.form
);

export const selectFormLoading = createSelector(
    selectFormState,
    (state) => state.loading
);

export const selectFormSubmitting = createSelector(
    selectFormState,
    (state) => state.submitting
);

export const selectFormError = createSelector(
    selectFormState,
    (state) => state.error
);