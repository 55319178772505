<div class="min-max-container">
  <input
    #minInput
    type="number"
    placeholder="Min"
    class="input"
    [(ngModel)]="min"
    (input)="minInput$.next(minInput.value)"
  />
  <div class="seperator">&mdash;</div>
  <input
    #maxInput
    type="number"
    placeholder="Max"
    class="input"
    [(ngModel)]="max"
    (input)="maxInput$.next(maxInput.value)"
  />
</div>
