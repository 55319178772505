import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wtls-providerdetails',
  templateUrl: './providerdetails.component.html',
  styleUrls: ['./providerdetails.component.scss']
})
export class ProviderdetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
