import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup,Validators  } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/services';
import { EndPoints } from 'src/app/constants/endpoints';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'wtls-editlocations',
  templateUrl: './editlocations.component.html',
  styleUrls: ['./editlocations.component.scss']
})
export class EditlocationsComponent  implements OnInit {
  form: FormGroup;
  id: number;
  li:any;
  lis=[];
  selectlist:any;
  selectlistorders=[];
  dataset = [];
  data:any;
  submitted = false;
 
  constructor(
    private router: Router, 
    private route: ActivatedRoute,
     private formBuilder: FormBuilder,
     private http: HttpClient, 
     private apiService: ApiService,
     private snackbar: MatSnackBar
     ) { 
    this.form = this.formBuilder.group({
      location_name: [''],
      state: [''],
      city: ['']
    })
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id']; 
    });
   

    this.apiService.get('/cliniclocations/'+this.id)
    .subscribe(Response => {
 
      if(Response){ 
        console.log("edit=>",Response)
      }
      console.log("edit=>",Response)
      this.li=Response;
      this.lis=this.li;
     this.dataset = this.lis;
     this.data = this.lis;
      console.log("datatest1=>",this.data)
      return this.dataset;
      
    });
////get users
   // this.http.get('http://localhost:4000/createusers/')
   this.apiService.get('/cliniclocations/')
    .subscribe(Response => {
      if(Response){ 
      }
      console.log("datatest=>",Response)
      this.selectlist=Response;
      this.selectlistorders=this.selectlist;
      console.log("datatest1=>",this.selectlistorders)
    });
  /// get users
 
  }
  get f() { return this.form.controls; }
  showMsg: boolean = false;
  submitForm() {

      let Formpostdata = this.form.value;
      console.log("test=>",this.id)
         // this.http.put('http://localhost:4000/createcompany/'+this.id,Formpostdata).subscribe(
            this.apiService.put('/cliniclocations/'+this.id,Formpostdata).subscribe(
              (response) =>{
                console.log("upadelocation",response)
                this.snackbar.open('Clinic Location Update Successfull!', 'Dismiss', {
                verticalPosition: 'bottom',
                horizontalPosition: 'left',
              }); 
              window.location.href = EndPoints.BASE_URL+'dashboard/cliniclocation';
              },
        
            //(response) => console.log(this.router.navigate(['dashboard/editlocations/'+this.id]),this.showMsg= true),
            
            (error) => console.log(error)
          );  
   
  }

  cancelForm(): void {
    this.router.navigate(['/dashboard/cliniclocation'])
  }


selectusers()
{
  //this.http.get('http://localhost:4000/createusers/')
  this.apiService.get('/cliniclocations/')

    .subscribe(Response => {
 
      // If response comes hideloader() function is called
      // to hide that loader
      if(Response){ 
        hideloader();
      }
      console.log("datatest=>",Response)
      this.li=Response;
      this.lis=this.li;
      console.log("datatest1=>",this.lis)
     
      
    });
    function hideloader(){
      document.getElementById('loading').style.display = 'none';}

}


  
}
