import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, OnChanges} from '@angular/core';
import { Patient, TimeSlot } from 'src/app/models';
import { Subscription, BehaviorSubject } from 'rxjs';
import { PatientsFacade } from 'src/app/services/facades/patients.facade';
import { valueInRange } from 'igniteui-angular';
import { PatientsService } from 'src/app/services/patients/patients.service';
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
//import { Provider } from 'src/app/models';



@Component({
  selector: 'wtls-patient-record',
  templateUrl: './patient-record.component.html',
  styleUrls: ['./patient-record.component.scss']
})
export class PatientRecordComponent implements OnInit, OnChanges, OnDestroy {
  closeResult = '';
  name: string = "";  
  //@Input() patient: Patient;
  @Input() patient;
  @Input() selectedPatient: Patient;
  @Output() change = new EventEmitter<Patient>();
  @Output() schange = new EventEmitter<Patient>();
  @Output() edit = new EventEmitter<Patient>();
  @Output() delete = new EventEmitter<Patient>();
  @Output() nameEmitter = new EventEmitter < string > ();  
  fullPatient: Patient;
  Patient: Patient[] = [];

  isExpanded = new BehaviorSubject<boolean>(false);
  checked: boolean = false;
   
  availability_status = [1,1,0,0,2];
  scheduleLoading: boolean;

  schedule: TimeSlot[] = [];
  days = ['Monday: ','Tuesday: ','Wednesday: ','Thursday: ','Friday: ','Saturday: ','Sunday: ']
  display_time = []
  availability = [];
  
  booking_time = []
  subscriptions = new Subscription();

  constructor(
    private patientsFacade: PatientsFacade,
    public router: Router,
    private PatientsService: PatientsService,
    
  ) { }

  ngOnInit(): void {
    //console.log("testlogic");
    const sub = this.isExpanded
    .subscribe(
      isExpanded => {
        if (isExpanded && this.availability.length == 0) {
          // Get full patient
          this.scheduleLoading = true;

          this.patientsFacade
            .getFullPatient(this.patient.id)
            .subscribe(
              patient => {
                // dispatch updates patient input and causes record to close
                //this.patientsFacade.updatePatientInList(patient);

                this.fullPatient = patient;

                this.schedule = patient.schedule;
                this.availability = patient.availability;
                console.log("kani=>",this.availability);
                
                // for(let i=0;i<this.availability.length;++i){
                //   time_string = this.days[this.availability[i].dayOfWeek-1]
                //   split_time = this.getTimeFormat(this.availability[i].startTime.getHours(), this.availability[i].startTime.getMinutes())
                //   time_string += ' '+ split_time + ' - '
                //   split_time = this.getTimeFormat(this.availability[i].endTime.getHours(), this.availability[i].endTime.getMinutes())
                //   time_string += split_time
                //   this.display_time.push(time_string)
                //   console.log('this.display_time',this.display_time)
                // }
                for(let i=0;i<this.availability.length;++i){
                  
                  this.display_time.push(this.availability[i].schedule)
                }
                this.scheduleLoading = false;
              },
              error => {
                this.scheduleLoading = false;

                alert(error);
              }
            )
          // this.patientsFacade
          //   .getPatientStatus(this.patient.id)
          //   .subscribe(
          //     (status) => {
          //       console.log('status_result',status)
          //       if(status.result == 'empty')
          //         this.availability_status = [2,2,2,2,2]
          //       else{
          //         this.availability_status = status.result
          //       }
          //     }
          //   )
        }
       
      }
    )
  
    this.subscriptions.add(sub);
  }

  ngOnChanges(): void {
    if (this.selectedPatient && this.patient.id == this.selectedPatient.id) {
      this.checked = true;
      this.isExpanded.next(true);
    } else {
      this.checked = false;
      this.isExpanded.next(false);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  changePatient(event) {
    event.preventDefault();

    this.change.emit(this.patient);
  }

  toggleExpanded(): void {
    this.isExpanded.next(!this.isExpanded.getValue());
  }


    
     updatepatientdetails(event)
  {
    
    console.log(event);
   
    var myid = event.onschedId;
    var patientselectid = myid;
    //alert(myid);
    //event.preventDefault();

   // this.schange.emit(this.patient);
    //this.schange.emit(myid);

  

    //window.location.href = 'dashboard/appointment?Client='+myid;

 }  
 
 getTimeFormat(hours, minutes){
  console.log('hours,minutes',hours,minutes)
  let timeString = ''
  let hourString = ''
  let minuteString = '' 
  let am = 'AM'
  let pm = 'PM'
  //hour conversion to 12 hour format
  if(hours == 0){
    hourString = '12'
    timeString += hourString+':'
  }
  else{
    if(hours > 12){
      hourString = (hours-12).toString()
      timeString = hourString+':'
    }
    else if(hours < 10){
      hourString = '0'+(hours).toString()
      timeString = hourString+':'
    }
    else{
      hourString = (hours).toString()
      timeString = hourString+':'
    }
  }
  //minute conversion
  if(minutes == 0){
    minuteString = '00'
    timeString += minuteString
  }
  else{
    timeString+= minutes
  }

  if(hours == 0 || hours < 11){
    timeString += ''+am
  }
  else{
    timeString += ''+pm
  }

  return timeString
 }
 
 
 
}
