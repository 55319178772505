import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { EndPoints } from '../../constants/endpoints'

@Component({
  selector: 'wtls-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.scss']
})
export class CompleteComponent {

  constructor(private router: Router, private route: ActivatedRoute) { }

  newForm(): void {
    this.route.queryParams.pipe(
      take(1)
    ).subscribe((params) => {
      console.log('params', params)
      //this.router.navigate([params['form']]);
      //this.router.navigate(['/dashboard/new-clients']);
      window.location.href = EndPoints.BASE_URL+'dashboard/create-client';
     // window.location.href = 'https://abaschedulezipper.com/dashboard/new-clients';
    })
  }

  dashboard(): void {
    window.location.href = EndPoints.BASE_URL+'dashboard/clientsshow';
  }
}
