import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Provider, TimeSlot } from 'src/app/models';
import { AppState } from 'src/app/store/app.state';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatchSelectors } from 'src/app/store/selectors';
import { MatchActions } from 'src/app/store/actions';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { ProvidersFacade } from 'src/app/services/facades/providers.facade';
import { AddEditProviderFacade } from 'src/app/services/facades/addEditProvider.facade';
import { MapFacade } from 'src/app/services/facades/map.facade';
import { MatchFacade } from 'src/app/services/facades/match.facade';

interface Marker {
  provider: Provider,
  icon: google.maps.Icon
}

@Component({
  selector: 'wtls-provider-map-marker',
  templateUrl: './provider-map-marker.component.html',
  styleUrls: ['./provider-map-marker.component.scss']
})
export class ProviderMapMarkerComponent implements OnInit, OnDestroy {
  @Input() provider: Provider;

  availabilityLoading: boolean;

  schedule: TimeSlot[] = [];
  availability: TimeSlot[] = [];

  defaultIcon: google.maps.Icon = {
    url: '../../../../assets/map-markers/provider-unassigned.svg',
  };

  selectedIcon: google.maps.Icon = {
    url: '../../../../assets/map-markers/provider-unassigned-selected.svg',
  };

  marker: Marker;

  selected: boolean;

  selectedProvider: Provider;

  isOpen: boolean;

  stayOpen: boolean;

  fullScreen: boolean;

  loading: boolean;

  subscriptions: Subscription = new Subscription();

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    private providersFacade: ProvidersFacade,
    private mapFacade: MapFacade,
    private matchFacade: MatchFacade,
    private addEditProviderFacade: AddEditProviderFacade
  ) { }

  ngOnInit(): void {
    this.marker = {
      provider: this.provider,
      icon: this.defaultIcon
    };

    const sub1 = this.mapFacade
      .getMapFullScreen()
      .subscribe((value) => {
        this.fullScreen = value;
      })

    const sub2 = this.matchFacade
      .getProvider()
      .subscribe((provider) => {
        this.selectedProvider = provider;

        if (provider && this.provider.id == provider.id) {
          this.marker.icon = this.selectedIcon;
          this.stayOpen = true;
          this.selected = true;
        } else {
          this.marker.icon = this.defaultIcon;
          this.stayOpen = false;
          this.selected = false;
        }
      });

    const sub3 = this.providersFacade
      .getLoading()
      .subscribe((loading) => {
        this.loading = loading
      })

    this.subscriptions.add(sub1);
    this.subscriptions.add(sub2);
    this.subscriptions.add(sub3);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  checkAvailability(): void {
    if (this.fullScreen && this.availability.length == 0) {
      this.availabilityLoading = true;

      this.providersFacade
        .getFullProvider(this.provider.id)
        .subscribe(
          provider => {
            this.schedule = provider.schedule;
            this.availability = provider.availability;

            this.availabilityLoading = false;
          },
          error => {
            this.availabilityLoading = false;

            alert(error)
          }
        )
    }
  }

  changeProvider(): void {
    if (!this.loading)
      this.matchFacade.selectProvider(this.provider);
  }

  mouseOverMarker(): void {
    this.isOpen = true;
  }

  mouseOutMarker(): void {
    this.isOpen = false;
  }

  closeInfoWindow(): void {
    this.stayOpen = false;
  }

  openEditPanel(): void {
    this.providersFacade
    .getFullProvider(this.provider.id)
    .subscribe(
      provider => {
        this.addEditProviderFacade.setProvider(provider);
        this.addEditProviderFacade.openPanel();
      },
      error => {
        alert(error);
      }
    )
  }

  openDeleteDialog(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: this.provider
    })

    dialogRef.beforeClosed()
      .subscribe(result => {
        if (result) {
          this.providersFacade.deleteProvider(this.provider)
            .subscribe(res => {
              console.log('provider deleted')
            })
        }
      })
  }
}
