import { createReducer, on, Action } from '@ngrx/store';
import { Provider } from "src/app/models";
import { AddEditProviderActions } from '../actions';

export interface AddEditProviderState {
    open: boolean,
    provider: Provider,
    loading: boolean,
    error: string
}

const initialState: AddEditProviderState = {
    open: false,
    provider: null,
    loading: false,
    error: ''
}

const reducer = createReducer(
    initialState,
    on(AddEditProviderActions.openPanel, (state) => ({ ...state, open: true })),
    on(AddEditProviderActions.closePanel, (state) => ({ ...state, open: false })),
    on(AddEditProviderActions.setProvider, (state, { provider }) => ({ ...state, provider })),
    on(AddEditProviderActions.clearProvider, (state) => ({ ...state, provider: null })),
    on(AddEditProviderActions.updateProvider, (state, { provider }) => ({ ...state, loading: true })),
    on(AddEditProviderActions.updateProviderSuccess, (state) => ({ ...state, loading: false })),
    on(AddEditProviderActions.updateProviderError, (state, { error }) => ({ ...state, loading: false, error })),
);

export function addEditProviderReducer(state: AddEditProviderState | undefined, action: Action) {
    return reducer(state, action);
}