import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FiltersState } from 'src/app/store/reducers';
import { LIST_OF_US_STATES_ABBREVIATIONS } from 'src/app/constants/usStates';

@Component({
  selector: 'wtls-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss'],
})
export class FilterDialogComponent implements OnInit {
  filterData: FiltersState;

  clinicLocationOptions = [];

  stateOptions = LIST_OF_US_STATES_ABBREVIATIONS;

  constructor(
    private dialogRef: MatDialogRef<FilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.filterData = { ...this.data.filterData };
    this.clinicLocationOptions = this.data.clinicLocationOptions;
  }

  onSave(): void {
    this.dialogRef.close(this.filterData);
  }
}
