
<div class="page-container">
    <div class="content-container" >
      

        <!-- <div class="row"  *ngIf="showMsg">
            <div class="col-xs-12">
              <p class="alert alert-primary alert-dismissible">
                
                  <strong>Provider Updated Success!</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
    
              </p>
            </div>
          </div> -->


      <div class="container">
        <form  [formGroup]="form" (ngSubmit)="submitForm()"
        > 
       <!--  <div *ngFor="let e of lis;" > -->
          <div class="header-section" >
          <!--   <h2 class="title">{{ e.form_title }}</h2>
            <h5 class="title">{{ e.form_sub_title }}</h5> -->
            <h2 class="title">Sandcastle Centers</h2>
            <h5 class="title">START A LIFE CHANGING DIALOGUE</h5>
          </div>
          <!-- <p align="left">CLIENT'S INFORMATION</p>
         
          <p align="right">(Fields marked with * are required)</p> -->
         
            <div class="row">
              <div class="col-md-6 text-left " ><h6 style="font-weight: bold;">Client'S Information</h6></div>
              <div class="col-md-6 text-right">(Fields marked with * are required)</div>
              
            </div><hr>
           
         
        
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                      <label for="name" > First Name* </label>
                      <input type="text" formControlName="first_name" name="first_name" placeholder="First Name" [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }"  class="form-control"  style="background-color: #f2f2f2"    >
                      <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                        <div *ngIf="f.first_name.errors.required">First Name  is required</div>
                    </div>
                </div></div>
                
                <div class="col-md-4">
                    <div class="form-group">
                          <label for="name" >Last Name* </label>
                          <input type="text" formControlName="last_name" name="last_name" placeholder="Last Name" [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }"  class="form-control"   style="background-color: #f2f2f2">
                          <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                            <div *ngIf="f.last_name.errors.required">Last Name  is required</div>
                        </div>
                    </div></div>
  
              
  
                    <div class="col-md-2">
                        <div class="form-group" >
                              <label for="name">DOB* </label>
                              <input type="date" formControlName="dob" name="dob"   class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dob.errors }" style="background-color: #f2f2f2" >
                              <div *ngIf="submitted && f.dob.errors" class="invalid-feedback">
                                <div *ngIf="f.dob.errors.required">DOB  is required</div>
                            </div>
                        </div></div>

                
                        <div class="col-md-2">
                            <div class="form-group">
                                  <label for="name" >Gender* </label>
                                  <select class="custom-select" formControlName="gender" [ngClass]="{ 'is-invalid': submitted && f.gender.errors }"  name="gender" style="background-color: #f2f2f2">
                                    <option value="" >Select Gender</option>
                                    <option value="Male" selected>Male</option>
                                    <option value="Female">Female</option>
                                   
                                  </select>
                                  <div *ngIf="submitted && f.gender.errors" class="invalid-feedback">
                                    <div *ngIf="f.gender.errors.required">Gender  is required</div>
                                </div>
                            </div></div>
              
                
             <!--  </div> -->
             <div class="col-md-12">
                <div class="form-group">
                      <label for="name" >Your Message/Concerns* </label>
                      <textarea class="form-control" placeholder="Your Message" formControlName="message" [ngClass]="{ 'is-invalid': submitted && f.message.errors }"  rows="2" style="background-color: #f2f2f2"></textarea>
                      <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                        <div *ngIf="f.message.errors.required">Message  is required</div>
                    </div>
                </div></div>

                <div class="col-md-6">
                    <div class="form-group">
                          <label for="name" >Cell Phone Number*</label>
                          <input type="number" formControlName="phone" placeholder="Cell Phone Number"  [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"  class="form-control"  style="background-color: #f2f2f2">
                          <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                            <div *ngIf="f.phone.errors.required"> Phone Number  is required</div>
                        </div>
                         
                    </div></div>
                   
                        
                            <div class="col-md-6">
                                <div class="form-group">
                                      <label for="name" >Email*</label>
                                      <input type="text" formControlName="email" placeholder="Email"  class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"  style="background-color: #f2f2f2">
                                      <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required"> Email  is required</div>
                                    </div>
                                </div></div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                          <label for="name" >How Did You Hear About us?*</label>
                                          <input type="text" formControlName="found_us" name="found_us" placeholder="How Did You Hear About us?" [ngClass]="{ 'is-invalid': submitted && f.found_us.errors }"  class="form-control"  style="background-color: #f2f2f2">
                                          <div *ngIf="submitted && f.found_us.errors" class="invalid-feedback">
                                            <div *ngIf="f.found_us.errors.required"> How Did You Hear About us?  is required</div>
                                        </div>
                                    </div></div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                              <label for="name" >What Is The Best Time Of Day To Reach You?</label>
                                              <input type="text" formControlName="contact_time_of_day" placeholder="What Is The Best Time Of Day To Reach You?" [ngClass]="{ 'is-invalid': submitted && f.contact_time_of_day.errors }"    class="form-control" style="background-color: #f2f2f2" >
                                              <div *ngIf="submitted && f.contact_time_of_day.errors" class="invalid-feedback">
                                                <div *ngIf="f.contact_time_of_day.errors.required"> What Is The Best Time Of Day To Reach You?  is required</div>
                                            </div>
                                        </div></div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                  <label for="name" >Preferred Method Of Contact*</label>
                                                  <input type="text" formControlName="contact_preferred_method" placeholder="Preferred Method Of Contact" [ngClass]="{ 'is-invalid': submitted && f.contact_preferred_method.errors }"  class="form-control" style="background-color: #f2f2f2" >
                                                  <div *ngIf="submitted && f.contact_preferred_method.errors" class="invalid-feedback">
                                                    <div *ngIf="f.contact_preferred_method.errors.required"> Preferred Method Of Contact  is required</div>
                                                </div>
                                            </div></div>

                                            <div class="col-md-6">
                                                <div class="form-group" >
                                                      <label for="name" >Client Type</label>
                                                     <!--  <input type="text" formControlName="name" placeholder="Client Type"  class="form-control"  style="background-color: #f2f2f2"> -->
                                                     <select  class="form-control" formControlName="patient_type" name="patient_type" [ngClass]="{ 'is-invalid': submitted && f.patient_type.errors }"   style="background-color: #f2f2f2" >
                                                      <option value="">select Client Type</option>
                                                      <option value="new">New</option>
                                                         
                                                       </select>
                                                       <div *ngIf="submitted && f.patient_type.errors" class="invalid-feedback">
                                                        <div *ngIf="f.patient_type.errors.required"> Client Type  is required</div>
                                                    </div>
                                                </div></div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                          <label for="name" >Insurance Type</label>
                                                          <!-- <input type="text" formControlName="name"  placeholder="Insurance Type"  class="form-control" style="background-color: #f2f2f2" > -->
                                                          <select name="cars" *ngIf="other == false" (ngModelChange)="onChange($event)" formControlName="insurance_type"  class="form-control" [ngClass]="{ 'is-invalid': submitted && f.insurance_type.errors }"  id="patient_id" style="background-color: #f2f2f2">
                                                         <option value="">select Insurance Type</option>
                                                          <option value="BCBS Care First">BCBS Care First</option>
                                                          <option value="Aetna">Aetna</option>
                                                          <option value="Magellan">Magellan</option>
                                                          <option value="BCBS FEP">BCBS FEP</option>
                                                          <option value="Cigna">Cigna</option>
                                                          <option value="Medicaid">Medicaid</option>
                                                          <option value="GEHA">GEHA</option>
                                                          <option value="Tricare">Tricare</option>
                                                          <option value="Humana">Humana</option>
                                                          <option value="John Hopkins Health">John Hopkins Health</option>
                                                          <option value="Kaiser">Kaiser</option>
                                                          <option value="Optum">Optum</option>
                                                          <option value="United Healthcare">United Healthcare</option>
                                                          <!-- <option value="" (selected)="changeOther()">Other</option> -->
                                                          <option value="other">other</option>
                                                         
                                                        </select> 
                                                       <input type="text" *ngIf="other == true" formControlName="insurance_type"  placeholder="Insurance Type" [ngClass]="{ 'is-invalid': submitted && f.insurance_type.errors }"  class="form-control" style="background-color: #f2f2f2" > 
                                                         <!-- <input list="browsers" formControlName="insurance_type" placeholder="select or type" name="browser" id="browser" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.insurance_type.errors }"  id="patient_id" style="background-color: #f2f2f2">
                                                          <datalist id="browsers">
                                                            <option value="">select Insurance Type</option>
                                                          <option value="BCBS Care First">BCBS Care First</option>
                                                          <option value="Aetna">Aetna</option>
                                                          <option value="Magellan">Magellan</option>
                                                          <option value="BCBS FEP">BCBS FEP</option>
                                                          <option value="Cigna">Cigna</option>
                                                          <option value="Medicaid">Medicaid</option>
                                                          <option value="GEHA">GEHA</option>
                                                          <option value="Tricare">Tricare</option>
                                                          <option value="Humana">Humana</option>
                                                          <option value="John Hopkins Health">John Hopkins Health</option>
                                                          <option value="Kaiser">Kaiser</option>
                                                          <option value="Optum">Optum</option>
                                                          <option value="United Healthcare">United Healthcare</option>
                                                       
                                                          </datalist>  -->

                                                          
                                                        <div *ngIf="submitted && f.insurance_type.errors" class="invalid-feedback">
                                                          <div *ngIf="f.insurance_type.errors.required"> Insurance Type is required</div>
                                                      </div>
                                                    </div></div>

                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                              <label for="name" >Intake Stage</label>
                                                            <!--   <input type="text" formControlName="patient_intake_stage"  placeholder="Intake Stage"  class="form-control" style="background-color: #f2f2f2" > -->
                                                            <select  class="form-control" formControlName="intake_stage" [ngClass]="{ 'is-invalid': submitted && f.intake_stage.errors }"  name="intake_stage" style="background-color: #f2f2f2" >
                                                                <option value="">select Intake Stage </option>
                                                              <option value="new">New</option>
                                                                 
                                                               </select>
                                                               <div *ngIf="submitted && f.intake_stage.errors" class="invalid-feedback">
                                                                <div *ngIf="f.intake_stage.errors.required"> Intake Stage is required</div>
                                                            </div>
                                                        </div></div>

                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                  <label for="name" >Address Line 1*</label>
                                                                  <input type="text" formControlName="addressline1"  placeholder="Address Line 1" [ngClass]="{ 'is-invalid': submitted && f.addressline1.errors }"  class="form-control" style="background-color: #f2f2f2" >
                                                                  <div *ngIf="submitted && f.addressline1.errors" class="invalid-feedback">
                                                                    <div *ngIf="f.addressline1.errors.required"> Address Line is required</div>
                                                                </div>
                                                               
                                                            </div></div>

                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                      <label for="name" >Address Line 2*</label>
                                                                      <input type="text" formControlName="addressline2" placeholder="Address Line 2"    class="form-control" style="background-color: #f2f2f2" >
                                                                    
                                                                    
                                                                </div></div>

                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                          <label for="name" >City*</label>
                                                                          <input type="text" formControlName="city" placeholder="City" [ngClass]="{ 'is-invalid': submitted && f.city.errors }"   class="form-control"  style="background-color: #f2f2f2">
                                                                          <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                                                            <div *ngIf="f.city.errors.required"> City is required</div>
                                                                        </div>
                                                                         
                                                                    </div></div>

                                                                    <div class="col-md-6">
                                                                        <div class="form-group">
                                                                              <label for="name" >State*</label>
                                                                              <input type="text" formControlName="state_abbr"  placeholder="State" [ngClass]="{ 'is-invalid': submitted && f.state_abbr.errors }"  class="form-control"  style="background-color: #f2f2f2">
                                                                              <div *ngIf="submitted && f.state_abbr.errors" class="invalid-feedback">
                                                                                <div *ngIf="f.state_abbr.errors.required"> State is required</div>
                                                                            </div>
                                                                        </div></div>

                                                                        <div class="col-md-4">
                                                                          <div class="form-group">
                                                                                <label for="name" >Country*</label>
                                                                                <input type="text" formControlName="country" placeholder="Country" [ngClass]="{ 'is-invalid': submitted && f.country.errors }"  class="form-control"  style="background-color: #f2f2f2">
                                                                                <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                                                                                  <div *ngIf="f.country.errors.required"> Country is required</div>
                                                                              </div>
                                                                          </div></div>
      
                                                                          <div class="col-md-4">
                                                                              <div class="form-group">
                                                                                    <label for="name" >Zipcode*</label>
                                                                                    <input type="text" formControlName="zipcode" placeholder="Zipcode"  [ngClass]="{ 'is-invalid': submitted && f.zipcode.errors }" class="form-control"  style="background-color: #f2f2f2">
                                                                                  
                                                                                    <div *ngIf="submitted && f.zipcode.errors" class="invalid-feedback">
                                                                                      <div *ngIf="f.zipcode.errors.required"> Zipcode is required</div>
                                                                                  </div>
                                                                              </div></div>


                                                                        <div class="col-md-4">
                                                                            <div class="form-group">
                                                                                  <label for="name" >Clinic Location*</label>
                                                                                
                                                                                  <select  class="form-control" formControlName="clinic_name" name="clinic_id" [ngClass]="{ 'is-invalid': submitted && f.clinic_name.errors }"  style="background-color: #f2f2f2" >
                      
                                                                                 <option>Alenta</option>
                                                                                    
                                                                                  </select>
                                                                                  <div *ngIf="submitted && f.clinic_name.errors" class="invalid-feedback">
                                                                                    <div *ngIf="f.clinic_name.errors.required"> Clinic Location is required</div>
                                                                                </div>
                                                                            </div></div>
              
                <!--Monday is open	11:30 AM	to	05:00 PM--->
                
                <div class="col-md-4">
                  <div class="form-group" >
                    Day

                   
                     <input type="text"    class="form-control" value="Monday"  style="background-color: #f2f2f2">
                    </div>  
                    </div>  
                   
                    <div class="col-md-4">
                      <div class="form-group" >
                        Start
                        <select class="select-input form-control" formControlName="mon_startTime"  style="background-color: #f2f2f2">
                          <option value="00:00:00" [selected]="1">12.00 AM</option>
                          <option value="00:30:00" selected="selected">12.30 AM</option>
                          <option value="01:00:00" selected="selected">1.00 AM</option>
                          <option value="01:30:00" selected="selected">1.30 AM</option>
                          <option value="02:00:00" selected="selected">2.00 AM</option>
                          <option value="02:30:00" selected="selected">2.30 AM</option>
                          <option value="03:00:00" selected="selected">3.00 AM</option>
                          <option value="03:30:00" selected="selected">3.30 AM</option>
                          <option value="04:00:00" selected="selected">4.00 AM</option>
                          <option value="04:30:00" selected="selected">4.30 AM</option>
                          <option value="05:00:00" selected="selected">5.00 AM</option>
                          <option value="05:30:00" selected="selected">5.30 AM</option>
                          <option value="06:00:00" selected="selected">6.00 AM</option>
                          <option value="06:30:00" selected="selected">6.30 AM</option>
                          <option value="07:00:00" selected="selected">7.00 AM</option>
                          <option value="07:30:00" selected="selected">7.30 AM</option>
                          <option value="08:00:00" selected="selected">8.00 AM</option>
                          <option value="08:30:00" selected="selected">8.30 AM</option>
                          <option value="09:00:00" selected="selected">9.00 AM</option>
                          <option value="09.30:00" selected="selected">9.30 AM</option>
                          <option value="10:00:00" selected="selected">10.00 AM</option>
                          <option value="10:30:00" selected="selected">10.30 AM</option>
                          <option value="11:00:00" selected="selected">11.00 AM</option>
                          <option value="11:30:00" selected="selected">11.30 AM</option>
                          <option value="12:00:00" selected="selected">12.00 PM</option>
                          <option value="12:30:00" selected="selected">12.30 PM</option>
                          <option value="13:00:00" selected="selected">1.00 PM</option>
                          <option value="13:30:00" selected="selected">1.30 PM</option>
                          <option value="14:00:00" selected="selected">2.00 PM</option>
                          <option value="14:30:00" selected="selected">2.30 PM</option>
                          <option value="15:00:00" selected="selected">3.00 PM</option>
                          <option value="15:30:00" selected="selected">3.30 PM</option>
                          <option value="16:00:00" selected="selected">4.00 PM</option>
                          <option value="16:30:00" selected="selected">4.30 PM</option>
                          <option value="17:00:00" selected="selected">5.00 PM</option>
                          <option value="17:30:00" selected="selected">5.30 PM</option>
                          <option value="18:00:00" selected="selected">6.00 PM</option>
                          <option value="18:30:00" selected="selected">6.30 PM</option>
                          <option value="19:00:00" selected="selected">7.00 PM</option>
                          <option value="19:30:00" selected="selected">7.30 PM</option>
                          <option value="20:00:00" selected="selected">8.00 PM</option>
                          <option value="20:30:00" selected="selected">8.30 PM</option>
                          <option value="21:00:00" selected="selected">9.00 PM</option>
                          <option value="21:30:00" selected="selected">9.30 PM</option>
                          <option value="22:00:00" selected="selected">10.00 PM</option>
                          <option value="22:30:00" selected="selected">10.30 PM</option>
                          <option value="23:00:00" selected="selected">11.00 PM</option>
                          <option value="23:30:00" selected="selected">11.30 PM</option>
                          <option value="00:00:00" selected="selected">12.00 AM</option>
                         </select>
                       
                        </div>  
                        </div>  
  
                        <div class="col-md-4">
                          <div class="form-group" >
                            End
                            <select class="select-input form-control" formControlName="mon_endTime" style="background-color: #f2f2f2">
                              <option value="00:00:00" selected="selected">12.00 AM</option>
                          <option value="00:30:00" selected="selected">12.30 AM</option>
                          <option value="01:00:00" selected="selected">1.00 AM</option>
                          <option value="01:30:00" selected="selected">1.30 AM</option>
                          <option value="02:00:00" selected="selected">2.00 AM</option>
                          <option value="02:30:00" selected="selected">2.30 AM</option>
                          <option value="03:00:00" selected="selected">3.00 AM</option>
                          <option value="03:30:00" selected="selected">3.30 AM</option>
                          <option value="04:00:00" selected="selected">4.00 AM</option>
                          <option value="04:30:00" selected="selected">4.30 AM</option>
                          <option value="05:00:00" selected="selected">5.00 AM</option>
                          <option value="05:30:00" selected="selected">5.30 AM</option>
                          <option value="06:00:00" selected="selected">6.00 AM</option>
                          <option value="06:30:00" selected="selected">6.30 AM</option>
                          <option value="07:00:00" selected="selected">7.00 AM</option>
                          <option value="07:30:00" selected="selected">7.30 AM</option>
                          <option value="08:00:00" selected="selected">8.00 AM</option>
                          <option value="08:30:00" selected="selected">8.30 AM</option>
                          <option value="09:00:00" selected="selected">9.00 AM</option>
                          <option value="09.30:00" selected="selected">9.30 AM</option>
                          <option value="10:00:00" selected="selected">10.00 AM</option>
                          <option value="10:30:00" selected="selected">10.30 AM</option>
                          <option value="11:00:00" selected="selected">11.00 AM</option>
                          <option value="11:30:00" selected="selected">11.30 AM</option>
                          <option value="12:00:00" selected="selected">12.00 PM</option>
                          <option value="12:30:00" selected="selected">12.30 PM</option>
                          <option value="13:00:00" selected="selected">1.00 PM</option>
                          <option value="13:30:00" selected="selected">1.30 PM</option>
                          <option value="14:00:00" selected="selected">2.00 PM</option>
                          <option value="14:30:00" selected="selected">2.30 PM</option>
                          <option value="15:00:00" selected="selected">3.00 PM</option>
                          <option value="15:30:00" selected="selected">3.30 PM</option>
                          <option value="16:00:00" selected="selected">4.00 PM</option>
                          <option value="16:30:00" selected="selected">4.30 PM</option>
                          <option value="17:00:00" [selected]="1">5.00 PM</option>
                          <option value="17:30:00" selected="selected">5.30 PM</option>
                          <option value="18:00:00" selected="selected">6.00 PM</option>
                          <option value="18:30:00" selected="selected">6.30 PM</option>
                          <option value="19:00:00" selected="selected">7.00 PM</option>
                          <option value="19:30:00" selected="selected">7.30 PM</option>
                          <option value="20:00:00" selected="selected">8.00 PM</option>
                          <option value="20:30:00" selected="selected">8.30 PM</option>
                          <option value="21:00:00" selected="selected">9.00 PM</option>
                          <option value="21:30:00" selected="selected">9.30 PM</option>
                          <option value="22:00:00" selected="selected">10.00 PM</option>
                          <option value="22:30:00" selected="selected">10.30 PM</option>
                          <option value="23:00:00" selected="selected">11.00 PM</option>
                          <option value="23:30:00" selected="selected">11.30 PM</option>
                          <option value="00:00:00" selected="selected">12.00 AM</option>
                             </select>
                            
                            </div>  
                            </div>  


                            <!----MONDAY END-->
  
                       
     <!--tueday is open	11:30 AM	to	05:00 PM--->
                
     <div class="col-md-4">
      <div class="form-group">
       <!-- <b>DAY</b>-->
       
         <input type="text"   class="form-control" value="Tuesday" style="background-color: #f2f2f2">
        </div>  
        </div>  
       
        <div class="col-md-4">
          <div class="form-group">
           <!--<b>START</b>--> 
            <select class="select-input form-control" formControlName="tue_startTime" name="tue_startTime" style="background-color: #f2f2f2">
              <option value="00:00:00" [selected]="1">12.00 AM</option>
              <option value="00:30:00" selected="selected">12.30 AM</option>
              <option value="01:00:00" selected="selected">1.00 AM</option>
              <option value="01:30:00" selected="selected">1.30 AM</option>
              <option value="02:00:00" selected="selected">2.00 AM</option>
              <option value="02:30:00" selected="selected">2.30 AM</option>
              <option value="03:00:00" selected="selected">3.00 AM</option>
              <option value="03:30:00" selected="selected">3.30 AM</option>
              <option value="04:00:00" selected="selected">4.00 AM</option>
              <option value="04:30:00" selected="selected">4.30 AM</option>
              <option value="05:00:00" selected="selected">5.00 AM</option>
              <option value="05:30:00" selected="selected">5.30 AM</option>
              <option value="06:00:00" selected="selected">6.00 AM</option>
              <option value="06:30:00" selected="selected">6.30 AM</option>
              <option value="07:00:00" selected="selected">7.00 AM</option>
              <option value="07:30:00" selected="selected">7.30 AM</option>
              <option value="08:00:00" selected="selected">8.00 AM</option>
              <option value="08:30:00" selected="selected">8.30 AM</option>
              <option value="09:00:00" selected="selected">9.00 AM</option>
              <option value="09.30:00" selected="selected">9.30 AM</option>
              <option value="10:00:00" selected="selected">10.00 AM</option>
              <option value="10:30:00" selected="selected">10.30 AM</option>
              <option value="11:00:00" selected="selected">11.00 AM</option>
              <option value="11:30:00" selected="selected">11.30 AM</option>
              <option value="12:00:00" selected="selected">12.00 PM</option>
              <option value="12:30:00" selected="selected">12.30 PM</option>
              <option value="13:00:00" selected="selected">1.00 PM</option>
              <option value="13:30:00" selected="selected">1.30 PM</option>
              <option value="14:00:00" selected="selected">2.00 PM</option>
              <option value="14:30:00" selected="selected">2.30 PM</option>
              <option value="15:00:00" selected="selected">3.00 PM</option>
              <option value="15:30:00" selected="selected">3.30 PM</option>
              <option value="16:00:00" selected="selected">4.00 PM</option>
              <option value="16:30:00" selected="selected">4.30 PM</option>
              <option value="17:00:00" selected="selected">5.00 PM</option>
              <option value="17:30:00" selected="selected">5.30 PM</option>
              <option value="18:00:00" selected="selected">6.00 PM</option>
              <option value="18:30:00" selected="selected">6.30 PM</option>
              <option value="19:00:00" selected="selected">7.00 PM</option>
              <option value="19:30:00" selected="selected">7.30 PM</option>
              <option value="20:00:00" selected="selected">8.00 PM</option>
              <option value="20:30:00" selected="selected">8.30 PM</option>
              <option value="21:00:00" selected="selected">9.00 PM</option>
              <option value="21:30:00" selected="selected">9.30 PM</option>
              <option value="22:00:00" selected="selected">10.00 PM</option>
              <option value="22:30:00" selected="selected">10.30 PM</option>
              <option value="23:00:00" selected="selected">11.00 PM</option>
              <option value="23:30:00" selected="selected">11.30 PM</option>
              <option value="00:00:00" selected="selected">12.00 AM</option>
             </select>
            
            </div>  
            </div>  
  
            <div class="col-md-4">
              <div class="form-group">
                <!--<b>END</b>-->
                <select class="select-input form-control" formControlName="tue_endTime" name="tue_endTime" style="background-color: #f2f2f2">
                  <option value="00:00:00" [selected]="1">12.00 AM</option>
                          <option value="00:30:00" selected="selected">12.30 AM</option>
                          <option value="01:00:00" selected="selected">1.00 AM</option>
                          <option value="01:30:00" selected="selected">1.30 AM</option>
                          <option value="02:00:00" selected="selected">2.00 AM</option>
                          <option value="02:30:00" selected="selected">2.30 AM</option>
                          <option value="03:00:00" selected="selected">3.00 AM</option>
                          <option value="03:30:00" selected="selected">3.30 AM</option>
                          <option value="04:00:00" selected="selected">4.00 AM</option>
                          <option value="04:30:00" selected="selected">4.30 AM</option>
                          <option value="05:00:00" selected="selected">5.00 AM</option>
                          <option value="05:30:00" selected="selected">5.30 AM</option>
                          <option value="06:00:00" selected="selected">6.00 AM</option>
                          <option value="06:30:00" selected="selected">6.30 AM</option>
                          <option value="07:00:00" selected="selected">7.00 AM</option>
                          <option value="07:30:00" selected="selected">7.30 AM</option>
                          <option value="08:00:00" selected="selected">8.00 AM</option>
                          <option value="08:30:00" selected="selected">8.30 AM</option>
                          <option value="09:00:00" selected="selected">9.00 AM</option>
                          <option value="09.30:00" selected="selected">9.30 AM</option>
                          <option value="10:00:00" selected="selected">10.00 AM</option>
                          <option value="10:30:00" selected="selected">10.30 AM</option>
                          <option value="11:00:00" selected="selected">11.00 AM</option>
                          <option value="11:30:00" selected="selected">11.30 AM</option>
                          <option value="12:00:00" selected="selected">12.00 PM</option>
                          <option value="12:30:00" selected="selected">12.30 PM</option>
                          <option value="13:00:00" selected="selected">1.00 PM</option>
                          <option value="13:30:00" selected="selected">1.30 PM</option>
                          <option value="14:00:00" selected="selected">2.00 PM</option>
                          <option value="14:30:00" selected="selected">2.30 PM</option>
                          <option value="15:00:00" selected="selected">3.00 PM</option>
                          <option value="15:30:00" selected="selected">3.30 PM</option>
                          <option value="16:00:00" selected="selected">4.00 PM</option>
                          <option value="16:30:00" selected="selected">4.30 PM</option>
                          <option value="17:00:00" [selected]="1">5.00 PM</option>
                          <option value="17:30:00" selected="selected">5.30 PM</option>
                          <option value="18:00:00" selected="selected">6.00 PM</option>
                          <option value="18:30:00" selected="selected">6.30 PM</option>
                          <option value="19:00:00" selected="selected">7.00 PM</option>
                          <option value="19:30:00" selected="selected">7.30 PM</option>
                          <option value="20:00:00" selected="selected">8.00 PM</option>
                          <option value="20:30:00" selected="selected">8.30 PM</option>
                          <option value="21:00:00" selected="selected">9.00 PM</option>
                          <option value="21:30:00" selected="selected">9.30 PM</option>
                          <option value="22:00:00" selected="selected">10.00 PM</option>
                          <option value="22:30:00" selected="selected">10.30 PM</option>
                          <option value="23:00:00" selected="selected">11.00 PM</option>
                          <option value="23:30:00" selected="selected">11.30 PM</option>
                          <option value="00:00:00" selected="selected">12.00 AM</option>
                 </select>
               
                </div>  
                </div>  
                <!----tueday END-->
              
                    <!--Wednesday is open	11:30 AM	to	05:00 PM--->
                
     <div class="col-md-4">
      <div class="form-group">
       <!--<b>DAY</b>---> 
       
         <input type="text"   class="form-control" value="Wednesday" style="background-color: #f2f2f2">
        </div>  
        </div>  
      
        <div class="col-md-4">
          <div class="form-group">
           <!--<b>START</b>---> 
            <select class="select-input form-control" formControlName="wed_startTime" name="wed_startTime" style="background-color: #f2f2f2">
              <option value="00:00:00" [selected]="1">12.00 AM</option>
              <option value="00:30:00" selected="selected">12.30 AM</option>
              <option value="01:00:00" selected="selected">1.00 AM</option>
              <option value="01:30:00" selected="selected">1.30 AM</option>
              <option value="02:00:00" selected="selected">2.00 AM</option>
              <option value="02:30:00" selected="selected">2.30 AM</option>
              <option value="03:00:00" selected="selected">3.00 AM</option>
              <option value="03:30:00" selected="selected">3.30 AM</option>
              <option value="04:00:00" selected="selected">4.00 AM</option>
              <option value="04:30:00" selected="selected">4.30 AM</option>
              <option value="05:00:00" selected="selected">5.00 AM</option>
              <option value="05:30:00" selected="selected">5.30 AM</option>
              <option value="06:00:00" selected="selected">6.00 AM</option>
              <option value="06:30:00" selected="selected">6.30 AM</option>
              <option value="07:00:00" selected="selected">7.00 AM</option>
              <option value="07:30:00" selected="selected">7.30 AM</option>
              <option value="08:00:00" selected="selected">8.00 AM</option>
              <option value="08:30:00" selected="selected">8.30 AM</option>
              <option value="09:00:00" selected="selected">9.00 AM</option>
              <option value="09.30:00" selected="selected">9.30 AM</option>
              <option value="10:00:00" selected="selected">10.00 AM</option>
              <option value="10:30:00" selected="selected">10.30 AM</option>
              <option value="11:00:00" selected="selected">11.00 AM</option>
              <option value="11:30:00" selected="selected">11.30 AM</option>
              <option value="12:00:00" selected="selected">12.00 PM</option>
              <option value="12:30:00" selected="selected">12.30 PM</option>
              <option value="13:00:00" selected="selected">1.00 PM</option>
              <option value="13:30:00" selected="selected">1.30 PM</option>
              <option value="14:00:00" selected="selected">2.00 PM</option>
              <option value="14:30:00" selected="selected">2.30 PM</option>
              <option value="15:00:00" selected="selected">3.00 PM</option>
              <option value="15:30:00" selected="selected">3.30 PM</option>
              <option value="16:00:00" selected="selected">4.00 PM</option>
              <option value="16:30:00" selected="selected">4.30 PM</option>
              <option value="17:00:00" selected="selected">5.00 PM</option>
              <option value="17:30:00" selected="selected">5.30 PM</option>
              <option value="18:00:00" selected="selected">6.00 PM</option>
              <option value="18:30:00" selected="selected">6.30 PM</option>
              <option value="19:00:00" selected="selected">7.00 PM</option>
              <option value="19:30:00" selected="selected">7.30 PM</option>
              <option value="20:00:00" selected="selected">8.00 PM</option>
              <option value="20:30:00" selected="selected">8.30 PM</option>
              <option value="21:00:00" selected="selected">9.00 PM</option>
              <option value="21:30:00" selected="selected">9.30 PM</option>
              <option value="22:00:00" selected="selected">10.00 PM</option>
              <option value="22:30:00" selected="selected">10.30 PM</option>
              <option value="23:00:00" selected="selected">11.00 PM</option>
              <option value="23:30:00" selected="selected">11.30 PM</option>
              <option value="00:00:00" selected="selected">12.00 AM</option>
             </select>
           
            </div>  
            </div>  
  
            <div class="col-md-4">
              <div class="form-group">
               <!---<b>END</b>---> 
                <select class="select-input form-control" formControlName="wed_endTime" name="wed_endTime" style="background-color: #f2f2f2">
                  <option value="00:30:00" selected="selected">12.30 AM</option>
                  <option value="01:00:00" selected="selected">1.00 AM</option>
                  <option value="01:30:00" selected="selected">1.30 AM</option>
                  <option value="02:00:00" selected="selected">2.00 AM</option>
                  <option value="02:30:00" selected="selected">2.30 AM</option>
                  <option value="03:00:00" selected="selected">3.00 AM</option>
                  <option value="03:30:00" selected="selected">3.30 AM</option>
                  <option value="04:00:00" selected="selected">4.00 AM</option>
                  <option value="04:30:00" selected="selected">4.30 AM</option>
                  <option value="05:00:00" selected="selected">5.00 AM</option>
                  <option value="05:30:00" selected="selected">5.30 AM</option>
                  <option value="06:00:00" selected="selected">6.00 AM</option>
                  <option value="06:30:00" selected="selected">6.30 AM</option>
                  <option value="07:00:00" selected="selected">7.00 AM</option>
                  <option value="07:30:00" selected="selected">7.30 AM</option>
                  <option value="08:00:00" selected="selected">8.00 AM</option>
                  <option value="08:30:00" selected="selected">8.30 AM</option>
                  <option value="09:00:00" selected="selected">9.00 AM</option>
                  <option value="09.30:00" selected="selected">9.30 AM</option>
                  <option value="10:00:00" selected="selected">10.00 AM</option>
                  <option value="10:30:00" selected="selected">10.30 AM</option>
                  <option value="11:00:00" selected="selected">11.00 AM</option>
                  <option value="11:30:00" selected="selected">11.30 AM</option>
                  <option value="12:00:00" selected="selected">12.00 PM</option>
                  <option value="12:30:00" selected="selected">12.30 PM</option>
                  <option value="13:00:00" selected="selected">1.00 PM</option>
                  <option value="13:30:00" selected="selected">1.30 PM</option>
                  <option value="14:00:00" selected="selected">2.00 PM</option>
                  <option value="14:30:00" selected="selected">2.30 PM</option>
                  <option value="15:00:00" selected="selected">3.00 PM</option>
                  <option value="15:30:00" selected="selected">3.30 PM</option>
                  <option value="16:00:00" selected="selected">4.00 PM</option>
                  <option value="16:30:00" selected="selected">4.30 PM</option>
                  <option value="17:00:00" [selected]="1">5.00 PM</option>
                  <option value="17:30:00" selected="selected">5.30 PM</option>
                  <option value="18:00:00" selected="selected">6.00 PM</option>
                  <option value="18:30:00" selected="selected">6.30 PM</option>
                  <option value="19:00:00" selected="selected">7.00 PM</option>
                  <option value="19:30:00" selected="selected">7.30 PM</option>
                  <option value="20:00:00" selected="selected">8.00 PM</option>
                  <option value="20:30:00" selected="selected">8.30 PM</option>
                  <option value="21:00:00" selected="selected">9.00 PM</option>
                  <option value="21:30:00" selected="selected">9.30 PM</option>
                  <option value="22:00:00" selected="selected">10.00 PM</option>
                  <option value="22:30:00" selected="selected">10.30 PM</option>
                  <option value="23:00:00" selected="selected">11.00 PM</option>
                  <option value="23:30:00" selected="selected">11.30 PM</option>
                  <option value="00:00:00" selected="selected">12.00 AM</option>
                 </select>
                </div>  
                </div>  
                
                <!----tueday END-->
              <!--Thursday is open	11:30 AM	to	05:00 PM--->
                
     <div class="col-md-4">
      <div class="form-group">
        <!--<b>DAY</b>-->
       
         <input type="text"    class="form-control" value="Thursday" style="background-color: #f2f2f2">
        </div>  
        </div>  
    
        <div class="col-md-4">
          <div class="form-group">
            <!--<b>START</b>--->
            <select class="select-input form-control" formControlName="thu_startTime" name="thu_startTime" style="background-color: #f2f2f2">
              <option value="00:30:00" selected="selected">12.30 AM</option>
                          <option value="01:00:00" selected="selected">1.00 AM</option>
                          <option value="01:30:00" selected="selected">1.30 AM</option>
                          <option value="02:00:00" selected="selected">2.00 AM</option>
                          <option value="02:30:00" selected="selected">2.30 AM</option>
                          <option value="03:00:00" selected="selected">3.00 AM</option>
                          <option value="03:30:00" selected="selected">3.30 AM</option>
                          <option value="04:00:00" selected="selected">4.00 AM</option>
                          <option value="04:30:00" selected="selected">4.30 AM</option>
                          <option value="05:00:00" selected="selected">5.00 AM</option>
                          <option value="05:30:00" selected="selected">5.30 AM</option>
                          <option value="06:00:00" selected="selected">6.00 AM</option>
                          <option value="06:30:00" selected="selected">6.30 AM</option>
                          <option value="07:00:00" selected="selected">7.00 AM</option>
                          <option value="07:30:00" selected="selected">7.30 AM</option>
                          <option value="08:00:00" selected="selected">8.00 AM</option>
                          <option value="08:30:00" selected="selected">8.30 AM</option>
                          <option value="09:00:00" selected="selected">9.00 AM</option>
                          <option value="09.30:00" selected="selected">9.30 AM</option>
                          <option value="10:00:00" selected="selected">10.00 AM</option>
                          <option value="10:30:00" selected="selected">10.30 AM</option>
                          <option value="11:00:00" selected="selected">11.00 AM</option>
                          <option value="11:30:00" selected="selected">11.30 AM</option>
                          <option value="12:00:00" selected="selected">12.00 PM</option>
                          <option value="12:30:00" selected="selected">12.30 PM</option>
                          <option value="13:00:00" selected="selected">1.00 PM</option>
                          <option value="13:30:00" selected="selected">1.30 PM</option>
                          <option value="14:00:00" selected="selected">2.00 PM</option>
                          <option value="14:30:00" selected="selected">2.30 PM</option>
                          <option value="15:00:00" selected="selected">3.00 PM</option>
                          <option value="15:30:00" selected="selected">3.30 PM</option>
                          <option value="16:00:00" selected="selected">4.00 PM</option>
                          <option value="16:30:00" selected="selected">4.30 PM</option>
                          <option value="17:00:00" [selected]="1">5.00 PM</option>
                          <option value="17:30:00" selected="selected">5.30 PM</option>
                          <option value="18:00:00" selected="selected">6.00 PM</option>
                          <option value="18:30:00" selected="selected">6.30 PM</option>
                          <option value="19:00:00" selected="selected">7.00 PM</option>
                          <option value="19:30:00" selected="selected">7.30 PM</option>
                          <option value="20:00:00" selected="selected">8.00 PM</option>
                          <option value="20:30:00" selected="selected">8.30 PM</option>
                          <option value="21:00:00" selected="selected">9.00 PM</option>
                          <option value="21:30:00" selected="selected">9.30 PM</option>
                          <option value="22:00:00" selected="selected">10.00 PM</option>
                          <option value="22:30:00" selected="selected">10.30 PM</option>
                          <option value="23:00:00" selected="selected">11.00 PM</option>
                          <option value="23:30:00" selected="selected">11.30 PM</option>
                          <option value="00:00:00" selected="selected">12.00 AM</option>
             </select>
            </div>  
            </div>  
  
            <div class="col-md-4">
              <div class="form-group">
               <!-- <b>END</b>--> 
                <select class="select-input form-control" formControlName="thu_endTime" name="thu_endTime" style="background-color: #f2f2f2">
                  <option value="00:30:00" selected="selected">12.30 AM</option>
                          <option value="01:00:00" selected="selected">1.00 AM</option>
                          <option value="01:30:00" selected="selected">1.30 AM</option>
                          <option value="02:00:00" selected="selected">2.00 AM</option>
                          <option value="02:30:00" selected="selected">2.30 AM</option>
                          <option value="03:00:00" selected="selected">3.00 AM</option>
                          <option value="03:30:00" selected="selected">3.30 AM</option>
                          <option value="04:00:00" selected="selected">4.00 AM</option>
                          <option value="04:30:00" selected="selected">4.30 AM</option>
                          <option value="05:00:00" selected="selected">5.00 AM</option>
                          <option value="05:30:00" selected="selected">5.30 AM</option>
                          <option value="06:00:00" selected="selected">6.00 AM</option>
                          <option value="06:30:00" selected="selected">6.30 AM</option>
                          <option value="07:00:00" selected="selected">7.00 AM</option>
                          <option value="07:30:00" selected="selected">7.30 AM</option>
                          <option value="08:00:00" selected="selected">8.00 AM</option>
                          <option value="08:30:00" selected="selected">8.30 AM</option>
                          <option value="09:00:00" selected="selected">9.00 AM</option>
                          <option value="09.30:00" selected="selected">9.30 AM</option>
                          <option value="10:00:00" selected="selected">10.00 AM</option>
                          <option value="10:30:00" selected="selected">10.30 AM</option>
                          <option value="11:00:00" selected="selected">11.00 AM</option>
                          <option value="11:30:00" selected="selected">11.30 AM</option>
                          <option value="12:00:00" selected="selected">12.00 PM</option>
                          <option value="12:30:00" selected="selected">12.30 PM</option>
                          <option value="13:00:00" selected="selected">1.00 PM</option>
                          <option value="13:30:00" selected="selected">1.30 PM</option>
                          <option value="14:00:00" selected="selected">2.00 PM</option>
                          <option value="14:30:00" selected="selected">2.30 PM</option>
                          <option value="15:00:00" selected="selected">3.00 PM</option>
                          <option value="15:30:00" selected="selected">3.30 PM</option>
                          <option value="16:00:00" selected="selected">4.00 PM</option>
                          <option value="16:30:00" selected="selected">4.30 PM</option>
                          <option value="17:00:00" [selected]="1">5.00 PM</option>
                          <option value="17:30:00" selected="selected">5.30 PM</option>
                          <option value="18:00:00" selected="selected">6.00 PM</option>
                          <option value="18:30:00" selected="selected">6.30 PM</option>
                          <option value="19:00:00" selected="selected">7.00 PM</option>
                          <option value="19:30:00" selected="selected">7.30 PM</option>
                          <option value="20:00:00" selected="selected">8.00 PM</option>
                          <option value="20:30:00" selected="selected">8.30 PM</option>
                          <option value="21:00:00" selected="selected">9.00 PM</option>
                          <option value="21:30:00" selected="selected">9.30 PM</option>
                          <option value="22:00:00" selected="selected">10.00 PM</option>
                          <option value="22:30:00" selected="selected">10.30 PM</option>
                          <option value="23:00:00" selected="selected">11.00 PM</option>
                          <option value="23:30:00" selected="selected">11.30 PM</option>
                          <option value="00:00:00" selected="selected">12.00 AM</option>
                 </select>
                </div>  
                </div>  
                
                 <!--Friday is open	11:30 AM	to	05:00 PM--->
                
     <div class="col-md-4">
      <div class="form-group">
        <!--<b>DAY</b>-->
       
         <input type="text"    class="form-control" value="Friday" style="background-color: #f2f2f2">
        </div>  
        </div>  
    
        <div class="col-md-4">
          <div class="form-group">
            <!--<b>START</b>-->
            <select class="select-input form-control" formControlName="fri_startTime" style="background-color: #f2f2f2">
              <option value="00:30:00" selected="selected">12.30 AM</option>
              <option value="01:00:00" selected="selected">1.00 AM</option>
              <option value="01:30:00" selected="selected">1.30 AM</option>
              <option value="02:00:00" selected="selected">2.00 AM</option>
              <option value="02:30:00" selected="selected">2.30 AM</option>
              <option value="03:00:00" selected="selected">3.00 AM</option>
              <option value="03:30:00" selected="selected">3.30 AM</option>
              <option value="04:00:00" selected="selected">4.00 AM</option>
              <option value="04:30:00" selected="selected">4.30 AM</option>
              <option value="05:00:00" selected="selected">5.00 AM</option>
              <option value="05:30:00" selected="selected">5.30 AM</option>
              <option value="06:00:00" selected="selected">6.00 AM</option>
              <option value="06:30:00" selected="selected">6.30 AM</option>
              <option value="07:00:00" selected="selected">7.00 AM</option>
              <option value="07:30:00" selected="selected">7.30 AM</option>
              <option value="08:00:00" selected="selected">8.00 AM</option>
              <option value="08:30:00" selected="selected">8.30 AM</option>
              <option value="09:00:00" selected="selected">9.00 AM</option>
              <option value="09.30:00" selected="selected">9.30 AM</option>
              <option value="10:00:00" selected="selected">10.00 AM</option>
              <option value="10:30:00" selected="selected">10.30 AM</option>
              <option value="11:00:00" selected="selected">11.00 AM</option>
              <option value="11:30:00" selected="selected">11.30 AM</option>
              <option value="12:00:00" selected="selected">12.00 PM</option>
              <option value="12:30:00" selected="selected">12.30 PM</option>
              <option value="13:00:00" selected="selected">1.00 PM</option>
              <option value="13:30:00" selected="selected">1.30 PM</option>
              <option value="14:00:00" selected="selected">2.00 PM</option>
              <option value="14:30:00" selected="selected">2.30 PM</option>
              <option value="15:00:00" selected="selected">3.00 PM</option>
              <option value="15:30:00" selected="selected">3.30 PM</option>
              <option value="16:00:00" selected="selected">4.00 PM</option>
              <option value="16:30:00" selected="selected">4.30 PM</option>
              <option value="17:00:00" [selected]="1">5.00 PM</option>
              <option value="17:30:00" selected="selected">5.30 PM</option>
              <option value="18:00:00" selected="selected">6.00 PM</option>
              <option value="18:30:00" selected="selected">6.30 PM</option>
              <option value="19:00:00" selected="selected">7.00 PM</option>
              <option value="19:30:00" selected="selected">7.30 PM</option>
              <option value="20:00:00" selected="selected">8.00 PM</option>
              <option value="20:30:00" selected="selected">8.30 PM</option>
              <option value="21:00:00" selected="selected">9.00 PM</option>
              <option value="21:30:00" selected="selected">9.30 PM</option>
              <option value="22:00:00" selected="selected">10.00 PM</option>
              <option value="22:30:00" selected="selected">10.30 PM</option>
              <option value="23:00:00" selected="selected">11.00 PM</option>
              <option value="23:30:00" selected="selected">11.30 PM</option>
              <option value="00:00:00" selected="selected">12.00 AM</option>
             </select>
            </div>  
            </div>  
  
            <div class="col-md-4">
              <div class="form-group">
               <!--<b>END</b>---> 
                <select class="select-input form-control" formControlName="fri_endTime" name="fri_endTime" style="background-color: #f2f2f2">
                  <option value="00:30:00" selected="selected">12.30 AM</option>
                  <option value="01:00:00" selected="selected">1.00 AM</option>
                  <option value="01:30:00" selected="selected">1.30 AM</option>
                  <option value="02:00:00" selected="selected">2.00 AM</option>
                  <option value="02:30:00" selected="selected">2.30 AM</option>
                  <option value="03:00:00" selected="selected">3.00 AM</option>
                  <option value="03:30:00" selected="selected">3.30 AM</option>
                  <option value="04:00:00" selected="selected">4.00 AM</option>
                  <option value="04:30:00" selected="selected">4.30 AM</option>
                  <option value="05:00:00" selected="selected">5.00 AM</option>
                  <option value="05:30:00" selected="selected">5.30 AM</option>
                  <option value="06:00:00" selected="selected">6.00 AM</option>
                  <option value="06:30:00" selected="selected">6.30 AM</option>
                  <option value="07:00:00" selected="selected">7.00 AM</option>
                  <option value="07:30:00" selected="selected">7.30 AM</option>
                  <option value="08:00:00" selected="selected">8.00 AM</option>
                  <option value="08:30:00" selected="selected">8.30 AM</option>
                  <option value="09:00:00" selected="selected">9.00 AM</option>
                  <option value="09.30:00" selected="selected">9.30 AM</option>
                  <option value="10:00:00" selected="selected">10.00 AM</option>
                  <option value="10:30:00" selected="selected">10.30 AM</option>
                  <option value="11:00:00" selected="selected">11.00 AM</option>
                  <option value="11:30:00" selected="selected">11.30 AM</option>
                  <option value="12:00:00" selected="selected">12.00 PM</option>
                  <option value="12:30:00" selected="selected">12.30 PM</option>
                  <option value="13:00:00" selected="selected">1.00 PM</option>
                  <option value="13:30:00" selected="selected">1.30 PM</option>
                  <option value="14:00:00" selected="selected">2.00 PM</option>
                  <option value="14:30:00" selected="selected">2.30 PM</option>
                  <option value="15:00:00" selected="selected">3.00 PM</option>
                  <option value="15:30:00" selected="selected">3.30 PM</option>
                  <option value="16:00:00" selected="selected">4.00 PM</option>
                  <option value="16:30:00" selected="selected">4.30 PM</option>
                  <option value="17:00:00" [selected]="1">5.00 PM</option>
                  <option value="17:30:00" selected="selected">5.30 PM</option>
                  <option value="18:00:00" selected="selected">6.00 PM</option>
                  <option value="18:30:00" selected="selected">6.30 PM</option>
                  <option value="19:00:00" selected="selected">7.00 PM</option>
                  <option value="19:30:00" selected="selected">7.30 PM</option>
                  <option value="20:00:00" selected="selected">8.00 PM</option>
                  <option value="20:30:00" selected="selected">8.30 PM</option>
                  <option value="21:00:00" selected="selected">9.00 PM</option>
                  <option value="21:30:00" selected="selected">9.30 PM</option>
                  <option value="22:00:00" selected="selected">10.00 PM</option>
                  <option value="22:30:00" selected="selected">10.30 PM</option>
                  <option value="23:00:00" selected="selected">11.00 PM</option>
                  <option value="23:30:00" selected="selected">11.30 PM</option>
                  <option value="00:00:00" selected="selected">12.00 AM</option>
                   </select>
                </div>  
                </div>  
               
                <!----Thursday END-->
                       
                <div class="col-md-4">
                  <div class="form-group">
                    <!--<b>DAY</b>-->
                   
                     <input type="text"   class="form-control" value="Saturday" style="background-color: #f2f2f2">
                    </div>  
                    </div>  
                
                    <div class="col-md-4">
                      <div class="form-group">
                        <!--<b>START</b>-->
                        <select class="select-input form-control" formControlName="sat_startTime" style="background-color: #f2f2f2">
                          <option value="00:30:00" [selected]="1">12.30 AM</option>
                          <option value="01:00:00" selected="selected">1.00 AM</option>
                          <option value="01:30:00" selected="selected">1.30 AM</option>
                          <option value="02:00:00" selected="selected">2.00 AM</option>
                          <option value="02:30:00" selected="selected">2.30 AM</option>
                          <option value="03:00:00" selected="selected">3.00 AM</option>
                          <option value="03:30:00" selected="selected">3.30 AM</option>
                          <option value="04:00:00" selected="selected">4.00 AM</option>
                          <option value="04:30:00" selected="selected">4.30 AM</option>
                          <option value="05:00:00" selected="selected">5.00 AM</option>
                          <option value="05:30:00" selected="selected">5.30 AM</option>
                          <option value="06:00:00" selected="selected">6.00 AM</option>
                          <option value="06:30:00" selected="selected">6.30 AM</option>
                          <option value="07:00:00" selected="selected">7.00 AM</option>
                          <option value="07:30:00" selected="selected">7.30 AM</option>
                          <option value="08:00:00" selected="selected">8.00 AM</option>
                          <option value="08:30:00" selected="selected">8.30 AM</option>
                          <option value="09:00:00" selected="selected">9.00 AM</option>
                          <option value="09.30:00" selected="selected">9.30 AM</option>
                          <option value="10:00:00" selected="selected">10.00 AM</option>
                          <option value="10:30:00" selected="selected">10.30 AM</option>
                          <option value="11:00:00" selected="selected">11.00 AM</option>
                          <option value="11:30:00" selected="selected">11.30 AM</option>
                          <option value="12:00:00" selected="selected">12.00 PM</option>
                          <option value="12:30:00" selected="selected">12.30 PM</option>
                          <option value="13:00:00" selected="selected">1.00 PM</option>
                          <option value="13:30:00" selected="selected">1.30 PM</option>
                          <option value="14:00:00" selected="selected">2.00 PM</option>
                          <option value="14:30:00" selected="selected">2.30 PM</option>
                          <option value="15:00:00" selected="selected">3.00 PM</option>
                          <option value="15:30:00" selected="selected">3.30 PM</option>
                          <option value="16:00:00" selected="selected">4.00 PM</option>
                          <option value="16:30:00" selected="selected">4.30 PM</option>
                          <option value="17:00:00" selected="selected">5.00 PM</option>
                          <option value="17:30:00" selected="selected">5.30 PM</option>
                          <option value="18:00:00" selected="selected">6.00 PM</option>
                          <option value="18:30:00" selected="selected">6.30 PM</option>
                          <option value="19:00:00" selected="selected">7.00 PM</option>
                          <option value="19:30:00" selected="selected">7.30 PM</option>
                          <option value="20:00:00" selected="selected">8.00 PM</option>
                          <option value="20:30:00" selected="selected">8.30 PM</option>
                          <option value="21:00:00" selected="selected">9.00 PM</option>
                          <option value="21:30:00" selected="selected">9.30 PM</option>
                          <option value="22:00:00" selected="selected">10.00 PM</option>
                          <option value="22:30:00" selected="selected">10.30 PM</option>
                          <option value="23:00:00" selected="selected">11.00 PM</option>
                          <option value="23:30:00" selected="selected">11.30 PM</option>
                          <option value="00:00:00" selected="selected">12.00 AM</option>
                         </select>
                        </div>  
                        </div>  
              
                        <div class="col-md-4">
                          <div class="form-group">
                           <!--<b>END</b>---> 
                            <select class="select-input form-control" formControlName="sat_endTime" name="sat_endTime" style="background-color: #f2f2f2">
                          
                              <option value="00:30:00" selected="selected">12.30 AM</option>
                              <option value="01:00:00" selected="selected">1.00 AM</option>
                              <option value="01:30:00" selected="selected">1.30 AM</option>
                              <option value="02:00:00" selected="selected">2.00 AM</option>
                              <option value="02:30:00" selected="selected">2.30 AM</option>
                              <option value="03:00:00" selected="selected">3.00 AM</option>
                              <option value="03:30:00" selected="selected">3.30 AM</option>
                              <option value="04:00:00" selected="selected">4.00 AM</option>
                              <option value="04:30:00" selected="selected">4.30 AM</option>
                              <option value="05:00:00" selected="selected">5.00 AM</option>
                              <option value="05:30:00" selected="selected">5.30 AM</option>
                              <option value="06:00:00" selected="selected">6.00 AM</option>
                              <option value="06:30:00" selected="selected">6.30 AM</option>
                              <option value="07:00:00" selected="selected">7.00 AM</option>
                              <option value="07:30:00" selected="selected">7.30 AM</option>
                              <option value="08:00:00" selected="selected">8.00 AM</option>
                              <option value="08:30:00" selected="selected">8.30 AM</option>
                              <option value="09:00:00" selected="selected">9.00 AM</option>
                              <option value="09.30:00" selected="selected">9.30 AM</option>
                              <option value="10:00:00" selected="selected">10.00 AM</option>
                              <option value="10:30:00" selected="selected">10.30 AM</option>
                              <option value="11:00:00" selected="selected">11.00 AM</option>
                              <option value="11:30:00" selected="selected">11.30 AM</option>
                              <option value="12:00:00" selected="selected">12.00 PM</option>
                              <option value="12:30:00" selected="selected">12.30 PM</option>
                              <option value="13:00:00" selected="selected">1.00 PM</option>
                              <option value="13:30:00" selected="selected">1.30 PM</option>
                              <option value="14:00:00" selected="selected">2.00 PM</option>
                              <option value="14:30:00" selected="selected">2.30 PM</option>
                              <option value="15:00:00" selected="selected">3.00 PM</option>
                              <option value="15:30:00" selected="selected">3.30 PM</option>
                              <option value="16:00:00" selected="selected">4.00 PM</option>
                              <option value="16:30:00" selected="selected">4.30 PM</option>
                              <option value="17:00:00" [selected]="1">5.00 PM</option>
                              <option value="17:30:00" selected="selected">5.30 PM</option>
                              <option value="18:00:00" selected="selected">6.00 PM</option>
                              <option value="18:30:00" selected="selected">6.30 PM</option>
                              <option value="19:00:00" selected="selected">7.00 PM</option>
                              <option value="19:30:00" selected="selected">7.30 PM</option>
                              <option value="20:00:00" selected="selected">8.00 PM</option>
                              <option value="20:30:00" selected="selected">8.30 PM</option>
                              <option value="21:00:00" selected="selected">9.00 PM</option>
                              <option value="21:30:00" selected="selected">9.30 PM</option>
                              <option value="22:00:00" selected="selected">10.00 PM</option>
                              <option value="22:30:00" selected="selected">10.30 PM</option>
                              <option value="23:00:00" selected="selected">11.00 PM</option>
                              <option value="23:30:00" selected="selected">11.30 PM</option>
                              <option value="00:00:00" selected="selected">12.00 AM</option>
                               </select>
                            </div>  
                            </div>  

                            <div class="col-md-4">
                              <div class="form-group">
                                <!--<b>DAY</b>-->
                               
                                 <input type="text"    class="form-control" value="Sunday" style="background-color: #f2f2f2">
                                </div>  
                                </div>  
                            
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <!--<b>START</b>-->
                                    <select class="select-input form-control" formControlName="sun_startTime" style="background-color: #f2f2f2">
                                      <option value="00:30:00" [selected]="1">12.30 AM</option>
                          <option value="01:00:00" selected="selected">1.00 AM</option>
                          <option value="01:30:00" selected="selected">1.30 AM</option>
                          <option value="02:00:00" selected="selected">2.00 AM</option>
                          <option value="02:30:00" selected="selected">2.30 AM</option>
                          <option value="03:00:00" selected="selected">3.00 AM</option>
                          <option value="03:30:00" selected="selected">3.30 AM</option>
                          <option value="04:00:00" selected="selected">4.00 AM</option>
                          <option value="04:30:00" selected="selected">4.30 AM</option>
                          <option value="05:00:00" selected="selected">5.00 AM</option>
                          <option value="05:30:00" selected="selected">5.30 AM</option>
                          <option value="06:00:00" selected="selected">6.00 AM</option>
                          <option value="06:30:00" selected="selected">6.30 AM</option>
                          <option value="07:00:00" selected="selected">7.00 AM</option>
                          <option value="07:30:00" selected="selected">7.30 AM</option>
                          <option value="08:00:00" selected="selected">8.00 AM</option>
                          <option value="08:30:00" selected="selected">8.30 AM</option>
                          <option value="09:00:00" selected="selected">9.00 AM</option>
                          <option value="09.30:00" selected="selected">9.30 AM</option>
                          <option value="10:00:00" selected="selected">10.00 AM</option>
                          <option value="10:30:00" selected="selected">10.30 AM</option>
                          <option value="11:00:00" selected="selected">11.00 AM</option>
                          <option value="11:30:00" selected="selected">11.30 AM</option>
                          <option value="12:00:00" selected="selected">12.00 PM</option>
                          <option value="12:30:00" selected="selected">12.30 PM</option>
                          <option value="13:00:00" selected="selected">1.00 PM</option>
                          <option value="13:30:00" selected="selected">1.30 PM</option>
                          <option value="14:00:00" selected="selected">2.00 PM</option>
                          <option value="14:30:00" selected="selected">2.30 PM</option>
                          <option value="15:00:00" selected="selected">3.00 PM</option>
                          <option value="15:30:00" selected="selected">3.30 PM</option>
                          <option value="16:00:00" selected="selected">4.00 PM</option>
                          <option value="16:30:00" selected="selected">4.30 PM</option>
                          <option value="17:00:00" selected="selected">5.00 PM</option>
                          <option value="17:30:00" selected="selected">5.30 PM</option>
                          <option value="18:00:00" selected="selected">6.00 PM</option>
                          <option value="18:30:00" selected="selected">6.30 PM</option>
                          <option value="19:00:00" selected="selected">7.00 PM</option>
                          <option value="19:30:00" selected="selected">7.30 PM</option>
                          <option value="20:00:00" selected="selected">8.00 PM</option>
                          <option value="20:30:00" selected="selected">8.30 PM</option>
                          <option value="21:00:00" selected="selected">9.00 PM</option>
                          <option value="21:30:00" selected="selected">9.30 PM</option>
                          <option value="22:00:00" selected="selected">10.00 PM</option>
                          <option value="22:30:00" selected="selected">10.30 PM</option>
                          <option value="23:00:00" selected="selected">11.00 PM</option>
                          <option value="23:30:00" selected="selected">11.30 PM</option>
                          <option value="00:00:00" selected="selected">12.00 AM</option>
                                     </select>
                                    </div>  
                                    </div>  
                          
                                    <div class="col-md-4">
                                      <div class="form-group">
                                       <!--<b>END</b>---> 
                                        <select class="select-input form-control" formControlName="sun_endTime" name="sun_endTime" style="background-color: #f2f2f2">
                                      
                                          <option value="00:30:00" selected="selected">12.30 AM</option>
                                          <option value="01:00:00" selected="selected">1.00 AM</option>
                                          <option value="01:30:00" selected="selected">1.30 AM</option>
                                          <option value="02:00:00" selected="selected">2.00 AM</option>
                                          <option value="02:30:00" selected="selected">2.30 AM</option>
                                          <option value="03:00:00" selected="selected">3.00 AM</option>
                                          <option value="03:30:00" selected="selected">3.30 AM</option>
                                          <option value="04:00:00" selected="selected">4.00 AM</option>
                                          <option value="04:30:00" selected="selected">4.30 AM</option>
                                          <option value="05:00:00" selected="selected">5.00 AM</option>
                                          <option value="05:30:00" selected="selected">5.30 AM</option>
                                          <option value="06:00:00" selected="selected">6.00 AM</option>
                                          <option value="06:30:00" selected="selected">6.30 AM</option>
                                          <option value="07:00:00" selected="selected">7.00 AM</option>
                                          <option value="07:30:00" selected="selected">7.30 AM</option>
                                          <option value="08:00:00" selected="selected">8.00 AM</option>
                                          <option value="08:30:00" selected="selected">8.30 AM</option>
                                          <option value="09:00:00" selected="selected">9.00 AM</option>
                                          <option value="09.30:00" selected="selected">9.30 AM</option>
                                          <option value="10:00:00" selected="selected">10.00 AM</option>
                                          <option value="10:30:00" selected="selected">10.30 AM</option>
                                          <option value="11:00:00" selected="selected">11.00 AM</option>
                                          <option value="11:30:00" selected="selected">11.30 AM</option>
                                          <option value="12:00:00" selected="selected">12.00 PM</option>
                                          <option value="12:30:00" selected="selected">12.30 PM</option>
                                          <option value="13:00:00" selected="selected">1.00 PM</option>
                                          <option value="13:30:00" selected="selected">1.30 PM</option>
                                          <option value="14:00:00" selected="selected">2.00 PM</option>
                                          <option value="14:30:00" selected="selected">2.30 PM</option>
                                          <option value="15:00:00" selected="selected">3.00 PM</option>
                                          <option value="15:30:00" selected="selected">3.30 PM</option>
                                          <option value="16:00:00" selected="selected">4.00 PM</option>
                                          <option value="16:30:00" selected="selected">4.30 PM</option>
                                          <option value="17:00:00" [selected]="1">5.00 PM</option>
                                          <option value="17:30:00" selected="selected">5.30 PM</option>
                                          <option value="18:00:00" selected="selected">6.00 PM</option>
                                          <option value="18:30:00" selected="selected">6.30 PM</option>
                                          <option value="19:00:00" selected="selected">7.00 PM</option>
                                          <option value="19:30:00" selected="selected">7.30 PM</option>
                                          <option value="20:00:00" selected="selected">8.00 PM</option>
                                          <option value="20:30:00" selected="selected">8.30 PM</option>
                                          <option value="21:00:00" selected="selected">9.00 PM</option>
                                          <option value="21:30:00" selected="selected">9.30 PM</option>
                                          <option value="22:00:00" selected="selected">10.00 PM</option>
                                          <option value="22:30:00" selected="selected">10.30 PM</option>
                                          <option value="23:00:00" selected="selected">11.00 PM</option>
                                          <option value="23:30:00" selected="selected">11.30 PM</option>
                                          <option value="00:00:00" selected="selected">12.00 AM</option>
                                           </select>
                                        </div>  
                                        </div>  
                   
                
  
          <div class="footer-section">
            <hr />
            
            <button
              type="submit"
              class="button submit-button"
            >
              <span class="button-text">Submit Form</span>
            </button>
            <button
              type="button"
              (click)="client()"
              class="button cancel-button"
            >
              <span class="button-text">Cancel</span>
            </button> 
            <!-- <button
              type="button"
              (click)="deleteProviderForm()"
              class="button cancel-button"
            >
              <span class="button-text">Delete</span>
            </button> -->
          </div>
        </div>
            
         <!--  </div> -->
        </form>
      </div>
    </div>
  </div>
  