import {
  Component,
  OnDestroy,
  OnInit
} from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { CLIENT_TYPES } from "src/app/constants/clientTypes";
import { CLINIC_LOCATIONS } from "src/app/constants/clinicLocations";
import { INSURANCE_TYPES } from "src/app/constants/insuranceTypes";
import { LIST_OF_US_STATES_ABBREVIATIONS } from "src/app/constants/usStates";
import { Patient, TimeSlot } from "src/app/models";
import { AddEditPatientFacade } from "src/app/services/facades/addEditPatient.facade";
import { PatientsFacade } from "src/app/services/facades/patients.facade";
import loadash from "lodash";
import { ALL_TIMESLOTS } from "src/app/constants/allTimeSlots";
import { map, mergeMap, take } from "rxjs/operators";
import { ProvidersFacade } from 'src/app/services/facades/providers.facade';
import lodash from 'lodash';

@Component({
  selector: "wtls-add-edit-patient",
  templateUrl: "./add-edit-patient.component.html",
  styleUrls: ["./add-edit-patient.component.scss"],
})
export class AddEditPatientComponent implements OnInit, OnDestroy {
  open$: Observable<boolean>;
  endDate
  initialPatient: Patient = {
    id: 0,
    name: "",
    first_name: '',
    last_name: '',
    clinicLocation: "",
    dob: null,
    age: null,
    clientType: "",
    insuranceType: "",
    intakeStage: "",
    day:"",
    startTime:"",
    endTime: "",
    address: {
      street: "",
      city: "",
      state: "",
      zip: "",
      lat: null,
      lng: null,
    },
    availability: [],
    schedule: [],
  };
  role = [];
  previousPatient: Patient;

  patient: Patient = this.initialPatient;

  availability: TimeSlot[];

  timeslot = ['12:30 AM','01:00 AM','01:30 AM','02:00 AM','02:30 AM','03:00 AM','03:30 AM','04:00 AM','04:30 AM','05:00 AM','05:30 AM',
  '06:00 AM','06:30 AM','07:00 AM','07:30 AM','08:00 AM','08:30 AM','09:00 AM','09:30 AM','10:00 AM','10:30 AM','11:00 AM','11:30 AM','12:00 PM','12:30 PM',
  '01:00 PM','01:30 PM','02:00 PM','02:30 PM','03:00 PM','03:30 PM','04:00 PM','04:30 PM','05:00 PM','05:30 PM','06:00 PM','06:30 PM','07:00 PM','07:30 PM',
  '08:00 PM','08:30 PM','09:00 PM','09:30 PM','10:00 PM','10:30 PM','11:00 PM','11:30 PM']

  months = ['Jan','Feb','Mar','Apr','May','June','July','Aug','Sep','Oct','Nov','Dec']
  days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat']
  selected_start_slot_mon
  selected_end_slot_mon
  selected_start_slot_tue
  selected_end_slot_tue
  selected_start_slot_wed
  selected_end_slot_wed
  selected_start_slot_thu
  selected_end_slot_thu
  selected_start_slot_fri
  selected_end_slot_fri
  
  loading$: Observable<boolean>;

  usStates = LIST_OF_US_STATES_ABBREVIATIONS;
  clinicLocationOptions;
  clientTypeOptions;
  insuranceTypeOptions;
  intakeStageOptions;

  subscriptions = new Subscription();

  constructor(
    private patientsFacade: PatientsFacade,
    private providersFacade: ProvidersFacade,
    private addEditPatientFacade: AddEditPatientFacade
  ) {
    this.endDate = new Date()
    this.open$ = addEditPatientFacade.getOpen();

    addEditPatientFacade.getPatient().subscribe((patient) => {
      if (patient) {
        console.log("patients",patient)
       console.log("textexchange=>",this.availability);
        let allTimeSlots: TimeSlot[];

        if (patient.schedule.length > 0) {
          allTimeSlots = patient.availability
          // allTimeSlots.map((timeSlot, index, arr) => {
          //   if (
          //     patient.schedule.some(
          //       (ts) =>
          //         ts.dayOfWeek == timeSlot.dayOfWeek &&
          //         ts.startTime == timeSlot.startTime
          //         //ts.startTime.getHours() == timeSlot.startTime.getHours()
          //     )
          //   ) {
          //     allTimeSlots.splice(index, 1);
          //   }
          // });
        }

        this.availability = allTimeSlots;

        this.patient = loadash.cloneDeep(patient);
        this.patient.dob = new Date(this.patient.dob); 
        this.patient.dob.setDate(this.patient.dob.getDate()+1); 
      }

      this.previousPatient = patient;
    });

    const sub1 = this.patientsFacade
    .getAllPatients().pipe(
      mergeMap(patients => this.providersFacade.getAllProviders().pipe(
        map(providers => {
          let clientTypes = [ ...new Set(patients.map(p => p.clientType)) ];
          let insuranceTypes = [ ...new Set(patients.map(p => p.insuranceType)) ];
          let intakeStages = [ ...new Set(patients.map(p => p.intakeStage)) ];
          
          let c1: string[] = [];
          let c2: string[] = [];

          patients.forEach((patient) => {
            c1.push(patient.clinicLocation);
          });
          providers.forEach((provider) => {
            c2.push(provider.clinicLocation);
          });

          this.clinicLocationOptions = lodash.uniq([ ...c1, ...c2 ].filter(filter => !(filter == '')))
          this.clientTypeOptions = clientTypes;
          this.insuranceTypeOptions = insuranceTypes;
          this.intakeStageOptions = intakeStages;
        })
      ))
    )
    .subscribe()


    this.subscriptions.add(sub1);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  //   changeSchedule(timeSlot: TimeSlot): void {
  
    
  //   if (
  //     this.patient.availability.some(
  //       (ts) =>
  //       //  ts.dayOfWeek == timeSlot.dayOfWeek &&
  //        // ts.startTime.getHours() == timeSlot.startTime.getHours()
  //        ts.startTime == timeSlot.startTime
  //     )
  //   ) {
  //     this.patient.availability = this.patient.availability.filter(
  //       (ts) =>
  //       //  ts.dayOfWeek != timeSlot.dayOfWeek ||
  //         //ts.startTime.getHours() != timeSlot.startTime.getHours()
  //        ts.startTime == timeSlot.startTime
  //     );
  //   } else {
  //     this.patient.availability.push(timeSlot);
     
  //     console.log("avaiablityhurs=>",this.patient.availability.push(timeSlot));
  //   }
    
  //   console.log("testedigkjahsgd=>",this.patient.availability);
    
  // }  


    changeSchedule(event): void {
  
    console.log(event)
    console.log("TESTDA",event.target.value)
     this.role.push(event.target.value);
    this.role.toString();
    this.availability =this.role; 
    console.log("final state => ",this.availability); 
   
  }  



  submitForm(): void {
    
    if (this.patient.id > 0) {
      // edit
      console.log('patient details', this.patient)
      console.log('mon selected_start_slot',this.selected_start_slot_mon)
      console.log('mon selected_end_slot',this.selected_end_slot_mon)
      console.log('mon selected_start_slot',this.selected_start_slot_tue)
      console.log('mon selected_end_slot',this.selected_end_slot_tue)
      console.log('mon selected_start_slot',this.selected_start_slot_wed)
      console.log('mon selected_end_slot',this.selected_end_slot_wed)
      console.log('mon selected_start_slot',this.selected_start_slot_thu)
      console.log('mon selected_end_slot',this.selected_end_slot_thu)
      console.log('mon selected_start_slot',this.selected_start_slot_fri)
      console.log('mon selected_end_slot',this.selected_end_slot_fri)
      
      let newDateString
      if(this.selected_start_slot_mon != null){
        newDateString = this.convertToDate(this.patient.availability[0].startTime,this.selected_start_slot_mon)
        this.patient.availability[0].startTime = newDateString
        if(this.selected_end_slot_mon){
          newDateString = this.convertToDate(this.patient.availability[0].endTime,this.selected_end_slot_mon)
          this.patient.availability[0].endTime = newDateString
        }
      }
      if(this.selected_start_slot_tue){
        newDateString = this.convertToDate(this.patient.availability[1].startTime,this.selected_start_slot_tue)
        this.patient.availability[1].startTime = newDateString
        if(this.selected_end_slot_tue){
          newDateString = this.convertToDate(this.patient.availability[1].endTime,this.selected_end_slot_tue)
          this.patient.availability[1].endTime = newDateString
        }
      }
      if(this.selected_start_slot_wed){
        newDateString = this.convertToDate(this.patient.availability[2].startTime,this.selected_start_slot_wed)
        this.patient.availability[2].startTime = newDateString
        if(this.selected_end_slot_wed){
          newDateString = this.convertToDate(this.patient.availability[2].endTime,this.selected_end_slot_wed)
          this.patient.availability[2].endTime = newDateString
        }
      }
      if(this.selected_start_slot_thu){
        newDateString = this.convertToDate(this.patient.availability[3].startTime,this.selected_start_slot_thu)
        this.patient.availability[3].startTime = newDateString
        if(this.selected_end_slot_thu){
          newDateString = this.convertToDate(this.patient.availability[3].endTime,this.selected_end_slot_thu)
          this.patient.availability[3].endTime = newDateString
        }
      }
      if(this.selected_start_slot_fri){
        newDateString = this.convertToDate(this.patient.availability[4].startTime,this.selected_start_slot_fri)
        this.patient.availability[4].startTime = newDateString
        if(this.selected_end_slot_fri){
          newDateString = this.convertToDate(this.patient.availability[4].endTime,this.selected_end_slot_fri)
          this.patient.availability[4].endTime = newDateString
        }
      }
      
      if (
        this.previousPatient.address.street !=
          this.patient.address.street.trim() ||
        this.previousPatient.address.city != this.patient.address.city.trim() ||
        this.previousPatient.address.state !=
          this.patient.address.state.trim() 
        //this.previousPatient.address.zip != this.patient.address.zip.trim()
      ) {
        // address changed, geocode
        this.addEditPatientFacade
          .geocodePatient(this.patient)
          .pipe(take(1))
          .subscribe((patient) => {
            this.addEditPatientFacade.updatePatient(patient);
          });
          
      } else {

       
        this.addEditPatientFacade.updatePatient(this.patient);
      }
    } else {
      // add
      this.addEditPatientFacade
        .geocodePatient(this.patient)
        .pipe(take(1))
        .subscribe((patient) => {
          this.addEditPatientFacade.addPatient(patient);
        });
    }
   
    console.log("Formsubmited=>", this.availability);

  }

  convertToDate(previousValue, newValue){

    let date
    let day
    let year
    let month
    let timeString = ''
    let splitTimeString = []
    let dateString = ''
    let checkAmPm
    timeString = previousValue.toTimeString()
    date = previousValue.getDate()
    day = previousValue.getDay()
    year = previousValue.getFullYear()
    month = previousValue.getMonth()
    console.log(timeString)
    splitTimeString = timeString.split(' ')
    checkAmPm = newValue.split(' ')[1]
    if(checkAmPm == 'AM') 
      splitTimeString[0] = newValue.split(' ')[0]+':00'
    else{ // convert to 24 hr time for PM
      let splitTime = newValue.split(' ')[0].split(':')
      let firstPart
      if(parseInt(splitTime[0]) != 12)
        firstPart = (parseInt(splitTime[0])+12).toString()
      else
        firstPart = (parseInt(splitTime[0])).toString()
      splitTimeString[0] = firstPart+':'+splitTime[1]+':00'
    }
    timeString = splitTimeString.toString().replace(/,/g,' ')
    // console.log(timeString)
    // console.log(date)
    // console.log(day)
    // console.log(year)
    // console.log(month)
    dateString +=  this.days[day] +' '+ this.months[month] +' '+date+' '+ year +' '+ timeString.replace(',',' ')
    console.log('dateString', dateString) 
    console.log('dateString', new Date(dateString))
    return new Date(dateString) 
  }

  closePanel(): void {
    this.addEditPatientFacade.closePanel();
    this.addEditPatientFacade.clearPatient();
  }
}
