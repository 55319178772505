

<div class="page-container">
  <div class="content-container">
    
    <div class="container">
    <div class="user-header">
      <h2>
        <a href="#"  mat-flat-button class="tab"  [routerLink]="['/dashboard/users']"  routerLinkActive="active"   type="button"  color="primary">New User</a> 

        <div class="spinner-wrapper" *ngIf="loading">
          <mat-progress-spinner
            color="primary"
            mode="indeterminate"
            diameter="30"
          >
          </mat-progress-spinner>
        </div>
       
      </h2>
    </div>


<div class="client-table">
  <div class="table-wrapper">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!-- Position Column -->
      
      <ng-container matColumnDef="S No.">
        <th mat-header-cell *matHeaderCellDef> S No.</th>
        <td mat-cell *matCellDef="let element let i=index" > {{i + 1}} </td>
      </ng-container>
    
      <!-- Name Column -->
      <ng-container matColumnDef="Fullname">
        <th mat-header-cell *matHeaderCellDef> Fullname </th>
        <td mat-cell *matCellDef="let element"> {{element.fullname}} </td>
      </ng-container>
    
      <ng-container matColumnDef="Email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>

      <ng-container matColumnDef="Address">
        <th mat-header-cell *matHeaderCellDef> Address </th>
        <td mat-cell *matCellDef="let element"> {{element.Address}} </td>
      </ng-container>

      <ng-container matColumnDef="Contact No">
        <th mat-header-cell *matHeaderCellDef> Contact No </th>
        <td mat-cell *matCellDef="let element"> {{element.contactno}} </td>
      </ng-container>

      
      <!-- Symbol Column -->
      <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element"> 
          <button 
            mat-icon-button   
            class="iconbutton"
            (click)="edit(element.id)" 
            color="primary"
          ><mat-icon matTooltip="Click to Edit">edit</mat-icon>
          </button>
          <button 
            mat-icon-button 
              
            class="iconbutton"
            (click)="delete(element.id)" 
            color="primary"
          ><mat-icon matTooltip="Click to Delete">delete</mat-icon>
          </button>
          <button 
            mat-icon-button 
            
            class="iconbutton"
            (click)="block(element.id)" 
            color="primary"
          ><mat-icon matTooltip="Click to Block">block</mat-icon>
          </button>
        </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions">
  </mat-paginator>
  <!-- <mat-paginator
  class="paginator"
  [length]="totalElements"
  [pageSize]="size"
  [pageSizeOptions]="pageSizeOptions"
  >
  </mat-paginator> -->
</div>
</div>

</div>


    </div>
  
