import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { take } from 'rxjs/operators';
import { Jwt } from 'src/app/models/jwt';


@Injectable({
    providedIn: 'root'
})
export class AuthFacade {

    constructor(
        private authService: AuthService,
    ) { }

    getAuthToken(): string {
        return this.authService.getAuthToken();
    }

    getRoleToken(): string {
        return this.authService.getRoleToken()
    }

    getEmailToken(): string {
        return this.authService.getEmailToken()
    }

    saveAuthToken(token: string, role: string, email: string): void {
        return this.authService.saveAuthToken(token, role,email);
    }

    login(email: string, password: string): Observable<Jwt> {
        return this.authService.login(email, password).pipe(
            take(1)
        );
    }

    resetPassword(email: string): Observable<any> {
        return this.authService.resetPassword(email).pipe(
            take(1)
        );
    }
}