<tr
  class="match-row"
  [ngClass]="{ 'border-light-bottom': match.id != selected.id }"
  (click)="selectMatch()"
>
  <td>
    <div class="icon-wrapper">
      <mat-icon *ngIf="match.id != selected.id">chevron_right</mat-icon>
      <mat-icon *ngIf="match.id == selected.id">expand_more</mat-icon>
    </div>
  </td>
  <td>{{ index }}</td>
  <td>
    <strong *ngIf="!match.patient.name"><em>No Name</em></strong>
    <strong *ngIf="match.patient.name">{{ match.patient.name }}</strong>
  </td>
  <td *ngIf="match.id != selected.id">
    <span *ngIf="!match.patient.address.street"><em>No address available</em></span>
    <span *ngIf="match.patient.address.street">
    {{
      match.patient.address.street +
        ", " +
        match.patient.address.city +
        " " +
        match.patient.address.state +
        " " +
        match.patient.address.zip | truncateEllipsis: 22
    }}
  </span>
  </td>
  <td *ngIf="match.id == selected.id">
    <span *ngIf="!match.patient.address.street"><em>No address available</em></span>
    <span *ngIf="match.patient.address.street">
    {{
      match.patient.address.street +
        ", " +
        match.patient.address.city +
        " " +
        match.patient.address.state +
        " " +
        match.patient.address.zip
    }}
    </span>
  </td>
  <td>
    <strong *ngIf="!match.provider.name"><em>No Name</em></strong>
    <strong *ngIf="match.provider.name">{{ match.provider.name }}</strong>
  </td>
  <td *ngIf="match.id != selected.id">
    <span *ngIf="!match.provider.address.street"><em>No address available</em></span>
    <span *ngIf="match.provider.address.street">
    {{
      match.provider.address.street +
        ", " +
        match.provider.address.city +
        " " +
        match.provider.address.state +
        " " +
        match.provider.address.zip | truncateEllipsis: 22
    }}
    </span>
  </td>
  <td *ngIf="match.id == selected.id">
    <span *ngIf="!match.provider.address.street"><em>No address available</em></span>
    <span *ngIf="match.provider.address.street">
    {{
      match.provider.address.street +
        ", " +
        match.provider.address.city +
        " " +
        match.provider.address.state +
        " " +
        match.provider.address.zip
    }}
    </span>
  </td>
</tr>
<div *ngIf="match.id == selected.id" class="d-contents">
  <tr>
    <td colspan="2"></td>
    <td colspan="2" class="border-light-top">
      <h5>Client Age:</h5>
      <span>{{ match.patient.age }}</span>
    </td>
    <td colspan="2" class="border-light-top">
      <h5>Employee Status:</h5>
      <span>{{ match.provider.employeeStatus }}</span>
    </td>
  </tr>
  <tr>
    <td colspan="2"></td>
    <td colspan="2">
      <h5>Client Type:</h5>
      <span>{{ match.patient.clientType }}</span>
    </td>
    <td colspan="2">
      <h5>Onboarding Stage:</h5>
      <span>{{ match.provider.onboardingStage }}</span>
    </td>
  </tr>
  <tr>
    <td colspan="2"></td>
    <td colspan="2">
      <h5>Insurance Type:</h5>
      <span>{{ match.patient.insuranceType }}</span>
    </td>
    <td colspan="2">
      <h5>Credentialing Stage:</h5>
      <span>{{ match.provider.credentialingStage }}</span>
    </td>
  </tr>
  <tr>
    <td colspan="2"></td>
    <td colspan="2">
      <h5>Intake Stage:</h5>
      <span>{{ match.patient.intakeStage }}</span>
    </td>
  </tr>
  <tr>
    <td colspan="2"></td>
    <td colspan="2" class="border-light-top">
      <h5>Clinic Location:</h5>
      <span><strong>Client: </strong>{{ match.patient.clinicLocation }}</span>
      <span><strong>Provider: </strong>{{ match.provider.clinicLocation }}</span>
    </td>
    <td colspan="2" class="border-light-top">
      <h5>Schedule:</h5>
      <span><strong>Date: </strong>{{ match.schedule.apptDate }}</span>
      <span><strong>Start Time: </strong>{{ match.schedule.startTime }}</span>
      <span><strong>End Time: </strong>{{ match.schedule.endTime }}</span>
    </td>
  </tr>
  <tr class="border-light-bottom">
    <td colspan="6">
      <button type="button" mat-stroked-button class="unassign-button" (click)="unassignMatch()">
        Unassign
      </button>
    </td>
  </tr>
</div>
