import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { createEffect, Actions, ofType, act } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { FormResponse, Page } from 'src/app/models';
import { NewClientsService } from 'src/app/services/new-clients/new-clients.service';
import { NewClientsActions } from '../actions';

@Injectable({
  providedIn: 'root',
})
export class NewClientsEffects {
  loadNewClients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewClientsActions.loadNewClients),
      mergeMap(({ sortBy, page, size, direction }) =>
        this.newClientsService.getNewClients(1, { mergeFormId: 2, sortBy, page, size, direction }).pipe(
          map((page) => NewClientsActions.loadNewClientsSuccess({ page })),
          catchError((error) =>
            of(NewClientsActions.loadNewClientsError({ error }))
          )
        )
      )
    )
  );

  exportCSV$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewClientsActions.exportCSV),
      mergeMap((action) =>
        this.newClientsService.exportCSV(1).pipe(
          map((csvString) => {
            console.log(csvString);

            // Download file
            const type = 'data:text/csv;charset=utf-8,';

            const encodedUri = encodeURI(type + csvString);

            const link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', `new_clients.csv`);
            document.body.appendChild(link); // Required for FF

            link.click(); // This will download the data file
            link.remove();

            return NewClientsActions.exportCSVSuccess();
          }),
          catchError((error) => {
            return of(NewClientsActions.exportCSVError({ error }));
          })
        )
      )
    )
  );

  error$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          NewClientsActions.loadNewClientsError,
          NewClientsActions.exportCSVError
        ),
        tap(({ error }) => {
          this.snackbar.open(`ERROR: ${error}`, 'Dismiss', {
            verticalPosition: 'bottom',
            horizontalPosition: 'left',
          });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private newClientsService: NewClientsService,
    private snackbar: MatSnackBar
  ) {}
}
