<div class="patients-container">
    <div class="patients-header">
        <h2>Clients
          <div class="spinner-wrapper" *ngIf="loading">
            <mat-progress-spinner color="primary" mode="indeterminate" diameter="30">
            </mat-progress-spinner>  
          </div>    
        </h2>
        <div class="tab-wrapper">
          <span class="schedule-tab" (click)="setHasSchedule(false)" [ngClass]="{'active': !filters?.patientsHasSchedule }">
            No Schedule
          </span>
        </div>
    </div>
    <input #fileUpload type="file" accept=".csv" style="display: none;" (change)="csvUpload($event.target.files)" multiple />
    <button
    mat-flat-button
    type="button"
    color="accent"
    (click)="fileUpload.click()"
  >
    Upload CSV
  </button>
    <div class="search-box border-dark-bottom">
      <wtls-text-field placeholder="Name" [(value)]="filters.patientName" (valueChange)="filterNameChange($event)"></wtls-text-field>
    </div>
    <div class="overflow-auto">
      <div class="filters-section border-light-bottom">
        <wtls-filter-tree [treeData]="treeData" (filterChange)="filterChanged($event)"></wtls-filter-tree>
      </div>
      <div class="list-section">
        <!-- <mat-radio-group [value]="matchPatient"> -->
          <wtls-patient-record *ngFor="let patient of patients" [patient]="patient" [selectedPatient]="matchPatient" (change)="changePatient($event)" (edit)="editPatient($event)" (delete)="deletePatient($event)"></wtls-patient-record>
        <!-- </mat-radio-group> -->
      </div>
    </div>
</div>