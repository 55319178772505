import * as PatientsActions from './patients.actions';
import * as ProvidersActions from './providers.actions';
import * as MatchesActions from './matches.actions';
import * as MatchActions from './match.actions';
import * as FiltersActions from './filters.actions';
import * as NewClientsActions from './newClients.actions';
import * as MapFullScreenActions from './fullScreen.actions';
import * as AddEditPatientActions from './addEditPatient.actions';
import * as AddEditProviderActions from './addEditProvider.actions';
import * as OptionsActions from './options.actions';
import * as FormActions from './form.actions';

export {
    PatientsActions,
    ProvidersActions,
    MatchesActions,
    MatchActions,
    FiltersActions,
    NewClientsActions,
    MapFullScreenActions,
    AddEditPatientActions,
    AddEditProviderActions,
    OptionsActions,
    FormActions
};