import { createAction, props } from '@ngrx/store';
import { Patient } from '../../models';

export enum PatientsActionTypes {
  LOAD_PATIENTS = '[Patients] Load Patients',
  LOAD_PATIENTS_SUCCESS = '[Patients] Load Patients Success',
  LOAD_PATIENTS_ERROR = '[Patients] Load Patients Error',
  DELETE_PATIENT = '[Patients] Delete Patient',
  DELETE_PATIENT_SUCCESS = '[Patients] Delete Patient Success',
  DELETE_PATIENT_ERROR = '[Patients] Delete Patient Error',
  UPDATE_PATIENTS = '[Patients] Update Patients',
  CLEAR_PATIENTS = '[Patients] Clear Patients',
}

export const loadPatients = createAction(
  PatientsActionTypes.LOAD_PATIENTS,
  // props<{
  //   options?: {
  //     assigned?: boolean;
  //     name?: string;
  //     clientTypes?: string[];
  //     insuranceTypes?: string[];
  //     minAge?: string;
  //     maxAge?: string;
  //     intakeStages?: string[];
  //     street?: string;
  //     city?: string;
  //     state?: string;
  //     zip?: string;
  //   };
  // }>()
);

export const loadPatientsSuccess = createAction(
  PatientsActionTypes.LOAD_PATIENTS_SUCCESS,
  props<{ patients: Patient[] }>()
);

export const loadPatientsError = createAction(
  PatientsActionTypes.LOAD_PATIENTS_ERROR,
  props<{ error }>()
);

export const deletePatient = createAction(
  PatientsActionTypes.DELETE_PATIENT,
  props<{ patient: Patient }>()
)

export const deletePatientSuccess = createAction(
  PatientsActionTypes.DELETE_PATIENT_SUCCESS
)

export const deletePatientError = createAction(
  PatientsActionTypes.DELETE_PATIENT_ERROR,
  props<{ error }>()
)

export const updatePatients = createAction(
  PatientsActionTypes.UPDATE_PATIENTS,
  props<{ patients: Patient[] }>()
)

export const clearPatients = createAction(PatientsActionTypes.CLEAR_PATIENTS);