import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

import {
  bufferCount,
  catchError,
  concatMap,
  delay,
  map,
  mergeMap,
  startWith,
  take,
  tap,
} from 'rxjs/operators';
import {
  Observable,
  throwError,
  of,
  from,
  Subject,
  interval,
  timer,
} from 'rxjs';
import { Patient, TimeSlot } from 'src/app/models';
import {
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { EndPoints } from 'src/app/constants/endpoints';
import { AgmGeocoder } from '@agm/core';
import lodash from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class PatientsService {
  constructor(
    private apiService: ApiService, 
    private geo: AgmGeocoder
  ) {}

  getPatients(options?: {
    assigned?: boolean;
  
    name?: string;
    patientName?: string;
    clientType?: string[];
    insuranceType?: string[];
    minAge?: string; // convert to number
    maxAge?: string; // convert to number
    intakeStage?: string[];
    street?: string;
    city?: string;
    state?: string;
    zip?: string;
    clinicLocation?: string;
    hasSchedule?: boolean;
    day?:string,
    startTime?:string;
    endTime?:string

  }): Observable<Patient[]> {
    let params = new HttpParams();

    if (options.patientName)
      params = params.append('name', options.patientName);
    else if (options.name) params = params.append('name', options.name);

    if (options.street) params = params.append('street', options.street);
    if (options.city) params = params.append('city', options.city);
    if (options.state) params = params.append('state', options.state);
    if (options.zip) params = params.append('zip', options.zip);
    if (options.clinicLocation)
      params = params.append('clinicLocation', options.clinicLocation);
      if (options.day)
      params = params.append('day', options.day);
      if (options.startTime)
      params = params.append('startTime', options.startTime);
      if (options.endTime)
      params = params.append('endTime', options.endTime);
  if (options.minAge) params = params.append('minAge', options.minAge);
    if (options.maxAge) params = params.append('maxAge', options.maxAge);
    console.log('check')
    if(options.clientType){
      if (options.clientType.length > 0)
        params = params.append('clientType', options.clientType.join(','));
    }
    if(options.insuranceType){
      if (options.insuranceType.length > 0)
        params = params.append('insuranceType', options.insuranceType.join(','));
    }
    if(options.intakeStage){
      if (options.intakeStage.length > 0)
        params = params.append('intakeStage', options.intakeStage.join(','));
    }
    if (options.hasSchedule == false) params = params.append('hasAvailability', 'false');

     
    return this.apiService.get<Patient[]>(EndPoints.PATIENTS.GET, { params });
    
  }

  

  getPatient(id: number): Observable<Patient> {
    return this.apiService
      .get<Patient>(EndPoints.PATIENTS.GET + `/${id}`)
      .pipe(
        map((Patient) => {
          Patient.schedule.map((timeSlot) => {
            timeSlot.startTime = new Date(timeSlot.startTime);
            timeSlot.endTime = new Date(timeSlot.endTime);
          });

          Patient.availability.map((timeSlot) => {
            timeSlot.startTime = new Date(timeSlot.startTime);
            timeSlot.endTime = new Date(timeSlot.endTime);
          });

          return Patient;
        })
      );
  }



  updatePatient(patient: Patient): Observable<Patient> {
    let availability = [];
    patient.availability.forEach(timeSlot => {
      availability.push({
        dayOfWeek: timeSlot.dayOfWeek,
        startTime: timeSlot.startTime.getTime(),
        endTime: timeSlot.endTime.getTime(),
      })
    })

    let dob = '';

    if (patient.dob) {
      console.log(patient.dob);
      const year = patient.dob.getFullYear();
      let month;
      if (patient.dob.getMonth()+1 < 10)
        month = `0${patient.dob.getMonth()+1}`;
      else
        month = patient.dob.getMonth();

      let day;
      if (patient.dob.getDate() < 10)
        day = `0${patient.dob.getDate()}`;
      else
        day = patient.dob.getDate();

      dob = `${year}-${month}-${day}T00:00:00`;
    }

    const form = JSON.stringify({
      name: patient.name,
      clinicLocation: patient.clinicLocation,
      clientType: patient.clientType,
      insuranceType: patient.insuranceType,
      dob,
      intakeStage: patient.intakeStage,
      street: patient.address.street,
      city: patient.address.city,
      state: patient.address.state,
      zip: patient.address.zip,
      lat: patient.address.lat,
      lng: patient.address.lng,
      day:patient.day,
      startTime: patient.startTime,
      endTime: patient.endTime,
      availability
    })
    
    //return this.apiService.put<Patient>(EndPoints.PATIENTS.PUT + patient.id, form, {
      return this.apiService.put<Patient>(EndPoints.PATIENTS.PUT + patient.id, form, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  getPatientStatus(id){
    return this.apiService.get(EndPoints.PATIENTS.GET_STATUS+`/${id}`)
  }

  updatePatientLocation(
    id: number,
    lat: number,
    lng: number
  ): Observable<Patient> {
    const form = JSON.stringify({
      lat,
      lng,
    });

    return this.apiService.put<Patient>(EndPoints.PATIENTS.PUT + id, form, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  deletePatient(id: number): Observable<any> {
    return this.apiService.delete(EndPoints.PATIENTS.DELETE + id);
  }

  uploadCSV(files: File[], clear: boolean = false): Observable<any> {
    const form = new FormData();
    files.forEach((file) => {
      form.append('csvs', file, file.name);
    });

    if (!clear)
      return this.apiService.post(`${EndPoints.PATIENTS.POST}?clear=false`, form);
    else
      return this.apiService.post(EndPoints.PATIENTS.POST, form);
  }

  geocode(patients: Patient[]): Observable<Patient[]> {
    let result = new Subject<Patient[]>();

    const currentPatients: Patient[] = [];

    from(patients)
      .pipe(
        bufferCount(20),
        concatMap((item) => of(item).pipe(delay(200)))
      )
      .subscribe((chunk) => {
        this.geocodeBatch(chunk, 0).then((p) => {
          p.forEach((pat) => {
            currentPatients.push(pat);
            console.log(currentPatients);
          });
          console.log(patients);
          if (patients.length == currentPatients.length) {
            result.next(currentPatients);
            result.complete();
          }
        });
      });

    return result;
  }

  geocodeBatch(patients: Patient[], index: number): Promise<Patient[]> {
    return new Promise((resolve) => {
      this.geo
        .geocode({
          address: `${patients[index].address.street}, ${patients[index].address.city} ${patients[index].address.state}, ${patients[index].address.zip}`,
        })
        .pipe(take(1))
        .subscribe(
          (res) => {
            const lat = res[0].geometry.location.lat();
            const lng = res[0].geometry.location.lng();

            let patArr: Patient[] = lodash.cloneDeep(patients);
            patArr[index].address.lat = lat;
            patArr[index].address.lng = lng;

            this.updatePatientLocation(
              patients[index].id,
              lat,
              lng
            ).subscribe();

            if (patients.length - 1 == index) {
              resolve(patArr);
            } else {
              this.geocodeBatch(patArr, index + 1).then((patients) => {
                resolve(patients);
              });
            }
          },
          (error) => {
            if (patients.length - 1 == index) {
              resolve(patients);
            } else {
              this.geocodeBatch(patients, index + 1).then((patients) => {
                resolve(patients);
              });
            }
          }
        );
    });
  }
}
