import { Component, OnInit, OnDestroy,Input, Output, EventEmitter  } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import { MatchActions, MapFullScreenActions, PatientsActions, ProvidersActions } from 'src/app/store/actions';
import { Subscription, Observable } from 'rxjs';
import { MatchesFacade } from 'src/app/services/facades/matches.facade';
import { Match } from 'src/app/models';
import { FiltersFacade } from 'src/app/services/facades/filters.facade';
import { MatchFacade } from 'src/app/services/facades/match.facade';
import { PatientsFacade } from 'src/app/services/facades/patients.facade';
import { ProvidersFacade } from 'src/app/services/facades/providers.facade';
import { MapFacade } from 'src/app/services/facades/map.facade';
import { ProvidersService } from 'src/app/services/providers/providers.service';

@Component({
  selector: 'app-assigned',
  templateUrl: './assigned.component.html',
  styleUrls: ['./assigned.component.scss']
})
export class AssignedComponent implements OnInit, OnDestroy {

  @Input() matches: Match[];
  mapFullScreen$: Observable<boolean>;

  showMap: boolean;

  matches$: Observable<Match[]>;
  match: Match;
  match$: Observable<Match>;

  matchesLoading$: Observable<boolean>;

  subscriptions = new Subscription();

  constructor(
    private matchFacade: MatchFacade,
    private patientsFacade: PatientsFacade,
    private providersFacade: ProvidersFacade,
    private matchesFacade: MatchesFacade,
    private filtersFacade: FiltersFacade,
    private mapFacade: MapFacade,  private providersService: ProvidersService
  ) { 
    filtersFacade.resetAssignedFilters();
    matchFacade.resetMatch();
    patientsFacade.clearPatients();
    providersFacade.clearProviders();
    mapFacade.resetMapFullScreen();

    this.mapFullScreen$ = mapFacade.getMapFullScreen();

    this.matches$ = matchesFacade.getMatches();
    this.match$ = matchFacade.getMatch();

    this.matchesLoading$ = matchesFacade.getLoading();

    const sub = matchesFacade
      .getError()
      .subscribe(
        error => {
          if (error)
            alert(error);
        }
      )

    this.subscriptions.add(sub);
  }

  ngOnInit(): void {

  
      

  // this.providersService.getappointment().subscribe(
  //   (response: any) => {
      
  // //var responsebody =  [];

  // //responsebody.push(JSON.parse(response));
    
  //  console.log("xx",response);  
  //   });

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();

    this.matchesFacade.clearError();
  }

  toggleMap(): void {
    this.showMap = !this.showMap;
  }
}
