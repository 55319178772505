import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort, SortDirection } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { FormResponse, Page } from 'src/app/models';
import { NewClientsFacade } from 'src/app/services/facades/newClients.facade';
import { FilesService } from 'src/app/services/files/files.service';

@Component({
  selector: 'wtls-new-clients',
  templateUrl: './new-clients.component.html',
  styleUrls: ['./new-clients.component.scss']
})
export class NewClientsComponent implements OnInit {
  page$: Observable<Page<FormResponse>> = this.newClientsFacade.getNewClientsPage();
  loading$: Observable<boolean> = this.newClientsFacade.getLoading();

  private _page: number;
  private _size: number;
  private _sortBy: string;
  private _direction: SortDirection;
  answer: any;

  constructor(private newClientsFacade: NewClientsFacade, private fileService: FilesService) { }

  private _setCurrentPage(options: { page?: number, size?: number, sortBy?: string, direction?: SortDirection }): void {
    if (options.page) this._page = options.page;
    if (options.size) this._size = options.size;
    if (options.sortBy != null) this._sortBy = options.sortBy;
    if (options.direction != null) this._direction = options.direction;
  }

  ngOnInit(): void {
    console.log("TamilRK12");
    this._setCurrentPage({ page: 1, size: 25 });

    this.newClientsFacade.loadNewClients(this._page, this._size);
  }

  pageChange(pageEvent: PageEvent): void {
    this._setCurrentPage({ page: pageEvent.pageIndex+1, size: pageEvent.pageSize });

    this.newClientsFacade.loadNewClients(this._page, this._size, this._sortBy, this._direction);
  }

  loadClients(): void {
    console.log("TamilRK");
    this.newClientsFacade.loadNewClients(this._page, this._size);
  }

  sortChanged(sort: Sort): void {
    if (sort.direction) 
      this._setCurrentPage({ sortBy: sort.active, direction: sort.direction });
    else
      this._setCurrentPage({ sortBy: '', direction: ''});

    this.newClientsFacade.loadNewClients(this._page, this._size, this._sortBy, this._direction);
  }

  downloadFile(url: string): void {
     this.fileService.downloadResource(url, url.split('/').pop());
  }

  exportCSV(): void {
    this.newClientsFacade.exportCSV();
  }
}
