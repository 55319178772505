<agm-marker
  *ngIf="marker.provider.address.lat && marker.provider.address.lng"
  [latitude]="marker.provider.address.lat"
  [longitude]="marker.provider.address.lng"
  [iconUrl]="marker.icon"
  (markerClick)="changeProvider()"
  (mouseOver)="mouseOverMarker()"
  (mouseOut)="mouseOutMarker()"
>
  <agm-snazzy-info-window
    [isOpen]="isOpen || stayOpen"
    (beforeOpen)="checkAvailability()"
    backgroundColor="white"
    placement="left"
    [latitude]="marker.provider.address.lat"
    [longitude]="marker.provider.address.lng"
  >
    <ng-template>
      <div *ngIf="fullScreen" class="info-window-content-container">
        <h2 *ngIf="!marker.provider.name"><em>No Name</em></h2>
        <h2 *ngIf="marker.provider.name">{{ marker.provider.name }}</h2>
        <p>{{ marker.provider.onboardingStage }}</p>
        <p *ngIf="!marker.provider.address.street"><em>No address available</em></p>
        <p *ngIf="marker.provider.address.street">
          {{ marker.provider.address.street }},
          {{ marker.provider.address.city }} {{ marker.provider.address.state }}
          {{ marker.provider.address.zip }}
        </p>
        <div class="info-window-row">
          <h5>Clinic Location:</h5>
          <span>{{ marker.provider.clinicLocation }}</span>
        </div>
        <div class="info-window-row">
          <h5>Availability:</h5>
          <div class="spinner-wrapper" *ngIf="availabilityLoading">
            <mat-progress-spinner color="primary" mode="indeterminate" diameter="30">
            </mat-progress-spinner>  
          </div>  
          <span *ngIf="!availabilityLoading && !availability[0]"><em>No availability</em></span>
          <span *ngIf="!availabilityLoading && availability[0]">{{ availability | timeSlots }}</span>
        </div>
        <div class="info-window-row">
          <h5>Employee Status:</h5>
          <span>{{ marker.provider.employeeStatus }}</span>
        </div>
        <div class="info-window-row">
          <h5>Onboarding Stage:</h5>
          <span>{{ marker.provider.onboardingStage }}</span>
        </div>
        <div class="info-window-row">
          <h5>Credentialing Stage:</h5>
          <span>{{ marker.provider.credentialingStage }}</span>
        </div>
        <div class="button-group">
          <button
          type="button"
          mat-stroked-button
          (click)="openEditPanel()"
        >
          Edit
        </button>
          <button
            mat-stroked-button
            type="button"
            class="delete-btn"
            (click)="openDeleteDialog()"
          >
            Delete 
          </button>
        </div>
      </div>
      <div *ngIf="!fullScreen" class="info-window-content-container info-window-content-container-custom">
        <h2 *ngIf="!marker.provider.name"><em>No Name</em></h2>
        <h2 *ngIf="marker.provider.name">{{ marker.provider.name }}</h2>
        <p>{{ marker.provider.onboardingStage }}</p>
        <p *ngIf="!marker.provider.address.street"><em>No address available</em></p>
        <p *ngIf="marker.provider.address.street">
          {{ marker.provider.address.street }},
          {{ marker.provider.address.city }} {{ marker.provider.address.state }}
          {{ marker.provider.address.zip }}
        </p>
        <!-- <div class="button-group">
          <button
          type="button"
          mat-stroked-button
          (click)="openEditPanel()"
        >
          Edit
        </button>
          <button
            mat-stroked-button
            type="button"
            class="delete-btn"
            (click)="openDeleteDialog()"
          >
            Delete
          </button>
        </div> -->
        <div class="toggle-collection-wrapper">
          <button type="button" class="map-minimize-button"  (click)="openEditPanel()" >
              <span class="toggle-text">Edit</span>
          </button>
          <button type="button" class="map-minimize-button"  (click)="openDeleteDialog()" >
              <span class="toggle-text">Delete</span>
          </button>    
        </div>
      </div>
    </ng-template>
  </agm-snazzy-info-window>
</agm-marker>
