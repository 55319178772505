import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Patient, TimeSlot } from 'src/app/models';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import { Subscription } from 'rxjs';
import { MatchSelectors } from 'src/app/store/selectors';
import { MatchActions } from 'src/app/store/actions';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { PatientsFacade } from 'src/app/services/facades/patients.facade';
import { MapFacade } from 'src/app/services/facades/map.facade';
import { MatchFacade } from 'src/app/services/facades/match.facade';
import { AddEditPatientFacade } from 'src/app/services/facades/addEditPatient.facade';

interface Marker {
  patient: Patient;
  icon: google.maps.Icon;
}

@Component({
  selector: 'wtls-patient-map-marker',
  templateUrl: './patient-map-marker.component.html',
  styleUrls: ['./patient-map-marker.component.scss'],
})
export class PatientMapMarkerComponent implements OnInit, OnDestroy {
  @Input() patient: Patient;

  scheduleLoading: boolean;

  schedule: TimeSlot[] = [];
  availability: TimeSlot[] = [];

  defaultIcon: google.maps.Icon = {
    url: '../../../../assets/map-markers/patient-unassigned.svg',
  };

  selectedIcon: google.maps.Icon = {
    url: '../../../../assets/map-markers/patient-unassigned-selected.svg',
  };

  marker: Marker;

  selected: boolean;

  selectedPatient: Patient;

  isOpen: boolean;

  stayOpen: boolean;

  fullScreen: boolean;

  loading: boolean;

  subscriptions: Subscription = new Subscription();

  constructor(
    private dialog: MatDialog,
    private patientsFacade: PatientsFacade,
    private matchFacade: MatchFacade,
    private mapFacade: MapFacade,
    private addEditPatientFacade: AddEditPatientFacade
  ) { }

  ngOnInit(): void {
    this.marker = {
      patient: this.patient,
      icon: this.defaultIcon
    };

    const sub1 = this.mapFacade
      .getMapFullScreen()
      .subscribe((value) => {
        this.fullScreen = value;
      })

    const sub2 = this.matchFacade
      .getPatient()
      .subscribe((patient) => {
        this.selectedPatient = patient;

        if (patient && this.patient.id == patient.id) {
          this.marker.icon = this.selectedIcon;
          this.stayOpen = true;
          this.selected = true;
        } else {
          this.marker.icon = this.defaultIcon;
          this.stayOpen = false;
          this.selected = false;
        }
      });

    const sub3 = this.patientsFacade
      .getLoading()
      .subscribe((loading) => {
        this.loading = loading
      })

    this.subscriptions.add(sub1);
    this.subscriptions.add(sub2);
    this.subscriptions.add(sub3);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  checkSchedule(): void {
    if (this.fullScreen && this.availability.length == 0) {
      this.scheduleLoading = true;

      this.patientsFacade
        .getFullPatient(this.patient.id)
        .subscribe(
          patient => {
            this.schedule = patient.schedule;
            this.availability = patient.availability;

            this.scheduleLoading = false;
          },
          error => {
            this.scheduleLoading = false;

            alert(error);
          }
        )
    }
  }

  changePatient(): void {
    if (!this.loading)
      this.matchFacade.selectPatient(this.patient);
  }

  mouseOverMarker(): void {
    this.isOpen = true;
  }

  mouseOutMarker(): void {
    this.isOpen = false;
  }

  closeInfoWindow(): void {
    this.stayOpen = false;
  }

  openEditPanel(): void {
    this.patientsFacade
    .getFullPatient(this.patient.id)
    .subscribe(
      patient => {
        this.addEditPatientFacade.setPatient(patient);
        this.addEditPatientFacade.openPanel();
      },
      error => {
        alert(error);
      }
    )
  }

  openDeleteDialog(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: this.patient
    })

    dialogRef.beforeClosed()
      .subscribe(result => {
        if (result) {
          this.patientsFacade
            .deletePatient(this.patient)
            .subscribe(res => {
              console.log('patient deleted')
            })
        }
      })
  }
  checkCenter(){
    console.log("check center")
  }
}
