<agm-marker
  *ngIf="marker.patient.address.lat && marker.patient.address.lng"
  [latitude]="marker.patient.address.lat"
  [longitude]="marker.patient.address.lng"
  [iconUrl]="marker.icon"
  (markerClick)="changePatient()"
  (mouseOver)="mouseOverMarker()"
  (mouseOut)="mouseOutMarker()"
>
  <agm-snazzy-info-window
    [isOpen]="isOpen || stayOpen"
    (beforeOpen)="checkSchedule()"
    backgroundColor="white"
    placement="right"
    [latitude]="marker.patient.address.lat"
    [longitude]="marker.patient.address.lng"
  >
    <ng-template>
      <div *ngIf="fullScreen" class="info-window-content-container">
        <h2 *ngIf="!marker.patient.name"><em>No Name</em></h2>
        <h2 *ngIf="marker.patient.name">{{ marker.patient.name }}</h2>
        <p>{{ marker.patient.age }} years old</p>
        <p *ngIf="!marker.patient.address.street"><em>No address available</em></p>
        <p *ngIf="marker.patient.address.street">
            {{ marker.patient.address.street }},
            {{ marker.patient.address.city }} {{ marker.patient.address.state }}
            {{ marker.patient.address.zip }}  
        </p>
        <div class="info-window-row">
          <h5>Clinic Location:</h5>
          <span>{{ marker.patient.clinicLocation }}</span>
        </div>
        <div class="info-window-row">
          <h5>Schedule:</h5>
          <div class="spinner-wrapper" *ngIf="scheduleLoading">
            <mat-progress-spinner color="primary" mode="indeterminate" diameter="30">
            </mat-progress-spinner>  
          </div>  
          <span *ngIf="!scheduleLoading && !availability[0]"><em>No schedule</em></span>
          <span *ngIf="!scheduleLoading && availability[0]">{{ availability | timeSlots }}</span>
        </div>
        <div class="info-window-row">
          <h5>Client Type:</h5>
          <span>{{ marker.patient.clientType }}</span>
        </div>
        <div class="info-window-row">
          <h5>Insurance Type:</h5>
          <span>{{ marker.patient.insuranceType }}</span>
        </div>
        <div class="info-window-row">
          <h5>Intake Stage:</h5>
          <span>{{ marker.patient.intakeStage }}</span>
        </div>
        <div class="button-group">
          <button
          type="button"
          mat-stroked-button
          class="minimize-map-button"
          (click)="openEditPanel()"
        >
          Edit
        </button>
          <button
            mat-stroked-button
            type="button"
            class="delete-btn minimize-map-button"
            (click)="openDeleteDialog()"
          >
            Delete
          </button>
        </div>
      </div>
      <div *ngIf="!fullScreen" class="info-window-content-container info-window-content-container-custom">
        <h2 *ngIf="!marker.patient.name"><em>No Name</em></h2>
        <h2 *ngIf="marker.patient.name">{{ marker.patient.name }}</h2>
        <p>{{ marker.patient.age }} years old</p>
        <p *ngIf="!marker.patient.address.street"><em>No address available</em></p>
        <p *ngIf="marker.patient.address.street">
            {{ marker.patient.address.street }},
            {{ marker.patient.address.city }} {{ marker.patient.address.state }}
            {{ marker.patient.address.zip }}  
        </p>
        <!-- <div class="button-group">
          <button
          type="button"
          mat-stroked-button
          (click)="openEditPanel()"
        >
          Edit111
        </button>
          <button
            mat-stroked-button
            type="button"
            class="delete-btn"
            (click)="openDeleteDialog()"
          >
            Delete
          </button>
        </div> -->
        <div class="toggle-collection-wrapper">
          <button type="button" class="map-minimize-button"  (click)="openEditPanel()" >
              <span class="toggle-text">Edit</span>
          </button>
          <button type="button" class="map-minimize-button"  (click)="openDeleteDialog()" >
              <span class="toggle-text">Delete</span>
          </button>    
        </div>
      </div>
    </ng-template>
  </agm-snazzy-info-window>
</agm-marker>
