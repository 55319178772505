import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import { BehaviorSubject, Observable } from 'rxjs';
import { FiltersState } from 'src/app/store/reducers';
import { FiltersSelectors } from 'src/app/store/selectors';
import { FiltersActions } from 'src/app/store/actions';

@Injectable({
  providedIn: 'root',
})
export class FiltersFacade {
  private filters$ = new BehaviorSubject<FiltersState>(null);

  private clinicLocations$ = new BehaviorSubject<string[]>([]);

  private startTime$ = new BehaviorSubject<string[]>([]);

  private endTime$ = new BehaviorSubject<string[]>([]);

  constructor(private store: Store<AppState>) {
    this.store.select('filters').subscribe((filters) => {
      this.filters$.next(filters);
    });
 
    this.store
    .select(FiltersSelectors.selectstartTime)
    .subscribe((startTime) => {
      this.startTime$.next(startTime);
    });
    
    this.store
    .select(FiltersSelectors.selectendTime)
    .subscribe((endTime) => {
      this.endTime$.next(endTime);
    });

    this.store
      .select(FiltersSelectors.selectClinicLocations)
      .subscribe((clinicLocations) => {
        this.clinicLocations$.next(clinicLocations);
      });
  }

  getFilters(): Observable<FiltersState> {
    return this.filters$.asObservable();
  }

  resetUnassignedFilters(): void {
    this.store.dispatch(FiltersActions.resetUnassignedFilter());
  }

  resetAssignedFilters(): void {
    this.store.dispatch(FiltersActions.resetAssignedFilter());
  }

  getAllClinicLocations(): Observable<string[]> {
    return this.clinicLocations$.asObservable();
  }

  getAllstartTime(): Observable<string[]> {
    return this.startTime$.asObservable();
  }

  updateUnassignedFilters(filters: FiltersState): void {
    this.store.dispatch(FiltersActions.updateUnassignedFilters(filters));
  }

  updateAssignedFilters(filters: FiltersState): void {
    this.store.dispatch(FiltersActions.updateAssignedFilters(filters));
    
  }
}
