import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { FormResponse, Page } from 'src/app/models';
import { NewClientsActions } from 'src/app/store/actions';
import { AppState } from 'src/app/store/app.state';
import { NewClientsSelectors } from 'src/app/store/selectors';

@Injectable({
    providedIn: 'root'
})
export class NewClientsFacade {

    constructor(private store: Store<AppState>) {}

    getNewClientsPage(): Observable<Page<FormResponse>> {
        return this.store.pipe(
            select(NewClientsSelectors.selectNewClientsPage)
        )
    }

    getLoading(): Observable<boolean> {
        return this.store.pipe(
            select(NewClientsSelectors.selectNewClientsLoading)
        )
    }

    loadNewClients(page: number, size: number, sortBy?: string, direction?: SortDirection): void {
        this.store.dispatch(NewClientsActions.loadNewClients({ sortBy, page, size, direction }));
    }

    exportCSV(): void {
        this.store.dispatch(NewClientsActions.exportCSV());
    }
}