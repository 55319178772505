import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import { ProvidersService } from '../providers/providers.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Provider } from 'src/app/models';
import { ProvidersSelectors } from 'src/app/store/selectors';
import { ProvidersActions } from 'src/app/store/actions';
import { FiltersFacade } from './filters.facade';
import { MatchFacade } from './match.facade';
import { AgmGeocoder } from '@agm/core';
import { map, take, switchMap, mergeMap, filter, catchError } from 'rxjs/operators';
import lodash from 'lodash';
import { PatientsFacade } from './patients.facade';

@Injectable({
  providedIn: 'root',
})
export class ProvidersFacade {
  private providers$ = new BehaviorSubject<Provider[]>([]);

  private allProviders$ = new BehaviorSubject<Provider[]>([]);

  private loading$ = new BehaviorSubject<boolean>(false);

  private error$ = new BehaviorSubject<string>('');

  constructor(
    private store: Store<AppState>,
    private providersService: ProvidersService,
    private filtersFacade: FiltersFacade,
    private matchFacade: MatchFacade,
    private geo: AgmGeocoder
  ) {
    this.store
      .select(ProvidersSelectors.selectProvidersList)
      .subscribe((providers) => {
        this.providers$.next(providers);

        if (this.allProviders$.getValue().length < providers.length)
          this.allProviders$.next(providers);
      });

    this.store
      .select(ProvidersSelectors.selectProvidersLoading)
      .subscribe((loading) => {
        this.loading$.next(loading);
      });

    this.store
      .select(ProvidersSelectors.selectProvidersError)
      .subscribe((error) => {
        this.error$.next(error);
      });
  }

  loadProviders(): void {
    this.store.dispatch(ProvidersActions.loadProviders());

    this.filtersFacade
      .getFilters()
      .pipe(
        take(1),
        mergeMap((filters) =>
          this.providersService
            .getProviders({
              first_name: filters.name.split(' ')[0],
              last_name: filters.name.split(' ')[1],
              providerName: filters.providerName,
              employeeStatus: filters.employeeStatuses,
              onboardingStage: filters.onboardingStages,
              credentialingStage: filters.credentialingStages,
              street: filters.address,
              city: filters.city,
              state: filters.state,
              zip: filters.zip,
              clinicLocation: filters.clinicLocation,
              hasSchedule: filters.providersHasSchedule,
              day: filters.day,
              startTime: filters.startTime,
              endTime: filters.endTime
            })
            .pipe(
              map((providers) => {
                this.matchFacade
                  .getProvider()
                  .pipe(take(1))
                  .subscribe((provider) => {
                    if (provider && !providers.some((p) => p.id == provider.id))
                      this.matchFacade.clearProvider();
                  })

                  return providers
              })
            )
        )
      )
      .subscribe(
        (providers) => {
          this.store.dispatch(ProvidersActions.loadProvidersSuccess({ providers }));
        },
        (error) => {
          console.log(error);
          this.store.dispatch(ProvidersActions.loadProvidersError({ error }));
        }
      );
  }

  loadNewProviders(): Observable<any> {
    this.store.dispatch(ProvidersActions.loadProviders());

    return this.filtersFacade
      .getFilters()
      .pipe(
        take(1),
        mergeMap((filters) =>
          this.providersService
            .getProviders({
              first_name: filters.name,
              providerName: filters.providerName,
              employeeStatus: filters.employeeStatuses,
              onboardingStage: filters.onboardingStages,
              credentialingStage: filters.credentialingStages,
              street: filters.address,
              city: filters.city,
              state: filters.state,
              zip: filters.zip,
              clinicLocation: filters.clinicLocation,
              hasSchedule: filters.providersHasSchedule,
              day:filters.day,
              startTime:filters.startTime,
              endTime:filters.endTime
            })
            .pipe(
              map((providers) => {
                if (this.allProviders$.getValue().length > 0)
                  this.allProviders$.next(providers);

                if (providers.length > 0) { // TODO remove check and make seperate method for geocode new patients
                  this.providersService.geocode(providers)
                    .subscribe(
                      providers => {
                        console.log(providers);
                        this.store.dispatch(
                          ProvidersActions.loadProvidersSuccess({
                            providers
                          })
                        );
                      },
                      error => {
                        this.store.dispatch(ProvidersActions.loadProvidersError({
                          error
                        }));
                      }
                    )
                  }

                  return providers
              }),
              catchError((error) => {
                this.store.dispatch(ProvidersActions.loadProvidersError({
                  error
                }));

                return error
              })
            )
        )
      );
  }

  getProviders(): Observable<Provider[]> {
    return this.providers$.asObservable();
  }

  getFullProvider(id: number): Observable<Provider> {
    return this.providersService.getProvider(id);
  }

  getAllProviders(): Observable<Provider[]> {
    return this.allProviders$.asObservable();
  }

  getProviderStatus(id: number): Observable<any>{
    return this.providersService.getProviderStatus(id)
  }

  exportProviders(): Observable<any>{
    return this.providersService.exportCsv()
  }

  deleteProvider(provider: Provider): Observable<any> {
    this.matchFacade
      .getProvider()
      .pipe(take(1))
      .subscribe((matchProvider) => {
        if (matchProvider && provider.id == matchProvider.id) {
          this.matchFacade.clearProvider();
        }
      });

    return this.providersService
      .deleteProvider(provider.id)
      .pipe(map(() => this.loadProviders()));
  }

  clearProviders(): void {
    if (this.providers$.getValue().length > 0)
      this.store.dispatch(ProvidersActions.clearProviders());
  }

  clearAllProviders(): void {
    if (this.allProviders$.getValue().length > 0)
      this.allProviders$.next([]);
  }

  uploadCSV(files: File[]): Observable<any> {
    return this.providersService.uploadCSV(files);
  }

  getLoading(): Observable<boolean> {
    return this.loading$.asObservable();
  }

  getError(): Observable<string> {
    return this.error$.asObservable();
  }
}
