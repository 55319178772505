<div class="d-flex justify-content-center align-items-center h-100">
  <div class="panel-container">
    <h1>Reset Password</h1>
    <form #resetPasswordForm="ngForm" (ngSubmit)="onSubmit()">
      <mat-form-field class="w-100">
        <mat-label>Email</mat-label>
        <input
          matInput
          type="text"
          name="email"
          #email="ngModel"
          [(ngModel)]="formData.email"
          required
        />
        <mat-error *ngIf="email.invalid">Email is required</mat-error>
      </mat-form-field>
      <button
        mat-flat-button
        type="submit"
        color="primary"
        [disabled]="!resetPasswordForm.form.valid || loading"
      >
        Send New Password
      </button>
      <div class="spinner-wrapper" *ngIf="loading">
        <mat-progress-spinner color="primary" mode="indeterminate" diameter="30">
        </mat-progress-spinner>  
      </div>  
    </form>
    <span class="error-text">{{ error }}</span>
  </div>
</div>
