<!-- <div className="list-select-container">
    <label className="list-select-label">Other Contacts</label>
  <div className="list-select-outer-wrapper" [ngClass]="{'open' : open}">
    <div className="list-select-overlay" (click)="toggle()"></div>
    <div className="list-select-wrapper">
      <div className="form-control list-select" (click)="toggle()">
        { this.state.selected !== -1
          ? this.state.contactLists[this.state.selected].name
          : 'Select contacts list' }
      </div>
      <ul className="list-select-options">
        { this.state.contactLists.map(
            (item, i) => <li onClick={ (event) => this.select(item, i) } key={ i }>
              <span title={ item.name }>{ item.name }</span>
              <div className="list-select-actions">
                <i className="fa fa-pencil" aria-hidden="true" onClick={ (event) => this.editList(event, i) }></i>
                <i className="fa fa-close" aria-hidden="true" onClick={ (event) => this.deleteList(event, item) }></i>
              </div>
              <div className="list-select-preview">
                { item.members.map( (item, j) => <div key={ j }>{ item.name }</div> ) }
              </div>
            </li>
          ) }
      </ul>
    </div>
    <button className="button-primary" onClick={ (event) => this.createList(event) }>Create New List</button>
  </div>
  </div> -->

<select #dropdown class="dropdown" [ngModel]="value" (input)="valueChanged(dropdown.value)">
    <option class="option" value="" selected>{{ placeholder }}</option>
    <option *ngFor="let option of options" class="option" [value]="option">{{ option }}</option>
</select>