import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/services';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator'; 
@Component({
  selector: 'wtls-cliniclocationshow',
  templateUrl: './cliniclocationshow.component.html',
  styleUrls: ['./cliniclocationshow.component.scss']
})
export class CliniclocationshowComponent implements OnInit {
  li:any;
  dataSource = new MatTableDataSource<any>();
  loading = true
  displayedColumns: string[] = ["Location Name","State",	"City","Action"];
  pageSize = 10
  pageSizeOptions = [10, 25, 50, 500];
  @ViewChild(MatPaginator) paginator: MatPaginator

  constructor(private router: Router, private route: ActivatedRoute,private http: HttpClient,private apiService: ApiService, private changeDetector: ChangeDetectorRef) { }
  lis=[];
  ngOnInit(): void {

    this.apiService.get('/cliniclocations')
    .subscribe(Response => {
 
      // If response comes hideloader() function is called
      // to hide that loader
      if(Response){ 
        //hideloader();
      }
      this.loading = false
      console.log("datatest=>",Response)
      this.li=Response;
      this.lis=this.li;
      this.dataSource.data =this.li;
      console.log("datatest1=>",this.lis)
     
      
    });
    function hideloader(){
      document.getElementById('loading').style.display = 'none';}
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator
  }

  delete(id : any)
  {
    console.log("delete=>",id);
      let deleteid = id;
      
           this.apiService.delete('/cliniclocations/'+ deleteid).subscribe(
        
            (response) => console.log(window.location.reload()),
            
            (error) => console.log(error)
          );  
  }

  edit(id: any){
    this.router.navigate(['/dashboard/editlocations/'+id])
  }

}
