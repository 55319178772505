import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { PatientsState } from '../reducers';


export const selectPatients = (state: AppState) => state.patients;

export const selectPatientsList = createSelector(
    selectPatients,
    (state: PatientsState) => state.list
)

export const selectPatientsLoading = createSelector(
    selectPatients,
    (state: PatientsState) => state.loading
)

export const selectPatientsError = createSelector(
    selectPatients,
    (state: PatientsState) => state.error
)