import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {

  constructor() { }

  contactValidate(): ValidatorFn {  
    return (control: AbstractControl): { [key: string]: any } => {  
      if (!control.value) {  
        return null;  
      }  
      let contactRegex = /[\d]{10}/
      let valid = contactRegex.test(control.value)
      return valid ? null : { contactValidation: true };  
    };  
  } 

  nameValidate(): ValidatorFn {
    return (control: AbstractControl): {[key:string]: any} => {
      if(!control.value){
        return null
      }
      let specialCharactersRegex = /[!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?]/
      let valid = specialCharactersRegex.test(control.value)
      return !valid ? null : { specialCharacters : true}
    }
  }

  addressValidate(): ValidatorFn {
    return (control: AbstractControl): {[key:string]: any} => {
      if(!control.value){
        return null
      }
      let specialCharactersRegex = /[!@$%^&*()_+\-=\[\]{};:\\|.<>\/?]/
      let valid = specialCharactersRegex.test(control.value)
      return !valid ? null : { addressValidation : true}
    }
  }

  cityValidate(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      if(!control.value)
        return
      let cityRegex = /[\w]/
      let valid = cityRegex.test(control.value)
      return valid ? null : { cityValidation: true}
    }
  }

  stateValidate(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      if(!control.value)
        return
      let stateRegex = /[\w]/
      let valid = stateRegex.test(control.value)
      return valid ? null : { stateValidation: true}
    }
  }

  zipValidate(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      if(!control.value)
        return
      let zipRegex = /[\d]{4,5}/
      let valid = zipRegex.test(control.value)
      return valid ? null : { zipValidation: true}
    }
  }

  MatchPassword(password: string, conformpassword: string) {  
    return (formGroup: FormGroup) => {  
      const passwordControl = formGroup.controls[password];  
      const confirmPasswordControl = formGroup.controls[conformpassword];  
      
      if (!passwordControl || !confirmPasswordControl) {  
        return null;  
      }  
  
      if (confirmPasswordControl.errors && !confirmPasswordControl.errors.passwordMismatch) {  
        return null;  
      }  

      if (passwordControl.value !== confirmPasswordControl.value) {  
        confirmPasswordControl.setErrors({ passwordMismatch: true });  
      } else {  
        confirmPasswordControl.setErrors(null);  
      }
      
    }  
  }

  
}
