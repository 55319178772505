import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "bytes",
})
export class BytesPipe implements PipeTransform {
  SIZES = {
    KB: 1000,
    MB: 1000000,
    GB: 1000000000,
  };

  transform(value: number, ...args: ("B" | "KB" | "MB" | "GB")[]): string {
    if (value) {
      let str: string;

      if (args.length > 0) {
      } else {
        let num: number;
        let size: string;
        
        if (value >= this.SIZES.GB) {
          num = value / this.SIZES.GB;
          size = "GB";
        } else if (value >= this.SIZES.MB) {
          num = value / this.SIZES.MB;
          size = "MB";
        } else if (value >= this.SIZES.KB) {
          num = value / this.SIZES.KB;
          size = "KB";
        } else {
          num = value;
          size = "B";
        }

        str = `${num.toFixed(2)}${size}`;
      }
      console.log(value);

      return str;
    } else {
      return "";
    }
  }
}
