import { Injectable } from "@angular/core";
import { catchError, mergeMap, switchMap, map, take } from "rxjs/operators";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { PatientsService } from 'src/app/services';
import { AddEditPatientActions, PatientsActions } from '../actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of } from 'rxjs';
import { PatientsFacade } from 'src/app/services/facades/patients.facade';

@Injectable({
  providedIn: "root",
})
export class AddEditPatientEffects {

    updatePatient$ = createEffect(() => 
        this.actions$.pipe(
            ofType(AddEditPatientActions.updatePatient),
            mergeMap(action => this.patientsService.updatePatient(action.patient).pipe(
                switchMap(patients => {

                    this.patientsFacade.loadPatients();
                    
                    return [
                    // AddEditPatientActions.updatePatientSuccess({ success: 'Update patient successful' }),
                    AddEditPatientActions.updatePatientSuccess(),
                    AddEditPatientActions.closePanel(),
                    AddEditPatientActions.clearPatient()
                ]
            })
            ))
        ))

    // updatePatientSuccess$ = createEffect(() => 
    //     this.actions$.pipe(
    //         ofType(AddEditPatientActions.updatePatientSuccess),
    //             mergeMap(action => {
    //                 console.log('hi!!!');
    //                 this.snackbar.open(action.success, '' , {
    //                     duration: 4000,
    //                     horizontalPosition: 'left',
    //                     verticalPosition: 'bottom'
    //                 })    

    //                 return of(action)
    //             })
    //     ))

    constructor(private actions$: Actions, private patientsService: PatientsService, private patientsFacade: PatientsFacade, private snackbar: MatSnackBar) { }
}
