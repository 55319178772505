import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiService, AuthService } from 'src/app/services';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator'; 

@Component({
  selector: 'wtls-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit, AfterViewInit {
  li:any;
  dataSource = new MatTableDataSource<any>();
  loading = true
  displayedColumns: string[] = ["S No.","Fullname","Company Name",	"Email",	"Address",	"Contact No",	"Opening time",	"Closing time",	"Url"	, "Status","Action"];
  pageSize = 10
  role = ''
  pageSizeOptions = [5, 10, 50, 500];
  @ViewChild(MatPaginator) paginator: MatPaginator
  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private http: HttpClient,
    private apiService: ApiService,
    private changeDetector: ChangeDetectorRef,
    private authService: AuthService
    ) { }

  ngOnInit(): void {
    this.role = this.authService.getRoleToken()
    if(this.role != '1') this.router.navigate(['/dashboard/blocks'])
    this.apiService.get('/createcompany')
    .subscribe(Response => {
 
      // If response comes hideloader() function is called
      // to hide that loader
      if(Response){ 
        this.loading = false
      }
      console.log("datatest=>",Response)
      this.li=Response;
      this.dataSource.data =this.li;
      console.log("datatest1=>",this.dataSource)
      
    });
    //this.changeDetector.detectChanges()
    //this.dataSource.paginator = this.paginator
    function hideloader(){
      document.getElementById('loading').style.display = 'none';}
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator
  }

  delete(id : any)
  {
    console.log("delete=>",id);
      let deleteid = id;
      
           this.apiService.delete('/createcompany/'+ deleteid).subscribe(
        
            (response) => console.log(window.location.reload()),
            
            (error) => console.log(error)
          );  
  }
  edit(id: any){
    this.router.navigate(['/dashboard/editcompany/'+id])
  }


}
