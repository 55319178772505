import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import { MapFullScreenActions } from 'src/app/store/actions';


@Injectable({
    providedIn: 'root'
})
export class MapFacade {

    private mapFullScreen$ = new BehaviorSubject<boolean>(false);

    constructor(
        private store: Store<AppState>
    ) { 
        store.select('mapFullScreen').subscribe(
            fullScreen => {
                this.mapFullScreen$.next(fullScreen);
            }
        )
    }

    getMapFullScreen(): Observable<boolean> {
        return this.mapFullScreen$.asObservable();
    }

    resetMapFullScreen(): void {
        if (this.mapFullScreen$.getValue())
            this.store.dispatch(MapFullScreenActions.resetFullScreen());
    }
}