import { Component, OnInit,Input,Output,OnDestroy,EventEmitter } from '@angular/core';
import { TimeSlot, Match } from 'src/app/models';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatchFacade } from 'src/app/services/facades/match.facade';
import { PatientsFacade } from 'src/app/services/facades/patients.facade';
import { ProvidersFacade } from 'src/app/services/facades/providers.facade';
import { Observable, Subscription } from 'rxjs';
import { MatchesFacade } from 'src/app/services/facades/matches.facade';
import { MatchesService } from 'src/app/services/matches/matches.service';
import { FiltersState } from 'src/app/store/reducers';
import lodash from 'lodash';
import { FiltersFacade } from 'src/app/services/facades/filters.facade';
import { map, mergeMap } from 'rxjs/operators';
import { FilterDialogComponent } from '../filter-dialog/filter-dialog.component';
import { LIST_OF_US_STATES_ABBREVIATIONS } from 'src/app/constants/usStates';

import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
@Component({
  selector: 'wtls-match',
  templateUrl: './match.component.html',
  styleUrls: ['./match.component.scss']
})
export class MatchComponent implements OnInit, OnDestroy {
  match: Match;
  matchprovider_details: Match;
   //filterData: FiltersState;
    filterSet 
    role = [];
    Canselected_day ='';
    // days = [
    //   { Mon : 'weekday_mon'}, { Tue : 'weekday_tue'}, { Wed : 'weekday_wed'}, { Thu : 'weekday_thu'}, { Fri : 'weekday_fri'},
    // ]
    days = JSON.parse(JSON.stringify({
      Mon : 'weekday_mon', Tue : 'weekday_tue', Wed : 'weekday_wed', Thu : 'weekday_thu', Fri : 'weekday_fri', Sat: 'weekday_sat', Sun: 'weekday_sun' 
    }))
    Allselected;
  @Input() clinicLocationOptions: string[];
  @Input() filterData: FiltersState;
  @Output() filtersChange = new EventEmitter<FiltersState>();
  
  stateOptions = LIST_OF_US_STATES_ABBREVIATIONS;
  filtersState: FiltersState;
  availability$: Observable<TimeSlot[]>;
  timeFilter = ['6:00 AM','11:30 PM']
  timeSlots = ['12:30 AM','01:00 AM','01:30 AM','02:00 AM','02:30 AM','03:00 AM','03:30 AM','04:00 AM','04:30 AM','05:00 AM','05:30 AM',
  '06:00 AM','06:30 AM','07:00 AM','07:30 AM','08:00 AM','08:30 AM','09:00 AM','09:30 AM','10:00 AM','10:30 AM','11:00 AM','11:30 AM','12:00 PM','12:30 PM',
  '01:00 PM','01:30 PM','02:00 PM','02:30 PM','03:00 PM','03:30 PM','04:00 PM','04:30 PM','05:00 PM','05:30 PM','06:00 PM','06:30 PM','07:00 PM','07:30 PM',
  '08:00 PM','08:30 PM','09:00 PM','09:30 PM','10:00 PM','10:30 PM','11:00 PM','11:30 PM']
  loading$: Observable<boolean>;
  subscriptions: Subscription = new Subscription();
  current_day = ''
  Ischecked=true;
  

  constructor(
    private matchFacade: MatchFacade,
    private matchesFacade: MatchesFacade,
    private filtersFacade: FiltersFacade,
    private Matche_service: MatchesService,
    private patientsFacade: PatientsFacade,
    private providersFacade: ProvidersFacade,
    private dialog: MatDialog,
    
  ) { 
      const sub1 = matchFacade.getMatch().subscribe((match) => {
        this.match = match;
        
      });

      this.availability$ = matchFacade.getAvailability();

      this.loading$ = matchesFacade.getLoading();

      this.subscriptions.add(sub1);

      
     
    }

  ngOnInit(): void {  
    this.filterSet = new Set()
    const sub1 = this.filtersFacade.getFilters().subscribe((filters) => {
      this.filtersState = lodash.cloneDeep(filters);
     
      //window.addEventListener('load', (event) => {
        let day ="";
    
      switch (new Date().getDay()) {
        case 0:
          day = "Sun";
          this.filtersState['day']=day;
          this.filtersState['startTime'] ='12.00 AM';
          this.filtersState['endTime'] ='11.30 PM';
          var slides = document.getElementById("weekday_sun").setAttribute('checked', 'true');
          break;
        case 1:
          day = "Mon";
          this.filtersState['day']=day;
          this.filtersState['startTime'] ='12.00 AM';
          this.filtersState['endTime'] ='11.30 PM';
          var slides = document.getElementById("weekday_mon").setAttribute('checked', 'true');
          break;
        case 2:
          day = "Tue";
          this.filtersState['day'] =day;
          this.filtersState['startTime'] ='12.00 AM';
          this.filtersState['endTime'] ='11.30 PM';
          var slides = document.getElementById("weekday_tue").setAttribute('checked', 'true');
         
          break;
        case 3:
          day = "Wed";
          this.filtersState['day'] =day;
          this.filtersState['startTime'] ='12.00 AM';
          this.filtersState['endTime'] ='11.30 PM';
          var slides = document.getElementById("weekday_wed").setAttribute('checked', 'true');
          break;
        case 4:
          day = "Thu";
          this.filtersState['day'] =day;
          this.filtersState['startTime'] ='12.00 AM';
          this.filtersState['endTime'] ='11.30 PM';
          var slides = document.getElementById("weekday_thu").setAttribute('checked', 'true');
          break;
        case 5:
          day = "Fri";
          this.filtersState['day'] =day;
          this.filtersState['startTime'] ='12.00 AM';
          this.filtersState['endTime'] ='11.30 PM';
          var slides = document.getElementById("weekday_fri").setAttribute('checked', 'true');
          break;
        case 6:
          day = "Sat";
          this.filtersState['day'] =day;
          this.filtersState['startTime'] ='12.00 AM';
          this.filtersState['endTime'] ='11.30 PM';
          var slides = document.getElementById("weekday_sat").setAttribute('checked', 'true');
          break;
        // case 6:
        //   day = "Sat";
      }
      console.log("current day=>",[day]);
      this.Canselected_day =  this.filtersState['day'] =day;
      //this.role.push(this.Canselected_day)
      //if(this.Canselected_day)
      if(day != "")
      this.filterSet.add(this.Canselected_day)
      this.filtersState['startTime'] = this.timeFilter[0]
      this.filtersState['endTime'] = this.timeFilter[1]
    //});
    });

   
    window.addEventListener('load', (event) => {
      this.clearSelection()
    });

  }

  ngOnDestroy(): void {
  this.subscriptions.unsubscribe();
    
  }

  




  assignSelected(){
    console.log('filter',this.filtersState['day'])
    console.log('filter',this.filtersState['endTime'])
    console.log('filter',this.filtersState['startTime'])
    this.filtersFacade.updateUnassignedFilters(this.filtersState);
   
    this.patientsFacade.loadPatients();
    this.providersFacade.loadProviders();
    

    // this.filtersState['day']='Mon';
    // this.filtersState['startTime'];
    // this.filtersState['endTime']; 
    
    // console.log("testing datas=>",this.filtersState);
    
     }
        
        
  

//updateFilters(): void {
  //console.log(this.filterData);
  //console.log();
 // console.log();
 //this.filterData[] = event.target.value;
 // console.log("this.filterData123 =>",event.target.value);

 // this.filtersChange.emit(this.filterData);
//}
//updateFilters(event): void {
//  console.log(field);
 // this.filterData = event.target.value;
//  console.log("this.filterData =>",this.filterData)
//  this.filtersChange.emit(this.filterData);
//} 

updateDateFilters(event, field): void {
  this.filtersState[field] = event.target.value 
}

updateFilters(event,field): void {
  
  console.log('event - field',event,field)
  console.log("TESTDA",event.target.value)
  
  //this.filtersState[field] = event.target.value;
  //this.role.add(event.target.value);
  if(this.filterSet.has(event.target.value)){
    this.filterSet.delete(event.target.value)
  }
  else{
    this.filterSet.add(event.target.value)
  }
  //this.role.toString();
  //this.Canselected_day;
  //this.role.add(this.Canselected_day);
  //this.role.push(this.Canselected_day); 
  this.role = [...this.filterSet]
  this.filtersState[field] =this.role; 
  this.Allselected = this.filtersState[field];
  console.log("final state => ",this.filtersState[field]);
  
}

  clearSelection(): void {
    let todayChecked = true
  this.matchFacade.resetMatch();
  this.Allselected;
  console.log('filter_state', this.filtersState['day'])
  if(this.filtersState['day'] != this.Canselected_day){
  lodash.forEach(this.filtersState['day'], element => {
    console.log('element',element)
    let id = this.days[element]
    document.getElementById(id).click()
    if(element == this.Canselected_day)
      todayChecked = false
      
  });
  console.log('type', typeof this.Canselected_day)
  if(!todayChecked)
    document.getElementById(this.days[this.Canselected_day]).click()
  this.filtersState['day'] = this.Canselected_day
  }
  this.filtersState['startTime'] = this.timeFilter[0]
  this.filtersState['endTime'] = this.timeFilter[1]
  // console.log(document.getElementById("start"))
  // console.log(document.getElementById("end"))
  // console.log('ST',this.filtersState['startTime'])
  // console.log('ET',this.filtersState['endTime'])
  // document.getElementById("start").setAttribute('selected', 'false');
  // document.getElementById("end").setAttribute('selected', 'false');
  // console.log(document.getElementById("start"))
  // console.log(document.getElementById("end"))
  // document.getElementById("weekday_fri").setAttribute('checked', 'true');
  //document.getElementById("weekday_mon").setAttribute('checked', 'true');
  //console.log('current_day',current_day)
  //document.getElementById(current_day).setAttribute('checked', 'true');
  this.assignSelected() 

  }
  

  changeSchedule(timeSlot: TimeSlot): void {
    this.matchFacade.changeTimeSlot(timeSlot);
  }
}
