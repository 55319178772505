import { createReducer, on, Action } from '@ngrx/store';
import { Match } from 'src/app/models';
import { MatchesActions } from '../actions';

export interface MatchesState {
  list: Match[];
  loading: boolean;
  error: string;
}

const initialState: MatchesState = {
  list: [],
  loading: false,
  error: '',
};

const reducer = createReducer(
  initialState,
  on(MatchesActions.loadMatches, (state) => ({
    ...state,
    loading: true,
  })),
  on(MatchesActions.loadMatchesSuccess, (state, { matches }) => ({
    ...state,
    list: matches,
    loading: false,
  })),
  on(MatchesActions.loadMatchesError, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
  on(MatchesActions.assignMatch, (state) => ({
    ...state,
    loading: true,
  })),
  on(MatchesActions.assignMatchSuccess, (state) => ({
    ...state,
    loading: false,
  })),
  on(MatchesActions.assignMatchError, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(MatchesActions.unassignMatch, (state, { matchId }) => ({
    ...state,
    loading: true,
  })),
  on(MatchesActions.unassignMatchSuccess, (state) => ({
    ...state,
    loading: false,
  })),
  on(MatchesActions.unassignMatchError, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(MatchesActions.exportCsv, (state) => ({ ...state, loading: true })),
  on(MatchesActions.exportCsvSuccess, (state) => ({
    ...state,
    loading: false,
  })),
  on(MatchesActions.exportCsvError, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(MatchesActions.clearMatches, (state) => ({ ...state, list: [] })),
  on(MatchesActions.clearError, (state) => ({ ...state, error: '' }))
);

export function matchesReducer(state: MatchesState, action: Action) {
  return reducer(state, action);
}
