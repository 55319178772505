import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { Match } from 'src/app/models';


export const selectMatch = (state: AppState) => state.match;

export const selectMatchPatient = (state: AppState) => state.match.patient;

export const selectMatchProvider = (state: AppState) => state.match.provider;

export const selectMatchSchedule = (state: AppState) => state.match.schedule;