
<div class="page-container">
    <div class="content-container">
      
      <div class="container" >

       <!--  <div class="row"  *ngIf="showMsg">
            <div class="col-xs-12">
              <p class="alert alert-primary alert-dismissible">
                
                  <strong>Edited Success!</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
    
              </p>
            </div>
          </div> -->
     
        <form [formGroup]="form" (ngSubmit)="submitForm()" autocomplete="on"
        >
          <div class="header-section">
            <h2 class="title">Edit Account</h2>
            
          </div>
          <div *ngFor="let e of lis;" >
           
            <div class="row" >
              <div class="col-md-6" >
                <div class="form-group">
                      <label for="name"> Full Name  </label>
                      <input type="text" name="fullname" formControlName="fullname" class="form-control" placeholder="Full Name">
                      <div *ngIf="submitted && form.get('fullname').errors" class="invalid-feedback">
                        <span *ngIf="validate.fullname.errors.specialCharacters">Avoid using special characters</span>
                    </div>
                </div>
                
                <div class="form-group">
                      <label for="name"> Address </label>
                      <input type="text" formControlName="Address"  name="Address" class="form-control"  placeholder="Address">
                      <div *ngIf="submitted && validate.Address.errors" class="invalid-feedback">
                        <span *ngIf="validate.Address.errors.addressValidation">Avoid using special characters</span>
                    </div>
                    </div>
  
                    <div class="form-group">
                      <label for="name"> Password </label>
                      <input type="password" formControlName="password" name="password"  class="form-control"  placeholder="Password">
                      
                    </div>
               
                
              </div>
              <div class="col-md-6">
                <div class="form-group">
                      <label for="name"> Email </label>
                      <input type="text" formControlName="email" name="email"  class="form-control"    placeholder="Email">
                      <div *ngIf="submitted && validate.email.errors" class="invalid-feedback">
                        <span *ngIf="validate.email.errors.email">Enter a valid email</span>
                    </div>
                    </div>
                <div class="form-group">
                      <label for="name"> Contact No </label>
                      <input type="text" formControlName="contactno" name="contactno" class="form-control"    placeholder="Contact no">
                      <div *ngIf="submitted && validate.contactno.errors" class="invalid-feedback">
                        <span *ngIf="validate.contactno.errors.contactValidation">Enter a valid contact</span>
                    </div>
                    </div>
                    <div class="form-group">
                      <label for="name"> Confirm Password </label>
                      <input type="password" formControlName="conformpassword"   name="conformpassword" class="form-control"   placeholder="Confirm Password">
                     
                    </div>
            
              </div>
          <div class="footer-section">
            <hr />
            <button
              type="submit"
              class="button submit-button"
             
            >
              <span class="button-text">Submit Form</span>
            </button>
            <button
            type="button"
            (click)="cancelForm()"
            class="button cancel-button"
          >
            <span class="button-text">Cancel</span>
          </button>

        </div>
          </div></div> 
        </form>
       


       
      
      </div>
    </div>
  </div>
  