import { Highlightable } from '@angular/cdk/a11y';
import { Component, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { DropDownService } from '../drop-down.service';
import { SelectComponent } from '../select.component';

@Component({
  selector: 'wtls-select-option',
  templateUrl: './select-option.component.html',
  styleUrls: ['./select-option.component.scss']
})
export class SelectOptionComponent implements Highlightable {
  @Input()
  public key: string;
 
  @Input()
  public value: string;

  private select: SelectComponent;

  constructor(private dropDownService: DropDownService) { 
    this.select = dropDownService.getSelect();
  }

  @HostListener('click', ['$event'])
  public onClick(event: UIEvent) {
    event.preventDefault();
    event.stopPropagation();
 
    this.select.selectOption(this);
  }

  @HostBinding('class.active')
  public active = false;
 
  public getLabel(): string {
    return this.value;
  }
 
  public setActiveStyles(): void {
    this.active = true;
  }
 
  public setInactiveStyles(): void {
    this.active = false;
  }
}
