import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, AuthService } from 'src/app/services';
import { ProvidersService } from 'src/app/services';
import { MatchesService } from 'src/app/services/matches/matches.service';
import { HttpClient } from '@angular/common/http';
import { AuthFacade } from 'src/app/services/facades/auth.facade';
@Component({
  selector: 'wtls-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {
  email
  constructor(
    private router: Router,
    private providerservice: ProvidersService,
    private matcheservice: MatchesService,
    private http: HttpClient,
    private authService: AuthService,
    private apiService: ApiService,
    private authFacade: AuthFacade
    ) { 
      this.email = this.authFacade.getEmailToken()
    }
    li:any;
    lis=[];
    companyli:any;
    companylis=[];
    

  ngOnInit(): void {
     //this.http.get('http://localhost:4000/authmenu')
     /* this.apiService.get('/authmenu')
    .subscribe(Response => {
 
      // If response comes hideloader() function is called
      // to hide that loader,
      if(Response){ 
       // hideloader();
      }
      console.log("datatest=>",Response)
      this.li=Response;
      this.lis=this.li;
    });
     //this.http.get('http://localhost:4000/urlcompany')
     this.apiService.get('/urlcompany')
    .subscribe(Response => {
 
      // If response comes hideloader() function is called
      // to hide that loader,
      if(Response){ 
       // hideloader();
      }
      console.log("datatest=>",Response)
      this.companyli=Response;
      this.companylis=this.companyli;
    
    });  */
    
  }

  /* selecteddata(){
  
    this.http.get('http://localhost:4000/authmenu')
    .subscribe(Response => {
 
      // If response comes hideloader() function is called
      // to hide that loader,
      if(Response){ 
        //hideloader();
        console.log("edit=>",Response)
      }
   
      this.li=Response;
      this.lis=this.li;
      
    });
    
  } */

   clickme(){
     //this.providerservice.getappointment().subscribe();
  // this.matcheservice.getMatches().subscribe();
  //window.location.href = '/dashboard/unassigned';
  } 
  
  

  logout() {
    this.authService.logout();
    //let Formpostdata ='1';
   
    /* this.apiService.put('/loginlogs/',Formpostdata)
    .subscribe(   
      (response) =>{ 
        localStorage.removeItem('token');
        this.router.navigate(['/login'])
      },
      
      (error) => console.log(error)
      );  */
     
  }
}
