
<div class="page-container">
    <div class="content-container">
      
      <div class="container">

     
       

        <form  [formGroup]="form" (ngSubmit)="submitForm()"
        >
          <div class="header-section">
            <h2 class="title">Create Company</h2>
          </div>


            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                      <label for="name"> Company Name </label>
                      <input type="text" formControlName="comapny_name" name="comapny_name" [ngClass]="{ 'is-invalid': submitted && f.comapny_name.errors }"  class="form-control"   placeholder="Enter Company Name">
                      <div *ngIf="submitted && f.comapny_name.errors" class="invalid-feedback">
                        <div *ngIf="f.comapny_name.errors.required">Company Name is required</div>
                    </div>
                </div>
                
                <div class="form-group">
                      <label for="name"> Email </label>
                      <input type="text" formControlName="company_email"  name="company_email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.company_email.errors }"   placeholder="Email">
                      <div *ngIf="submitted && f.company_email.errors" class="invalid-feedback">
                        <div *ngIf="f.company_email.errors.required">Company Email is required</div>
                    </div>
                    </div>
  
  
                <div class="form-group">
                      <label for="name"> Address </label>
                      <input type="text" formControlName="company_address"  name="company_address" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.company_address.errors }"  placeholder="Address">
                      <div *ngIf="submitted && f.company_address.errors" class="invalid-feedback">
                        <div *ngIf="f.company_address.errors.required">Company Address is required</div>
                    </div>
                    </div>
               
                    <div class="form-group">
                      <label for="name"> Opening Time </label>
                      <select class="select-input form-control" formControlName="companay_opening_time" name="companay_opening_time" [ngClass]="{ 'is-invalid': submitted && f.companay_opening_time.errors }" >
                        <option value="" selected>Select Opening Time </option>
                        <option value="12.00 AM" selected="selected">12.00 AM</option>
                        <option value="12.30 AM" selected="selected">12.30 AM</option>
                        <option value="1.00 AM" selected="selected">1.00 AM</option>
                        <option value="1.30 AM" selected="selected">1.30 AM</option>
                        <option value="2.00 AM" selected="selected">2.00 AM</option>
                        <option value="2.30 AM" selected="selected">2.30 AM</option>
                        <option value="3.00 AM" selected="selected">3.00 AM</option>
                        <option value="3.30 AM" selected="selected">3.30 AM</option>
                        <option value="4.00 AM" selected="selected">4.00 AM</option>
                        <option value="4.30 AM" selected="selected">4.30 AM</option>
                        <option value="5.00 AM" selected="selected">5.00 AM</option>
                        <option value="5.30 AM" selected="selected">5.30 AM</option>
                        <option value="6.00 AM" selected="selected">6.00 AM</option>
                        <option value="6.30 AM" selected="selected">6.30 AM</option>
                        <option value="7.00 AM" selected="selected">7.00 AM</option>
                        <option value="7.30 AM" selected="selected">7.30 AM</option>
                        <option value="8.00 AM" selected="selected">8.00 AM</option>
                        <option value="8.30 AM" selected="selected">8.30 AM</option>
                        <option value="9.00 AM" selected="selected">9.00 AM</option>
                        <option value="9.30 AM" selected="selected">9.30 AM</option>
                        <option value="10.00 AM" selected="selected">10.00 AM</option>
                        <option value="10.30 AM" selected="selected">10.30 AM</option>
                        <option value="11.00 AM" selected="selected">11.00 AM</option>
                        <option value="11.30 AM" selected="selected">11.30 AM</option>
                        <option value="12.00 PM" selected="selected">12.00 PM</option>
                        <option value="12.30 PM" selected="selected">12.30 PM</option>
                        <option value="1.00 PM" selected="selected">1.00 PM</option>
                        <option value="1.30 PM" selected="selected">1.30 PM</option>
                        <option value="2.00 PM" selected="selected">2.00 PM</option>
                        <option value="2.30 PM" selected="selected">2.30 PM</option>
                        <option value="3.00 PM" selected="selected">3.00 PM</option>
                        <option value="3.30 PM" selected="selected">3.30 PM</option>
                        <option value="4.00 PM" selected="selected">4.00 PM</option>
                        <option value="4.30 PM" selected="selected">4.30 PM</option>
                        <option value="5.00 PM" selected="selected">5.00 PM</option>
                        <option value="5.30 PM" selected="selected">5.30 PM</option>
                        <option value="6.00 PM" selected="selected">6.00 PM</option>
                        <option value="6.30 PM" selected="selected">6.30 PM</option>
                        <option value="7.00 PM" selected="selected">7.00 PM</option>
                        <option value="7.30 PM" selected="selected">7.30 PM</option>
                        <option value="8.00 PM" selected="selected">8.00 PM</option>
                        <option value="8.30 PM" selected="selected">8.30 PM</option>
                        <option value="9.00 PM" selected="selected">9.00 PM</option>
                        <option value="9.30 PM" selected="selected">9.30 PM</option>
                        <option value="10.00 PM" selected="selected">10.00 PM</option>
                        <option value="10.30 PM" selected="selected">10.30 PM</option>
                        <option value="11.00 PM" selected="selected">11.00 PM</option>
                        <option value="11.30 PM" selected="selected">11.30 PM</option>
                        <option value="12.00 AM" selected="selected">12.00 AM</option>
                       </select>
                       <div *ngIf="submitted && f.companay_opening_time.errors" class="invalid-feedback">
                        <div *ngIf="f.companay_opening_time.errors.required">Company Opening Time is required</div>
                    </div>
                    </div>
                    <div class="form-group">
                      <label for="name"> Clinic Location </label>
                    
                      <select class="form-control" formControlName="clinic_location_id" name="clinic_location_id" [ngClass]="{ 'is-invalid': submitted && f.clinic_location_id.errors }">
                        <option value="" selected>Select Clinic Location </option>
                        <option *ngFor="let a of locationsselectlistorders;" value="{{ a.id }}" >{{a.location_name}}</option>
                      
                      </select>
                      <div *ngIf="submitted && f.clinic_location_id.errors" class="invalid-feedback">
                        <div *ngIf="f.clinic_location_id.errors.required">Company Clinic Location is required</div>
                    </div>
                    </div>
                    <div class="form-group">
                      <label for="name"> Client Form Subtitle </label>
                   
                      <input type="text" formControlName="form_sub_title" name="form_sub_title" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.form_sub_title.errors }"  placeholder="Client Form Subtitle">
                      <div *ngIf="submitted && f.form_sub_title.errors" class="invalid-feedback">
                        <div *ngIf="f.form_sub_title.errors.required"> Subtitle is required</div>
                    </div>
                    </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                      <label for="name"> Contact No </label>
                      <input type="text" formControlName="company_contactno" name="company_contactno" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.company_contactno.errors }"  placeholder="Contcat No">
                      <div *ngIf="submitted && f.company_contactno.errors" class="invalid-feedback">
                        <div *ngIf="f.company_contactno.errors.required">Contact No is required</div>
                    </div>
                    </div>
                <div class="form-group">
                      <label for="name"> Company Name Url </label>
                      <input type="text" formControlName="company_url" name="company_url" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.company_url.errors }"   placeholder="Company Name Of the Url">
                      <div *ngIf="submitted && f.company_url.errors" class="invalid-feedback">
                        <div *ngIf="f.company_url.errors.required">Company Url is required</div>
                    </div>
                    </div>
                <div class="form-group">
                      <label for="name" > Select Admin </label>
                    
                      <select class="form-control" formControlName="user_id" name="user_id" [ngClass]="{ 'is-invalid': submitted && f.user_id.errors }" >
                        <option value="" selected>Select Admin </option>
                        <option *ngFor="let a of selectlistorders;" value="{{ a.id }}" >{{a.fullname}}</option>
                       
                      </select>
                      <div *ngIf="submitted && f.user_id.errors" class="invalid-feedback">
                        <div *ngIf="f.user_id.errors.required">User is required</div>
                    </div>
                    </div>
            

                    <div class="form-group">
                      <label for="name"> Closing Time </label>
                      <select class="select-input form-control" formControlName="company_closing_time" name="company_closing_time" [ngClass]="{ 'is-invalid': submitted && f.company_closing_time.errors }" >
                        <option value="" selected>Select Closing Time </option>
                        <option value="12.00 AM" >12.00 AM</option>
                        <option value="12.30 AM" >12.30 AM</option>
                        <option value="1.00 AM" >1.00 AM</option>
                        <option value="1.30 AM" >1.30 AM</option>
                        <option value="2.00 AM" >2.00 AM</option>
                        <option value="2.30 AM" >2.30 AM</option>
                        <option value="3.00 AM" >3.00 AM</option>
                        <option value="3.30 AM" >3.30 AM</option>
                        <option value="4.00 AM" >4.00 AM</option>
                        <option value="4.30 AM" >4.30 AM</option>
                        <option value="5.00 AM" >5.00 AM</option>
                        <option value="5.30 AM" >5.30 AM</option>
                        <option value="6.00 AM" >6.00 AM</option>
                        <option value="6.30 AM" >6.30 AM</option>
                        <option value="7.00 AM" >7.00 AM</option>
                        <option value="7.30 AM" >7.30 AM</option>
                        <option value="8.00 AM" >8.00 AM</option>
                        <option value="8.30 AM" >8.30 AM</option>
                        <option value="9.00 AM" >9.00 AM</option>
                        <option value="9.30 AM" >9.30 AM</option>
                        <option value="10.00 AM" >10.00 AM</option>
                        <option value="10.30 AM" >10.30 AM</option>
                        <option value="11.00 AM" >11.00 AM</option>
                        <option value="11.30 AM" >11.30 AM</option>
                        <option value="12.00 PM" >12.00 PM</option>
                        <option value="12.30 PM" >12.30 PM</option>
                        <option value="1.00 PM" >1.00 PM</option>
                        <option value="1.30 PM" >1.30 PM</option>
                        <option value="2.00 PM" >2.00 PM</option>
                        <option value="2.30 PM" >2.30 PM</option>
                        <option value="3.00 PM" >3.00 PM</option>
                        <option value="3.30 PM" >3.30 PM</option>
                        <option value="4.00 PM" >4.00 PM</option>
                        <option value="4.30 PM" >4.30 PM</option>
                        <option value="5.00 PM" >5.00 PM</option>
                        <option value="5.30 PM" >5.30 PM</option>
                        <option value="6.00 PM" >6.00 PM</option>
                        <option value="6.30 PM" >6.30 PM</option>
                        <option value="7.00 PM" >7.00 PM</option>
                        <option value="7.30 PM" >7.30 PM</option>
                        <option value="8.00 PM" >8.00 PM</option>
                        <option value="8.30 PM" >8.30 PM</option>
                        <option value="9.00 PM" >9.00 PM</option>
                        <option value="9.30 PM" >9.30 PM</option>
                        <option value="10.00 PM" >10.00 PM</option>
                        <option value="10.30 PM" >10.30 PM</option>
                        <option value="11.00 PM" >11.00 PM</option>
                        <option value="11.30 PM" >11.30 PM</option>
                        <option value="12.00 AM" >12.00 AM</option>
                       </select>
                       <div *ngIf="submitted && f.company_closing_time.errors" class="invalid-feedback">
                        <div *ngIf="f.company_closing_time.errors.required">Closing Time is required</div>
                    </div>
                    </div>     
                    <div class="form-group">
                      <label for="name"> Client Form Title </label>
                     
                      <!-- <select class="form-control" formControlName="form_title_id" [ngClass]="{ 'is-invalid': submitted && f.form_title_id.errors }" name="form_title_id">
                        <option *ngFor="let a of locationsselectlistorders;" value="{{a.id}}" >{{a.subtitle}}</option>
                      </select> -->
                      <input type="text" formControlName="form_title" name="form_title" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.form_title.errors }"  placeholder="Client Form Title">
                      <div *ngIf="submitted && f.form_title.errors" class="invalid-feedback">
                        <div *ngIf="f.form_title.errors.required">Client Form Title is required</div>
                    </div>
                    </div>
              </div>
          <div class="footer-section">
            <hr />
            <button
              type="submit"
              class="button submit-button"
             
            >
              <span class="button-text">Submit Form</span>
            </button>
            <button
            type="button"
            (click)="cancelForm()"
            class="button cancel-button"
          >
            <span class="button-text">Cancel</span>
          </button>
        </div>
          </div>
        </form>


       
      
      </div>
    </div>
  </div>
  