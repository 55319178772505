import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EndPoints } from 'src/app/constants/endpoints';
import { FormResponse, Page } from 'src/app/models';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class NewClientsService {
  constructor(private api: ApiService) {}

  // newClients$ = new Observable().pipe(
  //   combineLatest([
  //     this.getNewClients()
  //   ])
  // )

  getNewClients(
    id: number,
    options?: {
      mergeFormId?: number;
      sortBy?: string;
      page?: number;
      size?: number;
      direction?: SortDirection;
    }
  ): Observable<Page<FormResponse>> {
    let params = new HttpParams();

    if (options) {
      if (options.mergeFormId)
        params = params.append('mergeFormId', `${options.mergeFormId}`);

      if (options.sortBy) params = params.append('sortBy', options.sortBy);

      if (options.page) params = params.append('page', `${options.page}`);

      if (options.size) params = params.append('size', `${options.size}`);

      if (options.direction)
        params = params.append('direction', options.direction);
    }

    return this.api
      .get<Page<FormResponse>>(
        EndPoints.NEW_CLIENTS.FORM + id + EndPoints.NEW_CLIENTS.GET,
        { params }
      )
      .pipe(
        map((page) => {
          console.log("page result ",page)
          let i: number;
          page.content.forEach((response, index) => {
            if (response.answers.length > 0) i = index;
          });

          if (i) {
            let content: any[] = []; // transformed answers

           
            

            page.content.forEach((resp) => {
              const response = resp;
              const columns: string[] = [];
              response.answers.forEach((answer) => {
                columns.push(answer.label);
              });
              if (response.answers.length > 0) {
                let row = {};

                columns.forEach((column) => {
                  let answer = resp.answers.find(
                    (answer) => answer.label == column
                  );
                  let data = {}
                  if (answer) {
                    // check answer type and convert
                    if (answer.type == 'date' && answer.value) {
                      answer.value = new Date(parseInt(answer.value));
                    }
                    data['value'] = answer.value;
                    data['id'] = answer.id;
                    data['type'] = answer.type;
                    // row[column] = answer.value+"||"+answer.id;
                  } else {
                    data['value'] = '';
                    data['id'] = '';
                    data['type'] = '';
                  }
                  row[column] = data;
                });

                content.push(row);
              }
            });

            const newPage: Page<any> = {
              content,
              totalElements: page.totalElements,
              totalPages: page.totalPages,
              page: page.page,
              size: page.size,
              offset: page.offset,
              hasPrevious: page.hasPrevious,
              hasNext: page.hasNext,
            };

            return newPage;
          }

          // const columns = ['First Name', 'Last Name', 'Birth Date', 'Gender', 'Diagnosis', 'Date of Diagnosis', 'Clinic Location', 'Message/Concerns', ''];

          // const row: any[] = [];

          // page.content.forEach((response) => {
          //   if (response.answers.length > 0) {
          //     row['First Name'] = response.answers.find()
          //   }
          // })
          console.log("page result1 ",page)
          return page;
        })
      );
  }

  exportCSV(id: number): Observable<string> {
    return this.api.getCSV(
      EndPoints.NEW_CLIENTS.FORM + id + EndPoints.NEW_CLIENTS.GET_CSV
    );
  }
  updateClient(form_data:Object) {
    return this.api.put<any>(EndPoints.NEW_CLIENTS.FORM, form_data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  } 
}
