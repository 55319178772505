import { createReducer, on, Action } from '@ngrx/store';
import { Patient } from "src/app/models";
import { PatientsActions } from '../actions';

export interface PatientsState {
    list: Patient[],
    loading: boolean,
    error: string
}

const initialState: PatientsState = {
    list: [],
    loading: false,
    error: ''
}

const reducer = createReducer(
    initialState,
    on(PatientsActions.loadPatients, (state) => ({ ...state, loading: true })),
    on(PatientsActions.loadPatientsSuccess, (state, { patients }) => ({ ...state, list: patients, loading: false })),
    on(PatientsActions.loadPatientsError, (state, { error }) => ({ ...state, loading: false, error })),
    on(PatientsActions.updatePatients, (state, { patients }) => ({ ...state, list: patients })),
    on(PatientsActions.clearPatients, (state) => ({ ...state, list: [] })),
    // on(PatientsActions.patientsGeocoding, (state, { patients }) => ({ ...state, loading: true })),
    // on(PatientsActions.patientsGeocodingSuccess, (state, { patients }) => (({ ...state, list: patients, loading: false }))),
    // on(PatientsActions.patientsGeocodingError, (state, { error }) => ({ ...state, loading: false, error }))
);

export function patientsReducer(state: PatientsState | undefined, action: Action) {
    return reducer(state, action);
}