import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { createEffect, Actions, ofType, act } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import { FormService } from 'src/app/services/form/form.service';
import { FormActions } from "../actions";

@Injectable({
  providedIn: "root",
})
export class FormEffects {
  loadForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormActions.loadForm),
      mergeMap(({ formID }) =>
        this.formService.getForm(formID).pipe(
          map((form) => FormActions.loadFormSuccess({ form })),
          catchError((error) => {
            return of(FormActions.loadFormError({ error }));
          })
        )
      )
    )
  );

  submitForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormActions.submitForm),
      mergeMap(({ formID, answers, recaptchaResponse }) =>
        this.formService.submitForm(formID, answers, recaptchaResponse).pipe(
          map((res) => FormActions.submitFormSuccess({ formID })),
          catchError((error) => of(FormActions.submitFormError({ error })))
        )
      )
    )
  );

  submitFormSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FormActions.submitFormSuccess),
        tap(({ formID }) => {
            let form: string;

            if (formID == 1) {
                form = 'intake'
            } else {
                form = 'referral'
            }

          this.router.navigate(["/form-complete"], {
            queryParams: { submitted: true, form },
          })
        }
        )
      ),
    {
      dispatch: false,
    }
  );

  constructor(
    private actions$: Actions,
    private formService: FormService,
    private router: Router
  ) {}
}
